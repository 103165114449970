<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    transition="dialog-bottom-transition"
  >
    <v-flex xs12>
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('parts.infoCar.applicability') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          v-if="$store.getters.role === 'LekoAdmin' && applicability"
          :loading="loadingDelete"
          outline
          color="white"
          class="mt-2  deleteApplicability"
          @click="deleteApplicability"
        >
          {{ $t('parts.infoCar.deleteApplicability') }}
        </v-btn>
        <v-btn
          outline
          icon
          large
          color="white"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
    </v-flex>

    <v-card>
      <!--CAR DETAILS-->
      <v-layout
        v-if="car"
        row
        wrap
        align-center
        justify-center
        pa-2
      >
        <v-icon
          color="grey darken-2"
          class="mr-2"
        >
          directions_car
        </v-icon>

        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.brand') }}</span>
            <span class="body-2">{{ car.brand.title }}</span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
            <span class="body-2">{{ car.model.fullDescription }}</span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
            <span class="body-2">{{ car.version.description }}</span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
            <span class="body-2">
              {{ car.version.startingDate | moment }}
              -
              {{ car.version.endingDate | moment }}
            </span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
            <span class="body-2">{{ car.version.numberOfDoors }}</span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
            <span class="body-2"> {{ car.version.power.kw }} kw </span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
            <span class="body-2">{{ car.version.engineCode }}</span>
          </v-layout>
        </v-flex>
        <v-flex>
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
            <span class="body-2">{{ getSupplyLocale(car.version.supplyCode) }}</span>
          </v-layout>
        </v-flex>
      </v-layout>
      <!--APPLICABILITY-->
      <v-card-actions>
        <v-row>
          <!--          <v-tabs v-model="brandTabs" bg-color="primary" class="pa-2">-->
          <!--            <v-tab v-for="(brand, index) in uniqueBrands" :key="index" @click="onBrandClick(brand, index)" :value="index" :active="brandTabs == index">-->
          <!--              {{ brand }}-->
          <!--            </v-tab>-->
          <!--          </v-tabs>-->
          <v-list
            dense
            class="pa-2"
          >
            <v-list-item
              v-for="(brand, index) in uniqueBrands"
              :key="index"
              :class="{ 'brand-button-selected': brandTabs === index }"
              @click="onBrandClick(brand, index)"
            >
              <v-btn
                text
                outline
                color="black"
                class="rounded-button"
              >
                {{ brand }}
              </v-btn>
            </v-list-item>
          </v-list>
          <v-row class="custom-tabs-row">
            <v-col
              v-for="(model, index) in uniqueModels"
              :key="index"
              class="pt-2 pb-2 pl-2"
            >
              <v-btn
                class="model-button"
                :class="{ active: modelTabs === index }"
                @click="onModelClick(model, index)"
              >
                {{ model }}
              </v-btn>
            </v-col>
          </v-row>
          <v-col cols="4">
            <v-tabs
              v-model="yearsTabs"
              class="pa-2"
              bg-color="primary"
            >
              <v-tab
                v-for="(year, index) in uniqueYears"
                :key="index"
                :value="index"
                :active="yearsTabs == index"
                @click="onYearClick(year, index)"
              >
                {{ year.startingDate | moment }} - {{ year.endingDate | moment }}
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-card-text>
        <v-layout column>
          <v-layout
            v-if="loading"
            justify-center
          >
            <v-progress-circular
              :size="75"
              color="red darken-2"
              indeterminate
            />
          </v-layout>
          <div v-if="applicability">
            <v-flex xs12>
              <v-data-table
                v-if="brandTabs !== null && modelTabs !== null && yearsTabs !== null"
                :headers="subheaders"
                :items="getFilteredVersions"
                class="inventoryDialogDatatable"
                hide-actions
              >
                <template
                  slot="items"
                  slot-scope="sprops"
                >
                  <tr :class="{ 'tableRowColor': sprops.index == 0 }">
                    <td class="text-xs">
                      {{ sprops.item.version }}
                    </td>
                    <td class="text-xs">
                      <span v-if="sprops.item.versionId">
                        {{ sprops.item.versionId.startingDate | moment }}&nbsp;-&nbsp;{{ sprops.item.versionId.endingDate | moment }}
                      </span>
                    </td>
                    <td class="text-xs">
                      <span v-if="sprops.item.versionId">{{ sprops.item.versionId.numberOfDoors }}&nbsp;{{ $t('parts.infoCar.doors') }}</span>
                    </td>
                    <td class="text-xs">
                      <span v-if="sprops.item.versionId">{{ sprops.item.versionId.power.kw }} kw</span>
                    </td>
                    <td class="text-xs">
                      <span v-if="sprops.item.versionId">{{ sprops.item.versionId.engineCode }}</span>
                    </td>
                    <td class="text-xs">
                      <span v-if="sprops.item.versionId">{{ getSupplyLocale(sprops.item.versionId.supplyCode) }}</span>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>


<script>
import moment from 'moment';
import getRequest from '../api/getRequest';
import deleteRequest from '../api/deleteRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const currentMonth = new Date().toISOString();
      return date ? moment(date).format('MM/YYYY') : moment(currentMonth).format('MM/YYYY');
    },
  },
  data: () => ({
    brandTabs: 0,
    modelTabs: 0,
    yearsTabs: 0,
    dialog: false,
    notifications: false,
    sound: true,
    expand: false,
    loading: false,
    loadingDelete: false,
    applicability: null,
    changeBrandIndex: 0,
    changeModelIndex: 0,
    car: null,

    headers: [
      { text: 'Brand', sortable: false, value: '' },
      { text: 'Model', sortable: false, value: '' },
      { text: 'Year', sortable: false, value: '' },
    ],
    subheaders: [
      { text: 'Version', sortable: false, value: '' },
      { text: 'Year', sortable: false, value: '' },
      { text: 'Body', sortable: false, value: '' },
      { text: 'Power', sortable: false, value: '' },
      { text: 'Engine', sortable: false, value: '' },
      { text: 'Fuel', sortable: false, value: '' },
    ],
    part: {},
  }),
  computed: {
    getFilteredVersions() {
      if (this.applicability === null || this.uniqueYears[this.yearsTabs] === undefined) {
        return [];
      }

      const brandTabs = this.uniqueBrands[this.brandTabs];
      const modelTabs = this.uniqueModels[this.modelTabs];

      const filteredData = this.applicability.filter(item => (
        item.brand === brandTabs &&
            item.modelId.description === modelTabs
      ));

      const selectedYear = this.uniqueYears[this.yearsTabs];
      if (!selectedYear || selectedYear.startingDate === undefined || selectedYear.endingDate === undefined) {
        return [];
      }

      // Filter further by the selected year
      const filteredByYear = filteredData.filter(item => (
        item.modelId.startingDate === selectedYear.startingDate &&
            item.modelId.endingDate === selectedYear.endingDate
      ));

      // Extract versions for the selected year
      const versions = filteredByYear.flatMap(item => item.versions);

      return versions;
    },

    uniqueYears() {
      if (this.brandTabs !== null && this.modelTabs !== null) {
        if (this.applicability === null) {
          return [];
        }
        const brandTabs = this.uniqueBrands[this.brandTabs];
        const modelTabs = this.uniqueModels[this.modelTabs];

        // Filter the applicability data for the selected brand and model
        const filteredData = this.applicability.filter(item => (
          item.brand === brandTabs &&
              item.modelId.description === modelTabs
        ));

        // Extract and format unique starting and ending dates for years
        const years = [
          ...new Set(filteredData.map(item => ({
            startingDate: item.modelId.startingDate,
            endingDate: item.modelId.endingDate,
          }))),
        ];

        return years;
      }
      return [];
    },


    uniqueBrands() {
      // Check if applicability is null before using map
      if (this.applicability === null) {
        return [];
      }

      return [...new Set(this.applicability.map(item => item.brand))];
    },
    uniqueModels() {
      if (this.brandTabs !== null) {
        // Check if applicability is null before filtering
        if (this.applicability === null) {
          return [];
        }
        const brandTabs = this.uniqueBrands[this.brandTabs];
        const brandModels = this.applicability
          .filter(item => item.brand === brandTabs)
          .map(item => item.modelId.description); // Assuming 'model' is the key for models
        return [...new Set(brandModels)];
      }
      return [];
    },
    getFilteredApplicability() {
      if (this.brandTabs !== null && this.modelTabs !== null) {
        if (this.applicability === null) {
          return [];
        }
        const brandTabs = this.uniqueBrands[this.brandTabs];
        const modelTabs = this.uniqueModels[this.modelTabs];
        return this.applicability.filter(item =>
          item.brand === brandTabs && item.model === modelTabs);
      }
      return this.applicability || [];
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.close();
      }
    },

  },
  created() {
    this.getLocale();
  },
  methods: {
    onYearClick(index) {
      if (index < this.uniqueYears.length) {
        this.yearsTabs = index;
      }
    },

    onModelClick(model, index) {
      if (index < this.uniqueModels.length) {
        this.modelTabs = index;
      }
      this.yearsTabs = 0;
    },
    onBrandClick(brand, index) {
      this.brandTabs = index;
      this.modelTabs = 0;
      this.yearsTabs = 0;
    },
    open(data) {
      this.dialog = true;
      if (data.infoCar && data.infoCar._id) this.getInfoCarDetails(data.infoCar._id);
      if (data.car_id) this.getCarDetails(data.car_id);
      this.part = data;
      // Reset tabs to the first options when opening a new applicability
      this.brandTabs = 0;
      this.modelTabs = 0;
      this.yearsTabs = 0;
    },
    getInfoCarDetails(id) {
      this.loading = true;
      getRequest(`/info-car/id/${id}`)
        .then((response) => {
          if (response && response.applicability) this.applicability = response.applicability;
        })
        .catch(error => console.log(error))
        .finally(() => { this.loading = false; });
    },
    getCarDetails(id) {
      this.loading = true;
      getRequest('/car/', `id=${id}`)
        .then((response) => { this.car = response.car; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    close() {
      this.dialog = false;
      this.applicability = null;
      this.loading = false;
      this.loadingDelete = false;
      this.part = {};
      this.car = null;
      this.$emit('close');
    },
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.infoCar.brand');
      this.headers[1].text = this.$i18n.t('parts.infoCar.model');
      this.headers[2].text = this.$i18n.t('parts.infoCar.year');
      this.subheaders[0].text = this.$i18n.t('parts.infoCar.version');
      this.subheaders[1].text = this.$i18n.t('parts.infoCar.year');
      this.subheaders[2].text = this.$i18n.t('parts.infoCar.body');
      this.subheaders[3].text = this.$i18n.t('parts.infoCar.power');
      this.subheaders[4].text = this.$i18n.t('parts.infoCar.engine');
      this.subheaders[5].text = this.$i18n.t('parts.infoCar.supply.title');
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.infoCar.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.infoCar.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.infoCar.supply.gas');
        default:
          return supply;
      }
    },

    deleteApplicability() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loadingDelete = true;
        deleteRequest('/part/applicability', `id=${this.part._id}`)
          .then((response) => {
            console.log(response);
            this.$emit('delete-applicability');
            this.close();
          })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loadingDelete = false; });
      }
    },
  },
};
</script>


<style scoped>
.custom-tabs-row {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  box-shadow: none!important;
}

.model-button {
  height: 48px;
  background-color: white !important;
  background: white !important;
  border-radius: 0;
  font-weight: 500;
  border: none;
  color: #333;
  text-transform: none;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: none !important;
  opacity: 0.7;
}

.model-button.active {
  background-color: white !important;
  color: rgba(0,0,0,0.87);
  border-bottom: 2px solid #42A5F5;
  box-shadow: none!important;
  opacity: 1;
}


.v-btn--active:before, .v-btn:hover:before, .v-btn:focus:before{
  background-color: white !important;
}
@media (min-width: 960px) {
  .deleteApplicability {
    margin-right: 38px;
  }
}
</style>
