<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t('cities.form.edit_city') }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t('cities.form.create_city') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-select
                  v-model="city.state_id"
                  :items="states"
                  :loading="loadingStates"
                  :label="$t('cities.form.select_state')"
                  color="red darken-1"
                  item-text="name"
                  item-value="_id"
                  return-object
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="city.name"
                  :rules="rules.requiredLetters"
                  :label="$t('cities.form.name')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="city.lat"
                  :rules="rules.requiredNumbers"
                  :label="$t('cities.form.lat')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="city.long"
                  :rules="rules.requiredNumbers"
                  :label="$t('cities.form.long')"
                  color="red darken-1"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('cities.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('cities.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    dialog: false,
    edit: null,
    loading: false,
    loadingStates: false,
    valid: false,
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
    },
    states: [],
    city: {},
  }),
  methods: {
    open(edit, data) {
      this.edit = edit;
      if (data) this.city = data;
      this.dialog = true;
      this.getStates();
    },
    close() {
      this.edit = null;
      this.dialog = false;
      this.city = {};
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.edit === true) {
          putRequest('/city/', `id=${this.city._id}`, this.city).then((response) => {
            console.log(response);
            this.close();
            this.$emit('save');
          }).catch((error) => {
            console.log(error);
            this.close();
            this.$emit('error');
          });
        } else {
          postRequest.object('/city/', this.city).then((response) => {
            console.log(response);
            this.close();
            this.$emit('save');
          }).catch((error) => {
            console.log(error);
            this.close();
            this.$emit('error');
          });
        }
      }
    },
    getStates() {
      this.loadingStates = true;
      getRequest('/state/').then((response) => {
        console.log(response);
        this.states = response.states;
        this.loadingStates = false;
      }).catch((error) => {
        console.log(error);
        this.loadingStates = false;
      });
    },
  },
};
</script>
