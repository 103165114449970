<template>
  <v-data-table
    :headers="headers"
    :items="cities"
    :search="searchField"
    :loading="loading"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          <span v-if="props.item.state"> {{ props.item.state.name }} </span>
        </td>
        <td class="text-xs">
          {{ props.item.name }}
        </td>
        <td class="text-xs">
          {{ props.item.lat }}
        </td>
        <td class="text-xs">
          {{ props.item.long }}
        </td>
        <td
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          class="justify-center"
        >
          <v-layout row>
            <v-icon
              class="mr-2 pointer"
              @click="editCity(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              class="pointer"
              @click="deleteCity(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  data: () => ({
    loading: false,
    searchField: '',
    valid: false,
    headers: [{
      text: '',
      sortable: false,
      value: 'state',
    },
    {
      text: '',
      sortable: false,
      value: 'name',
    },
    {
      text: '',
      sortable: false,
      value: 'lat',
    },
    {
      text: '',
      sortable: false,
      value: 'long',
    },
    {
      text: '',
      sortable: false,
    }],
    cities: [],
    activeRow: null,
  }),
  created() {
    this.getLocale();
    this.getCities();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('cities.table.state');
      this.headers[1].text = this.$i18n.t('cities.table.name');
      this.headers[2].text = this.$i18n.t('cities.table.lat');
      this.headers[3].text = this.$i18n.t('cities.table.long');
      this.headers[4].text = this.$i18n.t('cities.table.actions');
    },
    search(value) {
      this.searchField = value;
    },
    getCities() {
      this.loading = true;
      getRequest('/city/').then((response) => {
        console.log(response);
        this.cities = response.cities;
        this.loading = false;
      })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    editCity(city) {
      this.$emit('edit', city);
    },
    deleteCity(cityId) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/city/', `id=${cityId}`).then((response) => {
          if (response.status === 200) {
            this.getCities();
          }
        });
      }
    },
  },
};
</script>
