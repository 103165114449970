<template>
  <div
    id="swagger"
    class="swagger"
  />
</template>

<script>
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

const spec = require('../../public/spec.json');

export default {
  mounted() {
    SwaggerUI({
      spec,
      dom_id: '#swagger',
    });
  },
};
</script>
