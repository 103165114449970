<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="600"
  >
    <v-card
      flat
      tile
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <v-flex xs12>
          <v-layout align-center>
            <v-spacer />
            <v-btn
              class="ma-2"
              outline
              icon
              large
              color="red"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex
          xs12
          class="ma-3"
        >
          {{ message }}
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    message: null,
  }),
  methods: {
    open(message) {
      this.dialog = true;
      this.message = message;
    },
    close() {
      this.dialog = false;
      this.message = null;
    },
  },
};
</script>
