<template>
  <div>
    <v-dialog
      v-model="loading"
      style="height: 100vh; width: 100vw;"
    >
      <v-card
        outlined
        color="transparent"
        class="col-12 justify-center pt-10 text-center"
        transparent
        style="height: 90vh; width: 95vw;"
      >
        <v-progress-circular
          color="#D32F2F"
          indeterminate
          size="100"
          style="margin-top: 35vh"
        />
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="alert.value"
      max-width="500"
    >
      <v-alert
        v-model="alert.value"
        :type="alert.type"
      >
        {{ $t(alert.message) }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="addGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_add_google') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptAddGoogle()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelAddGoogle()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_delete_google') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptDeleteGoogle()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelDeleteGoogle()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="successAddGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="success"
        >
          <strong> {{ $t('parts.inventory.success_google') }}</strong>
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="successAddGoogle()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="errorGoogleModal"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <v-alert
          :value="true"
          type="error"
        >
          <strong> {{ errorMsg ? errorMsg : $t('parts.inventory.error_google') }}</strong>
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="errorGoogle()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alreadyOnGoogleModal"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in onGoogle"
          :key="index"
          :value="true"
          type="warning"
        >
          <strong>
            {{ item.infoCar && item.infoCar.description && item.infoCar.description.descriptionId && item.infoCar.description.descriptionId.title ? item.infoCar.description.descriptionId.title.it : '' }}
          </strong>
          {{ $t('parts.inventory.already_on_google') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeAlreadyGoogle()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="googleMultipleDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in googleResponse"
          :key="index"
          :value="true"
          :type="item.errorMsg ? 'error' : 'success'"
        >
          <strong>
            {{ item.lekoItem }}
          </strong>
          {{ item.errorMsg ? item.errorMsg : $t('parts.inventory.success_google') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="closeGoogleMultiple()"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="googleRemoveMultipleDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in googleResponse"
          :key="index"
          :value="true"
          :type="item.errorMsg ? 'error' : 'success'"
        >
          <strong>
            {{ item.lekoItem }}
          </strong>
          {{ item.errorMsg ? item.errorMsg : $t('parts.inventory.success_deleted_google') }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="googleRemoveMultipleDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="acceptAddMultipleGoogleDialog"
      persistent
      width="400"
    >
      <v-card class="pa-3">
        <strong> {{ $t('parts.inventory.want_to_add_multiple_google') }}</strong>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="acceptAddMultipleGoogle()"
          >
            {{ $t('parts.inventory.yes') }}
          </v-btn>
          <v-btn
            small
            @click="cancelGoogleMultiple()"
          >
            {{ $t('parts.inventory.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import getRequest from '../../api/getRequest';


export default {
  components: {
  },
  data: () => ({
    carData: null,
    xmlData: null,
    successAddGoogleModal: false,
    errorGoogleModal: false,
    addGoogleModal: false,
    deleteGoogleModal: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    alreadyOnGoogleModal: false,
    onGoogle: [],
    googleResponse: null,
    googleMultipleDialog: false,
    loading: false,
    multipleItems: [],
    acceptAddMultipleGoogleDialog: false,
    googleRemoveMultipleDialog: false,
    errorMsg: null,
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
    googleItem: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    errorEbay() {
      this.errorGoogleModal = false;
    },
    successAddEbay() {
      this.successAddGoogleModal = false;
      this.close();
    },
    acceptDeleteGoogle() {
      // TBD
    },
    cancelDeleteGoogle() {
      this.deleteGoogleModal = false;
    },
    acceptAddGoogle(item) {
      // TBD
      this.addGoogleModal = false;
    },
    cancelAddGoogle() {
      this.addGoogleModal = false;
    },
    openAddGoogle(item) {
      // TBD
      this.addGoogleModal = true;
    },
    async getCarData(id) {
      await getRequest('/car/', `id=${id}`)
        .then((response) => { this.carData = response.car; })
        .catch((error) => { console.log(error); });
    },
    openDeleteGoogle(item) {
      this.googleItem = item;
      this.deleteGoogleModal = true;
    },
    addMultipleGoogleItems(items) {
      console.log('items selected', items.length);
      // TBD
    },
    deleteMultipleGoogleItems(items) {
      // TBD
    },
    acceptAddMultipleGoogle() { 
    //  TBD
    },
    cancelGoogleMultiple() {
      this.acceptAddMultipleGoogleDialog = false;
      this.googleResponse = null;
      this.multipleItems = [];
    },
    closeAlreadyGoogle() {
      this.alreadyOnGoogleModal = false;
      this.onEbay = [];
    },
    closeGoogleMultiple() {
      this.googleMultipleDialog = false;
      this.googleResponse = null;
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
    showActionsErrorModal(msg) {
      this.errorGoogleModal = true;
      this.errorMsg = msg;
    },
  },
};
</script>
