<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="red"
          class="mt-2 mr-2"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-layout column>
          <div
            v-if="kromedaData && kromedaData.codeInfo"
            class="mb-2"
          >
            <v-flex xs12>
              <v-toolbar
                flat
                dark
              >
                <v-toolbar-title>Code Info</v-toolbar-title>
              </v-toolbar>
            </v-flex>
            <v-flex xs12>
              <v-data-table
                :headers="headersCodeInfo"
                :items="kromedaData.codeInfo.data"
                hide-actions
                class="flat inventoryDialogDatatable"
              >
                <template
                  slot="items"
                  slot-scope="props"
                >
                  <tr class="inventoryDialogDatatableRow white--text">
                    <td class="text-xs">
                      {{ props.item.CodiceListino }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Tipo }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Listino }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.CodiceArticolo }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.S }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Descrizione }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.F }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.CS }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Foto }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.N }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.V }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.P }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </div>
          <div
            v-if="kromedaData && kromedaData.applicability"
            class="mt-2 mb-2"
          >
            <v-flex xs12>
              <v-toolbar
                flat
                dark
              >
                <v-toolbar-title>{{ $t('inventory.kromeda.applicability') }}</v-toolbar-title>
              </v-toolbar>
            </v-flex>
            <v-flex xs12>
              <v-data-table
                :headers="headersApplicability"
                :items="kromedaData.applicability.data"
                :expand="expand"
                item-key="idModello"
                hide-actions
                class="flat inventoryDialogDatatable"
              >
                <template
                  slot="items"
                  slot-scope="props"
                >
                  <tr
                    class="inventoryDialogDatatableRow white--text"
                    @click="props.expanded = !props.expanded"
                  >
                    <td class="text-xs">
                      {{ props.item.idMarca }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.idModello }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Marca }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Modello }}
                    </td>
                    <td class="text-xs">
                      {{ props.item.Anno }}
                    </td>
                  </tr>
                </template>
                <template
                  slot="expand"
                  slot-scope="props"
                >
                  <!--
                  <v-card flat>
                    <v-card-text>Peek-a-boo!</v-card-text>
                  </v-card>
                  -->
                  <v-data-table
                    :headers="expandedApplicabilityHeaders"
                    :items="props.item.versioni"
                    hide-actions
                    class="inventoryDialogDatatableExpand"
                  >
                    <template
                      slot="items"
                      slot-scope="props"
                    >
                      <tr class="tableRowColor">
                        <td class="text-xs">
                          {{ props.item.idVeicolo }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Versione }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.ModelloCodice }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Dal }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Al }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Cm3 }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Kw }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Motore }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Body }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Alimentazione }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Valvole }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Cilindri }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.PorteComm }}
                        </td>
                        <td class="text-xs">
                          {{ props.item.Status }}
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-data-table>
            </v-flex>
          </div>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    expand: false,
    kromedaData: {},
    otherInformation: [],
    headersCodeInfo: [
      {
        text: 'CodiceListino',
        sortable: false,
      },
      {
        text: 'Tipo',
        sortable: false,
      },
      {
        text: 'Listino',
        sortable: false,
      },
      {
        text: 'CodiceArticolo',
        sortable: false,
      },
      {
        text: 'S',
        sortable: false,
      },
      {
        text: 'Descrizione',
        sortable: false,
      },
      {
        text: 'F',
        sortable: false,
      },
      {
        text: 'CS',
        sortable: false,
      },
      {
        text: 'Foto',
        sortable: false,
      },
      {
        text: 'N',
        sortable: false,
      },
      {
        text: 'V',
        sortable: false,
      },
      {
        text: 'P',
        sortable: false,
      }],
    headersApplicability: [
      {
        text: 'ID Marca',
        sortable: false,
        value: 'idMarca',
      },
      {
        text: 'ID Modello',
        sortable: false,
        value: 'idModello',
      },
      {
        text: 'Marca',
        sortable: false,
        value: 'Marca',
      },
      {
        text: 'Modello',
        sortable: false,
        value: 'Modello',
      },
      {
        text: 'Anno',
        sortable: false,
        value: 'Anno',
      }],
    expandedApplicabilityHeaders: [
      {
        text: 'ID Veicolo',
        sortable: false,
        value: 'idVeicolo',
      },
      {
        text: 'Versione',
        sortable: false,
        value: 'Versione',
      },
      {
        text: 'Modello Codice',
        sortable: false,
        value: 'ModelloCodice',
      },
      {
        text: 'Dal',
        sortable: false,
        value: 'Dal',
      },
      {
        text: 'Al',
        sortable: false,
        value: 'Al',
      },
      {
        text: 'Cm3',
        sortable: false,
        value: 'Cm3',
      },
      {
        text: 'Kw',
        sortable: false,
        value: 'Kw',
      },
      {
        text: 'Motore',
        sortable: false,
        value: 'Motore',
      },
      {
        text: 'Body',
        sortable: false,
        value: 'Body',
      },
      {
        text: 'Alimentazione',
        sortable: false,
        value: 'Alimentazione',
      },
      {
        text: 'Valvole',
        sortable: false,
        value: 'Valvole',
      },
      {
        text: 'Cilindri',
        sortable: false,
        value: 'Cilindri',
      },
      {
        text: 'Porte Comm',
        sortable: false,
        value: 'ProteComm',
      },
      {
        text: 'Status',
        sortable: false,
        value: 'Status',
      }],


  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.kromedaData = {};
        this.$emit('close');
      }
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.kromedaData = data;
    },
    close() {
      this.dialog = false;
      this.kromedaData = {};
      this.$emit('close');
    },
  },
};
</script>
