<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
  >
    <v-card flat>
      <v-card-text>
        <v-layout
          row
          align-center
          justify-center
          wrap
        >
          <!-----------------------------------------ALERT----------------------------------------->
          <v-flex xs12>
            <v-alert
              :value="alert.value"
              type="warning"
              outline
            >
              <v-layout
                align-center
                justify-center
                row
                wrap
              >
                <v-flex
                  xs12
                  sm6
                >
                  {{ alert.message }}
                </v-flex>
                <v-flex
                  xs12
                  sm6
                >
                  <v-layout row>
                    <v-btn
                      outline
                      small
                      color="success"
                      @click="confirm = true, alert.value = false"
                    >
                      {{ $t('parts.ordered_parts.actions.alert.yes') }}
                    </v-btn>
                    <v-btn
                      outline
                      small
                      color="error"
                      @click="confirm = false, alert.value = false"
                    >
                      {{ $t('parts.ordered_parts.actions.alert.no') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-alert>
          </v-flex>
          <!-------------------------------------CONFIRM ORDER------------------------------------->
          <v-flex
            xs12
            sm6
            md3
          >
            <v-switch
              v-if="Object.keys(part).length > 0"
              v-model="confirmOrderSwitch"
              :disabled="part.order.id.status !== 0"
              :label="$t('parts.ordered_parts.actions.confirm_order')"
              color="success"
            />
          </v-flex>
          <!------------------------------------CONFIRM RETURN------------------------------------->
          <v-flex
            xs12
            sm6
            md3
          >
            <v-switch
              v-if="Object.keys(part).length > 0"
              v-model="confirmReturnSwitch"
              :disabled="part.order.id.status !== 5"
              :label="$t('parts.ordered_parts.actions.confirm_return')"
              color="orange"
            />
          </v-flex>
          <!-------------------------------------REFUSE RETURN------------------------------------->
          <v-flex
            xs12
            sm6
            md3
          >
            <v-switch
              v-if="Object.keys(part).length > 0"
              v-model="refuseReturnSwitch"
              :disabled="part.order.id.status !== 5"
              :label="$t('parts.ordered_parts.actions.refuse_return')"
              color="red"
            />
          </v-flex>
          <!-------------------------------------CANCEL ORDER-------------------------------------->
          <v-flex
            xs12
            sm6
            md3
          >
            <v-switch
              v-if="Object.keys(part).length > 0"
              v-model="cancelOrderSwitch"
              :disabled="part.order.id.status !== 0 && part.order.id.status !== 7"
              :label="$t('parts.ordered_parts.actions.cancel_order')"
              color="error"
            />
          </v-flex>
        </v-layout>
        <!----------------------------------------------------------------------------------------->
        <v-layout
          row
          align-center
          justify-center
        >
          <!-------------------------------------CONFIRM ORDER------------------------------------->
          <v-flex
            v-if="confirmOrderSwitch"
            xs12
          >
            <confirm-order
              ref="confirmOrder"
              :data="{ weight: part.weight, dimensions: part.dimensions }"
              :order_id="orderId"
              :part_id="partId"
              @confirmed="actionCompleted"
              @error="close"
            />
          </v-flex>
          <!-------------------------------------RETURN NOTES-------------------------------------->
          <v-flex
            v-if="Object.keys(part).length > 0 && part.order.id.status === 5"
            xs12
          >
            <v-layout
              align-center
              justify-center
            >
              <v-flex
                xs12
                sm10
              >
                <v-textarea
                  v-model="part.order.id.comment"
                  :disabled="!refuseReturnSwitch"
                  :label="$t('parts.ordered_parts.actions.return_notes')"
                  color="red"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!-------------------------------------CANCEL ORDER-------------------------------------->
          <v-flex v-if="Object.keys(part).length > 0 && cancelOrderSwitch">
            <v-layout
              align-center
              justify-center
              wrap
            >
              <v-flex
                xs12
                sm8
              >
                <v-select
                  v-model="destination"
                  :items="destinationItems"
                  :label="$t('parts.ordered_parts.actions.select_parts_destination')"
                  color="red"
                  item-text="title"
                  item-value="value"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="cancelComment"
                  :label="$t('parts.ordered_parts.actions.comment')"
                  color="red"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
        </v-layout>
      </v-card-text>
      <!------------------------------------------ACTIONS------------------------------------------>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          flat
          @click="close"
        >
          close
        </v-btn>
        <v-btn
          v-if="confirmOrderSwitch"
          :disabled="!confirm"
          :loading="loading"
          color="success"
          flat
          @click="confirmOrder"
        >
          {{ $t('parts.ordered_parts.actions.confirm') }}
        </v-btn>
        <v-btn
          v-if="confirmReturnSwitch"
          :disabled="!confirm"
          :loading="loading"
          color="orange"
          flat
          @click="confirmReturn"
        >
          {{ $t('parts.ordered_parts.actions.confirm') }}
        </v-btn>
        <v-btn
          v-if="refuseReturnSwitch"
          :disabled="!confirm"
          :loading="loading"
          color="red"
          flat
          @click="refuseReturn"
        >
          {{ $t('parts.ordered_parts.actions.refuse') }}
        </v-btn>
        <v-btn
          v-if="cancelOrderSwitch"
          :disabled="!confirm"
          :loading="loading"
          color="error"
          flat
          @click="cancelOrder"
        >
          {{ $t('parts.ordered_parts.actions.cancel_order') }}
        </v-btn>
      </v-card-actions>
      <!------------------------------------------------------------------------------------------->
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';
import ConfirmOrder from './confirmOrder.vue';

export default {
  components: {
    'confirm-order': ConfirmOrder,
  },
  data: () => ({
    dialog: false,
    loading: false,
    alert: {
      value: false,
      message: '',
    },
    confirm: false,
    confirmOrderSwitch: false,
    confirmReturnSwitch: false,
    refuseReturnSwitch: false,
    cancelOrderSwitch: false,
    destination: 'inventory',
    destinationItems: [
      { title: 'Inventory', value: 'inventory' },
      { title: 'Damaged Parts', value: 'damaged' },
      { title: 'Lost Parts', value: 'lost' },
    ],
    part: {},
    orderId: null,
    partId: null,
    cancelComment: '',
  }),
  watch: {
    confirmOrderSwitch(value) {
      if (value) {
        this.cancelOrderSwitch = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message =
          this.$i18n.t('parts.ordered_parts.actions.alert.confirm_order_message');
      } else if (!this.cancelOrderSwitch) {
        this.confirm = false;
        this.alert.value = false;
      }
    },
    cancelOrderSwitch(value) {
      if (value) {
        this.confirmOrderSwitch = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.ordered_parts.actions.alert.cancel_order_message');
      } else if (!this.confirmOrderSwitch) {
        this.confirm = false;
        this.alert.value = false;
      }
    },
    confirmReturnSwitch(value) {
      if (value) {
        this.refuseReturnSwitch = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message =
          this.$i18n.t('parts.ordered_parts.actions.alert.confirm_return_message');
      } else {
        this.confirm = false;
        if (!this.refuseReturnSwitch) {
          this.alert.value = false;
        }
      }
    },
    refuseReturnSwitch(value) {
      if (value) {
        this.confirmReturnSwitch = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message =
          this.$i18n.t('parts.ordered_parts.actions.alert.refuse_return_message');
      } else {
        this.confirm = false;
        if (!this.confirmReturnSwitch) {
          this.alert.value = false;
        }
      }
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.destinationItems[0].title =
        this.$i18n.t('parts.ordered_parts.actions.destination.inventory');
      this.destinationItems[1].title =
        this.$i18n.t('parts.ordered_parts.actions.destination.damaged_parts');
      this.destinationItems[2].title =
        this.$i18n.t('parts.ordered_parts.actions.destination.lost_parts');
    },
    open(data) {
      console.log(data);
      this.part = data;
      this.orderId = data.order.id._id;
      this.partId = data._id;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.confirmOrderSwitch = false;
      this.confirmReturnSwitch = false;
      this.cancelOrderSwitch = false;
      this.destination = 'inventory';
      this.part = {};
    },
    confirmOrder() {
      const { confirmOrder } = this.$refs;
      confirmOrder.validate((callback) => {
        if (callback) {
          this.loading = true;
        }
      });
    },
    confirmReturn() {
      this.loading = true;
      putRequest('/part/ordered/return', `order_id=${this.orderId}`)
        .then((response) => {
          console.log(response);
          this.actionCompleted();
        })
        .catch((error) => {
          console.log(error);
          this.close();
        });
    },
    refuseReturn() {
      this.loading = true;
      putRequest(
        '/part/ordered/refuse',
        `order_id=${this.orderId}`,
        { comment: this.part.order.id.comment },
      )
        .then((response) => {
          console.log(response);
          this.actionCompleted();
        })
        .catch((error) => {
          console.log(error);
          this.close();
        });
    },
    cancelOrder() {
      this.loading = true;
      putRequest(
        '/part/ordered/cancel',
        `order_id=${this.orderId}&part_id=${this.partId}&user_id=${
          this.$store.getters.userId}&destination=${this.destination}`,
        { comment: this.cancelComment },
      )
        .then((response) => {
          console.log(response);
          this.actionCompleted();
        })
        .catch((error) => {
          console.log(error);
          this.close();
        });
    },
    actionCompleted() {
      this.loading = false;
      this.$emit('action-completed');
      this.close();
    },
  },
};
</script>
