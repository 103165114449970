<template>
  <div>
    <v-data-table
      :pagination.sync="pagination"
      :headers="headers"
      :items="users"
      :total-items="totalItems"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      class="elevation-1"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr class="tableRowColor">
          <td>
            <v-chip
              v-if="props.item.status === 'Approved'"
              color="success"
              label
              outline
            >
              {{ $t('leko_employees.approve_users.table.approved') }}
            </v-chip>
            <v-chip
              v-if="props.item.status === 'Refused'"
              color="error"
              label
              outline
            >
              {{ $t('leko_employees.approve_users.table.refused') }}
            </v-chip>
            <v-chip
              v-if="props.item.status === 'Waiting Approval'"
              color="orange"
              label
              outline
            >
              {{ $t('leko_employees.approve_users.table.waiting_approval') }}
            </v-chip>
          </td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.surname }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.address }}</td>
          <td>{{ props.item.phone }}</td>
          <td>
            <v-layout
              ma-1
              row
              align-center
              justify-center
            >
              <img
                :src="`${server}/images/id-photos?q=${props.item._id}-${props.item.photo
                }&date=${props.item.date}`"
                height="75"
                width="100"
                @click="showImage({
                  filename: `${props.item._id}-${props.item.photo}`,
                  date: props.item.date
                })"
              >
            </v-layout>
          </td>
          <td>
            <v-layout row>
              <v-btn
                :disabled="props.item.status === 'Approved'"
                flat
                icon
                @click="approveUser(props.item._id)"
              >
                <v-icon color="grey darken-2">
                  done
                </v-icon>
              </v-btn>
              <v-btn
                :disabled="props.item.status === 'Refused'"
                flat
                icon
                @click="refuseUser(props.item._id)"
              >
                <v-icon color="grey darken-2">
                  clear
                </v-icon>
              </v-btn>
              <v-btn
                flat
                icon
                @click="deleteUser(props.item._id)"
              >
                <v-icon color="grey darken-2">
                  delete
                </v-icon>
              </v-btn>
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    users: [],
    totalItems: 0,
    pagination: {
      rowsPerPage: 5,
      page: 1,
    },
    currentRowData: {
      index: 0,
      discount: 0,
      savedDiscount: false,
    },
    discountRule: [v => (v >= 0 && v < 100) || 'Invalid value'],
    headers: [
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
      {
        text: 'Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Surname',
        sortable: true,
        value: 'surname',
      },
      {
        text: 'Email',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Address',
        sortable: true,
        value: 'address',
      },
      {
        text: 'Phone',
        sortable: true,
        value: 'phone',
      },
      {
        text: 'Photo',
        sortable: false,
        value: 'photo',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      }],
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    searchObject() {
      return this.$props.search;
    },
  },
  watch: {
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getUsers();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(
            this.searchObject.data.searchField,
            this.searchObject.data.advancedSearchFields,
          );
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getUsers();
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data);
        } else if (value.type === 'advancedSearch') {
          this.advancedSearch(value.data.searchField, value.data.advancedSearchFields);
        } else {
          console.log('search type is invalid');
        }
      },
    },
  },
  beforeMount() {
    this.getUsers();
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('leko_employees.approve_users.table.status');
      this.headers[1].text = this.$i18n.t('leko_employees.approve_users.table.name');
      this.headers[2].text = this.$i18n.t('leko_employees.approve_users.table.surname');
      this.headers[3].text = this.$i18n.t('leko_employees.approve_users.table.email');
      this.headers[4].text = this.$i18n.t('leko_employees.approve_users.table.address');
      this.headers[5].text = this.$i18n.t('leko_employees.approve_users.table.phone');
      this.headers[6].text = this.$i18n.t('leko_employees.approve_users.table.photo');
      this.headers[7].text = this.$i18n.t('leko_employees.approve_users.table.actions');
    },
    getUsers() {
      this.loading = true;
      getRequest(
        '/user/shop/all',
        `&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.users = response.users;
          this.totalItems = response.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    regularSearch(data) {
      this.loading = true;
      getRequest(
        '/user/shop/search/regular',
        `search=${data.searchField
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.users = response.users;
          this.totalItems = response.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    advancedSearch(searchField, advancedSearchFields) {
      this.loading = true;
      console.log(advancedSearchFields);
      getRequest(
        '/user/shop/search/advanced',
        `search=${searchField
        }&status=${advancedSearchFields.status
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.users = response.users;
          this.totalItems = response.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    approveUser(id) {
      this.$emit('approve-user', id);
    },
    refuseUser(id) {
      this.$emit('refuse-user', id);
    },
    deleteUser(id) {
      this.$emit('delete-user', id);
    },
    showImage(data) {
      this.$emit('image-dialog', data);
    },
  },
};
</script>
