<template>
  <v-layout
    row
    wrap
  >
    <v-flex xs12>
      <v-data-table
        :pagination.sync="pagination"
        :headers="$store.getters.role === 'LekoAdmin' ? headersAdmin : headers"
        :items="cars"
        :total-items="total"
        :rows-per-page-items="[5,10,25]"
        :loading="loading"
        class="elevation-1"
      >
        <v-progress-linear
          slot="progress"
          color="red"
          indeterminate
        />

        <template v-slot:items="props">
          <td>
            <v-btn
              icon
              :loading="loadingSelect[props.index.toString()]"
              @click="selectVehicle(props.item, props.index)"
            >
              <v-icon
                v-if="props.item.selected"
                color="grey darken-2"
              >
                check_box
              </v-icon>
              <v-icon
                v-else
                color="grey darken-2"
              >
                check_box_outline_blank
              </v-icon>
            </v-btn>
          </td>
          <td class="ma-1 pt-1 pb-1">
            <v-layout
              class="pointer"
              ma-1
              column
              align-center
              justify-center
              @click="openQrDialog({
                id: props.item._id,
                type: 'cars',
                vehicle: props.item,
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item._id"
              />
              <h4>{{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}</h4>
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td>
            <v-layout row>
              <!--SHARE-->
              <v-btn
                icon
                @click="share(props.item)"
              >
                <v-icon color="grey darken-2">
                  share
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="viewDetails(props.item)"
              >
                <v-icon color="grey darken-2">
                  list
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="editVehicle(props.item)"
              >
                <v-icon color="grey darken-2">
                  edit
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="openQrGenerationDialog(props.item)"
              >
                <v-icon color="grey darken-2">
                  qr_code
                </v-icon>
              </v-btn>

              <v-btn
                icon
                :loading="loadingArchive[props.index.toString()]"
                @click="exportArchive(props.item, props.index)"
              >
                <v-icon color="grey darken-2">
                  fas fa-file-archive
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="openInfoCarCad(props.item)"
              >
                <v-icon color="grey darken-2">
                  directions_car
                </v-icon>
              </v-btn>

              <v-btn
                icon
                @click="openTransferDialog(props.item)"
              >
                <v-icon color="grey darken-2">
                  sync_alt
                </v-icon>
              </v-btn>

              <v-btn
                icon
                :loading="loadingRemove[props.index.toString()]"
                @click="openConfirmRemoveModal(props.item, props.index)"
              >
                <v-icon color="grey darken-2">
                  clear
                </v-icon>
              </v-btn>

              <v-btn
                icon
                v-if="$store.getters.role === 'LekoAdmin'"
                :loading="loadingDelete[props.index.toString()]"
                :disabled="(!!props.item.shipment && !!props.item.shipment.id) || (!!props.item.shipment.entries && props.item.shipment.entries.length > 0)"
                @click="openConfirmDeleteModal(props.item, props.index)"
              >
                <v-icon color="grey darken-2">
                  delete
                </v-icon>
              </v-btn>
            </v-layout>
          </td>
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <img
              :src="`${server}/images?q=${props.item.photos && props.item.photos.length ? props.item.photos[0].thumb : ''}`"
              class="ma-1 pointer"
              height="75"
              width="100"
              @click="showImages(props.item.photos)"
            >
          </td>
          <td>
            <video
              v-if="props.item.video"
              :src="`${server}/videos/?q=${props.item.video}`"
              class="ma-1 pointer"
              height="75"
              width="100"
              @click="showVideo(props.item.video)"
            />
          </td>
          <td v-if="$store.getters.role === 'LekoAdmin'">
            {{ props.item.institution_id ? props.item.institution_id.title : '' }}
          </td>
          <td>{{ props.item.brand.title }}</td>
          <td>{{ props.item.model.description }}</td>
          <td>{{ props.item.version.description }}</td>
          <td>
            <span>
              {{ props.item.version.startingDate | date }}
              - {{ props.item.version.endingDate | date }}
            </span>
          </td>
          <td>{{ props.item.registrationYear }}</td>
          <td>{{ props.item.km }}</td>
          <td>{{ props.item.pra }}</td>
          <td>
            {{ props.item.version.numberOfDoors }} {{ $i18n.t('vehicle_entry.datatable.doors') }}
          </td>
          <td>{{ props.item.version && props.item.version.power && props.item.version.power.kw ?  props.item.version.power.kw : ''}} kw</td>
          <td>{{ props.item.version.engineCode }}</td>
          <td>{{ getSupplyLocale(props.item.version.supplyCode) }}</td>
          <td>{{ props.item.transmissionType }}</td>
          <td>{{ props.item.transmissionLayout }}</td>
          <td>
            {{ props.item.location && props.item.location.name
              ? props.item.location.name : '' }}
          </td>
          <td>{{ props.item.date_created | fullDate }}</td>
        </template>
      </v-data-table>
      
      <v-dialog
        v-model="confirmRemoveModal"
        hide-overlay
        persistent
        width="500"
      >
        <v-card>
          <v-card-text>
            {{ $t('vehicle_entry.datatable.confirmRemoveAlert') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="red darken-1"
              @click="confirmRemoveModal=false"
            >
              {{ $t('vehicle_entry.info.no') }}
            </v-btn>
            <v-btn
              text
              color="blue darken-1"
              @click="removeVehicle"
            >
              {{ $t('vehicle_entry.info.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="confirmDeleteModal"
        hide-overlay
        persistent
        width="500"
      >
        <v-card>
          <v-card-text>
            {{ $t('vehicle_entry.datatable.confirmDeleteAlert') }}
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              color="red darken-1"
              @click="confirmDeleteModal=false"
            >
              {{ $t('vehicle_entry.info.no') }}
            </v-btn>
            <v-btn
              text
              color="blue darken-1"
              @click="deleteVehicle"
            >
              {{ $t('vehicle_entry.info.yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    date(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
    fullDate(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  data: () => ({
    headers: [
      { text: 'Selected', sortable: false, value: '' },
      { text: 'ID', sortable: true, value: '_id' },
      { text: 'Actions', sortable: false, value: '' },
      { text: 'Photo', sortable: false, value: '' },
      { text: 'Video', sortable: false, value: '' },
      { text: 'Brand', sortable: true, value: 'brand.brandCode' },
      { text: 'Model', sortable: true, value: 'model.modelCode' },
      { text: 'Version', sortable: true, value: 'version.versionCode' },
      { text: 'Year', sortable: false, value: '' },
      { text: 'Registration Year', sortable: false, value: '' },
      { text: 'KM', sortable: false, value: '' },
      { text: 'PRA', sortable: true, value: 'pra' },
      { text: 'Number of doors', sortable: false, value: '' },
      { text: 'Power', sortable: false, value: '' },
      { text: 'Engine', sortable: false, value: '' },
      { text: 'Supply', sortable: false, value: '' },
      { text: 'Transmission Type', sortable: false, value: '' },
      { text: 'Transmission Layout', sortable: false, value: '' },
      { text: 'Location', sortable: false, value: '' },
      { text: 'Date Created', sortable: true, value: 'date_created' },
    ],
    headersAdmin: [
      { text: 'Selected', sortable: false, value: '' },
      { text: 'ID', sortable: true, value: '_id' },
      { text: 'Actions', sortable: false, value: '' },
      { text: 'Photo', sortable: false, value: '' },
      { text: 'Video', sortable: false, value: '' },
      { text: 'Institution', sortabe: true, value: 'institution_id' },
      { text: 'Brand', sortable: true, value: 'brand.brandCode' },
      { text: 'Model', sortable: true, value: 'model.modelCode' },
      { text: 'Version', sortable: true, value: 'version.versionCode' },
      { text: 'Year', sortable: false, value: '' },
      { text: 'Registration Year', sortable: false, value: '' },
      { text: 'KM', sortable: false, value: '' },
      { text: 'PRA', sortable: true, value: 'pra' },
      { text: 'Number of doors', sortable: false, value: '' },
      { text: 'Power', sortable: false, value: '' },
      { text: 'Engine', sortable: false, value: '' },
      { text: 'Supply', sortable: false, value: '' },
      { text: 'Transmission Type', sortable: false, value: '' },
      { text: 'Transmission Layout', sortable: false, value: '' },
      { text: 'Location', sortable: false, value: '' },
      { text: 'Date Created', sortable: true, value: 'date_created' },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    filters: {
      type: null,
      search: null,
      part: null,
      brand: null,
      model: null,
      version: null,
      engine: null,
      km: [0, 1000000],
      startDate: null,
      endDate: null,
    },
    cars: [],
    total: 0,
    loading: false,
    loadingSelect: {},
    loadingRemove: {},
    loadingDelete: {},
    loadingArchive: {},
    confirmRemoveModal: false,
    confirmDeleteModal: false,
    vehicle: {},
    index: '',
  }),
  computed: {
    server() { return this.$serverUri; },
  },
  watch: {
    pagination: {
      handler() {
        this.getCars();
      },
    },
  },
  created() {
    this.getLocale();
    this.getCars();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('vehicle_entry.datatable.selected');
      this.headers[1].text = this.$i18n.t('vehicle_entry.datatable.id');
      this.headers[2].text = this.$i18n.t('vehicle_entry.datatable.actions');
      this.headers[3].text = this.$i18n.t('vehicle_entry.datatable.images');
      this.headers[4].text = this.$i18n.t('vehicle_entry.datatable.video');
      this.headers[5].text = this.$i18n.t('vehicle_entry.datatable.brand');
      this.headers[6].text = this.$i18n.t('vehicle_entry.datatable.model');
      this.headers[7].text = this.$i18n.t('vehicle_entry.datatable.version');
      this.headers[8].text = this.$i18n.t('vehicle_entry.datatable.year');
      this.headers[9].text = this.$i18n.t('vehicle_entry.datatable.registration_year');
      this.headers[10].text = this.$i18n.t('vehicle_entry.datatable.number_of_km');
      this.headers[11].text = this.$i18n.t('vehicle_entry.datatable.practice_number');
      this.headers[12].text = this.$i18n.t('vehicle_entry.datatable.number_of_doors');
      this.headers[13].text = this.$i18n.t('vehicle_entry.datatable.power');
      this.headers[14].text = this.$i18n.t('vehicle_entry.datatable.engine');
      this.headers[15].text = this.$i18n.t('vehicle_entry.datatable.supply');
      this.headers[16].text = this.$i18n.t('vehicle_entry.datatable.transmission_type');
      this.headers[17].text = this.$i18n.t('vehicle_entry.datatable.transmission_layout');
      this.headers[18].text = this.$i18n.t('vehicle_entry.datatable.location');
      this.headers[19].text = this.$i18n.t('vehicle_entry.datatable.date_created');
      this.headersAdmin[0].text = this.$i18n.t('vehicle_entry.datatable.selected');
      this.headersAdmin[1].text = this.$i18n.t('vehicle_entry.datatable.id');
      this.headersAdmin[2].text = this.$i18n.t('vehicle_entry.datatable.actions');
      this.headersAdmin[3].text = this.$i18n.t('vehicle_entry.datatable.images');
      this.headersAdmin[4].text = this.$i18n.t('vehicle_entry.datatable.video');
      this.headersAdmin[5].text = this.$i18n.t('vehicle_entry.datatable.institution');
      this.headersAdmin[6].text = this.$i18n.t('vehicle_entry.datatable.brand');
      this.headersAdmin[7].text = this.$i18n.t('vehicle_entry.datatable.model');
      this.headersAdmin[8].text = this.$i18n.t('vehicle_entry.datatable.version');
      this.headersAdmin[9].text = this.$i18n.t('vehicle_entry.datatable.year');
      this.headersAdmin[10].text = this.$i18n.t('vehicle_entry.datatable.registration_year');
      this.headersAdmin[11].text = this.$i18n.t('vehicle_entry.datatable.number_of_km');
      this.headersAdmin[12].text = this.$i18n.t('vehicle_entry.datatable.practice_number');
      this.headersAdmin[13].text = this.$i18n.t('vehicle_entry.datatable.number_of_doors');
      this.headersAdmin[14].text = this.$i18n.t('vehicle_entry.datatable.power');
      this.headersAdmin[15].text = this.$i18n.t('vehicle_entry.datatable.engine');
      this.headersAdmin[16].text = this.$i18n.t('vehicle_entry.datatable.supply');
      this.headersAdmin[17].text = this.$i18n.t('vehicle_entry.datatable.transmission_type');
      this.headersAdmin[18].text = this.$i18n.t('vehicle_entry.datatable.transmission_layout');
      this.headersAdmin[19].text = this.$i18n.t('vehicle_entry.datatable.location');
      this.headersAdmin[20].text = this.$i18n.t('vehicle_entry.datatable.date_created');
    },
    getCars() {
      this.loading = true;
      // eslint-disable-next-line no-nested-ternary
      getRequest('/car', `status=1&institution_id=${this.$store.getters.role !== 'LekoAdmin' ? this.$store.getters.institutionId : this.filters.institution ? this.filters.institution : ''
      }&role=${this.$store.getters.role}&page=${this.pagination.page}&limit=${this.pagination.rowsPerPage
      }&sort_by=${this.pagination.sortBy}&descending=${this.pagination.descending
      }&type=${this.filters.type ? this.filters.type : ''}&search=${this.filters.search ? this.filters.search : ''}&part=${this.filters.part ? this.filters.part : ''
      }&km=${this.filters.km}&brand=${this.filters.brand ? this.filters.brand : ''}&model=${this.filters.model ? this.filters.model : ''
      }&engine=${this.filters.engine ? this.filters.engine : ''}&version=${this.filters.version ? this.filters.version : ''
      }&startDate=${this.filters.startDate ? this.filters.startDate : ''}&endDate=${this.filters.endDate ? this.filters.endDate : ''}`)
        .then((response) => {
          console.log(response);
          this.cars = response.cars;
          this.total = response.count;
          this.$emit('cars', this.cars);
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    setFilters(filters) {
      this.filters = filters;
      this.getCars();
    },
    viewDetails(car) {
      this.$emit('view-details', car);
    },
    share(data) {
      this.$emit('share', data);
    },
    openQrGenerationDialog(data) {
      this.$emit('generate-qr', data);
    },
    selectVehicle(vehicle, index) {
      this.loadingSelect[index.toString()] = true;
      console.log(vehicle);
      putRequest('/car/select', `id=${vehicle._id}`, {})
        .then((result) => {
          console.log(result);
          this.getCars();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingSelect[index.toString()] = false; });
    },
    editVehicle(vehicle) {
      this.$emit('edit-vehicle', vehicle);
    },
    openConfirmRemoveModal(vehicle, index) {
      this.confirmRemoveModal = true;
      this.vehicle = vehicle;
      this.index = index;
    },
    openConfirmDeleteModal(vehicle, index) {
      this.confirmDeleteModal = true;
      this.vehicle = vehicle;
      this.index = index;
    },
    removeVehicle() {
      this.confirmRemoveModal = true;
      this.loadingRemove[this.index.toString()] = true;
      deleteRequest('/car', `id=${this.vehicle._id}`)
        .then((response) => {
          console.log(response);
          this.getCars();
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.confirmRemoveModal = false;
          this.loadingRemove[this.index.toString()] = false;
          this.$emit('removed');
        });
    },
    deleteVehicle() {
      this.confirmDeleteModal = true;
      this.loadingDelete[this.index.toString()] = true;
      deleteRequest('/car/delete', `id=${this.vehicle._id}`)
        .then((response) => {
          console.log(response);
          this.getCars();
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.confirmDeleteModal = false;
          this.loadingDelete[this.index.toString()] = false;
          this.$emit('removed');
        });
    },
    showImages(images) {
      this.$emit('images', images);
    },
    showVideo(video) {
      this.$emit('video', video);
    },
    exportArchive(car, index) {
      const cars = [car._id];

      this.index = index;
      this.loadingArchive[this.index.toString()] = true;
      const { token } = localStorage;
      axios.post(`${this.$serverUri}/car/export`, { cars }, {
        responseType: 'arraybuffer',
        headers: {
          authorization: token,
        },
      })
        .then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'archive.zip');
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingArchive[this.index.toString()] = false; });
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    openInfoCarCad(car) {
      this.$emit('infocar-cad', {
        brandCode: car.brand.brandCode,
        modelCode: car.model.modelCode,
        versionCode: car.version.versionCode,
      })
    },
    openTransferDialog(car) {
      this.$emit('transfer', { car })
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
