var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"pagination":_vm.pagination,"headers":_vm.$store.getters.role === 'LekoAdmin' ? _vm.headersAdmin : _vm.headers,"items":_vm.parts,"total-items":_vm.totalItems,"rows-per-page-items":[5,10,25],"loading":_vm.loading},on:{"update:pagination":[function($event){_vm.pagination=$event},_vm.updatePagination]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"tableRowColor",class:{tableRowColorSelected : props.index === _vm.activeRow},on:{"click":function($event){_vm.activeRow = props.index}}},[(props.item.old_id && props.item.old_id !== undefined)?_c('td',[_c('v-layout',{staticClass:"pointer",attrs:{"ma-1":"","align-center":"","justify-center":"","column":""},on:{"click":function($event){return _vm.openQrDialog({
                id: props.item.old_id,
                type: 'inventory',
                printed: props.item.printed
              })}}},[_c('qr-code',{attrs:{"size":60,"text":props.item.old_id}}),_vm._v(" "+_vm._s(props.item.old_id.slice(props.item.old_id.length-6, props.item.old_id.length))+" ")],1)],1):_c('td',{staticClass:"text-xs"},[_c('v-layout',{staticClass:"pointer",attrs:{"ma-1":"","align-center":"","justify-center":"","column":""},on:{"click":function($event){return _vm.openQrDialog({
                id: props.item._id,
                type: 'inventory',
                printed: props.item.printed
              })}}},[_c('qr-code',{attrs:{"size":60,"text":props.item._id}}),_vm._v(" "+_vm._s(props.item._id.slice(props.item._id.length-4, props.item._id.length))+" ")],1)],1),_c('td',[_c('v-layout',{attrs:{"row":"","align-center":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.details(props.item)}}},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" view_list ")])],1)],1)],1),_c('td',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[(props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.group)?_c('span',{staticClass:"text-xs font-weight-bold"},[_vm._v(" "+_vm._s(props.item.infoCar.description.descriptionId.group[_vm.$store.getters.language])+" ")]):_vm._e()])],1)],1),_c('td',[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',[(props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.category)?_c('span',{staticClass:"text-xs font-weight-bold"},[_vm._v(" "+_vm._s(props.item.infoCar.description.descriptionId.category[_vm.$store.getters.language])+" ")]):_vm._e()])],1)],1),_c('td',{staticClass:"text-xs"},[_c('v-layout',{attrs:{"column":""}},[(props.item.infoCar && props.item.infoCar.description)?_c('v-flex',[(props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.title)?_c('span',{staticClass:"text-xs font-weight-bold"},[_vm._v(" "+_vm._s(props.item.infoCar.description.descriptionId.title[_vm.$store.getters.language])+" ")]):_vm._e(),(props.item.infoCar.description.positionCode)?_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm.getPosition(props.item.infoCar.description.positionCode))+" ")]):_vm._e()]):_vm._e()],1)],1),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.manufacturer_code)+" ")]),(_vm.$store.getters.role === 'LekoAdmin' && props.item.institution_id)?_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.institution_id.title)+" ")]):(_vm.$store.getters.role === 'LekoAdmin' && !props.item.institution_id)?_c('td'):_vm._e(),_c('td',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('img',{staticClass:"ma-1 pointer",attrs:{"src":`${_vm.server}/images?q=${props.item.images && props.item.images.length
                  ? props.item.images[0].thumb : ''}`,"height":"75","width":"100"},on:{"click":function($event){return _vm.showImages(props.item.images)}}})])],1),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.notes)+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.price)+" "+_vm._s(props.item.currency)+" ")]),(props.item.shelf_data)?_c('td',{staticClass:"text-xs"},[_c('span',[_vm._v(" "+_vm._s(props.item.shelf_data.shelf.room_name)+" - "+_vm._s(props.item.shelf_data.shelf.location_code)+": "+_vm._s(props.item.shelf_data.shelf.x)+", "+_vm._s(props.item.shelf_data.shelf.y)+" ")]),_c('div',{staticClass:"text-no-wrap"},[_c('span',{staticClass:"caption"},[_vm._v("Mag: ")]),_c('span',{staticClass:"font-weight-bold caption"},[_vm._v(_vm._s(props.item.shelf_data.repo))])])]):_c('td',{staticClass:"text-xs"},[_vm._v(" - ")]),(props.item.shipment_id && props.item.shipment_id.name)?_c('td',[_vm._v(" "+_vm._s(props.item.shipment_id.name)+" ")]):_c('td',[_vm._v(" - ")]),_c('td',{staticClass:"pt-2 pb-1"},[(props.item.quality)?_c('div',[_c('div',{staticStyle:{"width":"150px"}},[_c('v-rating',{attrs:{"value":props.item.quality.rating,"color":"red","background-color":"red darken-4","readonly":"","size":"14"}})],1)]):_c('div'),_c('div',[_vm._v(_vm._s(_vm._f("moment")(props.item.date_created)))]),_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[(props.item.status === 0)?_c('v-btn',{attrs:{"small":"","color":"error","outline":""},on:{"click":function($event){return _vm.movement(props.item)}}},[_vm._v(" "+_vm._s(_vm.$t('parts.status.recycled'))+" ")]):_vm._e()],1)],1)])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }