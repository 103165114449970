<template>
  <div>
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-cener
        justify-start
      >
        <v-toolbar-title>{{ $t('leko_employees.test_its.label') }}</v-toolbar-title>
        <v-divider
          class="mx-5 hidden-md-and-down"
          inset
          vertical
        />
      </v-layout>
    </v-toolbar>

    <v-layout
      column
      justify-center
      wrap
    >
      <v-flex xs12>
        <v-card flat>
          <v-layout
            row
            wrap
          >
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                :type="alert.type"
              >
                {{ alert.message }}
              </v-alert>
            </v-flex>
            <v-flex
              xs12
              lg6
            >
              <v-form ref="form">
                <v-container grid-list-md>
                  <!-- PART CODE -->
                  <v-toolbar
                    flat
                    dark
                  >
                    <v-toolbar-title>
                      {{ $t('leko_employees.test_its.part_code') }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-layout
                    mb-2
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex xs10>
                      <v-text-field
                        v-model="partCode"
                        :label="$t('leko_employees.test_its.manufacturer_code')"
                        color="red darken-1"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-layout
                        align-center
                        justify-center
                      >
                        <v-btn
                          :loading="loading1"
                          color="red darken-1"
                          @click="searchPart"
                        >
                          {{ $t('leko_employees.test_its.search') }}
                        </v-btn>
                        <v-btn
                          :loading="loading3"
                          :disabled="!result"
                          color="blue darken-1"
                          @click="updateCode"
                        >
                          {{ $t('leko_employees.test_its.update') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>

                  <!-- LICENSE PLATE -->
                  <v-toolbar
                    flat
                    dark
                  >
                    <v-toolbar-title>
                      {{ $t('leko_employees.test_its.license_plate') }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-layout
                    mb-2
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex xs10>
                      <v-text-field
                        v-model="licensePlate"
                        :label="$t('leko_employees.test_its.license_plate')"
                        color="red darken-1"
                      />
                    </v-flex>
                    <v-flex xs12>
                      <v-layout
                        align-center
                        justify-center
                      >
                        <v-btn
                          :loading="loading2"
                          color="red darken-1"
                          @click="searchPlate"
                        >
                          {{ $t('leko_employees.test_its.search') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-flex>
            <v-flex
              xs12
              lg6
            >
              <v-layout
                ma-4
                column
                align-start
                justify-center
              >
                <tree-view :data="result" />
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex xs12>
        <v-card flat>
          <v-layout
            row
            wrap
          >
            <v-flex xs12 />
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import getRequest from '../api/getRequest';

export default {
  data: () => ({
    loading1: false,
    loading2: false,
    loading3: false,
    alert: {
      value: false,
      type: 'error',
      message: String,
    },
    partCode: null,
    licensePlate: null,
    result: null,
  }),
  created() {},
  methods: {
    searchPart() {
      this.loading1 = true;
      getRequest('/info-car/search/', `code=${this.partCode}&populated=true`)
        .then((response) => { this.result = response; })
        .catch((error) => { this.result = null; this.showAlert('error', error); })
        .finally(() => { this.loading1 = false; });
    },
    updateCode() {
      this.loading3 = true;
      getRequest('/info-car/update/', `code=${this.partCode}`)
        .then((response) => { this.showAlert('success', response); })
        .catch((error) => { this.showAlert('error', error); })
        .finally(() => { this.loading3 = false; });
    },
    searchPlate() {
      this.loading2 = true;
      getRequest(`/info-car/plate/${this.licensePlate}`, '')
        .then((response) => { this.result = response; })
        .catch((error) => { this.showAlert('error', error); })
        .finally(() => { this.loading2 = false; });
    },

    showAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.value = true;
      setTimeout(() => { this.alert.value = false; }, 2000);
    },
  },
};
</script>

<!--

this.loading1 = false;
-->
