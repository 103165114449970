<template>
  <v-layout
    align-center
    justify-center
    row
    wrap
  >
    <!--CAMERA-->
    <v-flex
      xs12
      lg8
    >
      <camera @takePicture="takePicture" />
      <img-grid
        :images="images"
        @delete="deleteImage"
      />
    </v-flex>
    <!--PART DETAILS-->
    <v-flex
      xs12
      lg4
    >
      <v-card
        flat
        class="pa-4"
      >
        <v-card-title>
          <span class="headline"> {{ $t('reservations.new_part.part_details') }} </span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-layout
              row
              wrap
            >
              <v-flex xs12>
                <!--DESCRIPTION-->
                <v-text-field
                  v-model="description"
                  :rules="rules.required"
                  :label="$t('reservations.new_part.description')"
                  color="red darken-2"
                />
              </v-flex>
              <v-flex xs12>
                <!--NOTES-->
                <v-textarea
                  v-model="notes"
                  :label="$t('reservations.new_part.notes')"
                  color="red darken-2"
                />
              </v-flex>
              <v-flex
                xs6
                pr-1
              >
                <!--PRICE-->
                <v-text-field
                  v-model="price"
                  :rules="rules.required"
                  type="number"
                  min="0"
                  oninput="validity.valid||(value=0);"
                  :label="$t('reservations.new_part.price')"
                  color="red darken-2"
                />
              </v-flex>
              <v-flex
                xs6
                pl-1
              >
                <!--CURRENCY-->
                <v-select
                  v-model="currency"
                  :items="currencyItems"
                  :label="$t('reservations.new_part.currency')"
                  color="red darken-2"
                />
              </v-flex>
              <v-flex>
                <v-layout justify-center>
                  <v-btn
                    :disabled="!valid || price < 1"
                    color="primary"
                    depressed
                    @click="addPart"
                  >
                    {{ $t('reservations.new_part.add') }}
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import Camera from '@/components/camera.vue';
import ImgGrid from '@/components/imgGrid.vue';

export default {
  components: {
    camera: Camera,
    'img-grid': ImgGrid,
  },
  data: () => ({
    valid: false,
    description: '',
    notes: '',
    price: 0,
    rules: { required: [v => !!v || 'Please fill this field'] },
    images: [],
    currency: 'EUR',
    currencyItems: ['EUR'], // 'ALL',
  }),
  methods: {
    takePicture(data) {
      const randomString = Math.random().toString(36).substring(9);
      this.images.push({
        type: 'bin',
        blob: data.blob,
        dataUrl: data.dataUrl,
        title: `${randomString}-img${this.images.length + 1}.png`,
      });
    },
    deleteImage(data) {
      this.images.splice(data.index, 1);
    },
    reset() {
      this.$refs.form.resetValidation();
      this.images = [];
      this.description = '';
      this.notes = '';
      this.price = null;
    },
    addPart() {
      const valid = this.$refs.form.validate();
      if (valid) {
        this.$emit('submit', {
          images: this.images,
          description: this.description,
          notes: this.notes,
          price: this.price,
          currency: this.currency,
        });
        this.reset();
      }
    },
  },
};
</script>
