<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="850px"
  >
    <v-card>
      <!--TITLE-->
      <v-card-title class="red darken-3">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t('repositories.form.edit_repository') }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t('repositories.form.create_repository') }}</span>
      </v-card-title>
      <v-card-text>
        <!--FORM-->
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!--NOT EMPTY ALERT-->
              <v-flex
                v-if="isEmpty === false"
                xs6
              >
                <v-spacer />
              </v-flex>
              <v-flex
                v-if="isEmpty === false"
                xs6
              >
                <h4 class="red--text">
                  {{ $t('repositories.form.not_empty') }}
                </h4>
              </v-flex>


              <!--REPOSITORY NAME-->
              <v-flex xs12>
                <v-text-field
                  v-model="repository.name"
                  :rules="rules.required"
                  :label="$t('repositories.form.name')"
                  color="red darken-1"
                />
              </v-flex>


              <!--SELECT INSTITUTION-->
              <v-flex
                v-if="$store.getters.role == 'LekoAdmin'
                  || $store.getters.role == 'LekoEmployee'
                "
                xs12
              >
                <v-select
                  v-model="repository.institution_id"
                  :loading="loadingInstitutions"
                  :rules="rules.required"
                  :items="institutions"
                  :label="$t('repositories.form.select_institution')"
                  color="red darken-1"
                  item-text="title"
                  item-value="_id"
                />
              </v-flex>


              <!--ROOMS-->
              <v-flex xs12>
                <!--ADD ROOM-->
                <v-text-field
                  v-model="roomTitle"
                  :label="$t('repositories.form.rooms')"
                  color="red darken-1"
                  append-outer-icon="add_circle_outline"
                  @click:append-outer="addRoom"
                />

                <!--ROOM LIST-->
                <v-list>
                  <v-list-tile
                    v-for="(room, index) in rooms"
                    :key="index"
                    @click="''"
                  >
                    <v-layout
                      pt-2
                      justify-center
                    >
                      <!--SELECTION BUTTON-->
                      <v-list-tile-action @click="selectRoom(index)">
                        <v-radio-group v-model="room.selected">
                          <v-radio
                            :value="true"
                            color="primary"
                            hide-details
                          />
                        </v-radio-group>
                      </v-list-tile-action>
                      <!--TITLE-->
                      <v-list-tile-content>
                        <v-list-tile-title>{{ room.name }}</v-list-tile-title>
                      </v-list-tile-content>
                      <!--REMOVAL BUTTON-->
                      <v-list-tile-action>
                        <v-btn
                          icon
                          ripple
                          @click="confirmRemoveRoom(index)"
                        >
                          <v-icon color="grey darken-2">
                            remove_circle_outline
                          </v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-layout>
                  </v-list-tile>
                </v-list>

                <!--ROOM SELECTION ALERT-->
                <span
                  v-if="!room.selected && rooms.length > 0"
                  class="body-1 error--text"
                >
                  {{ $t('repositories.select_room') }}
                </span>
              </v-flex>


              <!--LOCATIONS-->
              <v-flex
                v-if="room.selected"
                xs12
              >
                <!--ADD LOCATION-->
                <v-layout row>
                  <v-flex xs4>
                    <!--POSITION X-->
                    <v-text-field
                      v-model="location.position_x"
                      :label="$t('repositories.form.position_x')"
                      color="red darken-1"
                      type="number"
                      min="1"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <!--POSITION Y-->
                    <v-text-field
                      v-model="location.position_y"
                      :label="$t('repositories.form.position_y')"
                      color="red darken-1"
                      type="number"
                      min="1"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <!--CODE-->
                    <v-text-field
                      v-model="location.code"
                      :label="$t('repositories.form.code')"
                      color="red darken-1"
                    />
                  </v-flex>
                </v-layout>
                <!--NOTES-->
                <v-text-field
                  v-model="location.notes"
                  :label="$t('repositories.form.notes')"
                  color="red darken-1"
                />
                <!--SAVE BUTTON-->
                <v-flex xs12>
                  <v-layout justify-center>
                    <v-btn
                      outline
                      :disabled="!location.position_x
                        || !location.position_y
                        || !location.code"
                      color="primary"
                      @click="addLocation()"
                    >
                      {{ $t('repositories.form.save_location') }}
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--LOCATIONS LIST-->
                <v-list two-line>
                  <v-list-tile
                    v-for="(location, index) in room.locations"
                    :key="index"
                    @click="''"
                  >
                    <!--LIST DETAILS-->
                    <v-list-tile-content>
                      <v-list-tile-title>
                        {{ $t('repositories.form.code') }}: {{ location.code }}
                      </v-list-tile-title>
                      <v-list-tile-sub-title class="text--primary">
                        {{ $t('repositories.form.position_x') }}:
                        {{ location.position_x }} {{ $t('repositories.form.position_y') }}:
                        {{ location.position_y }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title>
                        {{ $t('repositories.form.notes') }}: {{ location.notes }}
                      </v-list-tile-sub-title>
                    </v-list-tile-content>
                    <!--ACTIONS-->
                    <v-list-tile-action>
                      <v-layout
                        row
                        align-center
                      >
                        <!--EDIT BUTTON-->
                        <v-btn
                          class="mr-1"
                          icon
                          ripple
                          :disabled="!edit || !location._id"
                          @click="editLocation(location)"
                        >
                          <v-icon color="grey darken-2">
                            create
                          </v-icon>
                        </v-btn>
                        <!--REMOVE BUTTON-->
                        <v-btn
                          class="ml-1"
                          icon
                          ripple
                          @click="confirmRemoveLocation(index)"
                        >
                          <v-icon color="grey darken-2">
                            remove_circle_outline
                          </v-icon>
                        </v-btn>
                      </v-layout>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>


      <!--ACTION BUTTONS-->
      <v-card-actions>
        <v-spacer />
        <!--CLOSE-->
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('repositories.form.close') }}
        </v-btn>
        <!--SAVE-->
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('repositories.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';

export default {
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    edit: null,
    rules: {
      required: [v => !!v || 'Please fill this field'],
    },
    isEmpty: true,
    repository: {},
    loadingInstitutions: false,
    institutions: [],
    rooms: [],
    room: {},
    location: {},
    roomTitle: null,
  }),
  watch: {

  },
  methods: {
    open(edit, repository) {
      if (this.$store.getters.role === 'LekoEmployee' || this.$store.getters.role === 'LekoAdmin') {
        this.getInstitutions();
      }
      this.edit = edit;
      this.repository = repository;
      if (repository.rooms) this.rooms = repository.rooms;
      this.dialog = true;
    },
    close() {
      this.repository = {};
      this.institutions = [];
      for (let i = 0; i < this.rooms.length; i += 1) {
        this.rooms[i].selected = false; // Deselect all rooms.
      }
      this.room = {};
      this.rooms = [];
      this.location = {};
      this.dialog = false;
      this.valid = false;
      this.loading = false;
      this.isEmpty = true;
      this.edit = null;
      this.$emit('close');
    },
    hide() {
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
    save() {
      if (this.$refs.form.validate) {
        this.loading = true;
        this.repository.rooms = this.rooms;
        if (this.$store.getters.role === 'User'
          || this.$store.getters.role === 'InstitutionManager'
          || this.$store.getters.role === 'InstitutionAdmin'
        ) {
          this.repository.institution_id = this.$store.getters.institutionId;
        }
        this.loadingDialog = true;
        const formData = new FormData();
        formData.append('data', JSON.stringify(this.repository));
        if (this.edit === true) {
          postRequest.formData('/repository/edit', `id=${this.repository._id}`, formData)
            .then((response) => {
              console.log(response);
              this.close();
              this.$emit('save');
            })
            .catch((error) => {
              console.log(error);
              this.close();
            });
        } else if (this.edit === false) {
          postRequest.formData('/repository/new', '', formData)
            .then((response) => {
              console.log(response);
              this.close();
              this.$emit('save');
            })
            .catch((error) => {
              console.log(error);
              this.close();
            });
        } else {
          this.close();
        }
      }
    },
    addRoom() {
      this.rooms.push({
        selected: false,
        name: this.roomTitle,
        locations: [],
      });
      this.roomTitle = null;
    },
    confirmRemoveRoom(index) {
      this.$emit('remove-room', index);
    },
    removeRoom(index) {
      this.rooms.splice(index, 1);
    },
    selectRoom(index) {
      for (let i = 0; i < this.rooms.length; i += 1) {
        this.rooms[i].selected = false; // Deselect all rooms.
      }
      this.room = this.rooms[index]; // Set room's data.
      this.room.index = index; // Save array's index.
      this.room.selected = true; // Set selected flag.
    },
    addLocation() {
      this.room.locations.push(this.location); // Push newly created location to the room object.
      this.location = {}; // Clear location fields.
      this.rooms[this.room.index] = this.room; // Update room object in the rooms array.
    },
    confirmRemoveLocation(index) {
      this.$emit('remove-location', index);
    },
    removeLocation(index) {
      this.room.locations.splice(index, 1); // Remove location from the room object.
      this.rooms[this.room.index] = this.room; // Update room object in the rooms array.
    },
    editLocation(location) {
      this.$emit('location', {
        location,
        repository: this.repository,
        room: this.room,
      });
    },
    getInstitutions() {
      this.loadingInstitutions = true;
      getRequest('/institution/')
        .then((response) => {
          console.log(response);
          this.institutions = response.institutions;
          this.loadingInstitutions = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingInstitutions = false;
        });
    },
  },
};
</script>
