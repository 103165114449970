<template>
  <div>
    <div class="section-contact justify-center">
      <v-container grid-list-lg>
        <!------------------------------------------OptIn------------------------------------------>
        <v-layout row>
          <v-flex xs4>
            <phone-number-input
              v-model="optIn.phone"
              :input-id="'phone'"
              :options="{showDialCode: true}"
              :preferred-countries="['IT', 'FR', 'BE', 'DE']"
              color="#b71c1c"
              default-country-code="IT"
              label="ENTER YOUR PHONE NUMBER"
            />
          </v-flex>
        </v-layout>

        <v-layout row>
          <v-flex xs6>
            <v-switch
              v-model="optIn.enabled"
              :label="`Activate Opt-in Module`"
              class="background-grey"
              color="#b71c1c"
            />
          </v-flex>
        </v-layout>
        <div v-if="optIn.enabled">
          <a :href="activatedUrl"> {{ activatedUrl }} </a>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import PhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'OptIn',
  components: {
    PhoneNumberInput,
  },
  props: ['institution'], // eslint-disable-line vue/require-prop-types
  data() {
    return {};
  },
  computed: {
    optIn() {
      return this.$props.institution.preferences.optIn || {};
    },
    activatedUrl() { return this.$props.institution.shop; },
  },
  methods: {},
};
</script>

<style scoped>
.sms-sent {
  margin: 20px;
  background-color: green;
  color: white;
  padding: 10px;
  border-radius: 5px;
}
</style>
