<template>
  <v-data-table
    :headers="headers"
    :items="states"
    :search="searchField"
    :loading="loading"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          {{ props.item.name }}
        </td>
        <td class="text-xs">
          {{ props.item.language }}
        </td>
        <td>
          <flag
            :iso="props.item.flag"
            style="font-size: 25px"
          />
        </td>
        <td
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          class="justify-center"
        >
          <v-icon
            class="mr-2 pointer"
            @click="editState(props.item)"
          >
            edit
          </v-icon>
          <v-icon
            class="pointer"
            @click="deleteState(props.item._id)"
          >
            delete
          </v-icon>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  data: () => ({
    loading: false,
    searchField: '',
    headers: [{
      text: '',
      sortable: false,
      value: 'name',
    },
    {
      text: '',
      sortable: false,
      value: 'language',
    },
    {
      text: '',
      sortable: false,
      value: 'flag',
    },
    {
      text: '',
      sortable: false,
    }],
    states: [],
    activeRow: null,
  }),
  created() {
    this.getLocale();
    this.getStates();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('states.table.name');
      this.headers[1].text = this.$i18n.t('states.table.language');
      this.headers[2].text = this.$i18n.t('states.table.flag');
      this.headers[3].text = this.$i18n.t('states.table.actions');
    },
    search(value) {
      this.searchField = value;
    },
    getStates() {
      this.loading = true;
      getRequest('/state/').then((response) => {
        console.log(response);
        this.states = response.states;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    editState(state) {
      this.$emit('edit', state);
    },
    deleteState(stateId) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/state/', `id=${stateId}`).then((response) => {
          console.log(response);
          this.loading = false;
          this.getStates();
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      }
    },
  },
};
</script>
