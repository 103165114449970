<template>
  <v-layout
    column
    align-center
    justify-center
  >
    <!-------------------------------------------SWITCH-------------------------------------------->
    <v-flex xs12>
      <v-switch
        v-model="cameraSwitch"
        :label="$t('images.camera')"
        color="primary"
      />
    </v-flex>
    <!-------------------------------------------UPLOAD-------------------------------------------->
    <div v-if="!cameraSwitch">
      <v-flex xs12>
        <v-btn
          color="primary"
          outline
          @click="openInputComponent"
        >
          <v-icon left>
            attach_file
          </v-icon>
          {{ $t('images.upload') }}
        </v-btn>

        <input
          ref="input"
          type="file"
          style="display: none"
          accept="image/*"
          multiple="multiple"
          @change="onInput"
        >
      </v-flex>
    </div>
    <!-------------------------------------------CAMERA-------------------------------------------->
    <div v-if="cameraSwitch">
      <v-flex xs12>
        <video
          id="video"
          ref="video"
          :width="videoWidth"
          :class="{hideElement: hideCamera}"
          class="border"
        />
        <img
          ref="image"
          :width="videoWidth"
          :class="{hideElement: hideImage}"
          class="border"
        >
        <canvas
          ref="canvas"
          class="hideElement"
        />
      </v-flex>
      <v-flex xs12>
        <v-layout
          align-center
          justify-center
          row
        >
          <v-btn
            v-if="!hideCamera"
            color="#747474"
            flat
            icon
            @click="switchCamera"
          >
            <v-icon>switch_camera</v-icon>
          </v-btn>
          <!--
          <v-btn
            v-if="hideCamera"
            color="#747474"
            flat
            icon
            @click="savePicture"
          >
            <v-icon>done</v-icon>
          </v-btn>
          -->
          <v-btn
            :disabled="hideCamera"
            color="#05CBCD"
            fab
            outline
            @click="takePicture"
          >
            <v-icon
              color="#747474"
              x-large
            >
              camera
            </v-icon>
          </v-btn>
          <v-btn
            color="#747474"
            flat
            icon
            @click="deletePicture"
          >
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
    </div>
  </v-layout>
</template>

<script>
let video;

function initMediaDevices(v, constraints) {
  video = v;
  navigator.mediaDevices
    .getUserMedia(constraints)
    .then((stream) => {
      // eslint-disable-next-line no-param-reassign
      stream.onremovetrack = () => console.log('stream ended');
      if (video.srcObject !== undefined) {
        video.srcObject = stream;
      } else if (video.mozSrcObject !== undefined) {
        video.mozSrcObject = stream;
      } else if (window.URL.createObjectURL) {
        video.src = window.URL.createObjectURL(stream);
      } else if (window.webkitURL) {
        video.src = window.webkitURL.createObjectURL(stream);
      } else {
        video.src = stream;
      }
      video.play();
    })
    .catch((err) => {
      console.log(err);
    });
}

function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export default {
  data: () => ({
    cameraSwitch: true,
    front: false,
    hideCamera: false,
    hideImage: true,
    // Image Data
    dataUrl: null,
    blob: null,
  }),
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 240;
        case 'sm':
          return 480;
        case 'md':
          return 680;
        default:
          return 680;
      }
    },
  },
  watch: {
    cameraSwitch(value) {
      if (value) {
        this.startCamera();
      } else {
        this.stopCamera();
        this.dataUrl = null;
        this.blob = null;
      }
    },
  },
  mounted() {
    this.startCamera();
  },
  beforeDestroy() {
    this.stopCamera();
    this.dataUrl = null;
    this.blob = null;
  },
  methods: {
    openInputComponent() {
      this.$refs.input.click();
    },
    onInput(input) {
      const { files } = input.target;
      for (const file of files) { // eslint-disable-line no-restricted-syntax
        if (file && file !== undefined) {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.addEventListener('load', () => {
            const dataUrl = fileReader.result;
            const blob = dataURLtoBlob(dataUrl);
            this.$emit('takePicture', { dataUrl, blob });
          });
          console.log(fileReader);
        }
      }
    },
    startCamera() {
      this.hideCamera = false;
      const constraints = {
        video: { facingMode: 'environment' },
        audio: false,
      };
      /**
       * Timeout is set because when switching to camera from file upload
       * initMediaDevices fires before rendering template components, so $refs.video is null.
       *
       * TODO: Find a cleaner solution
       */
      setTimeout(() => {
        initMediaDevices(this.$refs.video, constraints);
      }, 500);
    },
    stopCamera() {
      if (video && video.srcObject) {
        const tracks = video.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
          console.log('video track stopped.');
        });
      }
      this.hideCamera = true;
    },
    switchCamera() {
      this.front = !this.front;
      const constraints = {
        video: { facingMode: this.front ? 'user' : 'environment' },
        audio: false,
      };
      this.stopCamera();
      this.startCamera(constraints);
    },
    takePicture() {
      this.hideCamera = true;
      const { canvas } = this.$refs;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext('2d');
      context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      const data = canvas.toDataURL('image/png');
      const blob = dataURLtoBlob(data);
      const { image } = this.$refs;
      image.setAttribute('src', data);
      this.hideImage = false;
      this.dataUrl = data;
      this.blob = blob;
      // save picture
      this.hideImage = true;
      this.hideCamera = false;
      this.$emit('takePicture', { dataUrl: this.dataUrl, blob: this.blob });
      this.dataUrl = null;
      this.blob = null;
    },
    /*
    savePicture() {
      this.hideImage = true;
      this.hideCamera = false;
      this.$emit('takePicture', { dataUrl: this.dataUrl, blob: this.blob });
      this.dataUrl = null;
      this.blob = null;
    },
    */
    deletePicture() {
      this.hideImage = true;
      this.hideCamera = false;
      this.dataUrl = null;
      this.blob = null;
    },
  },
};
</script>

<style scoped>
.hideElement {
  display: none !important;
}
.border {
  display: block;
  border-style: solid;
  border-width: 2px;
  border-radius: 5px;
  color: #455a64;
  /*color: #616161;*/
}
</style>
