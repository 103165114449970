<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('institutions.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('institutions.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-btn
        color="grey darken-4"
        dark
        @click="newInstitution"
      >
        {{ $t('institutions.button') }}
      </v-btn>
    </v-toolbar>

    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>

    <!--EDIT DIALOG-->
    <edit-dialog
      ref="dialog"
      @save="getInstitutions"
      @error="showAlert"
    />

    <!--EDIT CARS-->
    <edit-cars ref="editCars" />

    <!--DATATABLE-->
    <datatable
      ref="datatable"
      @edit="editInstitution"
      @cars="editCars"
      @error="showAlert"
    />
  </v-container>
</template>

<script>
import Datatable from '@/components/Institutions/datatable.vue';
import Dialog from '@/components/Institutions/dialog.vue';
import Cars from '@/components/Institutions/cars.vue';

export default {
  components: {
    datatable: Datatable,
    'edit-dialog': Dialog,
    'edit-cars': Cars,
  },
  data: () => ({
    search: '',
    alert: {
      value: false,
      message: '',
    },
  }),
  watch: {
    search(value) {
      const { datatable } = this.$refs;
      datatable.search(value);
    },
  },
  mounted() {
  },
  methods: {
    showAlert(error) {
      console.log(error);
      this.alert = {
        value: true,
        message: '',
      };
      setTimeout(() => {
        this.alert.value = false;
      }, 2000);
    },
    getInstitutions() {
      const { datatable } = this.$refs;
      datatable.getInstitutions();
    },
    newInstitution() {
      const { dialog } = this.$refs;
      dialog.open(false, {});
    },
    editInstitution(data) {
      const { dialog } = this.$refs;
      dialog.open(true, data);
    },
    editCars(id) {
      const { editCars } = this.$refs;
      editCars.open(id);
    },
  },
};
</script>
