<template>
  <v-data-table
    :pagination.sync="pagination"
    :headers="headers"
    :items="carLocations"
    :total-items="totalItems"
    :rows-per-page-items="[5,10,25]"
    item-key="_id"
    :loading="loading"
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      itermediate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <!--QR CODE-->
        <td>
          <v-layout
            class="pointer"
            ma-1
            column
            align-center
            justify-center
            @click="openQrDialog({
              id: props.item._id,
              type: 'carLocations',
              printed: props.item.printed
            })"
          >
            <qr-code
              :size="60"
              :text="props.item._id"
            />
            <h4>{{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}</h4>
          </v-layout>
        </td>
        <!--NAME-->
        <td class="text-xs">
          {{ props.item.name }}
        </td>
        <!--DESCRIPTION-->
        <td class="text-xs">
          {{ props.item.description }}
        </td>
        <!--DESCRIPTION-->
        <td
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          class="text-xs"
        >
          <span v-if="props.item.institution_id"> {{ props.item.institution_id.title }} </span>
        </td>
        <!--DATE-->
        <td class="text-xs">
          {{ props.item.date_created | moment }}
        </td>
        <!--ACTIONS-->
        <td class="justify-center">
          <v-layout row>
            <!--View cars list-->
            <v-btn
              icon
              @click="viewCarsList(props.item._id)"
            >
              <v-icon color="grey darken-2">
                list
              </v-icon>
            </v-btn>
            <!--Edit location-->
            <v-btn
              icon
              @click="editCarLocation(props.item)"
            >
              <v-icon color="grey darken-2">
                edit
              </v-icon>
            </v-btn>
            <!--Delete location-->
            <v-btn
              icon
              @click="deleteCarLocation(props.item._id)"
            >
              <v-icon color="grey darken-2">
                delete
              </v-icon>
            </v-btn>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    headers: [
      {
        text: 'Qr Code',
        sortable: true,
        value: '_id',
      },
      {
        text: 'Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Description',
        sortable: true,
        value: 'description',
      },
      // Insert institution here.
      {
        text: 'Date',
        sortable: true,
        value: 'date_created',
      },
      {
        text: 'Actions',
        sortable: false,
        value: '',
      },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: '_id',
      descending: true,
    },
    carLocations: [],
    totalItems: 0,
    loading: false,
    activeRow: null,
  }),
  computed: {
    searchField() { return this.$props.search; },
  },
  watch: {
    pagination: {
      handler() {
        if (!this.searchField || this.searchField.length < 1) this.getCarLocations();
        else this.searchCarLocations(this.searchField);
      },
    },
    searchField: {
      handler(value) {
        if (!value || value.length < 1) this.getCarLocations();
        else this.searchCarLocations(value);
      },
      deep: true,
    },
  },
  created() {
    this.getLocale();
    this.getCarLocations();
    this.activeRow = null;
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('car_locations.table.qr_code');
      this.headers[1].text = this.$i18n.t('car_locations.table.name');
      this.headers[2].text = this.$i18n.t('car_locations.table.description');
      this.headers[3].text = this.$i18n.t('car_locations.table.date');
      this.headers[4].text = this.$i18n.t('car_locations.table.actions');

      // Insert institution header.
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee') {
        this.headers.splice(3, 0, { text: 'Institution', sortable: false, value: 'institution_id' });
        this.headers[3].text = this.$i18n.t('car_locations.table.institution');
        // Overwrite headers' values.
        this.headers[4].text = this.$i18n.t('car_locations.table.date');
        this.headers[5].text = this.$i18n.t('car_locations.table.actions');
      }
    },
    getCarLocations() {
      this.loading = true;
      getRequest(
        '/car-location',
        `role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.carLocations = response.carLocations;
          this.totalItems = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    searchCarLocations(value) {
      this.loading = true;
      getRequest(
        '/car-location',
        `role=${this.$store.getters.role
        }&search=${value
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.carLocations = response.carLocations;
          this.totalItems = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    viewCarsList(id) {
      this.$emit('cars-list', id);
    },
    editCarLocation(carLocation) {
      this.$emit('edit', carLocation);
    },
    deleteCarLocation(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/car-location', `id=${id}`)
          .then(() => { this.getCarLocations(); })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loading = false; });
      }
    },
  },
};
</script>
