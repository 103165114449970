<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <!-------------------------------------------TITLE------------------------------------------->
      <v-toolbar-title class="hidden-xs-only">
        {{ $t('leko_employees.approve_users.label') }}
      </v-toolbar-title>
      <!------------------------------------------------------------------------------------------->
      <v-divider
        class="mx-5 hidden-xs-only"
        inset
        vertical
      />
      <!------------------------------------------------------------------------------------------->
      <v-text-field
        v-model="search"
        :label="$t('leko_employees.approve_users.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
        @keyup.enter="searchMethod()"
        @click:append="searchMethod()"
      />

      <!--ADVANCED MD-AND-UP-->
      <v-btn
        class=" mr-5 hidden-sm-and-down"
        dark
        small
        @click="advancedSearch = !advancedSearch"
      >
        <v-icon left>
          {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
        </v-icon>
        {{ $t('leko_employees.approve_users.advanced') }}
      </v-btn>

      <v-spacer class="hidden-sm-and-down" />
      <!------------------------------------------------------------------------------------------->
    </v-toolbar>
    <div class="redColor">
      <v-layout
        align-center
        justify-center
      >
        <!--ADVANCED SM-AND-DOWN-->
        <v-btn
          class="hidden-md-and-up"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('leko_employees.approve_users.advanced') }}
        </v-btn>
      </v-layout>
      <!--------------------------------------ADVANCED SEARCH-------------------------------------->
      <div v-if="advancedSearch">
        <v-layout
          row
          color="red darken-3"
          class="pb-2"
          wrap
        >
          <v-flex xs12>
            <v-select
              v-model="advancedSearchItems.status"
              :items="statusItems"
              item-text="title"
              item-value="value"
              solo
              flat
              chips
              deletable-chips
              multiple
              color="red darken-2"
              class="ml-2 mr-2 mt-2"
              persistent-hit
            />
          </v-flex>
          <v-flex xs12>
            <v-layout
              align-center
              justify-center
              row
              wrap
            >
              <v-btn
                dark
                color="grey darken-4"
                @click="clearSearchFields"
              >
                {{ $t('leko_employees.approve_users.clear') }}
                <v-icon right>
                  clear_all
                </v-icon>
              </v-btn>
              <v-btn
                dark
                color="grey darken-4"
                @click="searchMethod"
              >
                {{ $t('leko_employees.approve_users.search') }}
                <v-icon right>
                  search
                </v-icon>
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </div>
      <!------------------------------------------------------------------------------------------->
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    advancedSearch: false,
    search: '',
    advancedSearchItems: {
      status: ['Approved', 'Waiting Approval', 'Refused'],
    },
    statusItems: [
      { title: 'Approved', value: 'Approved' },
      { title: 'Waiting Approval', value: 'Waiting Approval' },
      { title: 'Refused', value: 'Refused' },
    ],
  }),
  watch: {
    search(value) {
      if (value.length < 1 && this.advancedSearch === false) {
        this.$emit('clear-search');
      }
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.statusItems[0].title = this.$i18n.t('leko_employees.approve_users.status.approved');
      this.statusItems[1].title = this.$i18n.t('leko_employees.approve_users.status.waiting_approval');
      this.statusItems[2].title = this.$i18n.t('leko_employees.approve_users.status.refused');
    },
    searchMethod() {
      if (this.advancedSearch) { // ADVANCED SEARCH
        this.$emit('adv-search', {
          searchField: this.search,
          advancedSearchFields: this.advancedSearchItems,
        });
      } else if (this.search.length > 0) { // REGULAR SEARCH
        this.$emit('search', { searchField: this.search });
      }
    },
    clearSearchFields() {
      this.search = '';
      this.advancedSearchItems = {
        status: ['Approved', 'Waiting Approval', 'Refused'],
      };
      this.$emit('clear-search');
    },
  },
};
</script>
