<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card flat>
      <v-layout
        column
        wrap
      >
        <!-------------------------------------CAMERA SCANNER-------------------------------------->
        <v-flex
          v-if="!physicalScanner"
          xs12
        >
          <qrcode-stream
            v-if="dialog"
            :camera="constraints"
            :paused="paused"
            @decode="decode"
          />
        </v-flex>
        <!-------------------------------------PHYSICAL SCANNER------------------------------------>
        <v-flex
          v-if="physicalScanner"
          xs12
        >
          <v-layout
            mt-2
            mb-2
            justify-center
          >
            <div
              v-if="found === null && !physicalScannerError"
              class="ma-3 blue--text subheading"
            >
              {{ $t('vehicle_entry.qr_scanner.physical_scanner') }}
            </div>
            <div
              v-if="physicalScannerError"
              class="ma-3 red--text subheading"
            >
              {{ $t('vehicle_entry.qr_scanner.physical_scanner_error') }}
            </div>
          </v-layout>
        </v-flex>
        <!----------------------------------------------------------------------------------------->
        <v-flex xs12>
          <v-layout
            mt-2
            ml-2
            mr-2
            column
            align-center
            justify-center
          >
            <!--LOADER-->
            <v-flex xs12>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              />
            </v-flex>

            <!--ALERT-->
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                :type="alert.type"
                outline
              >
                {{ alert.message }}
              </v-alert>
            </v-flex>

            <!---------------------------------------ACTIONS--------------------------------------->
            <v-flex xs12>
              <v-layout
                v-if="found === true"
                row
                wrap
                align-center
                justify-center
              >
                <!--VIEW DETAILS-->
                <v-flex v-if="mode === 'car'">
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="details"
                    >
                      {{ $t('vehicle_entry.qr_scanner.view_details') }}
                      <v-icon right>
                        view_list
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--GENERATE SHIPMENTS-->
                <v-flex v-if="mode === 'car'">
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="generateShipments"
                    >
                      {{ $t('vehicle_entry.qr_scanner.generate_shipments') }}
                      <v-icon right>
                        qr_code
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--EDIT-->
                <v-flex
                  v-if="mode === 'car' && ($store.getters.role == 'LekoAdmin'
                    || $store.getters.role == 'InstitutionAdmin')"
                >
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="edit"
                    >
                      {{ $t('vehicle_entry.qr_scanner.edit_car') }}
                      <v-icon right>
                        edit
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
                <!--OPEN LOCATION SCANNER-->
                <v-flex v-if="mode === 'car'">
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="openLocationScanner"
                    >
                      {{ $t('vehicle_entry.qr_scanner.open_location_scanner') }}
                      <v-icon right>
                        location_on
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
                <!--CHANGE LOCATION-->
                <v-flex v-if="mode === 'location'">
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      :loading="loadingChangeLocation"
                      @click="changeLocation"
                    >
                      {{ $t('vehicle_entry.qr_scanner.change_location') }}
                      <v-icon right>
                        edit
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- BOTTOM ACTION BUTTONS -->
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              flat
              icon
              @click="reScan"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              flat
              icon
              @click="switchScanner"
            >
              <v-icon>fas fa-qrcode</v-icon>
            </v-btn>
            <v-btn
              flat
              icon
              @click="switchCamera"
            >
              <v-icon>switch_camera</v-icon>
            </v-btn>
            <v-btn
              outline
              color="error"
              @click="close"
            >
              {{ $t('vehicle_entry.qr_scanner.close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    front: false,
    paused: false,
    loading: false,
    loadingChangeLocation: false,
    mode: 'car', // 'car' | 'location'
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    car: {},
    location: {},
    found: null,
    decodedString: null,
    hideLabel: true,
    physicalScanner: false,
    physicalScannerError: false,
    buffer: '',
  }),
  methods: {
    switchCamera() {
      this.physicalScanner = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.front = !this.front;
      this.constraints = {
        video: { facingMode: this.front ? 'user' : 'environment' },
        audio: false,
      };
    },
    switchScanner() {
      this.physicalScanner = true;
      document.addEventListener('keydown', this.listenToEvent);
    },
    listenToEvent(e) {
      // console.log(e);
      if (e.key !== 'Enter') {
        this.buffer += e.key;
      } else if (this.buffer.length > 24) {
        this.physicalScannerError = true;
        this.buffer = '';
      } else {
        this.decode(this.buffer);
        this.buffer = '';
      }
    },
    async decode(value) {
      if (value !== null && value.length > 0) {
        this.loading = true;
        this.found = null;
        this.hideLabel = true;
        this.decodedString = value;
        this.paused = true;
        this.alert.value = false;

        if (this.mode === 'car') {
          try {
            const response = await getRequest('/car', `id=${value}`);
            this.car = response.car;
            this.found = true;
            this.alert = {
              value: true,
              type: 'success',
              message: `${this.$i18n.t('vehicle_entry.qr_scanner.found_message_1')} ${this.$i18n.t('vehicle_entry.qr_scanner.car')} ${this.$i18n.t('vehicle_entry.qr_scanner.message_2')}: ${this.decodedString}`,
            };
          } catch (error) {
            this.car = {};
            this.found = false;
            this.alert = {
              value: true,
              type: 'error',
              message: `${this.$i18n.t('vehicle_entry.qr_scanner.not_found_message_1')} ${this.$i18n.t('vehicle_entry.qr_scanner.car')} ${this.$i18n.t('vehicle_entry.qr_scanner.message_2')}: ${this.decodedString}`,
            };
          }
        }

        if (this.mode === 'location') {
          try {
            const response = await getRequest('/car-location', `id=${value}`);
            console.log(response);
            this.location = response.carLocations;
            this.found = true;
            this.alert = {
              value: true,
              type: 'success',
              message: `${this.$i18n.t('vehicle_entry.qr_scanner.found_message_1')} ${this.$i18n.t('vehicle_entry.qr_scanner.location')} ${this.$i18n.t('vehicle_entry.qr_scanner.message_2')}: ${this.decodedString}`,
            };
          } catch (error) {
            this.location = {};
            this.found = false;
            this.alert = {
              value: true,
              type: 'error',
              message: `${this.$i18n.t('vehicle_entry.qr_scanner.not_found_message_1')} ${this.$i18n.t('vehicle_entry.qr_scanner.location')} ${this.$i18n.t('vehicle_entry.qr_scanner.message_2')}: ${this.decodedString}`,
            };
          }
        }

        this.loading = false;
      }
    },
    reScan() {
      this.found = null;
      this.hideLabel = false;
      this.loading = false;
      this.paused = false;
      this.decodedString = null;
      this.physicalScannerError = false;
      if (this.mode !== 'location') { // Preserve details in 'location' mode
        this.car = {};
        this.location = {};
      }
      this.alert = { value: true, type: 'info', message: '' };
      if (this.mode === 'car') this.alert.message = this.$i18n.t('vehicle_entry.qr_scanner.scan_car_message');
      if (this.mode === 'location') this.alert.message = this.$i18n.t('vehicle_entry.qr_scanner.scan_location_message');
    },
    openLocationScanner() {
      this.mode = 'location';
      this.reScan(true);
    },
    changeLocation() {
      this.loadingChangeLocation = true;
      putRequest('/car/change-location', `id=${this.car._id}`, { location: this.location._id })
        .then((response) => { console.log(response); this.$emit('changed-location'); })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingChangeLocation = false;
          this.close();
        });
    },
    details() {
      this.$emit('details', this.car);
      this.close();
    },
    generateShipments() {
      this.$emit('generate-qr', this.car);
      this.close();
    },
    edit() {
      this.$emit('edit', this.car);
      this.close();
    },
    open() {
      this.dialog = true;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('vehicle_entry.qr_scanner.scan_car_message'),
      };
    },
    close() {
      this.dialog = false;
      this.paused = false;
      this.loading = false;
      this.car = {};
      this.location = {};
      this.found = null;
      this.decodedString = null;
      this.physicalScanner = false;
      this.physicalScannerError = false;
      this.mode = 'car';
      this.alert = {
        value: false,
        type: 'success',
        message: '',
      };
      document.removeEventListener('keydown', this.listenToEvent);
    },
  },
};
</script>

<style>

</style>
