var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"800"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"outline":"","icon":"","large":"","color":"red"},on:{"click":_vm.close}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" close ")])],1)],1),_c('v-card-text',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"mb-2":"","xs12":""}},[(_vm.loading)?_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"red"}})],1):_vm._e(),(_vm.empty)?_c('v-layout',{attrs:{"justify-center":""}},[_c('h3',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.$t('repositories.shelf_content.empty'))+" ")])]):_vm._e()],1),(!_vm.loading)?_c('v-flex',{attrs:{"pr-1":"","xs6":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('repositories.shelf_content.parts'))+": "+_vm._s(_vm.parts.length)+" ")])],1),_c('v-list',_vm._l((_vm.parts),function(item,index){return _c('v-list-tile',{key:index,staticClass:"listRowColor",on:{"click":function($event){''}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[(item.old_id)?_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('span',[_vm._v(_vm._s(item.old_id.slice(item.old_id.length-6, item.old_id.length)))]),(item.infoCar
                        && item.infoCar.description
                        && item.infoCar.description.descriptionId
                        && item.infoCar.description.descriptionId.title)?_c('span',[_c('pre',[_vm._v(" - ")])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.multilanguage(item)))])]):_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('span',[_vm._v(_vm._s(item._id.slice(item._id.length-6, item._id.length)))]),(item.infoCar
                        && item.infoCar.description
                        && item.infoCar.description.descriptionId
                        && item.infoCar.description.descriptionId.title)?_c('span',[_c('pre',[_vm._v(" - ")])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.multilanguage(item)))])])],1)],1)],1)}),1)],1):_vm._e(),(!_vm.loading)?_c('v-flex',{attrs:{"pl-1":"","xs6":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('repositories.shelf_content.temporary_parts'))+": "+_vm._s(_vm.temporaryParts.length)+" ")])],1),_c('v-list',_vm._l((_vm.temporaryParts),function(item,index){return _c('v-list-tile',{key:index,staticClass:"listRowColor",on:{"click":function($event){{}}}},[_c('v-list-tile-content',[_c('v-list-tile-title',[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('span',[_vm._v(_vm._s(item._id.slice(item._id.length-6, item._id.length)))]),(item.infoCar
                        && item.infoCar.description
                        && item.infoCar.description.descriptionId
                        && item.infoCar.description.descriptionId.title)?_c('span',[_c('pre',[_vm._v(" - ")])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.multilanguage(item)))])])],1)],1)],1)}),1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }