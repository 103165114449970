<template>
  <v-dialog
    v-model="dialog"
    width="auto"
    max-width="600"
  >
    <v-card
      flat
      tile
      color="grey lighten-4"
      class="d-flex"
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              icon
              @click="rotateL"
            >
              <v-icon>rotate_left</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="rotateR"
            >
              <v-icon>rotate_right</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="ma-2"
              outline
              icon
              large
              color="red"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <v-img
            v-if="dialog"
            :style="{ 'transform': `rotate(${rotation}deg)` }"
            :src="source"
            aspect-ratio="1"
            contain
            @mousemove="zoomIn"
            @mouseleave="zoomOut"
          >
            <template v-slot:placeholder>
              <v-layout
                fill-height
                align-center
                justify-center
                ma-0
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                />
              </v-layout>
            </template>
          </v-img>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import zoom from '../utils/zoom';

export default {
  data: () => ({
    dialog: false,
    source: null,
    rotation: 0,
  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.source = null;
        this.$emit('close');
      }
    },
  },
  methods: {
    open(img) {
      console.log(img);
      if (img.type === 'string') {
        this.source = `${this.$serverUri}/images/?q=${img.data.image}`;
      } else if (img.type === 'dataUrl') {
        this.source = img.data;
      } else {
        this.source = null;
      }
      this.dialog = true;
    },
    rotateR() {
      this.rotation += 90;
      this.$refs.image.forEach((image) => {
        image.style.transform = `rotate(${this.rotation}deg) scale(${this.zoomLevel})`;
      });
    },
    rotateL() {
      this.rotation -= 90;
      this.$refs.image.forEach((image) => {
        image.style.transform = `rotate(${this.rotation}deg) scale(${this.zoomLevel})`;
      });
    },

    zoomIn(event) { zoom.zoomIn(2.4, event, this.rotation); },
    zoomOut(event) { zoom.zoomOut(event, this.rotation); },
    close() {
      this.source = null;
      this.dialog = false;
      this.rotation = 0;
      this.$emit('close');
    },
  },
};
</script>
