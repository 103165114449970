var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"pagination":_vm.pagination,"headers":_vm.headers,"items":_vm.shipments,"total-items":_vm.totalItems,"rows-per-page-items":[5,10,25],"item-key":"_id","loading":_vm.loading,"select-all":""},on:{"update:pagination":[function($event){_vm.pagination=$event},_vm.updatePagination]},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"tableRowColor",class:{tableRowColorSelected : props.index == _vm.activeRow},on:{"click":function($event){_vm.activeRow = props.index}}},[_c('td',[_c('v-checkbox',{attrs:{"color":"red darken-3","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})],1),(_vm.$store.getters.role === 'LekoAdmin')?_c('td',{staticClass:"ma-1 pt-1 pb-1"},[_c('v-layout',{staticClass:"pointer",attrs:{"ma-1":"","column":"","align-center":"","justify-center":""},on:{"click":function($event){return _vm.openQrDialog({
            id: props.item._id,
            type: 'shipments',
            printed: props.item.printed
          })}}},[_c('qr-code',{attrs:{"size":60,"text":props.item._id}}),_c('h4',[_vm._v(_vm._s(props.item._id.slice(props.item._id.length-6, props.item._id.length)))])],1)],1):_vm._e(),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.name)+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.description)+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.institution ? props.item.institution.title : '')+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.item.date))+" ")]),_c('td',{staticClass:"justify-center"},[_c('v-layout',{attrs:{"row":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.insertEmptyParts(props.item._id)}}},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" add ")])],1),(_vm.$store.getters.role == 'LekoAdmin'
              || _vm.$store.getters.role == 'InstitutionAdmin'
              || _vm.$store.getters.role == 'InstitutionManager'
            )?_c('v-btn',{attrs:{"disabled":props.item.vehicle,"icon":""},on:{"click":function($event){return _vm.editShipment(props.item)}}},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" edit ")])],1):_vm._e(),(_vm.$store.getters.role == 'LekoAdmin'
              || _vm.$store.getters.role == 'InstitutionAdmin'
              || _vm.$store.getters.role == 'InstitutionManager'
            )?_c('v-btn',{attrs:{"disabled":props.item.vehicle,"icon":""},on:{"click":function($event){return _vm.deleteShipment(props.item._id)}}},[_c('v-icon',{attrs:{"color":"grey darken-2"}},[_vm._v(" delete ")])],1):_vm._e()],1)],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }