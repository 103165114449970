<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-center
      >
        <!------------------------------------------TITLE------------------------------------------>
        <v-toolbar-title class="hidden-md-and-down">
          {{ $t('parts.insert_parts.label') }}
        </v-toolbar-title>
        <!----------------------------------------------------------------------------------------->
        <v-divider
          class="mx-5 hidden-md-and-down"
          inset
          vertical
        />
        <!--------------------------------------EXPORT BUTTONS------------------------------------->
        <download-excel
          :fetch="exportData"
          type="xls"
          name="TemporaryParts"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark
          >
            {{ $t('parts.insert_parts.export') }}
            <v-icon right>
              fas fa-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <!--
        <download-excel
          :fetch="exportData"
          type="csv"
          name="TemporaryParts"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark>
            {{ $t('parts.insert_parts.export') }}
            <v-icon right>fas fa-file-csv</v-icon>
          </v-btn>
        </download-excel>
        -->
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-md-and-down" />
        <!--------------------------------------SEARCH FIELD--------------------------------------->
        <v-text-field
          v-model="search"
          :label="$t('parts.insert_parts.search')"
          append-icon="search"
          flat
          solo
          color="grey darken-4"
          single-line
          hide-details
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />
        <!-----------------------------------SEARCH BY ID BUTTON----------------------------------->
        <v-btn
          dark
          small
          @click="searchById = !searchById"
        >
          <v-icon left>
            {{ searchById ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('parts.insert_parts.id_only') }}
        </v-btn>
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-md-and-down" />
        <!-------------------------------------APPROVE BUTTON-------------------------------------->
        <v-btn
          v-if="approveBtn"
          :loading="loading"
          class="hidden-sm-and-down"
          color="grey darken-4"
          dark
          @click="approveMethod"
        >
          <v-icon left>
            fas fa-file-medical
          </v-icon>
          {{ $t('parts.insert_parts.approve') }}
        </v-btn>
        <!---------------------------------------QR SCANNER---------------------------------------->
        <v-btn
          class="hidden-sm-and-down"
          color="grey darken-4"
          dark
          @click="openQrScanner"
        >
          <v-icon left>
            fas fa-qrcode
          </v-icon>
          {{ $t('parts.insert_parts.qr_scanner.open_qr_scanner') }}
        </v-btn>
        <!-----------------------------------INSERT EMPTY PARTS------------------------------------>
        <v-btn
          class="hidden-sm-and-down"
          color="grey darken-4"
          dark
          @click="insert"
        >
          <v-icon left>
            fas fa-file
          </v-icon>
          {{ $t('parts.insert_parts.empty_parts.insert_empty_parts') }}
        </v-btn>
        <!-------------------------------------DELETE BUTTON--------------------------------------->
        <v-btn
          v-if="deleteBtn"
          :loading="loadingDelete"
          color="grey darken-4"
          dark
          @click="deleteMethod"
        >
          <v-icon left>
            delete
          </v-icon>
          {{ $t('parts.insert_parts.delete') }}
        </v-btn>
        <!----------------------------------------------------------------------------------------->
      </v-layout>
    </v-toolbar>
    <!--------------------------------------------------------------------------------------------->
    <div class="redColor">
      <v-layout
        column
        wrap
      >
        <v-layout
          v-if="this.$vuetify.breakpoint.smAndDown"
          row
          align-center
          justify-center
          wrap
        >
          <!------------------------------------APPROVE BUTTON------------------------------------->
          <v-btn
            v-if="approveBtn"
            :loading="loading"
            class="ml-3 mr-3"
            color="grey darken-4"
            dark
            @click="approveMethod"
          >
            {{ $t('parts.insert_parts.approve') }}
            <v-icon right>
              fas fa-file-medical
            </v-icon>
          </v-btn>
          <!--------------------------------------QR SCANNER--------------------------------------->
          <v-btn
            class="ml-3 mr-3"
            color="grey darken-4"
            dark
            @click="openQrScanner"
          >
            <v-icon left>
              fas fa-qrcode
            </v-icon>
            {{ $t('parts.insert_parts.qr_scanner.open_qr_scanner') }}
          </v-btn>
          <!----------------------------------INSERT EMPTY PARTS----------------------------------->
          <v-btn
            class="ml-3 mr-3"
            color="grey darken-4"
            dark
            @click="insert"
          >
            {{ $t('parts.insert_parts.empty_parts.insert_empty_parts') }}
            <v-icon right>
              fas fa-file
            </v-icon>
          </v-btn>
        </v-layout>
      </v-layout>
    </div>
    <!--------------------------------------------------------------------------------------------->
  </div>
</template>
<script>
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import JsonExcel from 'vue-json-excel'; // eslint-disable-line import/extensions

export default {
  components: {
    'download-excel': JsonExcel,
  },
  props: ['items', 'approve', 'delete'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    search: '',
    searchById: false,
    loading: false,
    loadingDelete: false,
  }),
  computed: {
    approveBtn() {
      return this.$props.approve;
    },
    deleteBtn() {
      return this.$props.delete;
    },
  },
  watch: {
    search(value) {
      if (value.length === 0) {
        this.$emit('clear-search');
      }
    },
  },
  methods: {
    insert() {
      this.$emit('insert');
    },
    approveMethod() {
      this.$emit('approved');
    },
    deleteMethod() {
      this.$emit('deleted');
    },
    searchMethod() {
      if (this.search.length === 0) {
        console.log('invalid search parameters...');
      } else {
        this.$emit('search', {
          searchField: this.search,
          idOnly: this.searchById,
        });
      }
    },
    openQrScanner() {
      this.$emit('scanner');
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    startLoadingDelete() {
      this.loadingDelete = true;
    },
    stopLoadingDelete() {
      this.loadingDelete = false;
    },
    setId(id) {
      this.searchById = true;
      this.search = id;
    },
    exportData() {
      const items = [];
      this.$props.items.forEach((item) => {
        const itemObject = {
          Status: item.status === 1 ? 'Available'
            : item.status === 2 ? 'Waiting Approval'
              : item.status === 3 ? 'Review Needed'
                : item.status === 4 ? 'Refused'
                  : item.status === 5 ? 'Approved'
                    : '',
          ID: item._id,
          Institution: item.institution_id.title,
          User: `${item.user_id.name} ${item.user_id.surname}`,
          Price: `${item.price} ${item.currency}`,
          Cost: item.cost ? `${item.cost} ${item.currency}` : '',
          'Shipment Name': item.shipment_id.name,
          'Warranty ID': item.warranty_id.title,
          Quality: item.quality.title,
          Location: (item.shelf_id && item.shelf_data)
            ? `${item.shelf_data.shelf.room_name}-${item.shelf_data.shelf.location_code}: ${
              item.shelf_data.shelf.x}, ${item.shelf_data.shelf.y}`
            : '',
          'Date Created': moment(item.date_created).format('DD-MM-YYYY LT'),
        };
        items.push(itemObject);
      });
      return items;
    },
  },
};
</script>
