<template>
  <v-form @submit.prevent="saveAccount">
    <v-text-field
      v-model="accountName"
      label="Account Name"
    />
    <v-text-field
      v-model="accountNumber"
      label="Account Number"
    />
    <v-text-field
      v-model="bankName"
      label="Bank Name"
    />
    <v-text-field
      v-model="sortCode"
      label="Sort Code"
    />
    <v-text-field
      v-model="iban"
      label="IBAN"
    />
    <v-text-field
      v-model="bicSwift"
      label="BIC/Swift"
    />
  </v-form>
</template>

<script>
export default {
  data() {
    return {
      accountName: '',
      accountNumber: '',
      bankName: '',
      sortCode: '',
      iban: '',
      bicSwift: '',
    };
  },
  methods: {
    saveAccount() {
      // Save account data to database or store, then close dialog
      // You can emit an event or call a prop method to pass the data to parent component
      this.closeDialog();
    },
    closeDialog() {
      // Close the dialog when the form is submitted or cancelled
      this.$emit('close');
    },
  },
};
</script>
