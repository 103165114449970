<template>
  <v-data-table
    v-model="selected"
    :pagination.sync="pagination"
    :headers="headers"
    :items="clients"
    :total-items="totalItems"
    :rows-per-page-items="[5,10,25]"
    item-key="_id"
    :loading="loading"
    select-all
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td>
          <v-checkbox
            v-model="props.selected"
            color="red darken-3"
            hide-details
          />
        </td>
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.surname }}</td>
        <td>{{ props.item.total_debts }}</td>
        <td>{{ props.item.email }}</td>
        <td>{{ props.item.phone }}</td>
        <td>{{ props.item.address }}</td>
        <td>{{ props.item.comment }}</td>
        <td v-if="props.item.institution">
          {{ props.item.institution.title }}
        </td>
        <td v-else />
        <td>{{ props.item.date_created | moment }}</td>
        <td>
          <v-layout row>
            <!---------------------------------------DETAILS--------------------------------------->
            <v-icon
              class="mr-2 pointer"
              @click="showDetails(props.item._id)"
            >
              view_list
            </v-icon>
            <!----------------------------------------EDIT----------------------------------------->
            <v-icon
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'LekoEmployee'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'InstitutionManager'
              "
              class="mr-2 pointer"
              @click="editClient(props.item)"
            >
              edit
            </v-icon>
            <!---------------------------------------DELETE---------------------------------------->
            <v-icon
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'LekoEmployee'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'InstitutionManager'
              "
              class="pointer"
              @click="deleteClient(props.item._id)"
            >
              delete
            </v-icon>
            <!------------------------------------------------------------------------------------->
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';

import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    activeRow: null,
    loading: false,
    headers: [
      {
        text: '',
        sortable: false,
        value: 'name',
      },
      {
        text: '',
        sortable: false,
        value: 'surname',
      },
      {
        text: 'Total Debts',
        sortable: true,
        value: 'total_debts',
      },
      {
        text: '',
        sortable: false,
        value: 'email',
      },
      {
        text: '',
        sortable: false,
        value: 'phone',
      },
      {
        text: '',
        sortable: false,
        value: 'address',
      },
      {
        text: '',
        sortable: false,
        value: 'comment',
      },
      {
        text: '',
        sortable: false,
        value: 'institution',
      },
      {
        text: '',
        sortable: true,
        value: 'date_created',
      },
      {
        text: '',
        sortable: false,
      },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    selected: [],
    clients: [],
    totalItems: 0,
  }),
  computed: {
    searchField() { return this.$props.search; },
  },
  watch: {
    selected(value) { this.$emit('selected', value); },
    pagination: {
      handler() {
        if (!this.searchField || this.searchField.length < 1) this.getClients();
        else this.searchClients(this.searchField);
      },
    },
    searchField: {
      handler(value) {
        if (!value || value.length < 1) this.getClients();
        else this.searchClients(value);
      },
      deep: true,
    },


  },
  created() {
    this.getLocale();
    this.getClients();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('clients.table.name');
      this.headers[1].text = this.$i18n.t('clients.table.surname');
      this.headers[2].text = this.$i18n.t('clients.table.total_debts');
      this.headers[3].text = this.$i18n.t('clients.table.email');
      this.headers[4].text = this.$i18n.t('clients.table.phone');
      this.headers[5].text = this.$i18n.t('clients.table.address');
      this.headers[6].text = this.$i18n.t('clients.table.comment');
      this.headers[7].text = this.$i18n.t('clients.table.institution');
      this.headers[8].text = this.$i18n.t('clients.table.date_created');
      this.headers[9].text = this.$i18n.t('clients.table.actions');
    },
    getClients() {
      this.loading = true;
      getRequest(
        '/client/',
        `role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.clients = response.clients;
          this.totalItems = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    searchClients(value) {
      this.loading = true;
      getRequest(
        '/client/',
        `role=${this.$store.getters.role
        }&search=${value
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.clients = response.clients;
          this.totalItems = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    showDetails(id) {
      this.$emit('details', id);
    },
    editClient(data) {
      this.$emit('edit', data);
    },
    deleteClient(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/client/', `id=${id}`)
          .then((response) => {
            console.log(response);
            this.loading = false;
            this.getClients();
          }).catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
  },
};
</script>
