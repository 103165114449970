<template>
  <v-data-table
    :headers="headers"
    :items="institutions"
    :loading="loading"
    :pagination.sync="pagination"
    :rows-per-page-items="[-1]"
    :search="searchField"
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <!-- Status -->
        <td
          class="text-xs"
          style="width: 30px;"
        >
          <v-icon v-if="props.item.user_status">
            check_circle
          </v-icon>
          <br>
          <span>{{ props.item._id.slice(-4) }}</span>
        </td>
        <!-- Actions -->
        <td
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee'"
          class="text-xs-center"
        >
          <v-layout column>
            <v-icon
              class="mr-2 pointer"
              @click="editCars(props.item._id)"
            >
              drive_eta
            </v-icon>
            <v-icon
              class="mr-2 mt-2 pointer"
              @click="editInstitution(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              class="mr-2 mt-2 pointer"
              @click="deleteInstitution(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
        <!-- Created At -->
        <td
          v-if="props.item.date_created"
          class="text-xs-center"
        >
          {{ props.item.date_created | moment }}
        </td>
        <td
          v-if="!props.item.date_created"
          class="text-xs-center"
        >
          {{ todayDate | moment }}
        </td>
        <!-- Profile Details -->
        <td
          class="text-xs"
          style="height: 150px;
          width:200px;"
        >
          <a
            @click="showDetails(props.item)"
          >
            <strong>
              <v-icon small>
                store
              </v-icon>
              {{ props.item.title }}
            </strong>
          </a> <br>
          <v-icon small>
            place
          </v-icon>
          {{ props.item.address }} <br>
          <v-icon small>
            home
          </v-icon>
          {{ props.item.city }},
          {{ props.item.state }} <br>
          <v-icon small>
            email
          </v-icon>
          {{ props.item.email }}<br>
          <v-icon small>
            call
          </v-icon>
          {{ props.item.phone }}<br>
          <v-icon small>
            language
          </v-icon>
          <a
            :href="props.item.website"
            target="_blank"
          >{{ props.item.website }}</a><br>
          <v-icon small>
            language
          </v-icon>
          <a
            :href="props.item.shop"
            target="_blank"
          >{{ props.item.shop }}</a>
        </td>
        <!-- Logo -->
        <td>
          <v-layout
            align-center
            justify-center
          >
            <img
              v-if="props.item.logo"
              :src="`${server}/images?q=${props.item.logo}`"
              style="width: 170px; height: 70px;"
            >
          </v-layout>
        </td>


        <!-- Lekotech Shop -->
        <!-- <td class="text-xs">
          <a
            :href="props.item.shop"
            target="_blank"
          >{{ props.item.shop }}</a>
        </td> -->


        <!-- Cars -->
        <td class="text-xs">
          {{ props.item.carsCount }}
        </td>


        <!-- Users -->
        <td class="text-xs">
          {{ props.item.usersCount }}
        </td>
        <!-- Used / Remaining Plates -->
        <td class="text-xs">
          {{ props.item.plates.used }} /
          {{ props.item.plates.remaining }}
        </td>
        <!-- Used Plates Monthly -->
        <td class="text-xs">
          {{ props.item.usedPlatesNumber }}
        </td>
        <!-- Parts -->
        <td class="text-xs">
          {{ props.item.partsCount }}
        </td>
        <!--        <td-->
        <!--          class="text-xs-center"-->
        <!--        >-->
        <!--          <v-icon v-if="props.item.export_parts">-->
        <!--            check_circle-->
        <!--          </v-icon>-->
        <!--        </td>-->
        <td class="text-xs-center">
          {{ props.item.temporaryPartsCount }}
        </td>
        <!--        <td-->
        <!--          class="text-xs-center"-->
        <!--        >-->
        <!--          <v-icon v-if="props.item.export_temporary_parts">-->
        <!--            check_circle-->
        <!--          </v-icon>-->
        <!--        </td>-->
        <td class="text-xs-left">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 200px"
          >
            <span>{{ $t('institutions.table.exportParts') }}</span>
            <v-icon v-if="props.item.export_parts">
              check_circle
            </v-icon>
          </div>
          <br>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 200px"
          >
            <span>{{ $t('institutions.table.exportTempParts') }}</span>
            <v-icon v-if="props.item.export_temporary_parts">
              check_circle
            </v-icon>
          </div>
          <br>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 200px"
          >
            <span>{{ $t('institutions.table.exportSales') }}</span>
            <v-icon v-if="props.item.export_sales">
              check_circle
            </v-icon>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';
import institutionDetails from '@/components/Institutions/institutionDetails.vue';


export default {
  components: {
    institutionDetails,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LL');
    },
  },
  data: () => ({
    todayDate: new Date(),
    headers: [
      {
        text: '',
        sortable: true,
        value: 'user_status',
      },
      {
        text: '',
        sortable: false,
      },
      {
        text: '',
        sortable: true,
        value: 'createdDate',
      },
      {
        text: '',
        sortable: false,
        value: 'profile',
      },
      {
        text: '',
        sortable: false,
        value: 'logo',
      },
      // {
      //   text: '',
      //   sortable: false,
      //   value: 'shop',
      // },
      // {
      //   text: '',
      //   sortable: false,
      //   value: 'type',
      // },
      {
        text: '',
        sortable: true,
        value: 'carsCount',
      },
      {
        text: '',
        sortable: false,
        value: 'nrUsers',
      },
      {
        text: '',
        sortable: true,
        value: 'plates.remaining',
      },
      {
        text: '',
        sortable: true,
        value: 'usedPlatesNumber',
      },
      {
        text: '',
        sortable: true,
        value: 'partsCount',
      },
      // {
      //   text: '',
      //   sortable: false,
      //   value: 'export_parts',
      // },
      {
        text: '',
        sortable: true,
        value: 'temporaryPartsCount',
      },
      // {
      //   text: '',
      //   sortable: false,
      //   value: 'export_temporary_parts',
      // },
      {
        text: '',
        sortable: false,
        value: 'export_sales',
      },
    ],
    pagination: {
      sortBy: 'user_status',
      descending: true,
      page: 1,
    },
    institutions: [],
    loading: false,
    activeRow: null,
    searchField: '',
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  created() {
    this.getLocale();
  },
  beforeMount() {
    this.getInstitutions();
  },
  methods: {
    showDetails(item) {
      // Navigate to the institution details page and pass the institution data
      this.$router.push(`/institution/${item._id}`);
    },

    getLocale() {
      this.headers[0].text = this.$i18n.t('institutions.table.users');
      this.headers[1].text = this.$i18n.t('institutions.table.actions');
      this.headers[2].text = this.$i18n.t('institutions.table.createdDate');
      this.headers[3].text = this.$i18n.t('institutions.table.profile');
      this.headers[4].text = this.$i18n.t('institutions.table.logo');
      // this.headers[5].text = this.$i18n.t('institutions.table.shop');
      // this.headers[6].text = this.$i18n.t('institutions.table.type');
      this.headers[5].text = this.$i18n.t('institutions.table.cars');
      this.headers[6].text = this.$i18n.t('institutions.table.users');
      this.headers[7].text = this.$i18n.t('institutions.form.plates');
      this.headers[8].text = this.$i18n.t('institutions.form.used_plates');
      this.headers[9].text = this.$i18n.t('institutions.table.inventory');
      // this.headers[10].text = this.$i18n.t('institutions.table.exportParts');
      this.headers[10].text = this.$i18n.t('parts.insert_parts.inserted_parts');
      // this.headers[12].text = this.$i18n.t('institutions.table.exportTempParts');
      this.headers[11].text = this.$i18n.t('institutions.table.exportSales');
    },
    search(value) {
      this.searchField = value;
    },
    getInstitutions() {
      this.loading = true;
      getRequest('/institution/')
        .then((response) => {
          console.log(response);
          this.institutions = response.institutions;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$emit('error', error);
        });
    },
    editInstitution(data) {
      this.$emit('edit', data);
    },
    editCars(id) {
      this.$emit('cars', id);
    },
    deleteInstitution(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        deleteRequest('/institution', `id=${id}`)
          .then(() => {
            this.getInstitutions();
          })
          .catch((error) => {
            this.$emit('error', error);
          });
      }
    },
  },
};
</script>

