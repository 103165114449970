<template>
  <v-data-table
    v-model="selectedItems"
    :pagination.sync="pagination"
    :headers="headers"
    :items="parts"
    :total-items="totalItems"
    :rows-per-page-items="[5,10]"
    item-key="_id"
    select-all
    :loading="loading"
    class="elevation-1"
    @update:pagination="''"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr class="tableRowColor">
        <!----------------------------------------SELECTION---------------------------------------->
        <td>
          <v-layout
            align-center
            justify-center
          >
            <v-checkbox
              v-model="props.selected"
              class="mt-2"
              color="primary"
              hide-details
            />
          </v-layout>
        </td>
        <!-------------------------------------------ID-------------------------------------------->
        <!--OLD ID-->
        <td v-if="props.item.old_id && props.item.old_id !== undefined">
          <v-layout
            ma-1
            align-center
            justify-center
            column
          >
            <qr-code
              :size="60"
              :text="props.item.old_id"
            />
            {{ props.item.old_id.slice(props.item.old_id.length-6, props.item.old_id.length) }}
          </v-layout>
        </td>
        <!--NEW ID-->
        <td v-else>
          <v-layout
            ma-1
            align-center
            justify-center
            column
          >
            <qr-code
              :size="60"
              :text="props.item._id"
            />
            {{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}
          </v-layout>
        </td>
        <!-----------------------------------------ACTIONS----------------------------------------->
        <td>
          <v-layout
            row
            align-center
          >
            <!--DETAILS-->
            <v-btn
              icon
              @click="details(props.item)"
            >
              <v-icon color="grey darken-2">
                view_list
              </v-icon>
            </v-btn>
          </v-layout>
        </td>
        <!------------------------------------------GROUP------------------------------------------>
        <td>
          <v-layout column>
            <v-flex>
              <span
                v-if="props.item.infoCar
                  && props.item.infoCar.description
                  && props.item.infoCar.description.descriptionId
                  && props.item.infoCar.description.descriptionId.category"
                class="text-xs font-weight-bold"
              >
                <!-- eslint-disable-next-line max-len -->
                {{ props.item.infoCar.description.descriptionId.category[$store.getters.language] }}
              </span>
            </v-flex>
          </v-layout>
        </td>
        <!------------------------------------------TITLE------------------------------------------>
        <td class="text-xs">
          <v-layout column>
            <v-flex v-if="props.item.infoCar && props.item.infoCar.description">
              <span
                v-if="props.item.infoCar.description.descriptionId
                  && props.item.infoCar.description.descriptionId.title"
                class="text-xs font-weight-bold"
              >
                {{ props.item.infoCar.description.descriptionId.title[$store.getters.language] }}
              </span>
              <span
                v-if="props.item.infoCar.description.positionCode"
                class="text-xs"
              >
                {{ getPosition(props.item.infoCar.description.positionCode) }}
              </span>
            </v-flex>
          </v-layout>
        </td>
        <!------------------------------------MANUFACTURER CODE------------------------------------>
        <td class="text-xs">
          {{ props.item.manufacturer_code }}
        </td>
        <!-----------------------------------------IMAGES------------------------------------------>
        <td>
          <v-layout
            align-center
            justify-center
          >
            <img
              :src="`${server}/images/thumb?q=${props.item._id}-${props.item.images[0]
              }&date=${props.item.date_created}`"
              class="ma-1 pointer"
              height="75"
              width="100"
              @click="showImages(props.item._id, props.item.images, props.item.date_created)"
            >
          </v-layout>
        </td>
        <!------------------------------------------NOTES------------------------------------------>
        <td class="text-xs">
          {{ props.item.notes }}
        </td>
        <!------------------------------------------PRICE------------------------------------------>
        <td class="text-xs">
          {{ props.item.price }} {{ props.item.currency }}
        </td>
        <!----------------------------------------LOCATION----------------------------------------->
        <td
          v-if="props.item.shelf_data"
          class="text-xs"
        >
          {{ props.item.shelf_data.repo }} {{ props.item.shelf_data.shelf.room_name }} -
          {{ props.item.shelf_data.shelf.location_code }}: {{ props.item.shelf_data.shelf.x }},
          {{ props.item.shelf_data.shelf.y }}
        </td>
        <td
          v-else
          class="text-xs"
        >
          -
        </td>
        <!-----------------------------------------QUALITY----------------------------------------->
        <td v-if="props.item.quality">
          <div style="width: 150px">
            <v-rating
              :value="props.item.quality.rating"
              color="red"
              background-color="red darken-4"
              readonly
              small
            />
          </div>
        </td>
        <td v-else />
        <!------------------------------------------DATE------------------------------------------->
        <td>{{ props.item.date_created | moment }}</td>
        <!-----------------------------------------STATUS------------------------------------------>
        <td>
          <v-layout
            align-center
            justify-center
          >
            <v-btn
              v-if="props.item.status === 1"
              small
              color="success"
              outline
              @click="movement(props.item)"
            >
              {{ $t('parts.status.entered') }}
            </v-btn>
            <v-btn
              v-if="props.item.status === 2"
              small
              color="error"
              outline
              @click="movement(props.item)"
            >
              {{ $t('parts.status.returned') }}
            </v-btn>
            <v-btn
              v-if="props.item.status === 2.1"
              small
              color="error"
              outline
              @click="movement(props.item)"
            >
              {{ $t('parts.status.returned_shop') }}
            </v-btn>
            <v-btn
              v-if="props.item.status === 3"
              small
              color="info"
              outline
              @click="movement(props.item)"
            >
              {{ $t('parts.status.re_entered') }}
            </v-btn>
            <v-btn
              v-if="props.item.status === 4"
              small
              color="info"
              outline
              @click="movement(props.item)"
            >
              {{ $t('parts.status.found') }}
            </v-btn>
          </v-layout>
        </td>
        <!----------------------------------------------------------------------------------------->
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../../api/getRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: {
    search: {
      type: Object,
      default: null,
    },
    selected: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    selectedItems: [],
    totalItems: 0,
    parts: [],
    headers: [
      {
        text: 'QR Code',
        sortable: true,
        value: '_id',
      },
      {
        text: 'Actions',
        sortable: false,
        value: '',
      },
      {
        text: 'Group',
        sortable: true,
        value: 'group',
      },
      {
        text: 'Title',
        sortable: true,
        value: 'title',
      },
      {
        text: 'Manufacturer Code',
        sortable: false,
        value: 'manufacturer_code',
      },
      {
        text: 'Images',
        sortable: false,
        value: '',
      },
      {
        text: 'Notes',
        sortable: false,
        value: 'notes',
      },
      {
        text: 'Price',
        sortable: true,
        value: 'price',
      },
      {
        text: 'Location',
        sortable: true,
        value: 'shelf_data.shelf.room_name',
      },
      {
        text: 'Quality',
        sortable: true,
        value: 'quality',
      },
      {
        text: 'Date Created',
        sortable: true,
        value: 'date_created',
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    searchObject() {
      return this.$props.search;
    },
  },
  watch: {
    searchObject(value) {
      if (!value || !value.type) this.getParts();
      else this.searchMethod(value);
    },
    selectedItems(value) {
      this.$emit('select', value);
    },
    pagination: {
      handler() {
        if (!this.searchObject || !this.searchObject.type) {
          this.getParts();
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(
            this.searchObject.searchField,
            this.searchObject.advancedSearchFields,
          );
        } else if (this.searchObject.type === 'idOnly') {
          this.searchById(this.searchObject.searchField);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getLocale();
    this.getParts();
    this.selectedItems = this.$props.selected ? this.$props.selected : [];
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('reservations.parts.datatable.qr_code');
      this.headers[1].text = this.$i18n.t('reservations.parts.datatable.actions');
      this.headers[2].text = this.$i18n.t('reservations.parts.datatable.group');
      this.headers[3].text = this.$i18n.t('reservations.parts.datatable.title');
      this.headers[4].text = this.$i18n.t('reservations.parts.datatable.manufacturer_code');
      this.headers[5].text = this.$i18n.t('reservations.parts.datatable.images');
      this.headers[6].text = this.$i18n.t('reservations.parts.datatable.notes');
      this.headers[7].text = this.$i18n.t('reservations.parts.datatable.price');
      this.headers[8].text = this.$i18n.t('reservations.parts.datatable.location');
      this.headers[9].text = this.$i18n.t('reservations.parts.datatable.quality');
      this.headers[10].text = this.$i18n.t('reservations.parts.datatable.date_created');
      this.headers[11].text = this.$i18n.t('reservations.parts.datatable.status');
    },
    getParts() {
      this.loading = true;
      getRequest(
        '/part/inventory/datatable',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.totalItems = response.count;
          this.parts = response.parts;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    searchMethod(data) {
      this.loading = true;
      getRequest(
        '/part/inventory/search/advanced',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&language=${this.$store.getters.language
        }&title_code=${data.titleCode
        }&type=${data.type
        }&brand=${data.advancedSearchFields.brand
        }&model=${data.advancedSearchFields.model
        }&version=${data.advancedSearchFields.version
        }&institution=${data.advancedSearchFields.institution
        }&shipment=${data.advancedSearchFields.shipment
        }&status=${data.advancedSearchFields.status
        }&quality=${data.advancedSearchFields.quality
        }&price=${data.advancedSearchFields.price
        }&start_date=${data.advancedSearchFields.startDate
        }&end_date=${data.advancedSearchFields.endDate}`,
      )
        .then((response) => {
          console.log(response);
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    showImages() {},
    details(data) {
      this.$emit('details', data);
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
  },
};
</script>
