<template>
  <v-container>
    <v-card flat>
      <v-card-title class="redColor">
        <span class="headline">{{ $t('institutions.cars.label') }}</span>
      </v-card-title>
      <v-card-text>
        <v-layout
          v-if="loading"
          class="ma-4"
          justify-center
        >
          <v-progress-circular
            :size="100"
            color="red darken-2"
            indeterminate
          />
        </v-layout>

        <v-layout
          v-if="!loading"
          row
          wrap
        >
          <!--ALERT-->
          <v-flex xs12>
            <v-alert
              :value="alert.value"
              :type="alert.type"
            >
              {{ alert.message }}
            </v-alert>
          </v-flex>

          <!--SELECTED CARS-->
          <v-flex
            xs12
            class="mb-4"
          >
            <v-chip
              v-for="(car, index) in cars"
              :key="index"
              close
              @input="removeCar(index)"
            >
              {{ car.brand.title }} {{ car.model.description }} {{ car.version.description }}
            </v-chip>
          </v-flex>
          <!--BRAND-->
          <v-flex
            xs12
            sm6
          >
            <v-autocomplete
              v-model="car.brand"
              :items="brands"
              :label="$t('parts.advanced_search.brand')"
              item-text="title"
              flat
              solo
              color="red darken-1"
              class="ml-2 mr-2 mt-2"
              hide-details
              return-object
            >
              <template v-slot:item="{ item }">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                >
                  <v-flex
                    xs3
                    sm2
                    lg1
                  >
                    <v-img
                      :src="item.logo"
                      max-height="35"
                      max-width="35"
                    />
                  </v-flex>
                  <v-flex
                    xs9
                    sm10
                    lg11
                  >
                    <span> {{ item.title }} </span>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:selection="{ item }">
                <v-layout
                  row
                  wrap
                  align-center
                  justify-center
                >
                  <v-flex
                    xs3
                    lg2
                  >
                    <v-img
                      :src="item.logo"
                      max-height="35"
                      max-width="35"
                    />
                  </v-flex>
                  <v-flex
                    xs9
                    lg10
                  >
                    <span> {{ item.title }} </span>
                  </v-flex>
                </v-layout>
              </template>
            </v-autocomplete>
          </v-flex>
          <!--MODEL-->
          <v-flex
            xs12
            sm6
          >
            <v-autocomplete
              v-model="car.model"
              :items="models"
              :label="$t('parts.advanced_search.model')"
              item-text="fullDescription"
              flat
              solo
              color="red darken-1"
              class="ml-2 mr-2 mt-2"
              hide-details
              return-object
            >
              <template v-slot:item="{ item }">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    md7
                  >
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                      <span class="body-2">
                        {{ item.description }}
                      </span>
                    </v-layout>
                  </v-flex>

                  <v-flex
                    class="hidden-sm-and-down"
                    md5
                  >
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                      <span class="body-2">
                        {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                      </span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:selection="{ item }">
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    xs12
                    md7
                  >
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                      <span class="body-2">
                        {{ item.description }}
                      </span>
                    </v-layout>
                  </v-flex>

                  <v-flex
                    class="hidden-sm-and-down"
                    md5
                  >
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                      <span class="body-2">
                        {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                      </span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>
            </v-autocomplete>
          </v-flex>
          <!--VERSION-->
          <v-flex xs12>
            <v-autocomplete
              v-model="car.versions"
              :items="versions"
              :label="$t('parts.advanced_search.version')"
              item-text="description"
              flat
              solo
              chips
              color="red darken-1"
              class="ml-2 mr-2 mt-2"
              hide-details
              multiple
              return-object
            >
              <!--SELECTION LIST-->
              <template v-slot:item="{ item }">
                <v-layout
                  row
                  wrap
                >
                  <v-flex>
                    <v-layout class="mt-2">
                      <v-icon
                        v-if="car.versions.findIndex(v => v._id === item._id) > -1"
                        color="red darken-1"
                      >
                        check_box
                      </v-icon>
                      <v-icon v-else>
                        check_box_outline_blank
                      </v-icon>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    xs12
                    md5
                  >
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                      <span class="body-2">{{ item.description }}</span>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    class="hidden-sm-and-down"
                    md2
                  >
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                      <span class="body-2">
                        {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                      </span>
                    </v-layout>
                  </v-flex>
                  <v-flex class="hidden-sm-and-down">
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                      <span class="body-2">{{ item.numberOfDoors }}</span>
                    </v-layout>
                  </v-flex>
                  <v-flex class="hidden-sm-and-down">
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                      <span class="body-2">
                        {{ item.power.kw }} kw,
                      </span>
                    </v-layout>
                  </v-flex>
                  <v-flex class="hidden-sm-and-down">
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                      <span class="body-2">{{ item.engineCode }}</span>
                    </v-layout>
                  </v-flex>
                  <v-flex class="hidden-sm-and-down">
                    <v-layout
                      column
                      wrap
                    >
                      <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                      <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </template>

              <!--SELECTED-->
              <template v-slot:selection="{ item }">
                <v-chip
                  :selected="item.selected"
                  close
                  class="chip--select-multi"
                  @input="removeSelectedVersion(item)"
                >
                  {{ item.description }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-flex>
          <v-flex xs12>
            <v-layout
              justify-center
              wrap
            >
              <v-btn
                :disabled="!car.brand || !car.model || versions.length < 1"
                color="primary"
                depressed
                @click="addAllVersions"
              >
                {{ $t('institutions.cars.add_all_versions') }}
              </v-btn>

              <v-btn
                :disabled="!car.brand || !car.model || !car.versions || car.versions.length < 1"
                color="primary"
                depressed
                @click="addCar"
              >
                {{ $t('institutions.cars.add_car') }}
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :loading="loadingSave"
          color="blue darken-1"
          flat
          @click="save"
        >
          {{ $t('institutions.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment';

import getRequest from '../api/getRequest';
import putRequest from '../api/putRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    loading: false,
    loadingSave: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    brands: [],
    models: [],
    versions: [],
    car: {
      brand: null,
      model: null,
      versions: [],
    },
    cars: [],
    alert: {
      value: false,
      type: 'info',
      message: '',
    },
  }),
  watch: {
    'car.brand': {
      handler(value) {
        if (value) {
          this.models = [];
          this.model = null;
          this.versions = [];
          this.version = null;
          this.getModels(value.brandCode);
        }
      },
    },
    'car.model': {
      handler(value) {
        if (value) {
          this.versions = [];
          this.version = null;
          this.getVersions(value.brandCode, value.modelCode);
        }
      },
    },
  },
  created() {
    this.getCars();
    this.getBrands();
  },
  methods: {
    getCars() {
      this.loading = true;
      getRequest('/institution/cars', `institution_id=${this.$store.getters.institutionId}`)
        .then((response) => {
          console.log(response);
          this.cars = response.cars;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    clearCars() {
      this.car = {
        brand: null,
        model: null,
        versions: [],
      };
    },
    addAllVersions() {
      this.car.versions = [];
      this.versions.forEach(v => this.car.versions.push(v));
    },
    addCar() {
      const selectedCars = this.car.versions.map(version => ({
        brand: this.car.brand,
        model: this.car.model,
        version,
      }));
      // Check if car is already selected to avoid duplications.
      const cars = selectedCars.reduce((accumulator, current) => {
        const found = this.cars.find(car => car.brand._id === current.brand._id
        && car.model._id === current.model._id
        && car.version._id === current.version._id);
        if (!found) accumulator.push(current);
        return accumulator;
      }, []);

      this.cars = this.cars.concat(cars);
      this.clearCars();
    },
    removeCar(index) {
      this.cars.splice(index, 1);
    },
    removeSelectedVersion(version) {
      const index = this.car.versions.findIndex(v => v._id.toString() === version._id.toString());
      this.car.versions.splice(index, 1);
    },
    save() {
      this.loadingSave = true;
      const cars = this.cars.map(car => ({
        brand: car.brand._id,
        model: car.model._id,
        version: car.version._id,
      }));

      putRequest('/institution/cars', `institution_id=${this.$store.getters.institutionId}`, { cars })
        .then((response) => {
          console.log(response);
          this.getCars();
          this.alert = { value: true, type: 'success', message: this.$i18n.t('institutions.cars.saved_alert') };
        })
        .catch((error) => {
          console.log(error);
          this.alert = { value: true, type: 'error', message: this.$i18n.t('alert') };
        })
        .finally(() => {
          this.loadingSave = false;
          setTimeout(() => { this.alert.value = false; }, 5000);
        });
    },

    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => { this.models = response.models; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => { this.versions = response.versions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },

    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
