<template>
  <div>
    <v-data-table
      :pagination.sync="pagination"
      :headers="$store.getters.role === 'LekoAdmin' ? headersAdmin : headers"
      :items="parts"
      :total-items="totalItems"
      :rows-per-page-items="[5,10,25]"
      :loading="loading"
      class="elevation-1"
      @update:pagination="updatePagination"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          :class="{tableRowColorSelected : props.index === activeRow}"
          class="tableRowColor"
          @click="activeRow = props.index"
        >
          <!----------------------------------------STATUS----------------------------------------->
          <td>
            <v-layout
              align-center
              justify-start
            >
              <v-btn
                v-if="props.item.status === 1"
                small
                color="success"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.entered') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 2"
                small
                color="warning"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.returned') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 2.1"
                small
                color="warning"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.returned_shop') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 3"
                small
                color="info"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.re_entered') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 4"
                small
                color="info"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.found') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 5"
                small
                color="success"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.sold') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 6"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.lost') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 6.1"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.lost_shop') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 7"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.damaged_inventory') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 8"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.damaged_sales') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 8.1"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.damaged_shop') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 9"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.damaged_lost') }}
              </v-btn>
              <v-btn
                v-if="props.item.status === 0"
                small
                color="error"
                outline
                @click="movement(props.item)"
              >
                {{ $t('parts.status.recycled') }}
              </v-btn>
            </v-layout>
          </td>
          <!------------------------------------------ID------------------------------------------->
          <td v-if="props.item.old_id && props.item.old_id !== undefined">
            <v-layout
              class="pointer"
              ma-1
              align-start
              justify-start
              column
              @click="openQrDialog({
                id: props.item.old_id,
                type: 'inventory',
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item.old_id"
              />
              {{ props.item.old_id.slice(props.item.old_id.length-6, props.item.old_id.length) }}
            </v-layout>
          </td>
          <td
            v-else
            class="text-xs"
          >
            <v-layout
              class="pointer"
              ma-1
              align-start
              justify-center
              column
              @click="openQrDialog({
                id: props.item._id,
                type: 'inventory',
                printed: props.item.printed
              })"
            >
              <qr-code
                :size="60"
                :text="props.item._id"
              />
              {{ props.item._id.slice(props.item._id.length-4, props.item._id.length) }}
            </v-layout>
          </td>
          <!--------------------------------------LEKO GROUP--------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.group"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.infoCar.description.descriptionId.group[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------GROUP----------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="props.item.infoCar
                    && props.item.infoCar.description
                    && props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.category"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ props.item.infoCar.description.descriptionId.category[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------TITLE----------------------------------------->
          <td class="text-xs">
            <v-layout column>
              <v-flex v-if="props.item.infoCar && props.item.infoCar.description">
                <span
                  v-if="props.item.infoCar.description.descriptionId
                    && props.item.infoCar.description.descriptionId.title"
                  class="text-xs font-weight-bold"
                >
                  {{ props.item.infoCar.description.descriptionId.title[$store.getters.language] }}
                </span>
                <span
                  v-if="props.item.infoCar.description.positionCode"
                  class="text-xs"
                >
                  {{ getPosition(props.item.infoCar.description.positionCode) }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!--------------------------------------INSTITUTION-------------------------------------->
          <td
            v-if="$store.getters.role === 'LekoAdmin' && props.item.institution_id"
            class="text-xs"
          >
            {{ props.item.institution_id.title }}
          </td>
          <td v-else-if="$store.getters.role === 'LekoAdmin' && !props.item.institution_id" />
          <!----------------------------------------PHOTOS----------------------------------------->
          <td>
            <v-layout
              align-start
              justify-start
            >
              <img
                :src="`${server}/images?q=${props.item.images && props.item.images.length
                  ? props.item.images[0].thumb : ''}`"
                class="ma-1 pointer"
                height="75"
                width="100"
                @click="showImages(props.item.images)"
              >
            </v-layout>
          </td>
          <!-----------------------------------------DATE------------------------------------------>
          <td class="text-xs-start">
            {{ props.item.date_created | moment }}
          </td>
          <!--------------------------------------------------------------------------------------->
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator'; // eslint-disable-line import/extensions

import getRequest from '../../api/getRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['search'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    activeRow: null,
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    totalItems: 0,
    parts: [],
    headers: [
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
      {
        text: 'ID',
        sortable: false,
        value: '',
      },
      {
        text: '',
        sortable: true,
        value: 'leko_group',
      },
      {
        text: 'Group',
        sortable: true,
        value: 'group',
      },
      {
        text: 'Title',
        sortable: true,
        value: 'title',
      },
      {
        text: 'Photos',
        sortable: false,
        value: '',
      },
      {
        text: 'Date Created',
        sortable: true,
        value: 'date_created',
      },
    ],
    headersAdmin: [
      {
        text: 'Status',
        sortable: true,
        value: 'status',
      },
      {
        text: 'ID',
        sortable: false,
        value: '',
      },
      {
        text: '',
        sortable: true,
        value: 'leko_group',
      },
      {
        text: 'Group',
        sortable: true,
        value: 'group',
      },
      {
        text: 'Title',
        sortable: true,
        value: 'title',
      },
      {
        text: 'Institution',
        sortable: true,
        value: 'institution_id',
      },
      {
        text: 'Photos',
        sortable: false,
        value: '',
      },
      {
        text: 'Date Created',
        sortable: true,
        value: 'date_created',
      },
    ],
  }),
  computed: {
    server() { return this.$serverUri; },
    searchObject() { return this.$props.search; },
  },
  watch: {
    pagination: {
      handler() {
        if (this.searchObject.type === null) {
          this.getParts();
        } else if (this.searchObject.type === 'regularSearch') {
          this.regularSearch(this.searchObject.data);
        } else if (this.searchObject.type === 'advancedSearch') {
          this.advancedSearch(this.searchObject.data);
        } else {
          console.log('search type is invalid');
        }
      },
      deep: true,
    },
    searchObject: {
      handler(value) {
        if (value.type === null) {
          this.getParts();
        } else if (value.type === 'regularSearch') {
          this.regularSearch(value.data);
        } else if (value.type === 'advancedSearch') {
          this.advancedSearch(value.data);
        } else {
          console.log('search type is invalid');
        }
      },
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.table.status');
      this.headers[1].text = this.$i18n.t('parts.table.qr_code');
      this.headers[2].text = this.$i18n.t('parts.table.leko_group');
      this.headers[3].text = this.$i18n.t('parts.table.group');
      this.headers[4].text = this.$i18n.t('parts.table.title');
      this.headers[5].text = this.$i18n.t('parts.table.images');
      this.headers[6].text = this.$i18n.t('parts.table.date_created');
      this.headersAdmin[0].text = this.$i18n.t('parts.table.status');
      this.headersAdmin[1].text = this.$i18n.t('parts.table.qr_code');
      this.headersAdmin[2].text = this.$i18n.t('parts.table.leko_group');
      this.headersAdmin[3].text = this.$i18n.t('parts.table.group');
      this.headersAdmin[4].text = this.$i18n.t('parts.table.title');
      this.headersAdmin[5].text = this.$i18n.t('parts.table.institution');
      this.headersAdmin[6].text = this.$i18n.t('parts.table.images');
      this.headersAdmin[7].text = this.$i18n.t('parts.table.date_created');
    },
    getParts() {
      this.loading = true;
      getRequest(
        '/part/movements/datatable',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.loading = false;
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    regularSearch(data) {
      this.loading = true;
      getRequest(
        '/part/movements/search/regular',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&title_code=${data.titleCode
        }&title_code=${data.titleCode
        }&title_codes=${data.titleCodes
        }&type=${data.type
        }&language=${this.$store.getters.language}`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    advancedSearch(data) {
      this.loading = true;
      getRequest(
        '/part/movements/search/advanced',
        `institution_id=${this.$store.getters.institutionId
        }&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending
        }&search_field=${data.searchField
        }&language=${this.$store.getters.language
        }&title_code=${data.titleCode
        }&title_codes=${data.titleCodes
        }&type=${data.type
        }&status=${data.advancedSearchFields.status
        }&start_date=${data.advancedSearchFields.startDate
        }&end_date=${data.advancedSearchFields.endDate}`,
      )
        .then((response) => {
          console.log(response);
          this.$scrollToTop();
          this.totalItems = response.count;
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error.response); })
        .finally(() => { this.loading = false; });
    },
    movement(data) {
      this.$emit('movement', data);
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    showImages(images) {
      this.$emit('images', { type: 'string', images });
    },
    updatePagination() {
      this.activeRow = null;
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
  },
};

</script>
