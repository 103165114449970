<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
  >
    <v-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="ma-2"
          outline
          icon
          large
          color="red"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-layout wrap>
          <v-flex
            mb-2
            xs12
          >
            <!--LOADING-->
            <v-layout
              v-if="loading"
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                size="64"
                color="red"
              />
            </v-layout>

            <!--EMPTY-->
            <v-layout
              v-if="empty"
              justify-center
            >
              <h3 class="red--text">
                {{ $t('repositories.shelf_content.empty') }}
              </h3>
            </v-layout>
          </v-flex>

          <!--PARTS-->
          <v-flex
            v-if="!loading"
            pr-1
            xs6
          >
            <!--COUNT-->
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('repositories.shelf_content.parts') }}: {{ parts.length }}
              </v-toolbar-title>
            </v-toolbar>
            <!-- PARTS LIST-->
            <v-list>
              <v-list-tile
                v-for="(item, index) in parts"
                :key="index"
                class="listRowColor"
                @click="''"
              >
                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-layout
                      v-if="item.old_id"
                      align-center
                      justify-center
                    >
                      <span>{{ item.old_id.slice(item.old_id.length-6, item.old_id.length) }}</span>
                      <span
                        v-if="item.infoCar
                          && item.infoCar.description
                          && item.infoCar.description.descriptionId
                          && item.infoCar.description.descriptionId.title"
                      ><pre> - </pre></span>
                      <span>{{ multilanguage(item) }}</span>
                    </v-layout>
                    <v-layout
                      v-else
                      align-center
                      justify-center
                    >
                      <span>{{ item._id.slice(item._id.length-6, item._id.length) }}</span>
                      <span
                        v-if="item.infoCar
                          && item.infoCar.description
                          && item.infoCar.description.descriptionId
                          && item.infoCar.description.descriptionId.title"
                      ><pre> - </pre></span>
                      <span>{{ multilanguage(item) }}</span>
                    </v-layout>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>

          <!--EMPTY PARTS-->
          <v-flex
            v-if="!loading"
            pl-1
            xs6
          >
            <!--COUNT-->
            <v-toolbar flat>
              <v-toolbar-title>
                {{ $t('repositories.shelf_content.temporary_parts') }}: {{ temporaryParts.length }}
              </v-toolbar-title>
            </v-toolbar>
            <!-- TEMPORARY PARTS LIST-->
            <v-list>
              <v-list-tile
                v-for="(item, index) in temporaryParts"
                :key="index"
                class="listRowColor"
                @click="{}"
              >
                <v-list-tile-content>
                  <v-list-tile-title>
                    <v-layout
                      align-center
                      justify-center
                    >
                      <span>{{ item._id.slice(item._id.length-6, item._id.length) }}</span>
                      <span
                        v-if="item.infoCar
                          && item.infoCar.description
                          && item.infoCar.description.descriptionId
                          && item.infoCar.description.descriptionId.title"
                      ><pre> - </pre></span>
                      <span>{{ multilanguage(item) }}</span>
                    </v-layout>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    dialog: false,
    loading: false,
    empty: false,
    parts: [],
    temporaryParts: [],
  }),
  watch: {
    dialog(value) {
      if (!value) this.close();
    },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.getParts(data);
    },
    close() {
      this.empty = false;
      this.dialog = false;
      this.parts = [];
      this.temporaryParts = [];
      this.$emit('close');
    },
    getParts(data) {
      console.log(data);
      this.loading = true;
      getRequest('/repository/parts', `id=${data._id}`)
        .then((response) => {
          console.log(response);
          this.parts = response.parts;
          this.temporaryParts = response.temporaryParts;
          if (this.parts.length < 1 && this.temporaryParts.length < 1) this.empty = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    multilanguage(item) {
      if (item.infoCar
          && item.infoCar.description
          && item.infoCar.description.descriptionId
          && item.infoCar.description.descriptionId.title) {
        return item.infoCar.description.descriptionId.title[this.$store.getters.language];
      }
      return '';
    },
  },
};
</script>
