var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticStyle:{"margin-top":"100px"}},[_c('v-layout',{attrs:{"column":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":"","align-end":""}},[_c('div',{staticClass:"tl pointer",style:({ backgroundImage: _vm.position === 'tl'
              ? `url(${_vm.tl}), url(${_vm.tl_}), url(${_vm.tlb})`
              : `url(${_vm.tl}), url(${_vm.tl_})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('tl')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('tl')}}},[_c('div',{staticClass:"tll pointer",style:({ backgroundImage: _vm.position === 'tll'
                ? `url(${_vm.tll}),url(${_vm.tllb})`
                : `url(${_vm.tll})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('tll')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('tll')}}})])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":"","align-end":"","mt-4":""}},[_c('div',{staticClass:"bl pointer",style:({ backgroundImage: _vm.position === 'bl'
              ? `url(${_vm.bl}), url(${_vm.bl_}), url(${_vm.blb})`
              : `url(${_vm.bl}), url(${_vm.bl_})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('bl')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('bl')}}})])],1)],1)],1),_c('v-flex',[_c('v-layout',{attrs:{"column":"","wrap":"","justify-center":"","align-center":""}},[_c('v-flex',[_c('div',{staticClass:"ttc pointer",style:({ backgroundImage: _vm.position === 'ttc'
            ? `url(${_vm.ttc}),url(${_vm.ttcb})`
            : `url(${_vm.ttc})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('ttc')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('ttc')}}})]),_c('v-flex',[_c('div',{staticClass:"c",style:({ backgroundImage: `url(${_vm.c}), url(${_vm.c_})` })},[_c('v-layout',{attrs:{"column":"","wrap":"","align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"tc pointer",style:({ backgroundImage: _vm.position === 'tc'
                  ? `url(${_vm.tc}),url(${_vm.tcb})`
                  : `url(${_vm.tc})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('tc')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('tc')}}})]),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"cc pointer",style:({ backgroundImage: _vm.position === 'cc'
                  ? `url(${_vm.cc}),url(${_vm.ccb})`
                  : `url(${_vm.cc})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('cc')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('cc')}}})]),_c('v-flex',{attrs:{"xs12":""}},[_c('div',{staticClass:"bc pointer",style:({ backgroundImage: _vm.position === 'bc'
                  ? `url(${_vm.bc}),url(${_vm.bcb})`
                  : `url(${_vm.bc})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('bc')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('bc')}}})])],1)],1)]),_c('v-flex',[_c('div',{staticClass:"bbc pointer",style:({ backgroundImage: _vm.position === 'bbc'
            ? `url(${_vm.bbc}),url(${_vm.bbcb})`
            : `url(${_vm.bbc})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('bbc')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('bbc')}}})])],1)],1),_c('v-flex',{staticStyle:{"margin-top":"100px"}},[_c('v-layout',{attrs:{"column":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-start":"","align-start":""}},[_c('div',{staticClass:"tr pointer",style:({ backgroundImage: _vm.position === 'tr'
              ? `url(${_vm.tr}), url(${_vm.tr_}), url(${_vm.trb})`
              : `url(${_vm.tr}), url(${_vm.tr_})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('tr')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('tr')}}},[_c('div',{staticClass:"trr pointer",style:({ backgroundImage: _vm.position === 'trr'
                ? `url(${_vm.trr}),url(${_vm.trrb})`
                : `url(${_vm.trr})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('trr')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('trr')}}})])])],1),_c('v-flex',{attrs:{"xs12":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","justify-start":"","align-start":"","mt-4":""}},[_c('div',{staticClass:"br pointer",style:({ backgroundImage: _vm.position === 'br'
              ? `url(${_vm.br}), url(${_vm.br_}), url(${_vm.brb})`
              : `url(${_vm.br}), url(${_vm.br_})` }),on:{"mouseover":function($event){$event.stopPropagation();return _vm.setPosition('br')},"mouseleave":function($event){$event.stopPropagation();return _vm.setPosition(null)},"click":function($event){$event.stopPropagation();return _vm.select('br')}}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }