var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"pagination":_vm.pagination,"headers":_vm.headers,"items":_vm.clients,"total-items":_vm.totalItems,"rows-per-page-items":[5,10,25],"item-key":"_id","loading":_vm.loading,"select-all":""},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"tableRowColor",class:{tableRowColorSelected : props.index == _vm.activeRow},on:{"click":function($event){_vm.activeRow = props.index}}},[_c('td',[_c('v-checkbox',{attrs:{"color":"red darken-3","hide-details":""},model:{value:(props.selected),callback:function ($$v) {_vm.$set(props, "selected", $$v)},expression:"props.selected"}})],1),_c('td',[_vm._v(_vm._s(props.item.name))]),_c('td',[_vm._v(_vm._s(props.item.surname))]),_c('td',[_vm._v(_vm._s(props.item.total_debts))]),_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[_vm._v(_vm._s(props.item.phone))]),_c('td',[_vm._v(_vm._s(props.item.address))]),_c('td',[_vm._v(_vm._s(props.item.comment))]),(props.item.institution)?_c('td',[_vm._v(" "+_vm._s(props.item.institution.title)+" ")]):_c('td'),_c('td',[_vm._v(_vm._s(_vm._f("moment")(props.item.date_created)))]),_c('td',[_c('v-layout',{attrs:{"row":""}},[_c('v-icon',{staticClass:"mr-2 pointer",on:{"click":function($event){return _vm.showDetails(props.item._id)}}},[_vm._v(" view_list ")]),(_vm.$store.getters.role == 'LekoAdmin'
              || _vm.$store.getters.role == 'LekoEmployee'
              || _vm.$store.getters.role == 'InstitutionAdmin'
              || _vm.$store.getters.role == 'InstitutionManager'
            )?_c('v-icon',{staticClass:"mr-2 pointer",on:{"click":function($event){return _vm.editClient(props.item)}}},[_vm._v(" edit ")]):_vm._e(),(_vm.$store.getters.role == 'LekoAdmin'
              || _vm.$store.getters.role == 'LekoEmployee'
              || _vm.$store.getters.role == 'InstitutionAdmin'
              || _vm.$store.getters.role == 'InstitutionManager'
            )?_c('v-icon',{staticClass:"pointer",on:{"click":function($event){return _vm.deleteClient(props.item._id)}}},[_vm._v(" delete ")]):_vm._e()],1)],1)])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }