import axios from 'axios';

import config from '../config';

const serverUri = config.getServerUri();

export default (routeString, queryString) => new Promise((resolve, reject) => {
  const { token } = localStorage;
  let query = '';
  if (queryString) {
    query = queryString;
  }
  axios.get(`${serverUri}${routeString}?${query}`, {
    headers: {
      authorization: token,
    },
  }).then((res) => {
    if (res.status === 200) {
      resolve(res.data);
    }
  }).catch((err) => {
    reject(err);
  });
});
