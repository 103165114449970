<template>
  <v-dialog
    v-model="dialog"
    width="650"
    persistent
  >
    <v-card>
      <v-card-title class="redColor">
        <span class="headline">Actions</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!---------------------------------------ALERT--------------------------------------->
              <v-flex xs12>
                <v-alert
                  :value="alert.value"
                  type="warning"
                  outline
                >
                  <v-layout
                    align-center
                    justify-center
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      sm6
                    >
                      {{ alert.message }}
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                    >
                      <v-layout row>
                        <v-btn
                          outline
                          small
                          color="success"
                          @click="confirm = true, alert.value = false"
                        >
                          {{ $t('parts.inventory.actions.alert.yes') }}
                        </v-btn>
                        <v-btn
                          outline
                          small
                          color="error"
                          @click="confirm = false, alert.value = false"
                        >
                          {{ $t('parts.inventory.actions.alert.no') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-alert>
              </v-flex>
              <!--------------------------------------BUTTONS-------------------------------------->
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="switchS"
                      :disabled="$store.getters.role === 'User'"
                      :label="$t('parts.inventory.actions.sell')"
                      color="green"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="switchD"
                      :disabled="parts && parts.length > 1"
                      :label="$t('parts.inventory.actions.damaged')"
                      color="yellow darken-2"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="switchL"
                      :disabled="parts && parts.length > 1"
                      :label="$t('parts.inventory.actions.lost')"
                      color="orange"
                    />
                  </v-flex>
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="switchR"
                      :disabled="parts && parts.length > 1"
                      :label="$t('parts.inventory.actions.recycle')"
                      color="red"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!------------------------------------CLIENTS ROW------------------------------------>
              <v-flex
                v-if="switchS && $store.getters.role !== 'User'"
                xs12
              >
                <v-layout
                  align-center
                  wrap
                >
                  <v-flex
                    xs6
                    sm8
                    md9
                  >
                    <v-autocomplete
                      v-model="client"
                      :items="clients"
                      :label="$t('parts.inventory.actions.select_client')"
                      :rules="rules.requiredSelect"
                      color="red darken-1"
                      item-text="name"
                      item-value="_id"
                      prepend-icon="person"
                      return-object
                    >
                      <template
                        slot="selection"
                        slot-scope="data"
                      >
                        <span v-if="data && data.item">
                          {{ data.item.name }} {{ data.item.surname }}
                        </span>
                        <span v-else />
                      </template>
                      <template
                        slot="item"
                        slot-scope="data"
                      >
                        <span v-if="data && data.item">
                          {{ data.item.name }} {{ data.item.surname }}
                        </span>
                        <span v-else />
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs6
                    sm4
                    md3
                  >
                    <v-btn
                      color="success"
                      depressed
                      small
                      @click="addClient"
                    >
                      {{ $t('parts.inventory.actions.add_client') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!----------------------------------------------------------------------------------->
              <v-flex
                v-if="switchS && $store.getters.role !== 'User'"
                xs12
              >
                <v-textarea
                  v-model="notes"
                  outline
                  label="Notes"
                  color="red darken-2"
                />
              </v-flex>

              <v-flex
                v-if="switchS && $store.getters.role !== 'User'"
                xs12
              >
                <v-layout
                  row
                  wrap
                >
                  <!--------------------------------TOTAL PRICE ALL-------------------------------->
                  <v-flex
                    v-if="parts && parts.length > 1"
                    xs4
                  >
                    <v-text-field
                      v-model="partsPrice"
                      disabled
                      :label="$t('parts.inventory.actions.total_price')"
                      color="red darken-1"
                      type="number"
                      min="0"
                      step="0.01"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!-------------------------------TOTAL PRICE SINGLE------------------------------>
                  <v-flex
                    v-if="!parts || parts.length < 2"
                    xs4
                  >
                    <v-text-field
                      v-model="part.price"
                      :label="$t('parts.inventory.actions.total_price')"
                      color="red darken-1"
                      type="number"
                      min="0"
                      step="0.01"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!-----------------------------CHANGE OR DIFFERENCE------------------------------>
                  <v-flex
                    v-if="!parts || parts.length < 2"
                    xs4
                  >
                    <v-text-field
                      v-if="cash - part.price > 0"
                      v-model="difference"
                      :label="$t('parts.inventory.actions.change')"
                      color="red darken-1"
                      disabled
                    />
                    <v-text-field
                      v-if="cash - part.price <= 0"
                      v-model="difference"
                      :label="$t('parts.inventory.actions.difference')"
                      color="red darken-1"
                      disabled
                    />
                  </v-flex>
                  <!-------------------------------------CASH-------------------------------------->
                  <v-flex
                    v-if="!parts || parts.length < 2"
                    xs4
                  >
                    <v-text-field
                      v-model="cash"
                      :label="$t('parts.inventory.actions.cash')"
                      color="red darken-1"
                      type="number"
                      min="0"
                      :max="part.price"
                      step="0.01"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!------------------------------------------------------------------------------->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('parts.inventory.actions.cancel') }}
        </v-btn>
        <v-btn
          v-if="switchS"
          :disabled="!valid
            || (client && typeof client === 'object' && Object.keys(client).length < 1)"
          :loading="loading"
          color="green"
          flat
          @click.native="sell"
        >
          {{ $t('parts.inventory.actions.sell') }}
        </v-btn>
        <v-btn
          v-if="switchD"
          :loading="loading"
          :disabled="!confirm"
          color="yellow darken-2"
          flat
          @click.native="damaged"
        >
          {{ $t('parts.inventory.actions.damaged') }}
        </v-btn>
        <v-btn
          v-if="switchL"
          :loading="loading"
          :disabled="!confirm"
          color="orange"
          flat
          @click.native="lost"
        >
          {{ $t('parts.inventory.actions.lost') }}
        </v-btn>
        <v-btn
          v-if="switchR"
          :loading="loading"
          :disabled="!confirm"
          color="red"
          flat
          @click.native="recycle"
        >
          {{ $t('parts.inventory.actions.recycle') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    switchS: true,
    switchD: false,
    switchL: false,
    switchR: false,
    alert: {
      value: false,
      message: '',
    },
    confirm: false,
    part: {},
    parts: null,
    partsPrice: null,
    sale: {
      part: {},
      client: {},
    },
    client: {},
    clients: [],
    cash: 0,
    notes: '',
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
      requiredSelect: [v => (v && !!v.name) || 'Item is required'],
    },
  }),
  computed: {
    difference() {
      if (this.cash - this.part.price >= 0) {
        const difference = (this.cash - this.part.price).toString();
        const trimedNumber = difference.substring(0, 6);
        return trimedNumber;
      }
      const difference = (this.part.price - this.cash).toString();
      const trimedNumber = difference.substring(0, 6);
      return trimedNumber;
    },
  },
  watch: {
    switchS(value) {
      if (value) {
        this.switchD = false;
        this.switchL = false;
        this.switchR = false;
        this.alert.value = false;
        this.alert.message = '';
      } else if (
        this.switchD === false &&
          this.switchL === false &&
          this.switchR === false
      ) {
        this.switchR = true;
      }
    },
    switchD(value) {
      if (value) {
        this.switchS = false;
        this.switchL = false;
        this.switchR = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.inventory.actions.alert.damaged_message');
      } else if (
        this.switchS === false &&
          this.switchL === false &&
          this.switchR === false
      ) {
        this.switchS = true;
      }
    },
    switchL(value) {
      if (value) {
        this.switchS = false;
        this.switchD = false;
        this.switchR = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.inventory.actions.alert.lost_message');
      } else if (
        this.switchS === false &&
          this.switchD === false &&
          this.switchR === false
      ) {
        this.switchS = true;
      }
    },
    switchR(value) {
      if (value) {
        this.switchS = false;
        this.switchD = false;
        this.switchL = false;
        this.confirm = false;
        this.alert.value = true;
        this.alert.message = this.$i18n.t('parts.inventory.actions.alert.recycled_message');
      } else if (
        this.switchS === false &&
          this.switchD === false &&
          this.switchL === false
      ) {
        this.switchS = true;
      }
    },
    client(value) {
      if (value && typeof value === 'object' && Object.keys(value).length < 1) {
        this.sale.client_id = value._id;
        this.sale.client.name = value.name;
        this.sale.client.surname = value.surname;
        this.sale.client.email = value.email;
        this.sale.client.phone = value.phone;
      }
    },
  },
  methods: {
    open(data) {
      console.log(data);
      this.getClients();
      this.part = data.part;
      this.cash = data.part.price;
      this.parts = data.parts;
      if (this.parts && this.parts.length > 1) { // Calc total price.
        this.partsPrice = this.parts.reduce((acc, val) => {
          // eslint-disable-next-line no-restricted-globals, no-param-reassign
          if (!isNaN(parseInt(val.part.price, 10))) { acc += parseInt(val.part.price, 10); }
          return acc;
        }, 0);
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.part = {};
      this.parts = null;
      this.partsPrice = null;
      this.sale = {
        part: {},
        client: {},
      };
      this.confirm = false;
      this.alert.value = false;
      this.alert.message = '';
      this.cash = 0;
      this.notes = '';
      this.client = {};
      this.clients = [];
      this.switchS = true;
      this.$emit('close');
    },
    async sell() {
      console.log(this.client._id);
      this.loading = true;
      const promises = [];

      if (this.parts && this.parts.length > 0) {
        const parts = [];
        const tempParts = [];
        this.parts.forEach((p) => {
          if (p.type === 'inventory') parts.push(p.part);
          else if (p.type === 'temporary') tempParts.push(p.part);
        });
        // Sell inventory parts.
        if (parts.length) {
          parts.forEach((part) => {
            promises.push(postRequest.object('/sale/new', {
              price: part.price,
              cash: this.cash,
              notes: this.notes,
              user_id: this.$store.getters.userId,
              client_id: this.client._id,
              part_id: part._id,
            }));
          });
        }
        // Sell temporary parts.
        if (tempParts.length) {
          tempParts.forEach((part) => {
            promises.push(putRequest('/temporary-part/insert/sell', '', {
              user_id: this.$store.getters.userId,
              institution_id: this.$store.getters.institutionId,
              part_id: part._id,
              price: part.price,
              currency: 'EUR',
              cash: this.cash,
              notes: this.notes,
              client_id: this.client._id,
            }));
          });
        }
      } else { // Sell single inventory part.
        promises.push(postRequest.object('/sale/new', {
          price: this.part.price,
          cash: this.cash,
          notes: this.notes,
          user_id: this.$store.getters.userId,
          client_id: this.client._id,
          part_id: this.part._id,
        }));
      }

      Promise.all(promises)
        .then((response) => {
          this.loading = false;
          const ebayErrorObject = response[0].data.find(item => item.ebayError);
          if (ebayErrorObject && ebayErrorObject.ebayError && ebayErrorObject.ebayError != null) {
            this.$emit('show-error-modal', ebayErrorObject.ebayError);
          }
          this.close();
        })
        .catch((error) => {
          console.log('error ', error.response);
          this.loading = false;
          this.close();
        });
    },
    async damaged() {
      this.loading = true;
      putRequest(
        `/part/damaged/${this.part._id}`,
        `user_id=${this.$store.getters.userId
        }&institution_id=${this.$store.getters.institutionId
        }&source=inventory`,
      )
        .then((response) => {
          this.loading = false;
          const ebayErrorObject = response.data.find(item => item.ebayError);
          if (ebayErrorObject && ebayErrorObject.ebayError && ebayErrorObject.ebayError != null) {
            console.log('Object with ebayError:', ebayErrorObject);
            this.$emit('show-error-modal', ebayErrorObject.ebayError);
          }
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.close();
        });
    },
    async lost() {
      this.loading = true;
      putRequest(
        `/part/lost/${this.part._id}`,
        `user_id=${this.$store.getters.userId
        }&institution_id=${this.$store.getters.institutionId
        }&source=inventory`,
      )
        .then((response) => {
          console.log(response);
          this.loading = false;
          const ebayErrorObject = response.data.find(item => item.ebayError);
          if (ebayErrorObject && ebayErrorObject.ebayError && ebayErrorObject.ebayError != null) {
            console.log('Object with ebayError:', ebayErrorObject);
            this.$emit('show-error-modal', ebayErrorObject.ebayError);
          }
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.close();
        });
    },
    async recycle() {
      this.loading = true;
      putRequest(
        `/part/recycled/${this.part._id}`,
        `user_id=${this.$store.getters.userId
        }&institution_id=${this.$store.getters.institutionId
        }&source=inventory`,
      )
        .then((response) => {
          console.log(response);
          this.loading = false;
          const ebayErrorObject = response.data.find(item => item.ebayError);
          if (ebayErrorObject && ebayErrorObject.ebayError && ebayErrorObject.ebayError != null) {
            console.log('Object with ebayError:', ebayErrorObject);
            this.$emit('show-error-modal', ebayErrorObject.ebayError);
          }
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.close();
        });
    },
    searchClient(search) {
      getRequest(
        '/client/search',
        `search=${search
        }&role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId}`,
      )
        .then(response => console.log(response))
        .catch(error => console.log(error));
    },
    getClients() {
      getRequest(
        '/client/',
        `role=${this.$store.getters.role}&institution_id=${
          this.$store.getters.institutionId
        }`,
      )
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addClient() {
      this.$emit('add-client');
    },
    hide() {
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
  },
};
</script>
