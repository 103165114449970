<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title>
        {{ $t('leko_employees.car_part_groups.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-spacer class="hidden-sm-and-down" />
    </v-toolbar>
    <!--VEHICLE VIEW-->
    <v-layout
      align-center
      justify-center
      row
      wrap
      mt-4
    >
      <v-flex
        xs12
        md6
      >
        <vehicle-view
          ref="vehicleView"
          @position="openDialog"
        />
      </v-flex>
    </v-layout>
    <!--PART SELECTION-->
    <selection-dialog ref="selectionDialog" />
  </v-container>
</template>

<script>
import VehicleView from '@/components/VehicleEntry/vehicleView.vue';
import SelectionDialog from '@/components/CarPartGroups/dialog.vue';

export default {
  components: {
    'vehicle-view': VehicleView,
    'selection-dialog': SelectionDialog,
  },
  data: () => ({}),
  mounted() {},
  methods: {
    openDialog(position) {
      const { selectionDialog } = this.$refs;
      selectionDialog.open(position);
    },
    closeDialog() {
      const { selectionDialog } = this.$refs;
      selectionDialog.close();
    },
  },
};
</script>
