<template>
  <div>
    <h3>Payment methods</h3>
    <p class="pb-2 pt-2">
      LekoTech can integrate the payment methods that are listed below and can the sorted to
      control their display order on the frontend.
    </p>
    <v-data-table
      :headers="headers"
      :items="payments"
      class="elevation-1"
      hide-actions
    >
      <template
        slot="accountCell"
        slot-scope="props"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">
              {{ props.header.text }}
            </span>
          </template>
          <span>
            {{ props.header.text }}
          </span>
        </v-tooltip>
      </template>


      <template v-slot:items="props">
        <td>
          <v-icon>fas fa-list</v-icon>
          <v-icon>fas fa-menu</v-icon>
        </td>
        <td class="text-xs-left">
          {{ props.item.method }}
        </td>
        <td class="text-xs-left">
          <v-switch
            v-model="props.item.enabled"
            color="error"
            hide-details
            label=""
            value="error"
          />
        </td>
        <td class="text-xs-left">
          {{ props.item.description }}
        </td>
        <td class="text-xs-left">
          <v-btn
            color="indigo"
            outline
            @click="dialog = true"
          >
            {{ props.item.setUp }}
          </v-btn>
        </td>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialog"
      :scrim="false"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <h3 class="font-weight-bold justify-center">
            Direct Bank Transfer
          </h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout>
              <v-flex xs12 md12 sm12>
                <p>
                  Take payments in person via BACS. More commonly known as direct
                  bank/wire transfer.
                </p>
              </v-flex>
            </v-layout>
            <v-flex xs12 md4 sm6>
              <v-switch
                :label="`Enable bank transfer`"
                color="red darken-3"
              />
            </v-flex>
            <v-flex xs12 md4 sm6>
              <v-text-field
                hint="Direct bank transfer"
                label="Title"
                required
              />
            </v-flex>
            <v-flex xs12>
              <v-textarea
                bg-color="grey-lighten-2"
                label="Description"
                required
                variant="filled"
              />
            </v-flex>
            <v-flex xs12>
              <v-textarea
                bg-color="grey-lighten-2"
                label="Instructions"
                required
                variant="filled"
              />
            </v-flex>
            <v-flex xs12>
              <v-card-text>
                <v-data-table
                  :headers="account"
                  :items="accounts"
                  :items-per-page="5"
                  class="elevation-1"
                >
                  <template v-slot:item.bank_name="{ item }">
                    {{ item.bank_name}}
                  </template>
                  <template v-slot:item.sort_code="{ item }">
                    {{ item.sort_code}}
                  </template>
                  <template v-slot:item.iban="{ item }">
                    {{ item.iban}}
                  </template>
                  <template v-slot:item.bic_swift="{ item }">
                    {{ item.bic_swift}}
                  </template>

                  <template v-slot:items="props">
                    <td class="text-xs-left">
                      {{ props.item.account_name }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.account_number }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.bank_name }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.sort_code }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.iban }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.bic_swift }}
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.actions }}
                    </td>
                  </template>

                  <template>
                    <v-data-table :headers="headers" :items="accounts">
                      <template v-slot:item.actions="{ item }">
                        <v-btn color="primary" small @click="editAccount(item)">
                          <v-icon small>mdi-pencil</v-icon>
                          Edit
                        </v-btn>
                        <v-btn color="error" small @click="deleteAccount(item)">
                          <v-icon small>mdi-delete</v-icon>
                          Delete
                        </v-btn>
                      </template>
                    </v-data-table>
                  </template>
                </v-data-table>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="addAccount"
                  >
                    Add Account
                  </v-btn>
                  <v-dialog
                    v-model="accountDialog"
                    max-width="500"
                  >
                    <v-card class="ma-4">
                      <v-card-title>Add Account</v-card-title>
                      <v-card-text>
                        <accountForm />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="primary"
                          @click="saveAccount"
                        >
                          Save
                        </v-btn>
                        <v-btn
                          color="secondary"
                          @click="accountDialog = false"
                        >
                          Cancel
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn
                    class="ml-2"
                    color="error"
                    @click="clearAccounts"
                  >
                    Clear Accounts
                  </v-btn>
                </v-card-actions>
              </v-card-text>
            </v-flex>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import accountForm from '@/components/Settings/accountForm.vue';

export default {
  components: { accountForm },
  props: ['institution'], // eslint-disable-line vue/require-prop-types
  data() {
    return {
      accountDialog: false,
      dialog: false,
      account: [
        { text: 'Account Name', value: 'account_name' },
        { text: 'Account Number', value: 'account_number' },
        { text: 'Bank Name', value: 'bank_name' },
        { text: 'Sort Code', value: 'sort_code' },
        { text: 'IBAN', value: 'iban' },
        { text: 'BIC/Swift', value: 'bic_swift' },
        { text: 'Actions', value: 'actions' },
      ],
      accounts: [
        {
          account_name: 'John Smith',
          account_number: '1234567890',
          bank_name: 'Chase Bank',
          sort_code: '12-34-56',
          iban: 'GB29 NWBK 6016 1331 9268 19',
          bic_swift: 'NWBKGB2L',
        },
        {
          account_name: 'Jane Doe',
          account_number: '0987654321',
          bank_name: 'Bank of America',
          sort_code: '98-76-54',
          iban: 'US42 1234 5678 9012 3456 7890',
          bic_swift: 'BOFAUS3N',
        },
      ],
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'icons',
        },
        { text: 'Methods', value: 'method' },
        { text: 'Enabled', value: 'enable' },
        { text: 'Description', value: 'description' },
        { text: 'Set up', value: 'setUp' },

      ],

      payments: [
        {
          method: 'Direct bank transfer',
          description: 'Take payments in person vua BASC.More commonly known as direct bank/wire transfer',
          setUp: 'Set up',
          enabled: false,
        },
        {

          method: 'Online payments',
          description: 'Receive online payments',
          setUp: 'Set up',
          enabled: false,
        },
        {

          method: 'Cash on delivery',
          description: 'Have your customers pay with cash(or by other means)upon delivery.',
          setUp: 'Manage',
          enabled: false,
        },
      ],
    };
  },
  methods: {
    addAccount() {
      this.accountDialog = true;
    },
    clearAccounts() {
      // Logic for clearing all accounts
    },
    editAccount(account) {
      // Logic for editing an account
    },
    deleteAccount(account) {
      // Logic for deleting an account
    },
    openDialog() {
      this.dialog = true;
      console.log('dialog', this.dialog);
    },
    saveAccount() {
      // your code to save the account goes here
      this.accountDialog = false; // hide the dialog when done
    },
  },
};

</script>
