<template>
  <v-dialog
    v-model="dialog"
    width="350"
  >
    <v-card>
      <v-layout
        pt-2
        pb-2
        pl-4
        align-center
        row
        wrap
      >
        <v-flex xs6>
          <v-text-field
            v-model="quantity"
            :label="$t('parts.insert_parts.empty_parts.quantity')"
            type="number"
            min="1"
            max="100"
            oninput="validity.valid||(value='');"
          />
        </v-flex>
        <v-flex xs6>
          <v-layout
            align-center
            justify-center
            row
            wrap
          >
            <v-btn
              :loading="loading"
              color="primary"
              @click="insert"
            >
              {{ $t('parts.insert_parts.empty_parts.create_parts') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import postRequest from '../../api/postRequest';

export default {
  data: () => ({
    dialog: false,
    loading: false,
    quantity: null,
  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.loading = false;
        this.quantity = null;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.quantity = null;
    },
    insert() {
      if (this.quantity) {
        this.loading = true;
        postRequest.object(`/temporary-part/insert/empty/multiple/${this.quantity}?user_id=${
          this.$store.getters.userId}&institution_id=${this.$store.getters.institutionId}`)
          .then((response) => {
            console.log(response);
            this.$emit('inserted');
            this.close();
          })
          .catch((error) => {
            console.log(error);
            this.close();
          });
      }
    },
  },
};
</script>
