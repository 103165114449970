<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-toolbar
        flat
        color="red darken-2"
      >
        <v-toolbar-title>{{ $t('part_requests.return.label') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout column>
          <span v-if="selectedOfferIndex > -1">
            {{ request.assignedInstitutions[selectedOfferIndex].offer.notes }}
          </span>
          <v-text-field
            v-model="notes"
            :label="$t('part_requests.return.notes')"
            color="red"
          />
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="red"
          flat
          @click="close"
        >
          {{ $t('part_requests.return.close') }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="red"
          flat
          :loading="loadingRefuse"
          @click="refuse"
        >
          {{ $t('part_requests.return.refuse') }}
        </v-btn>
        <v-btn
          color="green"
          flat
          :loading="loadingConfirm"
          @click="confirm"
        >
          {{ $t('part_requests.return.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    loadingRefuse: false,
    loadingConfirm: false,
    notes: null,
    request: null,
  }),
  computed: {
    selectedOfferIndex() {
      if (!this.request) return -1;
      return this.request.assignedInstitutions.findIndex(i => i.offer.wantToReturn === true);
    },
  },
  methods: {
    open(request) {
      console.log(request);
      this.dialog = true;
      this.request = request;
    },
    close() {
      this.request = null;
      this.dialog = false;
    },
    confirm() {
      this.loadingConfirm = true;
      putRequest('/part-request/confirm-return', `id=${this.request._id}`, { notes: this.notes })
        .then((response) => { console.log(response); this.$emit('confirmed'); })
        .catch(error => console.log(error))
        .finally(() => {
          this.loadingConfirm = false;
          this.close();
        });
    },
    refuse() {
      this.loadingRefuse = true;
      putRequest('/part-request/refuse-return', `id=${this.request._id}`, { notes: this.notes })
        .then((response) => { console.log(response); this.$emit('refused'); })
        .catch(error => console.log(error))
        .finally(() => {
          this.loadingRefuse = false;
          this.close();
        });
    },
  },
};
</script>
