<template>
  <v-dialog
    v-model="dialog"
    width="250"
  >
    <v-card flat>
      <v-card-text>
        {{ notes }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    notes: null,
  }),
  watch: {
    dialog(value) {
      if (!value) { this.notes = null; }
    },
  },
  methods: {
    open(notes) {
      this.dialog = true;
      this.notes = notes;
    },
  },
};
</script>
