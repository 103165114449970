<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-center
      >
        <v-toolbar-title class="hidden-xs-only">
          {{ $t('parts.lost_parts.label') }}
        </v-toolbar-title>
        <v-divider
          class="mx-5 hidden-xs-only"
          inset
          vertical
        />
        <!-------------------------------------EXPORT BUTTONS-------------------------------------->
        <download-excel
          :fetch="exportData"
          type="xls"
          name="Lost"
          class="hidden-md-and-down"
        >
          <v-btn
            color="grey darken-4"
            dark
          >
            {{ $t('parts.lost_parts.export') }}
            <v-icon right>
              fas fa-file-excel
            </v-icon>
          </v-btn>
        </download-excel>
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-md-and-down" />
        <!--SEARCH BY TITLE-->
        <v-autocomplete
          v-if="searchType === 'title'"
          v-model="titleCode"
          :loading="loadingTitle"
          :items="titles"
          :search-input.sync="searchTitle"
          :label="$t('parts.lost_parts.search')"
          :filter="() => true"
          :item-text="`title.${$store.getters.language}`"
          item-value="partCode"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              class="listRowColor"
            >
              <v-flex>
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">Info Car</span>
                  <span
                    class="body-2"
                    v-html="highlight(item.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
              <v-flex
                v-for="(element, index) in item.related"
                :key="index"
                class="hidden-sm-and-down"
              >
                <v-layout
                  column
                  wrap
                >
                  <span
                    class="body-1"
                    style="margin-top: 17px;"
                    v-html="highlight(element.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>

        <!--SEARCH BY MULTIPLE TITLES-->
        <v-autocomplete
          v-else-if="searchType === 'multipleTitles'"
          v-model="titleCodes"
          :loading="loadingMultipleTitles"
          :items="titleGroups"
          :search-input.sync="searchMultipleTitles"
          :label="$t('parts.movements.search')"
          :item-text="`title.${$store.getters.language}`"
          item-value="codes"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />

        <!--SEARCH OTHER FIELDS-->
        <v-text-field
          v-else
          v-model="search"
          :label="$t('parts.lost_parts.search')"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />

        <v-spacer />

        <!--SEARCH TYPE MD-AND-UP-->
        <v-flex
          md2
          lg1
          class="hidden-sm-and-down"
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
          />
        </v-flex>

        <!--ADVANCED MD-AND-UP-->
        <v-btn
          class=" mr-5 hidden-sm-and-down"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('parts.damaged_parts.advanced') }}
        </v-btn>

        <v-spacer class="hidden-md-and-down" />
      </v-layout>
    </v-toolbar>
    <div class="redColor">
      <!------------------------------------------------------------------------------------------->
      <v-layout
        align-center
        justify-center
        :class="{ 'pt-1 pb-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-spacer />

        <!--SEARCH TYPE SM-AND-DOWN-->
        <v-flex
          xs4
          sm2
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
            class="hidden-md-and-up"
          />
        </v-flex>


        <!--ADVANCED SM-AND-DOWN-->
        <v-btn
          class="hidden-md-and-up"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('parts.lost_parts.advanced') }}
        </v-btn>

        <v-spacer />
      </v-layout>

      <!--------------------------------------ADVANCED SEARCH-------------------------------------->
      <div v-if="advancedSearch">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-layout
            row
            color="red darken-3"
            class="pb-2"
            wrap
          >
            <!---------------------------------------DIVIDER--------------------------------------->
            <v-flex xs12>
              <v-layout
                class="mt-2 mb-2"
                row
                wrap
              >
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
                <div class="ml-4 mr-4 title white--text">
                  {{ $t('parts.advanced_search.car_details') }}
                </div>
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
              </v-layout>
            </v-flex>
            <!----------------------------------------PLATE---------------------------------------->
            <v-flex
              xs12
              sm3
              lg4
            >
              <v-text-field
                v-model="plate"
                flat
                solo
                :disabled="!canSearchPlates"
                :color="foundPlate ? 'success': 'error'"
                :label="$t('parts.advanced_search.plate')"
                :loading="loadingPlate"
                class="ml-2 mr-2 mt-2"
                hide-details
                :append-icon="foundPlate ? 'check_circle': 'error'"
                @keyup.enter="searchPlate()"
                @click:append="searchPlate()"
              />
            </v-flex>
            <!----------------------------------------BRAND---------------------------------------->
            <v-flex
              xs12
              sm7
              lg4
            >
              <v-autocomplete
                v-model="brand"
                :items="brands"
                :loading="loadingBrand"
                :label="$t('parts.advanced_search.brand')"
                item-text="title"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                return-object
                :append-icon="brand === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="brand = null"
              >
                <template v-slot:item="{ item }">
                  <v-layout
                    row
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex
                      xs3
                      sm2
                      lg1
                    >
                      <v-img
                        :src="item.logo"
                        max-height="35"
                        max-width="35"
                      />
                    </v-flex>
                    <v-flex
                      xs9
                      sm10
                      lg11
                    >
                      <span> {{ item.title }} </span>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-slot:selection="{ item }">
                  <v-layout
                    row
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex
                      xs3
                      lg2
                    >
                      <v-img
                        :src="item.logo"
                        max-height="35"
                        max-width="35"
                      />
                    </v-flex>
                    <v-flex
                      xs9
                      lg10
                    >
                      <span> {{ item.title }} </span>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <!----------------------------------------YEAR----------------------------------------->
            <v-flex
              xs12
              sm2
              lg4
            >
              <v-text-field
                v-model="year"
                :label="$t('parts.advanced_search.year')"
                :rules="[yearRule]"
                flat
                solo
                type="number"
                :max="new Date().getFullYear()"
                oninput="validity.valid||(value=new Date().getFullYear());"
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
              />
            </v-flex>
            <!----------------------------------------MODEL---------------------------------------->
            <v-flex
              xs12
              sm8
              xl4
            >
              <v-autocomplete
                v-model="model"
                :items="models"
                :loading="loadingModel"
                :label="$t('parts.advanced_search.model')"
                item-text="fullDescription"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                return-object
                :append-icon="model === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="model = null"
              >
                <template v-slot:item="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md7
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                        <span class="body-2">
                          {{ item.description }}
                        </span>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      class="hidden-sm-and-down"
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-slot:selection="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md7
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                        <span class="body-2">
                          {{ item.description }}
                        </span>
                      </v-layout>
                    </v-flex>

                    <v-flex
                      class="hidden-sm-and-down"
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <!---------------------------------------ENGINE---------------------------------------->
            <v-flex
              xs12
              sm4
              xl2
            >
              <v-autocomplete
                v-model="engine"
                :items="versions"
                :loading="loadingVersion"
                :label="$t('parts.advanced_search.engine')"
                item-text="engineCode"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                :append-icon="engine === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="
                  engine = null;
                  version = null;
                  if (model) getVersions(model.brandCode, model.modelCode);
                "
              />
            </v-flex>
            <!---------------------------------------VERSION--------------------------------------->
            <v-flex
              xs12
              xl6
            >
              <v-autocomplete
                v-model="version"
                :items="versions"
                :loading="loadingVersion"
                :label="$t('parts.advanced_search.version')"
                item-text="fullDescription"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
                return-object
                :append-icon="version === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                @click:append="version = null"
              >
                <template v-slot:item="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                        <span class="body-2">{{ item.description }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      class="hidden-sm-and-down"
                      md2
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                        <span class="body-2">{{ item.numberOfDoors }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                        <span class="body-2">
                          {{ item.power.kw }} kw,
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                        <span class="body-2">{{ item.engineCode }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                        <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>

                <template v-slot:selection="{ item }">
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      md5
                    >
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                        <span class="body-2">{{ item.description }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                        <span class="body-2">
                          {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                        <span class="body-2">{{ item.numberOfDoors }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                        <span class="body-2">
                          {{ item.power.kw }} kw,
                        </span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                        <span class="body-2">{{ item.engineCode }}</span>
                      </v-layout>
                    </v-flex>
                    <v-flex class="hidden-sm-and-down">
                      <v-layout
                        column
                        wrap
                      >
                        <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                        <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </template>
              </v-autocomplete>
            </v-flex>
            <!---------------------------------------SUPPLY---------------------------------------->
            <v-flex xs12>
              <v-layout
                column
                class="ml-2 mt-2"
              >
                <div class="subheading white--text">
                  {{ $t('parts.advanced_search.supply.supply') }}
                </div>
                <v-radio-group
                  v-model="supply"
                  class="ma-0"
                  dark
                  row
                  hide-details
                >
                  <v-radio
                    color="grey lighten-4"
                    :label="supplies[0].title"
                    :value="supplies[0].code"
                  />
                  <v-radio
                    color="grey lighten-4"
                    :label="supplies[1].title"
                    :value="supplies[1].code"
                  />
                  <v-radio
                    color="grey lighten-4"
                    :label="supplies[2].title"
                    :value="supplies[2].code"
                  />
                </v-radio-group>
              </v-layout>
            </v-flex>
            <!---------------------------------------DIVIDER--------------------------------------->
            <v-flex xs12>
              <v-layout
                class="mt-2 mb-3"
                row
                wrap
              >
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
                <div class="ml-4 mr-4 title white--text">
                  {{ $t('parts.advanced_search.part_details') }}
                </div>
                <v-divider
                  dark
                  class="mt-2"
                  style="border-width: 1px; border-color: white;"
                />
              </v-layout>
            </v-flex>
            <!------------------------------------INSTITUTIONS------------------------------------->
            <v-flex
              v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
              xs12
              :sm6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
              :xl2="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
            >
              <v-autocomplete
                v-model="advancedSearchItems.institution"
                :items="institutions"
                :loading="loadingInstitution"
                :label="$t('parts.advanced_search.institution')"
                :hint="$t('parts.advanced_search.institution')"
                persistent-hint
                item-text="title"
                item-value="_id"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
              />
            </v-flex>
            <!--------------------------------------SHIPMENTS-------------------------------------->
            <v-flex
              xs12
              :sm6="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
              :lg4="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee')"
              :xl2="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
              :xl4="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee')"
            >
              <v-autocomplete
                v-model="advancedSearchItems.shipment"
                :items="shipments"
                :loading="loadingShipment"
                :label="$t('parts.advanced_search.shipment')"
                :hint="$t('parts.advanced_search.shipment')"
                persistent-hint
                item-text="name"
                item-value="_id"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
              />
            </v-flex>
            <!----------------------------------------PRICE---------------------------------------->
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee')"
              xl2
            >
              <v-combobox
                v-model="advancedSearchItems.price[0]"
                :items="prices"
                flat
                solo
                :label="this.$i18n.t('parts.inventory.from')"
                :hint="$t('parts.inventory.from')"
                persistent-hint
                color="red darken-1"
                class="ml-2 mr-2"
                prepend-icon="euro"
                type="number"
                oninput="validity.valid||(value='');"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee')"
              xl2
            >
              <v-combobox
                v-model="advancedSearchItems.price[1]"
                :items="prices"
                solo
                :label="this.$i18n.t('parts.inventory.upto')"
                :hint="$t('parts.inventory.upto')"
                persistent-hint
                flat
                color="red darken-1"
                class="ml-2 mr-2"
                prepend-icon="euro"
                type="number"
                oninput="validity.valid||(value='');"
              />
            </v-flex>
            <!----------------------------------------DATE----------------------------------------->
            <!--STARTING DATE-->
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee')"
              xl2
            >
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="advancedSearchItems.startDate"
                    :label="$t('parts.advanced_search.starting_date')"
                    :hint="$t('parts.advanced_search.select_starting_date')"
                    solo
                    flat
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    class="ml-2 mr-2"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="advancedSearchItems.startDate"
                  color="red darken-3"
                  @input="startDateMenu = false"
                />
              </v-menu>
            </v-flex>
            <!--ENDING DATE-->
            <v-flex
              xs12
              sm6
              md3
              :lg2="
                !($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee')"
              xl2
            >
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="advancedSearchItems.endDate"
                    :label="$t('parts.advanced_search.ending_date')"
                    :hint="$t('parts.advanced_search.select_ending_date')"
                    solo
                    flat
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    class="ml-2 mr-2"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="advancedSearchItems.endDate"
                  color="red darken-3"
                  @input="endDateMenu = false"
                />
              </v-menu>
            </v-flex>
            <!---------------------------------------STATUS---------------------------------------->
            <v-flex
              xs12
              sm6
              pa-2
            >
              <div class="subheading white--text mt-2 mb-2">
                {{ $t('parts.advanced_search.select_status') }}
              </div>
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-for="(status, index) in statusItems"
                  :key="index"
                  xs12
                  sm6
                  d-flex
                >
                  <v-checkbox
                    v-model="advancedSearchItems.status"
                    :label="status.title"
                    :value="status.value"
                    dark
                    class="ma-0 pa-0"
                    color="grey lighten-4"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
            <!---------------------------------------QUALITY--------------------------------------->
            <v-flex
              xs12
              sm6
              pa-2
            >
              <div class="subheading white--text mt-2 mb-2">
                {{ $t('parts.advanced_search.select_quality') }}
              </div>
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-for="(quality, index) in qualityItems"
                  :key="index"
                  xs12
                  sm6
                  d-flex
                >
                  <span>
                    <v-checkbox
                      v-model="advancedSearchItems.quality"
                      :value="quality._id"
                      dark
                      class="ma-0 pa-0"
                      color="grey lighten-4"
                    >
                      <template v-slot:label>
                        <v-rating
                          v-model="quality.rating"
                          :color="advancedSearchItems.quality.includes(quality._id)
                            ? 'yellow': 'grey'"
                          small
                          :background-color="advancedSearchItems.quality.includes(quality._id)
                            ? 'yellow': 'grey'
                          "
                          readonly
                        />
                      </template>
                    </v-checkbox>

                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
        <!----------------------------------------------------------------------------------------->
        <v-layout
          align-center
          justify-center
          row
          wrap
        >
          <v-btn
            dark
            color="grey darken-4"
            @click="clearSearchFields"
          >
            {{ $t('parts.advanced_search.clear') }}
            <v-icon right>
              clear_all
            </v-icon>
          </v-btn>
          <v-btn
            dark
            color="grey darken-4"
            @click="searchMethod"
          >
            {{ $t('parts.advanced_search.search') }}
            <v-icon right>
              search
            </v-icon>
          </v-btn>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-nested-ternary */
import moment from 'moment';
import JsonExcel from 'vue-json-excel'; // eslint-disable-line import/extensions

import getRequest from '../../api/getRequest';

export default {
  components: {
    'download-excel': JsonExcel,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  props: ['items'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    searchTypes: [
      { label: 'Title', value: 'title' },
      { lable: 'Multiple Titles', value: 'multipleTitles' },
      { label: 'ID', value: 'id' },
      { label: 'Other', value: 'other' },
    ],
    searchType: 'title',
    advancedSearch: false,
    search: '',
    foundPlate: false,
    canSearchPlates: false,
    plate: null,
    searchTitle: null,
    searchMultipleTitles: null,
    titleCode: null,
    titleCodes: [],
    titles: [],
    brands: [],
    models: [],
    versions: [],
    titleGroups: [],
    brand: null,
    model: null,
    version: null,
    engine: null,
    year: null,
    yearRule: '',
    supply: null,
    supplies: [
      { code: 'B', title: 'Petrol' },
      { code: 'D', title: 'Diesel' },
      { code: 'G', title: 'LPG' },
    ],
    advancedSearchItems: {
      brand: null,
      model: null,
      version: null,
      institution: null,
      shipment: null,
      status: [6, 6.1],
      quality: [],
      price: [0, 50000],
      startDate: '',
      endDate: '',
    },
    statusItems: [
      { title: 'Lost', value: 6 },
      { title: 'Lost-Shop', value: 6.1 },
    ],
    qualityItems: [],
    institutions: [],
    shipments: [],
    loadingTitle: false,
    loadingMultipleTitles: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    loadingQuality: false,
    loadingInstitution: false,
    loadingShipment: false,
    loadingPlate: false,
    startDateMenu: false,
    endDateMenu: false,
    prices: [],
  }),
  computed: {},
  watch: {
    search(value) {
      if (!value && this.advancedSearch === false) this.$emit('clear-search');
    },
    searchTitle(value) {
      if (value) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCode = null;
        this.titles = [];
      }
    },
    searchMultipleTitles(value) {
      if (value) {
        this.loadingMultipleTitles = true;
        getRequest('/info-car/partgroups', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titleGroups = response.partgroups; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingMultipleTitles = false; });
      } else {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCodes = [];
        this.titleGroups = [];
      }
    },
    advancedSearch(value) {
      console.log(value);
      if (!value) {
        this.advancedSearchItems = {
          brand: null,
          model: null,
          version: null,
          institution: null,
          shipment: null,
          status: [6, 6.1],
          quality: [],
          price: [0, 50000],
          startDate: '',
          endDate: '',
        };
        this.brands = [];
        this.models = [];
        this.versions = [];
        this.brand = null;
        this.model = null;
        this.version = null;
      } else {
        this.getQualities();
        this.getBrands();
        this.getInstitution();
        if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee') {
          this.getInstitutions();
        } else {
          this.getShipments(this.$store.getters.institutionId);
        }
      }
    },
    'advancedSearchItems.institution': {
      handler(value) { if (value) this.getShipments(value); },
    },
    brand(value) {
      if (value) {
        this.models = [];
        this.model = null;
        this.versions = [];
        this.version = null;
        // Make sure that we don't get the models if we are searching for a license plate.
        if (this.loadingPlate === false) this.getModels(value.brandCode);
        this.advancedSearchItems.brand = value.brandCode;
      } else { this.advancedSearchItems.brand = null; }
    },
    model(value) {
      if (value) {
        this.versions = [];
        this.version = null;
        // Make sure that we don't get the versions if we are searching for a license plate.
        if (this.loadingPlate === false) this.getVersions(value.brandCode, value.modelCode);
        this.advancedSearchItems.model = value.modelCode;
        // Set year rule.
        this.setYearRule(value.startingDate, value.endingDate);
      } else { this.advancedSearchItems.model = null; }
    },
    version(value) {
      if (value) {
        this.advancedSearchItems.version = value.versionCode;
        // Set year rule.
        this.setYearRule(value.startingDate, value.endingDate);
      } else { this.advancedSearchItems.version = null; }
    },
    engine(value) {
      this.versions = this.versions.reduce((acc, v) => {
        if (v.engineCode === value) acc.push(v);
        return acc;
      }, []);
    },
    year(value) {
      if (value) {
        this.$refs.form.validate();
        // filter models
        if (value.length === 4) { // Filter only when year is valid (4 characters long)
          this.filterModels();
          this.filterVersions();
        } else if (value.length !== 4 && this.brand && this.brand.brandCode) { // Reset models.
          this.models = this.getModels(this.brand.brandCode);
        }
      }
    },
    supply(value) {
      if (value) {
        this.filterVersions();
      } else if (!value && this.brand && this.brand.brandCode
        && this.model && this.model.modelCode) { // reset versions.
        this.versions = this.getVersions(this.brand.brandCode, this.model.modelCode);
      }
    },
  },
  mounted() {
    this.getLocale();
    // Set initial year rule.
    this.setYearRule();
  },
  methods: {
    getLocale() {
      this.searchTypes[0].label = this.$i18n.t('parts.lost_parts.title');
      this.searchTypes[1].label = this.$i18n.t('parts.lost_parts.multiple_titles');
      this.searchTypes[2].label = this.$i18n.t('parts.lost_parts.id');
      this.searchTypes[3].label = this.$i18n.t('parts.lost_parts.other');
      this.statusItems[0].title = this.$i18n.t('parts.status.lost');
      this.statusItems[1].title = this.$i18n.t('parts.status.lost_shop');
      this.supplies[0].title = this.$i18n.t('parts.advanced_search.supply.petrol');
      this.supplies[1].title = this.$i18n.t('parts.advanced_search.supply.diesel');
      this.supplies[2].title = this.$i18n.t('parts.advanced_search.supply.gas');
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
    setYearRule(startingDate, endingDate) {
      const startingYear = startingDate ? new Date(startingDate).getFullYear() : 1900;
      const endingYear = endingDate ? new Date(endingDate).getFullYear() : new Date().getFullYear();
      this.yearRule = v => (v >= startingYear && v <= endingYear) || this.$i18n.t('parts.advanced_search.year_error');
    },
    searchMethod() {
      if (this.advancedSearch) { // ADVANCED SEARCH
        this.$emit('adv-search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          titleCodes: this.titleCodes,
          advancedSearchFields: this.advancedSearchItems,
        });
      } else if (this.search.length > 0 || this.titleCode || this.titleCodes) { // REGULAR SEARCH
        this.$emit('search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          titleCodes: this.titleCodes,
        });
      }
    },
    clearSearchFields() {
      this.search = '';
      this.titleCode = null;
      this.advancedSearchItems = {
        brand: null,
        model: null,
        version: null,
        institution: null,
        shipment: null,
        status: [6, 6.1],
        quality: [],
        price: [0, 50000],
        startDate: '',
        endDate: '',
      };
      this.year = null;
      this.supply = null;
      this.plate = null;
      this.brand = null;
      this.model = null;
      this.version = null;
      this.engine = null;
      this.models = [];
      this.versions = [];
      this.qualityItems.forEach(quality => this.advancedSearchItems.quality.push(quality._id));
      this.$emit('clear-search');
    },

    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => {
          this.models = response.models;
          this.filterModels();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => {
          this.versions = response.versions;
          this.filterVersions();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },

    filterModels() {
      if (this.year && this.year.length === 4 && this.models && this.models.length > 0) {
        this.models = this.models.filter((model) => {
          const sYear = model.startingDate ? new Date(model.startingDate).getFullYear() : 1900;
          const eYear = model.endingDate
            ? new Date(model.endingDate).getFullYear() : new Date().getFullYear();

          return this.year >= sYear && this.year <= eYear;
        });
      }
    },
    filterVersions() {
      if (this.brand && this.brand.brandCode
        && this.model && this.model.modelCode && this.versions && this.versions.length > 0) {
        if (this.year) { // filter by year
          this.versions = this.versions.filter((version) => {
            const sYear = version.startingDate
              ? new Date(version.startingDate).getFullYear()
              : 1900;
            const eYear = version.endingDate
              ? new Date(version.endingDate).getFullYear()
              : new Date().getFullYear();

            return this.year >= sYear && this.year <= eYear;
          });
        }

        if (this.supply) { // filter by supply
          this.versions = this.versions.filter(version => version.supplyCode === this.supply);
        }
      }
    },

    exportData() {
      const items = [];
      this.$props.items.forEach((item) => {
        const itemObject = {
          Status: item.status === 6 ? 'Lost'
            : item.status === 6.1 ? 'Lost-Shop'
              : '',
          ID: item.old_id ? item.old_id : item._id,
          Title: item.title,
          'Manufacturer Code': item.manufacturer_code,
          Notes: item.notes,
          Quality: item.quality.title,
          Location: (item.shelf_id && item.shelf_data)
            ? `${item.shelf_data.shelf.room_name}-${item.shelf_data.shelf.location_code}: ${
              item.shelf_data.shelf.x}, ${item.shelf_data.shelf.y}`
            : '',
          Price: `${item.price} ${item.currency}`,
          'Date Created': moment(item.date_created).format('DD-MM-YYYY LT'),
        };
        items.push(itemObject);
      });
      return items;
    },
    getQualities() {
      this.loadingQuality = true;
      getRequest('/quality/').then((response) => {
        console.log(response);
        this.qualityItems = response.qualities;
        this.advancedSearchItems.quality = [];
        this.qualityItems.forEach(quality => this.advancedSearchItems.quality.push(quality._id));
        this.loadingQuality = false;
      }).catch((error) => {
        console.log(error);
        this.loadingQuality = false;
      });
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/').then((response) => {
        console.log(response);
        this.institutions = response.institutions;
        this.loadingInstitution = false;
      }).catch((error) => {
        console.log(error);
        this.loadingInstitution = false;
      });
    },
    getInstitution() {
      getRequest('/institution', `id=${this.$store.getters.institutionId}`)
        .then((response) => {
          if (response.institution && response.institution.length > 0) {
            this.canSearchPlates = !!(response.institution[0].plates.remaining > 0);
          }
        })
        .catch((error) => { console.log(error); });
    },
    getShipments(institutionId) {
      this.loadingShipment = true;
      getRequest('/shipment/institution', `id=${institutionId}`).then((response) => {
        console.log(response);
        this.shipments = response.shipments;
        this.loadingShipment = false;
      }).catch((error) => {
        console.log(error);
        this.loadingShipment = false;
      });
    },
    searchPlate() {
      if (this.plate && this.plate.length > 0) {
        this.loadingPlate = true;
        getRequest(`/info-car/plate/${this.plate}`, '')
          .then(async (response) => {
            console.log(response);
            try {
              const { brands } = await getRequest('/info-car/brands');
              this.brands = brands;
              this.brand = await getRequest('/info-car/brand', `id=${response.brandId}`);

              const { models } = await getRequest('/info-car/models', `brand_code=${response.brandCode}`);
              this.models = models;
              this.model = await getRequest('/info-car/model', `id=${response.modelId}`);

              const { versions } = await getRequest('/info-car/versions', `brand_code=${response.brandCode}&model_code=${response.modelCode}`);
              this.versions = versions;
              this.version = await getRequest('/info-car/version', `id=${response.versionId}`);

              this.foundPlate = true;
              this.getInstitution();
            } catch (error) {
              console.log(error);
              this.$emit('alert', {
                message: this.$i18n.t('parts.advanced_search.plate_not_found'),
                type: 'error',
                timeout: 5000,
              });
              this.foundPlate = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit('alert', {
              message: this.$i18n.t('parts.advanced_search.plate_not_found'),
              type: 'error',
              timeout: 5000,
            });
            this.foundPlate = false;
          })
          .finally(() => { this.loadingPlate = false; });
      }
    },
    setId(id) {
      this.searchType = 'id';
      this.search = id;
    },
    highlight(title, search) {
      let result = title || '';
      if (search) {
        const index = title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());
        // mark the text.
        if (index > -1) result = [title.slice(0, index), '<mark>', title.slice(index, index + search.length), '</mark>', title.slice(index + search.length)].join('');
      }
      return `<span>${result}</span>`;
    },
  },
};
</script>
