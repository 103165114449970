<template>
  <v-container
    fluid
    class="pa-0"
  >
    <toolbar
      @search="search"
      @adv-search="advancedSearch"
      @clear-search="clearSearch"
    />
    <datatable
      ref="datatable"
      :search="searchObject"
      @approve-user="approveUser"
      @refuse-user="refuseUser"
      @delete-user="deleteUser"
      @put-discount="putDiscount"
      @image-dialog="showImage"
    />
    <photo-dialog ref="photoDialog" />
  </v-container>
</template>

<script>
import Toolbar from '@/components/ApproveUsers/toolbar.vue';
import Datatable from '@/components/ApproveUsers/datatable.vue';
import PhotoDialog from '@/components/ApproveUsers/photoDialog.vue';

import putRequest from '../api/putRequest';
import deleteRequest from '../api/deleteRequest';

export default {
  components: {
    toolbar: Toolbar,
    datatable: Datatable,
    'photo-dialog': PhotoDialog,
  },
  data: () => ({
    searchObject: {
      type: null,
      data: {},
    },
  }),
  mounted() {},
  methods: {
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    advancedSearch(data) {
      this.searchObject = {
        type: 'advancedSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    approveUser(id) {
      putRequest(`/user/shop/status/${id}`, 'operation=approve')
        .then((response) => {
          console.log(response);
          const { datatable } = this.$refs;
          datatable.getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    refuseUser(id) {
      putRequest(`/user/shop/status/${id}`, 'operation=refuse')
        .then((response) => {
          console.log(response);
          const { datatable } = this.$refs;
          datatable.getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    putDiscount(data) {
      putRequest(`/user/shop/discount/${data.id}`, `discount=${data.discount}`)
        .then(response => console.log(response))
        .catch(error => console.log(error));
    },
    deleteUser(id) {
      const c = confirm(this.$i18n.t('delete_alert')); // eslint-disable-line no-restricted-globals, no-alert
      if (c === true) {
        deleteRequest('/user/shop', `id=${id}`)
          .then((response) => {
            console.log(response);
            const { datatable } = this.$refs;
            datatable.getUsers();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    showImage(data) {
      const { photoDialog } = this.$refs;
      photoDialog.showImage(data);
    },
  },
};
</script>
