<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-center
        justify-start
      >
        <!--TITLE-->
        <v-toolbar-title class="hidden-xs-only">
          {{ $t('parts.movements.label') }}
        </v-toolbar-title>
        <v-divider
          class="mx-5 hidden-xs-only"
          inset
          vertical
        />
        <!----------------------------------------------------------------------------------------->
        <v-spacer class="hidden-sm-and-down" />
        <!--SEARCH BY TITLE-->
        <v-autocomplete
          v-if="searchType === 'title'"
          v-model="titleCode"
          :loading="loadingTitle"
          :items="titles"
          :search-input.sync="searchTitle"
          :label="$t('parts.movements.search')"
          :filter="() => true"
          :item-text="`title.${$store.getters.language}`"
          item-value="partCode"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              class="listRowColor"
            >
              <v-flex>
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">Info Car</span>
                  <span
                    class="body-2"
                    v-html="highlight(item.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
              <v-flex
                v-for="(element, index) in item.related"
                :key="index"
                class="hidden-sm-and-down"
              >
                <v-layout
                  column
                  wrap
                >
                  <span
                    class="body-1"
                    style="margin-top: 17px;"
                    v-html="highlight(element.title[$store.getters.language], searchTitle)"
                  />
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>

        <!--SEARCH BY MULTIPLE TITLES-->
        <v-autocomplete
          v-else-if="searchType === 'multipleTitles'"
          v-model="titleCodes"
          :loading="loadingMultipleTitles"
          :items="titleGroups"
          :search-input.sync="searchMultipleTitles"
          :label="$t('parts.movements.search')"
          :item-text="`title.${$store.getters.language}`"
          item-value="codes"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />

        <!--SEARCH OTHER FIELDS-->
        <v-text-field
          v-else
          v-model="search"
          :label="$t('parts.movements.search')"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          @keyup.enter="searchMethod()"
          @click:append="searchMethod()"
        />

        <v-spacer />

        <!--SEARCH TYPE MD-AND-UP-->
        <v-flex
          md2
          lg1
          class="hidden-sm-and-down"
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
          />
        </v-flex>

        <!--ADVANCED MD-AND-UP-->
        <v-btn
          class=" mr-5 hidden-sm-and-down"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('parts.movements.advanced') }}
        </v-btn>

        <v-spacer class="hidden-sm-and-down" />
      </v-layout>
    </v-toolbar>
    <div class="redColor">
      <!------------------------------------------------------------------------------------------->
      <v-layout
        align-center
        justify-center
        :class="{ 'pt-1 pb-2': $vuetify.breakpoint.smAndDown }"
      >
        <v-spacer />

        <!--SEARCH TYPE SM-AND-DOWN-->
        <v-flex
          xs4
          sm2
        >
          <v-select
            v-model="searchType"
            :items="searchTypes"
            item-text="label"
            item-value="value"
            color="grey darken-4"
            solo
            flat
            hide-details
            class="hidden-md-and-up"
          />
        </v-flex>


        <!--ADVANCED SM-AND-DOWN-->
        <v-btn
          class="hidden-md-and-up"
          dark
          small
          @click="advancedSearch = !advancedSearch"
        >
          <v-icon left>
            {{ advancedSearch ? 'check_box': 'check_box_outline_blank' }}
          </v-icon>
          {{ $t('parts.movements.advanced') }}
        </v-btn>

        <v-spacer />
      </v-layout>

      <!--------------------------------------ADVANCED SEARCH-------------------------------------->
      <div v-if="advancedSearch">
        <v-layout
          row
          color="red darken-3"
          class="pb-2"
          wrap
        >
          <!----------------------------------------STATUS----------------------------------------->
          <v-flex
            xs12
            md6
          >
            <v-select
              v-model="status"
              :items="statusItems"
              :hint="$t('parts.movements.select_parts_status')"
              item-text="title"
              item-value="value"
              solo
              flat
              chips
              deletable-chips
              multiple
              color="red darken-2"
              class="ml-2 mr-2"
              label="Status"
              persistent-hint
            />
          </v-flex>
          <!-----------------------------------------DATE------------------------------------------>
          <!--STARTING DATE-->
          <v-flex
            xs12
            sm6
            md3
          >
            <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDate"
                  :label="$t('parts.movements.starting_date')"
                  :hint="$t('parts.movements.select_starting_date')"
                  solo
                  flat
                  color="black"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  class="ml-2 mr-2"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="startDate"
                color="red darken-3"
                @input="startDateMenu = false"
              />
            </v-menu>
          </v-flex>
          <!--ENDING DATE-->
          <v-flex
            xs12
            sm6
            md3
          >
            <v-menu
              v-model="endDateMenu"
              :close-on-content-click="false"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDate"
                  :label="$t('parts.movements.ending_date')"
                  :hint="$t('parts.movements.select_ending_date')"
                  solo
                  flat
                  color="black"
                  persistent-hint
                  prepend-icon="event"
                  readonly
                  class="ml-2 mr-2"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="endDate"
                color="red darken-3"
                @input="endDateMenu = false"
              />
            </v-menu>
          </v-flex>
        </v-layout>
        <!----------------------------------------------------------------------------------------->
        <v-layout
          align-center
          justify-center
          row
          wrap
        >
          <v-btn
            dark
            color="grey darken-4"
            @click="clearSearchFields"
          >
            {{ $t('parts.advanced_search.clear') }}
            <v-icon right>
              clear_all
            </v-icon>
          </v-btn>
          <v-btn
            dark
            color="grey darken-4"
            @click="searchMethod"
          >
            {{ $t('parts.movements.search') }}
            <v-icon right>
              search
            </v-icon>
          </v-btn>
        </v-layout>
      </div>
    </div>
  </div>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    searchTypes: [
      { label: 'Title', value: 'title' },
      { lable: 'Multiple Titles', value: 'multipleTitles' },
      { label: 'ID', value: 'id' },
      { label: 'Other', value: 'other' },
    ],
    searchType: 'title',
    advancedSearch: false,
    search: '',
    searchTitle: null,
    searchMultipleTitles: null,
    titleCode: null,
    titleCodes: [],
    titles: [],
    titleGroups: [],
    status: [1, 2, 2.1, 3, 4, 5, 6, 6.1, 7, 8, 8.1, 9, 0],
    statusItems: [
      { title: 'Entered', value: 1 },
      { title: 'Returned-Sales', value: 2 },
      { title: 'Returned-Shop', value: 2.1 },
      { title: 'Re-Entered', value: 3 },
      { title: 'Found', value: 4 },
      { title: 'Sold', value: 5 },
      { title: 'Lost', value: 6 },
      { title: 'Lost-Shop', value: 6.1 },
      { title: 'Damaged', value: 7 },
      { title: 'Damaged-Sales', value: 8 },
      { title: 'Damaged-Shop', value: 8.1 },
      { title: 'Damaged-Lost', value: 9 },
      { title: 'Recycled', value: 0 },
    ],
    startDate: '',
    endDate: '',
    startDateMenu: false,
    endDateMenu: false,
    loadingTitle: false,
    loadingMultipleTitles: false,
  }),
  watch: {
    search(value) {
      if (!value && this.advancedSearch === false) this.$emit('clear-search');
    },
    searchTitle(value) {
      if (value) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCode = null;
        this.titles = [];
      }
    },
    searchMultipleTitles(value) {
      if (value) {
        this.loadingMultipleTitles = true;
        getRequest('/info-car/partgroups', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titleGroups = response.partgroups; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingMultipleTitles = false; });
      } else {
        if (this.advancedSearch === false) this.$emit('clear-search');
        this.titleCodes = [];
        this.titleGroups = [];
      }
    },
    advancedSearch(value) {
      if (!value) {
        this.status = [1, 2, 2.1, 3, 4, 5, 6, 6.1, 7, 8, 8.1, 9, 0];
        this.startDate = '';
        this.endDate = '';
      }
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.searchTypes[0].label = this.$i18n.t('parts.movements.title');
      this.searchTypes[1].label = this.$i18n.t('parts.movements.multiple_titles');
      this.searchTypes[2].label = this.$i18n.t('parts.movements.id');
      this.searchTypes[3].label = this.$i18n.t('parts.movements.other');
      this.statusItems[0].title = this.$i18n.t('parts.status.entered');
      this.statusItems[1].title = this.$i18n.t('parts.status.returned');
      this.statusItems[2].title = this.$i18n.t('parts.status.returned_shop');
      this.statusItems[3].title = this.$i18n.t('parts.status.re_entered');
      this.statusItems[4].title = this.$i18n.t('parts.status.found');
      this.statusItems[5].title = this.$i18n.t('parts.status.sold');
      this.statusItems[6].title = this.$i18n.t('parts.status.lost');
      this.statusItems[7].title = this.$i18n.t('parts.status.lost_shop');
      this.statusItems[8].title = this.$i18n.t('parts.status.damaged_inventory');
      this.statusItems[9].title = this.$i18n.t('parts.status.damaged_sales');
      this.statusItems[10].title = this.$i18n.t('parts.status.damaged_shop');
      this.statusItems[11].title = this.$i18n.t('parts.status.damaged_lost');
      this.statusItems[12].title = this.$i18n.t('parts.status.recycled');
    },
    searchMethod() {
      if (this.advancedSearch) {
        this.$emit('adv-search', { // ADVANCED
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          titleCodes: this.titleCodes,
          advancedSearchFields: {
            status: this.status,
            startDate: this.startDate,
            endDate: this.endDate,
          },
        });
      } else if (this.search.length > 0 || this.titleCode || this.titleCodes) { // REGULAR
        this.$emit('search', {
          type: this.searchType,
          searchField: this.search,
          titleCode: this.titleCode,
          titleCodes: this.titleCodes,
        });
      }
    },
    clearSearchFields() {
      this.search = '';
      this.status = [1, 2, 2.1, 3, 4, 5, 6, 6.1, 7, 8, 8.1, 9, 0];
      this.startDate = '';
      this.endDate = '';
      this.$emit('clear-search');
    },
    highlight(title, search) {
      let result = title || '';
      if (search) {
        const index = title.toLocaleLowerCase().indexOf(search.toLocaleLowerCase());
        // mark the text.
        if (index > -1) result = [title.slice(0, index), '<mark>', title.slice(index, index + search.length), '</mark>', title.slice(index + search.length)].join('');
      }
      return `<span>${result}</span>`;
    },
  },
};
</script>
