<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <span class="headline"> {{ $i18n.t('vehicle_entry.transfer.label') }} </span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-layout wrap>
          <!-----------------------------------------ALERT----------------------------------------->
          <v-flex xs12>
            <v-alert
              :value="alert"
              type="warning"
              outline
            >
              <v-layout
                align-center
                justify-center
                row
                wrap
              >
                <v-flex
                  xs12
                  sm6
                >
                  {{ $i18n.t('vehicle_entry.transfer.alert_message') }}
                </v-flex>
                <v-flex
                  xs12
                  sm6
                >
                  <v-layout row>
                    <v-btn
                      outline
                      small
                      color="success"
                      @click="confirm = true, alert = false"
                    >
                      {{ $t('parts.inventory.actions.alert.yes') }}
                    </v-btn>
                    <v-btn
                      outline
                      small
                      color="error"
                      @click="confirm = false, alert = false"
                    >
                      {{ $t('parts.inventory.actions.alert.no') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-alert>
          </v-flex>
          <!--------------------------------------INSTITUTION-------------------------------------->
          <v-flex xs12>
            <v-autocomplete
              v-model="institution"
              :items="institutions"
              :loading="loadingInstitution"
              :label="$t('vehicle_entry.institution')"
              item-text="title"
              item-value="_id"
              flat
              color="red darken-1"
            />
          </v-flex>
          <!--------------------------------------------------------------------------------------->
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          depressed
          flat
          @click="close"
        >
          {{ $i18n.t('vehicle_entry.close') }}
        </v-btn>
        <v-btn
          color="success"
          depressed
          flat
          :disabled="!confirm || !institution"
          :loading="loadingTransfer"
          @click="transfer"
        >
          {{ $i18n.t('vehicle_entry.transfer.transfer') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  components: {},
  data: () => ({
    dialog: false,
    confirm: false,
    alert: false,
    car: null,
    institution: null,
    institutions: [],
    loadingInstitution: false,
    loadingTransfer: false,
  }),
  watch: {
    dialog(value) { if (!value) this.close(); },
    institution(value) { if (value) this.alert = true; },
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.getInstitutions();
      this.car = data.car;
    },
    close() {
      this.dialog = false;
      this.confirm = false;
      this.alert = false;
      this.car = null;
      this.institution = null;
      this.institutions = [];
      this.loadingInstitution = false;
      this.loadingTransfer = false;
      this.$emit('close');
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/transfer')
        .then((response) => {
          this.institutions = response.institutions
            .filter(i => i._id.toString() !== this.$store.getters.institutionId.toString());
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInstitution = false; });
    },
    transfer() {
      this.loadingTransfer = true;
      putRequest('/car/transfer', '', {
        car_id: this.car._id,
        institution_id: this.institution,
      })
        .then((response) => { console.log(response); })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingTransfer = false;
          this.close();
        });
    },
  },
};
</script>
