<template>
  <v-dialog
    v-model="dialog"
    max-width="1300"
  >
    <v-card>
      <!--CLOSE-->
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="ma-2"
          outline
          icon
          large
          color="red"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-actions>

      <v-card-text>
        <!--LOADING-->
        <v-layout
          v-if="loading"
          align-center
          justify-center
        >
          <v-progress-circular
            indeterminate
            size="64"
            color="red"
          />
        </v-layout>

        <!--CODES-->
        <v-layout
          row
          wrap
        >
          <v-flex
            v-for="(data, index) in shelves"
            :key="index"
            xs12
            sm4
            md3
            lg2
            d-flex
          >
            <v-card flat>
              <v-layout
                align-center
                justify-center
                column
              >
                <v-flex
                  pa-2
                  xs12
                >
                  <!--QR CODE-->
                  <qr-code
                    :size="150"
                    :text="data._id"
                  />
                  <!--DETAILS-->
                  <div
                    class="text-xs-center"
                  >
                    <div>{{ repository }}</div>
                    <span>{{ data.room_name }} - {{ data.location_code }}: </span>
                    <span>{{ data.x }},{{ data.y }}</span>
                  </div>
                  <h3 class="text-xs-center">
                    {{ data._id.slice(data._id.length-6, data._id.length) }}
                  </h3>
                  <!--BUTTONS-->
                  <v-layout
                    align-center
                    justify-center
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          large
                          icon
                          v-on="on"
                          @click="printQrCode(index, data)"
                        >
                          <v-icon>
                            {{ data.printed ? 'print_disabled' : 'print' }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span v-if="data.printed">
                        <v-layout align-center>
                          <v-icon> print_disabled</v-icon>
                          &nbsp;
                          {{ $t('printed_alert') }}
                        </v-layout>
                      </span>
                    </v-tooltip>

                    <v-btn
                      flat
                      icon
                      @click="showParts(data)"
                    >
                      <v-icon>view_list</v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <!--PRINT-->
      <v-card-actions>
        <v-layout
          row
          align-center
          justify-end
          wrap
        >
          <v-spacer />

          <v-flex
            xs2
            md1
            mr-2
          >
            <v-select
              v-model="paperSize"
              color="red"
              :items="paperSizes"
              item-text="title"
              item-value="value"
              :label="$t('repositories.paper_size')"
            />
          </v-flex>

          <v-btn
            flat
            :disabled="shelves.length < 1"
            color="primary"
            @click="printShelves()"
          >
            {{ $t('repositories.print_shelf') }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrCode from 'vue-qr-generator';
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';

const QrCode = require('qrcode');
const { Printd } = require('printd');

const smcss = `@media print{
  @page {
    size: 4cm, 3cm;
    size: landscape;
  }
}`;

const lgcss = `@media print{
  @page{
    size: A4;
  }
  #container{
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .wrapper{
    margin: 8px 5px 8px 5px;
  }
}`;

const css = `
  body{
    height:100%;
    width:100%;
    font: 10px "Lucida Grande", Helvetica, Arial, sans-serif;
  }
  #content{
    padding-top: 6px;
    padding-bottom: 8px;
    display: grid;
  }
  #image{
    margin:0 auto;
  }
  #repository{
    font-size: 9px;
    text-align:center;
    margin: 0 auto;
  }
  #location{
    font-size: 9px;
    text-align:center;
    margin: 0 auto;
  }
  #code{
    font-size:11px;
    text-align:center;
    font-weight:bold;
    text-transform:uppercase;
    margin: 0 auto;
  }`;

function generateQrComponent(repo, shelf) {
  return new Promise((resolve, reject) => {
    QrCode.toDataURL(shelf._id, (error, dataUrl) => {
      if (error) return reject(error);
      // Repository string.
      const repository = document.createElement('span');
      repository.textContent = repo;
      repository.setAttribute('id', 'repository');
      // Location string.
      const location = document.createElement('span');
      location.textContent = `${shelf.room_name}-${shelf.location_code}:${shelf.x},${shelf.y}`;
      location.setAttribute('id', 'location');
      // Id component.
      const code = document.createElement('span');
      code.textContent = shelf._id.slice(shelf._id.length - 6, shelf._id.length);
      code.setAttribute('id', 'code');
      // Image component.
      const image = document.createElement('img');
      image.setAttribute('id', 'image');
      image.setAttribute('src', dataUrl);
      image.style.width = '65px';
      image.style.height = '65px';
      // Wrapper.
      const content = document.createElement('div');
      content.setAttribute('class', 'wrapper');
      content.setAttribute('id', 'content');
      content.appendChild(location);
      content.appendChild(code);
      content.appendChild(image);
      content.appendChild(repository);
      return resolve(content);
    });
  });
}

export default {
  components: {
    'qr-code': VueQrCode,
  },
  data: () => ({
    dialog: false,
    loading: true,
    paperSizes: [{ title: 'Small', value: 's' }, { title: 'Large', value: 'l' }],
    paperSize: 's',
    repository: null,
    room: {},
    shelves: [],
  }),
  watch: {
    dialog(value) {
      if (!value) this.close();
    },
  },
  created() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.paperSizes[0].title = this.$i18n.t('repositories.small_paper');
      this.paperSizes[1].title = this.$i18n.t('repositories.large_paper');
    },
    open(data) {
      this.dialog = true;
      this.getShelves(data);
    },
    close() {
      this.dialog = false;
      this.repository = null;
      this.room = {};
      this.shelves = [];
      this.$emit('close');
    },
    getShelves(data) {
      console.log(data);
      this.loading = true;
      getRequest(
        '/repository/shelves',
        `repository_id=${data.repositoryId}&room_id=${data.roomId}${data.locationId ? `&location_id=${data.locationId}` : ''}`,
      )
        .then((response) => {
          this.repository = response.repository;
          this.room = response.room;
          this.shelves = data.locationId
            ? response.room.locations.shelves
            : response.room.locations.reduce((acc, val) => acc.concat(val.shelves), []);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    async printQrCode(index, shelf) {
      try {
        const content = await generateQrComponent(this.repository, shelf);
        const d = new Printd();
        d.print(content, [smcss, css], '', (callback) => {
          const { iframe, launchPrint } = callback;
          const { contentWindow } = iframe;
          contentWindow.addEventListener('afterprint', () => { postRequest.object('/print', { type: 'shelves', id: shelf._id }); });
          launchPrint();
        });
      } catch (error) {
        console.log(error);
      }
    },
    async printShelves() {
      try {
        const promises = [];
        this.shelves.forEach(shelf => promises.push(generateQrComponent(this.repository, shelf)));
        const response = await Promise.all(promises);
        const content = document.createElement('div');
        content.setAttribute('id', 'container');
        response.forEach(c => content.appendChild(c));
        const d = new Printd();
        if (this.paperSize === 's') {
          d.print(content, [smcss, css], '', (callback) => {
            const { iframe, launchPrint } = callback;
            const { contentWindow } = iframe;
            contentWindow.addEventListener('afterprint', () => { postRequest.object('/print', { type: 'shelves', ids: this.shelves.map(s => s._id) }); });
            launchPrint();
          });
        } else if (this.paperSize === 'l') {
          d.print(content, [lgcss, css], '', (callback) => {
            const { iframe, launchPrint } = callback;
            const { contentWindow } = iframe;
            contentWindow.addEventListener('afterprint', () => { postRequest.object('/print', { type: 'shelves', ids: this.shelves.map(s => s._id) }); });
            launchPrint();
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    showParts(data) {
      this.$emit('parts', data);
    },
  },
};
</script>
