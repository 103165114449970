<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
  >
    <v-card>
      <v-card-title class="redColor">
        <!--TITLE-->
        <v-flex
          xs10
          sm11
        >
          <span class="headline"> {{ $t('car_locations.cars_list.title') }} </span>
        </v-flex>
        <!--CLOSE-->
        <v-flex
          xs2
          sm1
        >
          <v-btn
            outline
            icon
            large
            color="black"
            @click="close"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :pagination.sync="pagination"
          :headers="headers"
          :items="cars"
          :total-items="total"
          :rows-per-page-items="[5,10,25]"
          :loading="loading"
        >
          <v-progress-linear
            slot="progress"
            color="red"
            indeterminate
          />
          <template v-slot:items="props">
            <tr>
              <td class="ma-1 pt-1 pb-1">
                <v-layout
                  class="pointer"
                  ma-1
                  column
                  align-center
                  justify-center
                  @click="openQrDialog({
                    id: props.item._id,
                    type: 'cars',
                    vehicle: props.item,
                    printed: props.item.printed
                  })"
                >
                  <qr-code
                    :size="60"
                    :text="props.item._id"
                  />
                  <h4>
                    {{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}
                  </h4>
                </v-layout>
              </td>
              <td>
                <img
                  :src="`${server}/images/car?q=${props.item.photos[0]}`"
                  class="ma-1 pointer"
                  height="75"
                  width="100"
                  @click="showImages(props.item.photos)"
                >
              </td>
              <td>{{ props.item.brand.title }}</td>
              <td>{{ props.item.model.description }}</td>
              <td>{{ props.item.version.description }}</td>
              <td>
                <span>
                  {{ props.item.version.startingDate | date }}
                  - {{ props.item.version.endingDate | date }}
                </span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    date(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    headers: [
      { text: 'ID', sortable: true, value: '_id' },
      { text: 'Photo', sortable: false, value: '' },
      { text: 'Brand', sortable: true, value: 'brand.brandCode' },
      { text: 'Model', sortable: true, value: 'model.modelCode' },
      { text: 'Version', sortable: true, value: 'version.versionCode' },
      { text: 'Year', sortable: false, value: '' },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: '_id',
      descending: true,
    },
    dialog: false,
    location: null,
    cars: [],
    total: 0,
    loading: false,
  }),
  computed: {
    server() { return this.$serverUri; },
  },
  watch: {
    dialog(value) { if (!value) this.close(); },
    pagination: {
      handler() { this.getCars(); },
    },
  },
  created() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('vehicle_entry.datatable.id');
      this.headers[1].text = this.$i18n.t('vehicle_entry.datatable.images');
      this.headers[2].text = this.$i18n.t('vehicle_entry.datatable.brand');
      this.headers[3].text = this.$i18n.t('vehicle_entry.datatable.model');
      this.headers[4].text = this.$i18n.t('vehicle_entry.datatable.version');
      this.headers[5].text = this.$i18n.t('vehicle_entry.datatable.year');
    },
    open(location) {
      this.location = location;
      this.dialog = true;
      this.getCars();
    },
    close() {
      this.dialog = false;
      this.location = null;
      this.cars = [];
      this.total = 0;
    },
    getCars() {
      this.loading = true;
      getRequest('/car/location', `id=${this.location}&page=${this.pagination.page}&limit=${this.pagination.rowsPerPage}&sort_by=${this.pagination.sortBy}&descending=${this.pagination.descending}`)
        .then((response) => {
          this.cars = response.cars;
          this.total = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
  },
};
</script>
