import axios from 'axios';

// const url = 'http://localhost:3000';
const url = 'https://api.lekotech.com';

const ebayService = {

  async endItemEbay(id, institutionId) {
    try {
      const response = await axios.get(`${url}/ebay/delete-item/${id}?institutionId=${institutionId}`, {
        headers: { authorization: localStorage.token },
      });
      if (response.status === 200) {
        return { success: true, msg: 'Item Ended successfully' };
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return { success: false, msg: error.response.data.errorMsg };
      }
      return { success: false, msg: error.message };
    }
  },

  async editItemEbay(id, institutionId) {
    console.log('inside editItemEbay service');
    try {
      const response = await axios.get(`${url}/ebay/edit-item/${id}?institutionId=${institutionId}`, {
        headers: { authorization: localStorage.token },
      });
      if (response.status === 200) {
        return { success: true, msg: 'Item Edited successfully' };
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error('Error from backend:', error.response.data);
        return { success: false, msg: error.response.data.errorMsg };
      }
      return { success: false, msg: error.message };
    }
  },

};

export default ebayService;
