var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.repositories,"search":_vm.searchField,"loading":_vm.loading,"hide-actions":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{staticClass:"tableRowColor",class:{tableRowColorSelected : props.index == _vm.activeRow},on:{"click":function($event){_vm.activeRow = props.index}}},[_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.name)+" ")]),_c('td',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(props.item.institution_id.title)+" ")]),_c('td',{staticClass:"justify-center"},[_c('v-layout',{attrs:{"row":""}},[_c('v-icon',{staticClass:"mr-2 pointer",on:{"click":function($event){return _vm.viewDetails(props.item)}}},[_vm._v(" view_list ")]),(_vm.$store.getters.role == 'LekoAdmin'
              || _vm.$store.getters.role == 'InstitutionAdmin'
              || _vm.$store.getters.role == 'InstitutionManager'
            )?_c('v-icon',{staticClass:"mr-2 pointer",on:{"click":function($event){return _vm.editRepository(props.item)}}},[_vm._v(" edit ")]):_vm._e(),(_vm.$store.getters.role == 'LekoAdmin'
              || _vm.$store.getters.role == 'InstitutionAdmin'
              || _vm.$store.getters.role == 'InstitutionManager'
            )?_c('v-icon',{staticClass:"pointer",on:{"click":function($event){return _vm.deleteRepository(props.item._id)}}},[_vm._v(" delete ")]):_vm._e()],1)],1)])]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"red","indeterminate":""},slot:"progress"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }