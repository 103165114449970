<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('part_requests.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-spacer class="hidden-sm-and-down" />
    </v-toolbar>

    <!--------------------------------------PENDING REQUESTS--------------------------------------->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
    >
      <v-toolbar-title
        class="pointer"
        @click="pendingRequests = !pendingRequests"
      >
        {{ $t('part_requests.pending_requests') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-select
          v-if="pendingRequests"
          v-model="selectedPendingRequestsItems"
          :items="pendingRequestsItems"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          class="hidden-sm-and-down"
          color="red darken-2"
          background-color="grey darken-4"
        />

        <v-btn
          flat
          icon
          @click="pendingRequests = !pendingRequests"
        >
          <v-icon v-if="pendingRequests">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!--SELECTION 'SM AND DOWN'-->
    <v-layout
      v-if="pendingRequests"
      class="grey darken-4"
      row
      wrap
      justify-center
    >
      <v-flex
        xs12
        sm10
      >
        <v-select
          v-if="pendingRequests"
          v-model="selectedPendingRequestsItems"
          :items="pendingRequestsItems"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          class="hidden-md-and-up"
          color="red darken-2"
          background-color="grey darken-4"
        />
      </v-flex>
    </v-layout>


    <pending-requests
      v-if="pendingRequests"
      ref="pendingRequests"
      :selected="selectedPendingRequestsItems"
      @assign-institutions="assignInstitutions"
      @view-details="viewDetails"
      @view-offers="viewOffers"
      @open-return-dialog="openReturnDialog"
    />


    <!-------------------------------------CONFIRMED REQUESTS-------------------------------------->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
    >
      <v-toolbar-title
        class="pointer"
        @click="confirmedRequests = !confirmedRequests"
      >
        {{ $t('part_requests.confirmed_requests') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-select
          v-if="confirmedRequests"
          v-model="selectedConfirmedRequestsItems"
          :items="confirmedRequestsItems"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          class="hidden-sm-and-down"
          color="red darken-2"
          background-color="grey darken-4"
        />

        <v-btn
          flat
          icon
          @click="confirmedRequests = !confirmedRequests"
        >
          <v-icon v-if="confirmedRequests">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!--SELECTION 'SM AND DOWN'-->
    <v-layout
      v-if="confirmedRequests"
      class="grey darken-4"
      row
      wrap
      justify-center
    >
      <v-flex
        xs12
        sm10
      >
        <v-select
          v-if="confirmedRequests"
          v-model="selectedConfirmedRequestsItems"
          :items="confirmedRequestsItems"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          class="hidden-md-and-up"
          color="red darken-2"
          background-color="grey darken-4"
        />
      </v-flex>
    </v-layout>

    <confirmed-requests
      v-if="confirmedRequests"
      ref="confirmedRequests"
      :selected="selectedConfirmedRequestsItems"
      @assign-institutions="assignInstitutions"
      @view-details="viewDetails"
      @view-offers="viewOffers"
      @confirmed-sale="getPartRequests(), openInfoDialog('confirmed-sale')"
    />

    <!---------------------------------------OTHER REQUESTS---------------------------------------->
    <v-toolbar
      color="grey darken-4"
      flat
      dark
      dense
    >
      <v-toolbar-title
        class="pointer"
        @click="otherRequests = !otherRequests"
      >
        {{ $t('part_requests.other_requests') }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-select
          v-if="otherRequests"
          v-model="selectedOtherRequestsItems"
          :items="otherRequestsItems"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          class="hidden-sm-and-down"
          color="red darken-2"
          background-color="grey darken-4"
        />

        <v-btn
          flat
          icon
          @click="otherRequests = !otherRequests"
        >
          <v-icon v-if="otherRequests">
            expand_less
          </v-icon>
          <v-icon v-else>
            expand_more
          </v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!--SELECTION 'SM AND DOWN'-->
    <v-layout
      v-if="otherRequests"
      class="grey darken-4"
      row
      wrap
      justify-center
    >
      <v-flex
        xs12
        sm10
      >
        <v-select
          v-if="otherRequests"
          v-model="selectedOtherRequestsItems"
          :items="otherRequestsItems"
          item-text="text"
          item-value="value"
          solo
          flat
          dense
          dark
          chips
          deletable-chips
          multiple
          class="hidden-md-and-up"
          color="red darken-2"
          background-color="grey darken-4"
        />
      </v-flex>
    </v-layout>

    <other-requests
      v-if="otherRequests"
      ref="otherRequests"
      :selected="selectedOtherRequestsItems"
      @view-details="viewDetails"
      @view-offers="viewOffers"
    />


    <!--DETAILS-->
    <view-details
      ref="details"
      @view-permit-image="viewPermitImage"
      @view-request-images="viewRequestImages"
    />
    <!--OFFERS-->
    <offers
      ref="offers"
      @close="getPartRequests"
    />
    <!--IMAGES-->
    <view-images
      ref="images"
      @close="showDetails"
    />
    <!--ASSIGN INSTITUTIONS-->
    <assign-institutions
      ref="assignInstitutions"
      @saved="getPartRequests"
    />
    <!--RETURN DIALOG-->
    <return-dialog
      ref="returnDialog"
      @confirmed="getPartRequests(), openInfoDialog('confirmed-return')"
      @refused="getPartRequests(), openInfoDialog('refused-return')"
    />
    <!--INFO DIALOG-->
    <info-dialog ref="infoDialog" />
  </v-container>
</template>

<script>
import PendingRequests from '../components/PartRequests/pendingRequests.vue';
import ConfirmedRequests from '../components/PartRequests/confirmedRequests.vue';
import OtherRequests from '../components/PartRequests/otherRequests.vue';
import AssignInstitutions from '../components/PartRequests/assignInstitutions.vue';
import InfoDialog from '../components/PartRequests/infoDialog.vue';
import ReturnDialog from '../components/PartRequests/returnDialog.vue';
import Details from '../components/PartRequests/details.vue';
import Images from '../components/PartRequests/images.vue';
import Offers from '../components/PartRequests/offers.vue';

export default {
  components: {
    // requests: Requests,
    'pending-requests': PendingRequests,
    'confirmed-requests': ConfirmedRequests,
    'other-requests': OtherRequests,
    'assign-institutions': AssignInstitutions,
    'info-dialog': InfoDialog,
    'return-dialog': ReturnDialog,
    'view-details': Details,
    'view-images': Images,
    offers: Offers,
  },
  data: () => ({
    pendingRequests: true,
    confirmedRequests: false,
    otherRequests: false,

    pendingRequestsItems: [
      { text: 'Waiting offer', value: 1 },
      { text: 'Pending return', value: 2 },
    ],
    confirmedRequestsItems: [
      { text: 'Pending offer confirmation', value: 1 },
      { text: 'Confirmed offer', value: 2 },
    ],
    otherRequestsItems: [
      { text: 'Sold', value: 1 },
      { text: 'Returned', value: 2 },
      { text: 'Expired', value: 3 },
      { text: 'Refused', value: 4 },
      { text: 'No Offer', value: 5 },
      { text: 'Not Found', value: 6 },
    ],
    selectedPendingRequestsItems: [1, 2],
    selectedConfirmedRequestsItems: [1, 2],
    selectedOtherRequestsItems: [1, 2, 3, 4, 5, 6],
  }),
  created() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.pendingRequestsItems[0].text = this.$i18n.t('part_requests.status.waiting_offer');
      this.pendingRequestsItems[1].text = this.$i18n.t('part_requests.status.pending_return');
      this.confirmedRequestsItems[0].text = this.$i18n.t('part_requests.status.pending_offer_confirmation');
      this.confirmedRequestsItems[1].text = this.$i18n.t('part_requests.status.confirmed_offer');
      this.otherRequestsItems[0].text = this.$i18n.t('part_requests.status.sold');
      this.otherRequestsItems[1].text = this.$i18n.t('part_requests.status.returned');
      this.otherRequestsItems[2].text = this.$i18n.t('part_requests.status.expired');
      this.otherRequestsItems[3].text = this.$i18n.t('part_requests.status.refused');
      this.otherRequestsItems[4].text = this.$i18n.t('part_requests.status.no_offer');
      this.otherRequestsItems[5].text = this.$i18n.t('part_requests.status.not_found');
    },
    assignInstitutions(data) {
      const { assignInstitutions } = this.$refs;
      assignInstitutions.open(data);
    },
    viewDetails(data) {
      const { details } = this.$refs;
      details.open(data);
    },
    viewOffers(data) {
      const { offers } = this.$refs;
      offers.open(data);
    },
    viewPermitImage(data) {
      const { images, details } = this.$refs;
      images.open('single', data);
      details.hide();
    },
    viewRequestImages(data) {
      const { images, details } = this.$refs;
      images.open('multiple', data);
      details.hide();
    },
    showDetails() {
      const { details } = this.$refs;
      details.show();
    },
    getPartRequests() {
      const { pendingRequests, confirmedRequests, otherRequests } = this.$refs;
      if (this.pendingRequests) pendingRequests.getPartRequests();
      if (this.confirmedRequests) confirmedRequests.getPartRequests();
      if (this.otherRequests) otherRequests.getPartRequests();
    },
    openInfoDialog(operation) {
      const { infoDialog } = this.$refs;
      let message;
      if (operation === 'confirmed-sale') message = this.$i18n.t('part_requests.sale_confirmation');
      if (operation === 'confirmed-return') message = this.$i18n.t('part_requests.return_confirmation');
      if (operation === 'refused-return') message = this.$i18n.t('part_requests.return_refusal');

      infoDialog.open(message);
    },
    openReturnDialog(data) {
      const { returnDialog } = this.$refs;
      returnDialog.open(data);
    },
  },
};
</script>
