<template>
  <v-dialog v-model="dialog" max-width="800px" persistent>
    <v-card>
      <v-card-title class="red darken-2">
        <span class="headline">{{ $t('vehicle_entry.photos') }}</span>
        <v-spacer />
        <v-btn outline icon large color="black" @click="close">
          <v-icon x-large>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <!-- CAMERA -->
          <v-flex xs12>
            <camera v-if="dialog" @takePicture="takePicture" />
          </v-flex>
          <!-- PHOTOS -->
          <v-flex xs12>
            <v-container grid-list-sm fluid>
              <v-layout row wrap>
                <v-flex v-for="(data, index) in photos" :key="index" xs6 sm4 d-flex>
                  <v-hover>
                    <v-card
                      slot-scope="{ hover }"
                      flat
                      tile
                      class="d-flex"
                      draggable="true"
                      :data-index="index"
                      @dragstart="dragStartHandler"
                      @dragover.prevent
                      @drop="dropHandler"
                      @dragend="dragEndHandler"
                    >
                      <!-- EXISTING PHOTOS -->
                      <v-img v-if="data && data.thumb" :src="`${$serverUri}/images?q=${data.thumb}`">
                        <v-layout row ma-1>
                          <v-icon
                            v-if="hover"
                            x-large
                            color="primary"
                            class="pointer"
                            @click="viewImage('string', data)"
                          >
                            image
                          </v-icon>
                          <v-spacer />
                          <v-icon
                            v-if="hover"
                            x-large
                            color="red"
                            class="pointer"
                            @click="deleteImage(index)"
                          >
                            delete_forever
                          </v-icon>
                        </v-layout>
                      </v-img>

                      <!-- NEW PHOTOS -->
                      <v-img v-else :src="data.dataUrl">
                        <v-layout row ma-1>
                          <v-icon
                            v-if="hover"
                            x-large
                            color="primary"
                            class="pointer"
                            @click="viewImage('dataUrl', data.dataUrl)"
                          >
                            image
                          </v-icon>
                          <v-spacer />
                          <v-icon
                            v-if="hover"
                            x-large
                            color="red"
                            class="pointer"
                            @click="deleteImage(index)"
                          >
                            delete_forever
                          </v-icon>
                        </v-layout>
                      </v-img>
                    </v-card>
                  </v-hover>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="error" flat @click="close">{{ $i18n.t('vehicle_entry.close') }}</v-btn>
        <v-btn color="primary" flat @click="save">{{ $i18n.t('vehicle_entry.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Camera from '../camera.vue';

export default {
  components: {
    camera: Camera,
  },
  data() {
    return {
      draggingIndex: -1,
      dropIndex: -1,
      dialog: false,
      photos: [],
      photosToDelete: [],
    };
  },
  methods: {
    open(photos) {
      this.dialog = true;
      this.photos = [...photos];
    },
    save() {
      this.$emit('save', { photos: this.photos, photosToDelete: this.photosToDelete });
      this.close();
    },
    close() {
      this.dialog = false;
      this.photos = [];
      this.photosToDelete = [];
      this.$emit('close');
    },
    takePicture(data) {
      const random = Math.random().toString(36).substring(9);
      const photo = {
        title: `${random}-photo${this.photos.length}.png`,
        dataUrl: data.dataUrl,
        blob: data.blob,
      };
      this.photos.push(photo);
    },
    viewImage(type, data) {
      this.$emit('open-img-dialog', { type, data });
    },
    deleteImage(index) {
      if (typeof this.photos[index] === 'string') {
        this.photosToDelete.push(this.photos[index]);
      }
      this.photos.splice(index, 1);
    },
    dragStartHandler(e) {
      this.draggingIndex = parseInt(e.currentTarget.getAttribute('data-index'));
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/html', null);
    },
    dropHandler(e) {
      e.preventDefault(); // Prevent the browser's default behavior
      this.dropIndex = parseInt(e.currentTarget.getAttribute('data-index'));
      if (this.draggingIndex !== this.dropIndex) {
        const draggedImage = this.photos[this.draggingIndex];
        this.photos.splice(this.draggingIndex, 1);
        if (this.dropIndex > this.draggingIndex) {
          this.photos.splice(this.dropIndex, 0, draggedImage);
        } else {
          this.photos.splice(this.dropIndex, 0, draggedImage);
        }
      }
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },

    dragEndHandler() {
      this.draggingIndex = -1;
      this.dropIndex = -1;
    },

    hide() { this.dialog = false; },
    show() { this.dialog = true; },
  },
};
</script>
