<template>
  <v-dialog
    v-model="dialog"
    max-width="1000px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <v-flex
          xs10
          sm11
        >
          <span class="headline"> {{ $t('vehicle_entry.inventory_list.label') }} </span>
        </v-flex>
        <v-flex
          xs2
          sm1
        >
          <v-btn
            outline
            icon
            large
            color="black"
            @click="close"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <!---------------------------------------CAR DETAILS--------------------------------------->
        <v-layout
          row
          wrap
        >
          <!--BRAND-->
          <v-flex>
            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.brand') }}</span>
              <span
                v-if="car.brand"
                class="body-2"
              >
                {{ car.brand.title }}
              </span>
            </v-layout>
          </v-flex>
          <!--MODEL-->
          <v-flex>
            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
              <span
                v-if="car.model"
                class="body-2"
              >
                {{ car.model.description }}
              </span>
            </v-layout>
          </v-flex>
          <!--VERSION-->
          <v-flex>
            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
              <span
                v-if="car.version"
                class="body-2"
              >
                {{ car.version.description }}
              </span>
            </v-layout>
          </v-flex>
          <!--ENGINE-->
          <v-flex>
            <v-layout
              column
              wrap
            >
              <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
              <span
                v-if="car.version"
                class="body-2"
              >
                {{ car.version.engineCode }}
              </span>
            </v-layout>
          </v-flex>
        </v-layout>
        <!---------------------------------------PARTS LIST---------------------------------------->
        <v-progress-linear
          v-if="loading"
          slot="progress"
          color="red"
          indeterminate
        />

        <v-list>
          <v-list-group
            v-for="item in parts"
            :key="item._id"
            v-model="item.active"
            no-action
          >
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.category.it }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>

            <v-data-table
              :headers="headers"
              :items="item.items"
              :loading="loading"
              hide-actions
            >
              <v-progress-linear
                slot="progress"
                color="red"
                indeterminate
              />

              <template v-slot:items="props">
                <tr
                  :class="{
                    'green darken-1': quantity[props.item.data.description.descriptionCode.toString()] < props.item.count, // eslint-disable-line max-len
                    'yellow darken-1': quantity[props.item.data.description.descriptionCode.toString()] == props.item.count // eslint-disable-line max-len
                  }"
                >
                  <!-- DESCRIPTION -->
                  <td>
                    <span
                      v-if="props.item.data.description.descriptionId
                        && props.item.data.description.descriptionId.title"
                      class="text-xs font-weight-bold"
                    >
                      {{ props.item.data.description.descriptionId.title[$store.getters.language] }}
                    </span>
                    <span
                      v-if="props.item.data.description.positionCode"
                      class="text-xs"
                    >
                      {{ getPosition(props.item.data.description.positionCode) }}
                    </span>
                  </td>
                  <!-- QUANTITY -->
                  <td> {{ props.item.count }} </td>
                  <!-- ACTIONS -->
                  <td>
                    <v-btn
                      icon
                      @click="goToInventory(props.item.data.description.descriptionCode)"
                    >
                      <v-icon>open_in_new</v-icon>
                    </v-btn>
                  </td>
                  <!-- MINIMAL QUANTITY -->
                  <td>
                    <v-layout wrap>
                      <v-flex
                        xs6
                        sm4
                        md3
                      >
                        <v-text-field
                          v-model="quantity[props.item.data.description.descriptionCode.toString()]"
                          color="red darken-2"
                          type="number"
                          min="0"
                        />
                      </v-flex>
                    </v-layout>
                  </td>
                  <!-- NOTES -->
                  <td>
                    <v-text-field
                      v-model="notes[props.item.data.description.descriptionCode.toString()]"
                      color="red darken-2"
                    />
                  </td>
                  <!-- SELECT -->
                  <td>
                    <v-btn
                      icon
                      @click="select(props.item)"
                    >
                      <v-icon
                        v-if="!!selected[props.item.data.description.descriptionCode.toString()]"
                      >
                        check_box
                      </v-icon>
                      <v-icon v-else>
                        check_box_outline_blank
                      </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-list-group>
        </v-list>


        <!-- ACTIONS -->
        <v-layout justify-center>
          <v-btn
            color="primary"
            dark
            :loading="loadingSelect"
            @click="selectAll"
          >
            {{ $t('vehicle_entry.inventory_list.select_all') }}
            <v-icon right>
              check_box
            </v-icon>
          </v-btn>

          <download-excel
            :fetch="exportData"
            type="xls"
            name="data"
          >
            <v-btn
              color="primary"
              dark
            >
              {{ $t('vehicle_entry.inventory_list.export') }}
              <v-icon right>
                fas fa-file-excel
              </v-icon>
            </v-btn>
          </download-excel>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import JsonExcel from 'vue-json-excel'; // eslint-disable-line import/extensions

import getRequest from '../../api/getRequest';

export default {
  components: {
    'download-excel': JsonExcel,
  },
  data: () => ({
    dialog: false,
    loading: false,
    loadingSelect: false,
    headers: [
      { text: 'Description', sortable: false, value: '' },
      { text: 'Actual quantity', sortable: false, value: '' },
      { text: 'Actions', sortable: false, value: '' },
      { text: 'Minimal quantity', sortable: false, value: '' },
      { text: 'Notes', sortable: false, value: '' },
      { text: 'Select', sortable: false, value: '' },
    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
    },
    car: { brand: {}, model: {}, version: {} },
    parts: [],
    total: 0,
    quantity: {},
    selected: {},
    notes: {},
  }),
  watch: {
    pagination: {
      handler() { this.getParts(); },
      deep: true,
    },
    dialog(value) {
      if (!value) this.close();
    },
  },
  methods: {
    setHeaders() {
      this.headers[0].text = this.$i18n.t('vehicle_entry.inventory_list.description');
      this.headers[1].text = this.$i18n.t('vehicle_entry.inventory_list.actual_quantity');
      this.headers[2].text = this.$i18n.t('vehicle_entry.inventory_list.actions');
      this.headers[3].text = this.$i18n.t('vehicle_entry.inventory_list.minimal_quantity');
      this.headers[4].text = this.$i18n.t('vehicle_entry.inventory_list.notes');
      this.headers[5].text = this.$i18n.t('vehicle_entry.inventory_list.select');
    },
    getParts() {
      this.loading = true;
      getRequest(
        '/part/vehicle-list-v2',
        `institution_id=${this.$store.getters.institutionId
        }&lang=${this.$store.getters.language
        }&brand=${this.car.brand.brandCode
        }&model=${this.car.model.modelCode
        }&version=${this.car.version.versionCode
        }&page=${this.pagination.page
        }&limit=${this.pagination.rowsPerPage}`,
      )
        .then((response) => {
          this.parts = response.parts;
          this.total = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    open(car) {
      this.dialog = true;
      this.car = car;
      this.setHeaders();
      this.getParts();
    },
    close() {
      this.dialog = false;
      this.parts = [];
      this.total = 0;
      this.quantity = {};
      this.selected = {};
      this.notes = {};
      this.$emit('close');
    },
    select(item) {
      if (!this.selected[item.data.description.descriptionCode.toString()]) {
        this.selected[item.data.description.descriptionCode.toString()] = item;
      } else {
        delete this.selected[item.data.description.descriptionCode.toString()];
      }
      // Replate the object with its copy to invoke selection changes.
      this.parts = JSON.parse(JSON.stringify(this.parts));
    },
    selectAll() {
      this.loadingSelect = true;
      this.parts.forEach((part) => { part.items.forEach(item => this.select(item)); });
      this.loadingSelect = false;
    },
    exportData() {
      const result = Object.keys(this.selected).map(key => ({
        [this.headers[0].text]: `${this.selected[key].data.description.descriptionId.title[this.$store.getters.language]} ${this.selected[key].data.description.positionCode ? this.getPosition(this.selected[key].data.description.positionCode) : ''}`, // eslint-disable-line max-len
        [this.$i18n.t('vehicle_entry.inventory_list.vehicle')]: `${this.car.brand.title ? this.car.brand.title : ''} ${this.car.model.description ? this.car.model.description : ''} ${this.car.version.description ? this.car.version.description : ''}`,
        [this.headers[1].text]: this.selected[key].count,
        [this.headers[2].text]: this.quantity[key],
        [this.headers[3].text]: this.notes[key],
      }));
      return result;
    },
    goToInventory(code) {
      this.$emit('goToInventory', {
        code,
        brand: this.car.brand.brandCode,
        model: this.car.model.modelCode,
        version: this.car.version.versionCode,
      });
      this.close();
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
  },
};
</script>
