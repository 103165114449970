<template>
  <v-layout
    align-center
    justify-center
    row
    wrap
    class="red darken-3"
  >
    <!--SEARCH DROPDOWN-->
    <v-flex
      xs12
      :sm3="$store.getters.role !== 'LekoAdmin'"
      :sm2="$store.getters.role === 'LekoAdmin'"
    >
      <v-select
        v-model="car.type"
        :items="searchTypes"
        item-text="label"
        item-value="value"
        color="grey darken-4"
        solo
        flat
        hide-details
        class="ml-2 mr-2 mt-2"
      />
    </v-flex>

    <!--SEARCH TEXTFIELD-->
    <v-flex
      xs12
      :sm3="$store.getters.role !== 'LekoAdmin'"
      :sm2="$store.getters.role === 'LekoAdmin'"
    >
      <!-- SEARCH OTHER FIELDS -->
      <v-text-field
        v-model="car.search"
        :label="$t('parts.inventory.search')"
        flat
        solo
        color="grey darken-4"
        append-icon="search"
        single-line
        hide-details
        class="ml-2 mr-2 mt-2"
        @click:append="search"
        @keyup.enter="search"
      />
    </v-flex>

    <!--INSTITUTION-->
    <v-flex
      v-if="$store.getters.role === 'LekoAdmin'"
      xs12
      sm4
    >
      <v-autocomplete
        v-model="car.institution"
        :items="institutions"
        :loading="loadingInstitution"
        :label="$t('vehicle_entry.institution')"
        item-text="title"
        item-value="_id"
        flat
        solo
        color="red darken-1"
        class="ml-2 mr-2 mt-2"
        hide-details
      />
    </v-flex>
    <!--PARTS-->
    <v-flex
      xs12
      :sm6="$store.getters.role !== 'LekoAdmin'"
      :sm4="$store.getters.role === 'LekoAdmin'"
    >
      <v-autocomplete
        v-model="car.part"
        :loading="loadingPart"
        :items="parts"
        :search-input.sync="searchPart"
        :label="$t('vehicle_entry.part')"
        :item-text="`title.${$store.getters.language}`"
        item-value="partCode"
        flat
        solo
        color="grey darken-4"
        class="ml-2 mr-2 mt-2"
        hide-details
        hide-no-data
      />
    </v-flex>
    <!--BRAND-->
    <v-flex
      xs12
      sm5
      xl3
    >
      <v-autocomplete
        v-model="car.brand"
        :items="brands"
        :label="$t('parts.advanced_search.brand')"
        item-text="title"
        flat
        solo
        color="red darken-1"
        class="ml-2 mr-2 mt-2"
        hide-details
        return-object
      >
        <template v-slot:item="{ item }">
          <v-layout
            row
            wrap
            align-center
            justify-center
          >
            <v-flex
              xs3
              sm2
              lg1
            >
              <v-img
                :src="item.logo"
                max-height="35"
                max-width="35"
              />
            </v-flex>
            <v-flex
              xs9
              sm10
              lg11
            >
              <span> {{ item.title }} </span>
            </v-flex>
          </v-layout>
        </template>
        <template v-slot:selection="{ item }">
          <v-layout
            row
            wrap
            align-center
            justify-center
          >
            <v-flex
              xs3
              lg2
            >
              <v-img
                :src="item.logo"
                max-height="35"
                max-width="35"
              />
            </v-flex>
            <v-flex
              xs9
              lg10
            >
              <span> {{ item.title }} </span>
            </v-flex>
          </v-layout>
        </template>
      </v-autocomplete>
    </v-flex>
    <v-flex
              xs12
              sm2
              lg3
            >
              <v-text-field
                v-model="year"
                :label="$t('parts.advanced_search.year')"
                :rules="[yearRule]"
                flat
                solo
                type="number"
                :max="new Date().getFullYear()"
                oninput="validity.valid||(value=new Date().getFullYear());"
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
              />
            </v-flex>
    <!--MODEL-->
    <v-flex
      xs12
      sm4
      xl3
    >
      <v-autocomplete
        v-model="car.model"
        :items="models"
        :label="$t('parts.advanced_search.model')"
        item-text="fullDescription"
        flat
        solo
        color="red darken-1"
        class="ml-2 mr-2 mt-2"
        hide-details
        return-object
      >
        <template v-slot:item="{ item }">
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md7
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                <span class="body-2">
                  {{ item.description }}
                </span>
              </v-layout>
            </v-flex>

            <v-flex
              class="hidden-sm-and-down"
              md5
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                <span class="body-2">
                  {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                </span>
              </v-layout>
            </v-flex>
          </v-layout>
        </template>
        <template v-slot:selection="{ item }">
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md7
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                <span class="body-2">
                  {{ item.description }}
                </span>
              </v-layout>
            </v-flex>

            <v-flex
              class="hidden-sm-and-down"
              md5
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                <span class="body-2">
                  {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                </span>
              </v-layout>
            </v-flex>
          </v-layout>
        </template>
      </v-autocomplete>
    </v-flex>
    <!--ENGINE-->
    <v-flex
      xs12
      sm4
      md2
      xl1
    >
      <v-autocomplete
        v-model="car.engine"
        :items="versions"
        :loading="loadingVersion"
        :label="$t('parts.advanced_search.engine')"
        item-text="engineCode"
        flat
        solo
        color="red darken-1"
        class="ml-2 mr-2 mt-2"
        hide-details
        :append-icon="car.engine === null ? 'arrow_drop_down' : 'keyboard_backspace'"
        @click:append="
          car.engine = null;
          car.version = null;
          if (car.model) getVersions(car.model.brandCode, car.model.modelCode);
        "
      />
    </v-flex>
    <!--VERSION-->
    <v-flex
      xs12
      sm8
      md10
      xl5
    >
      <v-autocomplete
        v-model="car.version"
        :items="versions"
        :label="$t('parts.advanced_search.version')"
        item-text="description"
        flat
        solo
        chips
        color="red darken-1"
        class="ml-2 mr-2 mt-2"
        hide-details
        return-object
      >
        <!--SELECTION LIST-->
        <template v-slot:item="{ item }">
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md5
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                <span class="body-2">{{ item.description }}</span>
              </v-layout>
            </v-flex>
            <v-flex
              class="hidden-sm-and-down"
              md2
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                <span class="body-2">
                  {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                </span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                <span class="body-2">{{ item.numberOfDoors }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                <span class="body-2">
                  {{ item.power.kw }} kw,
                </span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                <span class="body-2">{{ item.engineCode }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
              </v-layout>
            </v-flex>
          </v-layout>
        </template>

        <!--SELECTED-->
        <template v-slot:selection="{ item }">
          <v-layout
            row
            wrap
          >
            <v-flex
              xs12
              md5
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                <span class="body-2">{{ item.description }}</span>
              </v-layout>
            </v-flex>
            <v-flex
              class="hidden-sm-and-down"
              md2
            >
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                <span class="body-2">
                  {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                </span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                <span class="body-2">{{ item.numberOfDoors }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                <span class="body-2">
                  {{ item.power.kw }} kw,
                </span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                <span class="body-2">{{ item.engineCode }}</span>
              </v-layout>
            </v-flex>
            <v-flex class="hidden-sm-and-down">
              <v-layout
                column
                wrap
              >
                <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
              </v-layout>
            </v-flex>
          </v-layout>
        </template>
      </v-autocomplete>
    </v-flex>

    <!--NUMBER OF KM-->
    <v-flex
      xs12
      md4
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <v-flex
          xs8
          sm6
          lg8
        >
          <v-range-slider
            v-model="car.km"
            :max="1000000"
            :min="0"
            :step="1000"
            color="grey darken-4"
            class="ml-3"
          />
        </v-flex>
        <v-flex
          xs4
          sm6
          lg4
        >
          <span class="pl-3 pr-2">
            {{ car.km[0] }} - {{ car.km[1] }} km
          </span>
        </v-flex>
      </v-layout>
    </v-flex>

    <!--STARTING DATE-->
    <v-flex
      xs12
      sm6
      md4
    >
      <v-menu
        v-model="startDateMenu"
        :close-on-content-click="false"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="car.startDate"
            :label="$t('parts.advanced_search.starting_date')"
            solo
            flat
            color="black"
            persistent-hint
            prepend-icon="event"
            readonly
            hide-details
            class="ml-2 mr-2 mt-2"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="car.startDate"
          color="red darken-3"
          @input="startDateMenu = false"
        />
      </v-menu>
    </v-flex>
    <!--ENDING DATE-->
    <v-flex
      xs12
      sm6
      md4
    >
      <v-menu
        v-model="endDateMenu"
        :close-on-content-click="false"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="car.endDate"
            :label="$t('parts.advanced_search.ending_date')"
            solo
            flat
            color="black"
            persistent-hint
            prepend-icon="event"
            readonly
            hide-details
            class="ml-2 mr-2 mt-2"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-model="car.endDate"
          color="red darken-3"
          @input="endDateMenu = false"
        />
      </v-menu>
    </v-flex>

    <!--BUTTONS-->
    <v-flex xs12>
      <v-layout
        align-center
        justify-center
        row
        wrap
      >
        <v-btn
          dark
          color="grey darken-4"
          @click="clear"
        >
          {{ $t('parts.advanced_search.clear') }}
          <v-icon right>
            clear_all
          </v-icon>
        </v-btn>

        <v-btn
          dark
          color="grey darken-4"
          @click="search"
        >
          {{ $t('parts.advanced_search.search') }}
          <v-icon right>
            search
          </v-icon>
        </v-btn>

        <v-btn
          v-if="$store.getters.institutionDisplayFeatures.infoCarCad
            && car.brand && car.model && car.version"
          dark
          color="grey darken-4"
          @click="openInfoCarCad"
        >
          {{ $t('parts.inventory.cad') }}
        </v-btn>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';

import getRequest from '../../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    loading: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    loadingPart: false,
    loadingInstitution: false,
    institutions: [],
    parts: [],
    brands: [],
    models: [],
    versions: [],
    searchPart: null,
    car: {
      type: 'id',
      search: null,
      institution: null,
      part: null,
      brand: null,
      model: null,
      version: null,
      engine: null,
      km: [0, 1000000],
      startDate: null,
      endDate: null,
    },
    startDateMenu: false,
    endDateMenu: false,
    searchTypes: [
      { label: 'ID', value: 'id' },
      { label: 'PRA', value: 'pra' },
      { label: 'Other', value: 'other' },
    ],
    disableAutoAsign: false,
    yearRule: '',
    year: null,
  }),
  watch: {
    searchPart(value) {
      if (value) {
        this.loadingPart = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.parts = response.parts; })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loadingPart = false; });
      } else {
        this.car.part = false;
      }
    },
    'car.brand': {
      handler(value) {
        if (value && !this.disableAutoAsign) {
          this.models = [];
          this.car.model = null;
          this.versions = [];
          this.car.version = null;
          this.car.engine = null;
          this.getModels(value.brandCode);
        }
      },
    },
    'car.model': {
      handler(value) {
        console.log('model value', value)
        if (value && !this.disableAutoAsign) {
          this.versions = [];
          this.car.version = null;
          this.car.engine = null;
          this.setYearRule(value.startingDate, value.endingDate);
          this.getVersions(value.brandCode, value.modelCode);
          
        }
      },
    },
    'car.version': {
      handler(value) {
        console.log('version value', value)

        if (value){
           this.$emit('selected', this.car);
           this.setYearRule(value.startingDate, value.endingDate);
        }
        else{this.$emit('selected', null);} 
      },
    },
    'car.engine': {
      handler(value) {
        this.versions = this.versions.reduce((acc, v) => {
          if (v.engineCode === value) acc.push(v);
          return acc;
        }, []);
      },
    },
    year(value) {
      if (value) {
        // this.$refs.form.validate();
        // filter models
        if (value.length === 4) { // Filter only when year is valid (4 characters long)
          this.filterModels();
          this.filterVersions();
        } else if (value.length !== 4 && this.brand && this.brand.brandCode) { // Reset models.
          this.models = this.getModels(this.brand.brandCode);
        }
      }
    },
  },
  created() {
    this.getBrands();
    this.getInstitutions();
  },
  mounted() {
    // Set initial year rule.
    this.setYearRule();
  },
  methods: {
    setYearRule(startingDate, endingDate) {
      const startingYear = startingDate ? new Date(startingDate).getFullYear() : 1900;
      const endingYear = endingDate ? new Date(endingDate).getFullYear() : new Date().getFullYear();
      this.yearRule = v => (v >= startingYear && v <= endingYear) || this.$i18n.t('parts.advanced_search.year_error');
    },
    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => { 
          this.models = response.models; 
          this.filterModels();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => { 
          this.versions = response.versions;
          this.filterVersions();

        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },
    filterModels() {
      if (this.year && this.year.length === 4 && this.models && this.models.length > 0) {
        this.models = this.models.filter((model) => {
          const sYear = model.startingDate ? new Date(model.startingDate).getFullYear() : 1900;
          const eYear = model.endingDate
            ? new Date(model.endingDate).getFullYear() : new Date().getFullYear();

          return this.year >= sYear && this.year <= eYear;
        });
      }
    },
    filterVersions() {
 
        if (this.year) { // filter by year
          this.versions = this.versions.filter((version) => {
            const sYear = version.startingDate
              ? new Date(version.startingDate).getFullYear()
              : 1900;
            const eYear = version.endingDate
              ? new Date(version.endingDate).getFullYear()
              : new Date().getFullYear();

            return this.year >= sYear && this.year <= eYear;
          });
        }
      
    },
    async forceSetFilters(brand, model) {
      this.disableAutoAsign = true;
      try {
        const brandsResult = await getRequest('/info-car/brands');
        const modelsResult = await getRequest('/info-car/models', `brand_code=${brand}`);
        const selectedBrand = brandsResult.brands.find(b => b.brandCode === parseInt(brand, 10));
        const selectedModel = modelsResult.models
          .find(m => m.brandCode === parseInt(brand, 10) && m.modelCode === parseInt(model, 10));

        this.brands = brandsResult.brands;
        this.car.brand = selectedBrand;
        this.models = modelsResult.models;
        this.car.model = selectedModel;

        this.search();
      } catch (error) { console.log(error); }
    },

    clear() {
      this.car = {
        type: 'id',
        search: null,
        institution: null,
        part: null,
        brand: null,
        model: null,
        version: null,
        engine: null,
        km: [0, 1000000],
        startDate: null,
        endDate: null,
      };

      this.brands = [];
      this.models = [];
      this.versions = [];

      this.disableAutoAsign = false;

      this.getBrands();
      this.search();
    },
    search() {
      this.$emit('search', {
        type: this.car.type ? this.car.type : null,
        search: this.car.search ? this.car.search : null,
        institution: this.car.institution ? this.car.institution : null,
        part: this.car.part ? this.car.part : null,
        km: this.car.km,
        brand: this.car.brand ? this.car.brand.brandCode : null,
        model: this.car.model ? this.car.model.modelCode : null,
        engine: this.car.engine ? this.car.engine : null,
        version: this.car.version ? this.car.version.versionCode : null,
        startDate: this.car.startDate ? this.car.startDate : null,
        endDate: this.car.endDate ? this.car.endDate : null,
      });
    },

    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },

    openInfoCarCad() {
      this.$emit('infocar-cad', {
        brandCode: this.car.brand.brandCode,
        modelCode: this.car.model.modelCode,
        versionCode: this.car.version.versionCode,
      });
    },

    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/').then((response) => {
        console.log(response);
        this.institutions = response.institutions;
        this.loadingInstitution = false;
      }).catch((error) => {
        console.log(error);
        this.loadingInstitution = false;
      });
    },
  },
};
</script>
