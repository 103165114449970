<template>
  <div>
    <v-dialog
      v-model="ebayDetailsModal"
      persistent
      width="550"
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('institutions.table.ebay_details') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeEbayDetailsModal"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <v-progress-linear
          v-if="loading"
          color="#D32F2F"
          indeterminate
          rounded
          height="6"
        />
        <div>
          <v-alert
            :value="true"
            type="error"
            border="bottom"
            outline
            prominent
            text
          >
            {{ $t('institutions.form.ebay_condition') }}
          </v-alert>
        </div>
        <div>
          <v-text-field
            v-model="item.token"
            :label="$t('institutions.form.token')"
            color="red darken-1"
            prepend-icon="security"
          />
        </div>
        <div>
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="formattedTokenExpirationDate"
                :label="$t('institutions.form.tokenExpirationDate')"
                color="black"
                persistent-hint
                prepend-icon="event"
                readonly
                v-on="on"
              />
            </template>

            <v-card class="d-md-flex">
              <!-- Date Picker -->
              <v-date-picker
                v-model="date"
                color="red darken-3"
                @input="updateTokenExpirationDate"
              />

              <!-- Time Picker -->
              <v-time-picker
                v-model="time"
                color="red darken-3"
                full-width
                format="24hr"
                @input="updateTokenExpirationDate"
              />
              <!-- <v-card-actions>
                    <v-btn text @click="saveDateTime">{{ $t('institutions.form.save') }}</v-btn>
                  </v-card-actions> -->
            </v-card>
          </v-menu>
        </div>
        <div>
          <v-text-field
            v-model="item.shippingId"
            :label="$t('institutions.form.shippingId')"
            color="red darken-1"
            prepend-icon="shop"
          />
        </div><div>
          <v-text-field
            v-model="item.paymentId"
            :label="$t('institutions.form.paymentId')"
            color="red darken-1"
            prepend-icon="payment"
          />
        </div>
        <div>
          <v-text-field
            v-model="item.returnId"
            :label="$t('institutions.form.returnId')"
            color="red darken-1"
            prepend-icon="refresh"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="gray darken-1"
            @click.native="closeEbayDetailsModal"
          >
            {{ $t('institutions.form.close') }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            :disabled="checkEbayDetails"
            @click="saveEbayDetails"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="ebayStoreCategoriesModal"
      persistent
      width="650"
    >
      <v-toolbar
        flat
        dark
        style="background-color: #D32F2F"
      >
        <v-toolbar-title>{{ $t('institutions.table.ebay_store_categories_title') }}</v-toolbar-title>
        <v-spacer />
        <v-btn
          outline
          icon
          small
          color="white"
          @click="closeEbayCategoriesModal"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card class="pa-3">
        <v-progress-linear
          v-if="loadingSave"
          color="#D32F2F"
          indeterminate
          rounded
          height="6"
        />
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-tabs>
            <v-tab
              v-for="lang in languages"
              :key="lang.code"
            >
              {{ lang.label }}
            </v-tab>

            <v-tab-item
              v-for="lang in languages"
              :key="lang.code"
            >
              <v-card class="pa-3 pb-5">
                <div class="col-12 d-flex">
                  <v-text-field
                    v-model="categoryNames[lang.code]"
                    :label="$t('institutions.form.ebay_category_name')"
                    color="red darken-1"
                    class="mr-4"
                  />
                  <v-text-field
                    v-model="categoryNumbers[lang.code]"
                    :label="$t('institutions.form.ebay_category_number')"
                    color="red darken-1"
                    @keypress="isNumber($event)"
                  />
                  <v-btn
                    small
                    color="red"
                    class="white--text pa-0 mt-3 mr-0 ml-3"
                    @click="addCategory(lang.code)"
                  >
                    {{ $t('institutions.form.add') }}
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <v-divider
                  class="mt-3 mb-3"
                  horizontal
                />
                <div
                  v-if="categories.find(c => c.language === lang.code).categoryList.length > 0"
                  class="col-12 d-flex"
                >
                  <strong>{{ $t('institutions.form.ebay_category_name') }}</strong>
                  <strong>{{ $t('institutions.form.ebay_category_number') }}</strong>
                </div>
                <div
                  v-for="(category, index) in categories.find(c => c.language === lang.code).categoryList"
                  :key="index"
                  class="mt-2 col-12 d-flex"
                >
                  <v-text-field
                    v-model="category.title"
                    :label="$t('institutions.form.ebay_category_name')"
                    color="red darken-1"
                    class="mr-2"
                    solo
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="category.ebayCode"
                    :label="$t('institutions.form.ebay_category_number')"
                    color="red darken-1"
                    solo
                    :rules="rules.required"
                    @keypress="isNumber($event)"
                  />
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="gray darken-1"
            @click.native="closeEbayCategoriesModal"
          >
            {{ $t('institutions.form.close') }}
          </v-btn>
          <v-btn
            color="red"
            class="white--text"
            @click="saveCategories()"
          >
            {{ $t('institutions.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="ebayErrorDialog"
      persistent
      width="500"
    >
      <v-card class="pa-3">
        <v-alert
          v-for="(item,index) in normalizedEbayResponse"
          :key="index"
          :value="true"
          type="error"
        >
          {{ item.ShortMessage }}
        </v-alert>
        <v-card-actions class="col-12 justify-end mt-2">
          <v-btn
            small
            @click="ebayErrorDialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="alert"
      max-width="500"
    >
      <v-alert
        v-model="alert"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.verification_success') }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="success"
      max-width="300"
    >
      <v-alert
        v-model="success"
        type="success"
        dismissible
        :value="true"
      >
        {{ $t('institutions.form.success') }}
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
  },
  data: () => ({
    institution_id: null,
    ebayDetailsModal: false,
    item: {
      token: '',
      tokenExpirationDate: '',
      shippingId: '',
      paymentId: '',
      returnId: '',
    },
    dateMenu: false,
    date: null,
    time: null,
    ebayResponse: null,
    ebayErrorDialog: false,
    loading: false,
    alert: false,
    inst: null,
    ebayStoreCategoriesModal: false,
    loadingSave: false,
    institutionDetails: null,
    languages: [
      { code: 'it', label: 'IT' },
      { code: 'en', label: 'EN' },
      { code: 'de', label: 'DE' },
      { code: 'fr', label: 'FR' },
    ],
    categoryNames: {
      it: '',
      en: '',
      de: '',
      fr: '',
    },
    categoryNumbers: {
      it: '',
      en: '',
      de: '',
      fr: '',
    },
    // categories: {
    //   it: [],
    //   en: [],
    //   de: [],
    //   fr: [],
    // },
    categoryInputs: {
      it: { title: '', ebayCode: '' },
      en: { title: '', ebayCode: '' },
      de: { title: '', ebayCode: '' },
      fr: { title: '', ebayCode: '' },
    },
    defaultCategories: [
      {
        language: 'it',
        categoryList: [
          { title: 'Lamierati esterni', ebayCode: '' },
          { title: 'Lamierati interni', ebayCode: '' },
          { title: 'Fanaleria', ebayCode: '' },
          { title: 'Selleria', ebayCode: '' },
          { title: 'Motore e cambio con supporti', ebayCode: '' },
          { title: 'Paraurti anteriore', ebayCode: '' },
          { title: 'Paraurti posteriore', ebayCode: '' },
          { title: 'Sterzo', ebayCode: '' },
          { title: 'Sospensioni', ebayCode: '' },
          { title: 'Cristalli', ebayCode: '' },
          { title: 'Alimentazione e scarico', ebayCode: '' },
          { title: 'Cambio e trasmissioni', ebayCode: '' },
          { title: 'Impianto frenante', ebayCode: '' },
          { title: 'Rivestimenti interni e parti interne', ebayCode: '' },
          { title: 'Dispositivi di sicurezza', ebayCode: '' },
          { title: 'Climatizzazione', ebayCode: '' },
          // { title: 'Altro', ebayCode: '' },
        ],
      },
      {
        language: 'en',
        categoryList: [
          // { title: 'Other', ebayCode: '' },
        ],
      },
      {
        language: 'de',
        categoryList: [
          // { title: 'Andere', ebayCode: '' },
        ],
      },
      {
        language: 'fr',
        categoryList: [
          // { title: 'Autre', ebayCode: '' },
        ],
      },
    ],
    categories: [
      {
        language: 'it',
        categoryList: [],
      },
      { language: 'en', categoryList: [] },
      { language: 'de', categoryList: [] },
      { language: 'fr', categoryList: [] },
    ],
    // baseUrl: 'http://localhost:3000',
    baseUrl: 'https://api.lekotech.com',
    rules: {
      required: [v => !!v || 'Please fill this field'],
    },
    valid: false,
    success: false,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    checkEbayDetails() {
      return !(this.item.token &&
             this.item.tokenExpirationDate &&
             this.item.shippingId &&
             this.item.paymentId &&
             this.item.returnId);
    },
    formattedTokenExpirationDate() {
      if (!this.item.tokenExpirationDate) return '';
      const date = new Date(this.item.tokenExpirationDate);
      return date.toString().slice(0, 28);
    },
    normalizedEbayResponse() {
      if (this.ebayResponse && typeof this.ebayResponse === 'object' && !Array.isArray(this.ebayResponse)) {
        return [this.ebayResponse];
      }
      return this.ebayResponse || [];
    },
  },
  watch: {
    valid(value) {
      console.log('valid', value);
      this.$emit('valid', value);
    },
  },
  methods: {
    openEbayDetailsModal(institution) {
      this.institution_id = institution._id;
      this.inst = institution;
      this.item = institution.eBayDetails || {
        token: '',
        tokenExpirationDate: '',
        shippingId: '',
        paymentId: '',
        returnId: '',
      };
      this.ebayDetailsModal = true;
    },
    saveEbayDetails() {
      console.log('item', this.item);
      this.loading = true;
      axios.put(
        `${this.baseUrl}/institution/edit-ebay-details/${this.institution_id}`,
        { eBayDetails: this.item }, {
          headers: { authorization: localStorage.token },
        },
      )
        .then((response) => {
          console.log('res:', response.data);
          if (response && response.data && response.data.errorMsg) {
            this.ebayResponse = response.data.errorMsg;
            this.ebayErrorDialog = true;
          } else {
            this.alert = true;
            this.ebayDetailsModal = false;
            this.item = response.data && response.data.eBayDetails ? response.data.eBayDetails : {
              token: '',
              tokenExpirationDate: '',
              shippingId: '',
              paymentId: '',
              returnId: '',
            };
            setTimeout(() => { this.alert = false; }, 3000);
            this.$emit('fetchInstitutionDetails');
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('Error adding items to eBay:', error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeEbayDetailsModal() {
      this.ebayDetailsModal = false;
      this.$emit('fetchInstitutionDetails');
    },
    updateTokenExpirationDate() {
      if (this.date && this.time) {
        const [hours, minutes] = this.time.split(':');
        const fullDate = new Date(this.date);
        fullDate.setHours(hours, minutes, 0, 0);
        this.item.tokenExpirationDate = fullDate.toISOString();
      }
    },
    saveDateTime() {
      this.dateMenu = false;
    },
    closeDateTimeMenu() {
      this.dateMenu = false;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        console.log('Text copied to clipboard:', text);
      }).catch((err) => {
        console.error('Failed to copy text:', err);
      });
    },
    openEbayStoreCategoriesModal(institution) {
      this.ebayStoreCategoriesModal = true;
      this.institutionDetails = institution;
      this.institution_id = institution._id;
      axios.get(`${this.baseUrl}/ebay-local-categories/?institution_id=${institution._id}`, {
        headers: { authorization: localStorage.token },
      }).then((res) => {
        if (res) {
          console.log('resss', res);
          const dbCategories = res.data.ebayCategories && res.data.ebayCategories.categories ? res.data.ebayCategories.categories : null;
          if (dbCategories == null) {
            this.categories = this.defaultCategories;
          } else {
            this.categories = dbCategories;
          }
        }
      }).catch((err) => {
        console.log('ebay error', err.response.data);
      })
        .finally(() => {
        // nth
        });
    },
    closeEbayCategoriesModal() {
      this.ebayStoreCategoriesModal = false;
    },
    saveCategories() {
      if (this.$refs.form.validate()) {
        console.log('valid');
        this.loadingSave = true;
        axios.put(
          `${this.baseUrl}/ebay-local-categories/?institution_id=${this.institution_id}`,
          { categories: this.categories }, {
            headers: { authorization: localStorage.token },
          },
        )
          .then((response) => {
            console.log('res:', response.data);
            this.loadingSave = false;
            this.success = true;
            this.$emit('fetchInstitutionDetails');
            setTimeout(() => { this.success = false; }, 3000);
          })
          .catch((error) => {
            this.loadingSave = false;
            console.error('Error :', error);
          })
          .finally(() => {
            this.loadingSave = false;
          });
      } else {
        console.log('categories', this.categories);
      }
    },
    addCategory(lang) {
      if (this.categoryNames[lang]) {
        const categoryForLang = this.categories.find(cat => cat.language === lang);
        if (categoryForLang) {
          categoryForLang.categoryList.push({
            title: this.categoryNames[lang],
            ebayCode: this.categoryNumbers[lang],
          });
        }
        this.categoryNames[lang] = '';
        this.categoryNumbers[lang] = '';
      } else {
      // alert("Please fill both fields before adding.");
      }
    },
    removeCategory(lang, index) {
      this.categories[lang].splice(index, 1);
    },
    isNumber(evt) {
      // Only allow numeric values (digits 0-9)
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      }
    },
  },
};
</script>
