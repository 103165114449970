<template>
  <v-container
    fluid
    class="pa-0"
  >
    <manage-tests />
  </v-container>
</template>

<script>
import ManageTests from '@/components/Tests/Tests.vue';

export default {
  components: {
    'manage-tests': ManageTests,
  },
  data() {
    return {};
  },
  mounted() {
  },
};
</script>
