<template>
  <v-container
    grid-list-md
    class="pa-0"
  >
    <v-layout
      row
      wrap
    >
      <!--ALERT-->
      <v-flex xs12>
        <v-alert
          :value="alert.value"
          :type="alert.type"
        >
          {{ alert.message }}
        </v-alert>
      </v-flex>
      <!--BRAND-->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-combobox
          v-model="car.brand"
          :items="brands"
          :label="$t('parts.advanced_search.brand')"
          item-text="title"
          outline
          color="red darken-1"
          hide-details
          return-object
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                xs3
                sm2
                lg1
              >
                <v-img
                  :src="item.logo"
                  max-height="35"
                  max-width="35"
                />
              </v-flex>
              <v-flex
                xs9
                sm10
                lg11
              >
                <span> {{ item.title }} </span>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                xs3
                lg2
              >
                <v-img
                  :src="item.logo"
                  max-height="35"
                  max-width="35"
                />
              </v-flex>
              <v-flex
                xs9
                lg10
              >
                <span> {{ item.title }} </span>
              </v-flex>
            </v-layout>
          </template>
        </v-combobox>
      </v-flex>
      <!--MODEL-->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-layout
          row
          wrap
          class="ma-0"
        >
          <v-combobox
            v-model="car.model"
            :items="models"
            :label="$t('parts.advanced_search.model')"
            item-text="fullDescription"
            outline
            color="red darken-1"
            hide-details
            return-object
          >
            <template v-slot:item="{ item }">
              <v-layout
                row
                wrap
              >
                <v-flex>
                  <v-layout
                    column
                    wrap
                  >
                    <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                    <span class="body-2">
                      {{ item.description }}
                    </span>
                  </v-layout>
                </v-flex>

                <v-flex class="hidden-sm-and-down">
                  <v-layout
                    column
                    wrap
                  >
                    <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                    <span class="body-2">
                      {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>
            </template>
            <template v-slot:selection="{ item }">
              <v-layout
                row
                wrap
              >
                <v-flex>
                  <v-layout
                    column
                    wrap
                  >
                    <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                    <span class="body-2">
                      {{ item.description }}
                    </span>
                  </v-layout>
                </v-flex>

                <v-flex class="hidden-sm-and-down">
                  <v-layout
                    column
                    wrap
                  >
                    <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                    <span class="body-2">
                      {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                    </span>
                  </v-layout>
                </v-flex>
              </v-layout>
            </template>
          </v-combobox>

          <v-menu
            v-if="car.model && car.model.customModel"
            v-model="modelDateMenu"
            :close-on-content-click="false"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                flat
                icon
                v-on="on"
              >
                <v-icon> event </v-icon>
              </v-btn>
            </template>
            <v-date-picker
              v-model="car.model.startingDate"
              color="red darken-3"
              type="month"
              @input="modelDateMenu = false"
            />
          </v-menu>
        </v-layout>
      </v-flex>
      <!--VERSION-->
      <v-flex
        xs12
        lg6
      >
        <v-combobox
          v-model="car.version"
          :items="versions"
          :label="$t('parts.advanced_search.version')"
          item-text="description"
          outline
          color="red darken-1"
          hide-details
          return-object
        >
          <!--SELECTION LIST-->
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex>
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ item.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ item.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ item.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ item.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>

          <!--SELECTED-->
          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex>
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ item.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex
                class="hidden-sm-and-down"
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ item.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ item.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ item.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-combobox>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';

import getRequest from '../../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    loading: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    brands: [],
    models: [],
    versions: [],
    car: {
      brand: null,
      model: null,
      version: null,
    },
    alert: {
      value: false,
      type: 'info',
      message: '',
    },
    customModel: false,
    modelDateMenu: false,
    modelDate: new Date()
      .toISOString()
      .split('-')
      .slice(0, 2)
      .join('-'),

    prevent: false, // Prevent from calling `get*` methods
  }),
  watch: {
    'car.brand': {
      handler(value) {
        if (value) {
          if (typeof value === 'string') { // Set custom brand.
            this.car.brand = {
              brandCode: 0,
              categoryCode: 0,
              logo: '',
              title: value.toUpperCase(),
            };
          }

          this.models = [];
          this.car.model = null;
          this.versions = [];
          this.car.version = null;
          if (!this.prevent) this.getModels(value.brandCode);
        }
      },
    },
    'car.model': {
      handler(value) {
        if (value) {
          if (typeof value === 'string') { // Set custom model.
            this.car.model = {
              brandCode: this.car.brand.brandCode,
              modelCode: 0,
              description: value,
              fullDescription: value,
              startingDate: null,
              endingDate: null,
              customModel: true,
            };
          }

          this.versions = [];
          this.car.version = null;
          if (!this.prevent) this.getVersions(value.brandCode, value.modelCode);
        }
      },
    },
    'car.version': {
      handler(value) {
        if (value) {
          if (typeof value === 'string') { // Set custom version.
            this.car.version = {
              brandCode: this.car.brand.brandCode,
              modelCode: this.car.model.modelCode,
              versionCode: 0,
              description: value,
              startingDate: this.car.model.startingDate,
              endingDate: this.car.model.endingDate,
              chassisCode: '',
              engineCode: '',
              numberOfDoors: 0,
              supplyCode: '',
              displacement: { cc: 0 },
              power: { kw: 0 },
              tractionCode: '',
              fullDescription: value,
            };
          }


          this.$emit('selected', this.car);
        } else this.$emit('selected', null);
      },
    },
  },
  created() {
    this.getBrands();
  },
  methods: {
    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => { this.models = response.models; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => { this.versions = response.versions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },

    clear() {
      this.car = {
        brand: null,
        model: null,
        version: null,
      };
      this.brands = [];
      this.models = [];
      this.versions = [];
      this.getBrands();
    },

    async setVehicle(vehicle) {
      this.prevent = true;

      this.loadingBrand = true;
      const { brands } = await getRequest('/info-car/brands');
      this.brands = brands;
      this.car.brand = vehicle.brand;
      this.loadingBrand = false;

      this.loadingModel = true;
      const { models } = await getRequest('/info-car/models', `brand_code=${vehicle.brand.brandCode}`);
      this.models = models;
      this.car.model = vehicle.model;
      this.loadingModel = false;

      this.loadingVersion = true;
      const { versions } = await getRequest('/info-car/versions', `brand_code=${vehicle.brand.brandCode}&model_code=${vehicle.model.modelCode}`);
      this.versions = versions;
      this.car.version = vehicle.version;
      this.loadingVersion = false;

      this.prevent = false;
    },

    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
