<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card flat>
      <v-layout
        column
        wrap
      >
        <!-------------------------------------CAMERA SCANNER-------------------------------------->
        <v-flex
          v-if="!physicalScanner"
          xs12
        >
          <qrcode-stream
            v-if="dialog"
            :camera="constraints"
            :paused="paused"
            @decode="decode"
          />
        </v-flex>
        <!-------------------------------------PHYSICAL SCANNER------------------------------------>
        <v-flex
          v-if="physicalScanner"
          xs12
        >
          <v-layout
            mt-2
            mb-2
            justify-center
          >
            <div
              v-if="found === null && !physicalScannerError"
              class="ma-3 blue--text subheading"
            >
              {{ $t('parts.insert_parts.qr_scanner.physical_scanner') }}
            </div>
            <div
              v-if="physicalScannerError"
              class="ma-3 red--text subheading"
            >
              {{ $t('parts.insert_parts.qr_scanner.physical_scanner_error') }}
            </div>
          </v-layout>
        </v-flex>
        <!----------------------------------------------------------------------------------------->
        <v-flex xs12>
          <v-layout
            mt-2
            ml-2
            mr-2
            column
            align-center
            justify-center
          >
            <!--LOADER-->
            <v-flex xs12>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              />
            </v-flex>

            <!--ALERT-->
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                :type="alert.type"
                outline
              >
                {{ alert.message }}
              </v-alert>
            </v-flex>
            <!---------------------------------------BUTTONS--------------------------------------->
            <!-- SCAN ANOTHER PART -->
            <v-flex xs12>
              <v-btn
                v-if="found === true && (mode === 'single' || mode === 'multiple')"
                outline
                @click="scanAnotherPart"
              >
                {{ $t('parts.insert_parts.qr_scanner.scan_another_part') }}
                <v-icon right>
                  autorenew
                </v-icon>
              </v-btn>
            </v-flex>

            <!--EDIT-->
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                v-if="found === true && mode === 'single'"
                xs12
                sm6
              >
                <!--EDIT PART-->
                <v-layout justify-center>
                  <v-btn
                    outline
                    color="primary"
                    @click="edit"
                  >
                    {{ $t('parts.insert_parts.qr_scanner.edit_part') }}
                    <v-icon right>
                      edit
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>

              <v-flex
                v-if="found === true && (mode === 'single' || mode === 'multiple')"
                xs12
                sm6
              >
                <!--CHANGE LOCATION-->
                <v-layout justify-center>
                  <v-btn
                    outline
                    color="primary"
                    @click="switchToLocationChanger"
                  >
                    {{ $t('parts.insert_parts.qr_scanner.change_location') }}
                    <v-icon right>
                      meeting_room
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>

            <!--CREATE PART-->
            <v-btn
              v-if="found === false && mode === 'single'"
              :loading="loadingBtn"
              outline
              color="warning"
              @click="create"
            >
              {{ $t('parts.insert_parts.qr_scanner.create_new_part') }}
            </v-btn>

            <!--SCANNED PARTS-->
            <v-flex
              v-if="mode === 'multiple' && idArray.length > 0"
              xs12
              class="mt-2"
            >
              <span
                v-for="id in idArray"
                :key="id"
              >
                <v-chip>
                  {{ id.slice(id.length - 6, id.length) }}
                  <v-icon
                    right
                    @click="removeId(id)"
                  >cancel</v-icon>
                </v-chip>
              </span>
            </v-flex>

            <!------------------------------------LOCATION MODE------------------------------------>
            <v-layout v-if="found === true && mode === 'location'">
              <v-btn
                outline
                color="success"
                :loading="loadingLocation"
                @click="changeLocation"
              >
                {{ $t('parts.insert_parts.qr_scanner.change_location') }}
              </v-btn>
            </v-layout>
            <!--------------------------------------KIT MODE--------------------------------------->
            <v-layout v-if="found === true && mainPart && mode === 'kit'">
              <v-btn
                outline
                color="success"
                @click="selectMainPart"
              >
                {{ $t('parts.insert_parts.qr_scanner.select_main_part') }}
                <v-icon right>
                  done
                </v-icon>
              </v-btn>
            </v-layout>
            <!------------------------------------------------------------------------------------->
          </v-layout>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-btn
          flat
          icon
          @click="reScan"
        >
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          flat
          icon
          @click="switchScanner"
        >
          <v-icon>fas fa-qrcode</v-icon>
        </v-btn>
        <v-btn
          flat
          icon
          @click="switchCamera"
        >
          <v-icon>switch_camera</v-icon>
        </v-btn>
        <v-btn
          outline
          color="error"
          @click="close"
        >
          {{ $t('parts.insert_parts.qr_scanner.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';

export default {
  data: () => ({
    dialog: false,
    front: false,
    paused: false,
    loading: false,
    loadingLocation: false,
    loadingBtn: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    part: {},
    found: null,
    decodedString: null,
    mainPart: false,
    physicalScanner: false,
    physicalScannerError: false,
    mode: null, // 'single' | 'multiple' | 'kit' | 'location'
    buffer: '',
    idArray: [],
    locationData: null,
  }),
  methods: {
    listenToEvent(e) { // Used for Physical Scanner.
      if (e.key !== 'Enter') {
        this.buffer += e.key;
      } else if (this.buffer.length > 24) {
        this.physicalScannerError = true;
        this.buffer = '';
      } else {
        this.decode(this.buffer);
        this.buffer = '';
      }
    },
    switchCamera() {
      this.physicalScanner = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.front = !this.front;
      this.constraints = {
        audio: false,
        video: { facingMode: this.front ? 'user' : 'environment' },
      };
    },
    switchScanner() {
      this.physicalScanner = true;
      document.addEventListener('keydown', this.listenToEvent);
    },
    decode(value) {
      if (value !== null && value.length > 0) {
        this.loading = true;
        this.found = null;
        this.decodedString = value;
        this.paused = true;

        if (this.mode === 'location') { // Scan location.
          getRequest('/repository/shelf/', `id=${value}`)
            .then((response) => {
              console.log(response);
              this.found = true;
              this.loading = false;
              const { repository } = response;
              const room = repository.rooms[response.roomIndex];
              const location = room.locations[response.locationIndex];
              const { shelf } = response;
              this.locationData = {
                repository,
                room,
                location,
                shelf,
              };
              this.alert.value = true;
              this.alert.type = 'success';
              this.alert.message = `${
                this.$i18n.t('parts.insert_parts.qr_scanner.found_shelf')} ${
                this.decodedString}`;
            })
            .catch((error) => {
              console.log(error);
              this.found = false;
              this.loading = false;
              this.alert.value = true;
              this.alert.type = 'error';
              this.alert.message = `${
                this.$i18n.t('parts.insert_parts.qr_scanner.cant_find_shelf')} ${
                this.decodedString}`;
            });
        } else { // Scan part.
          getRequest(`/temporary-part/single/${value}/`)
            .then((response) => {
              console.log(response);
              if (response.temporaryPart && response.temporaryPart.length > 0) {
                [this.part] = response.temporaryPart;
                this.found = true;
                if (this.part.kit_main_part) {
                  this.mainPart = true;
                } else {
                  this.mainPart = false;
                }

                if (!this.idArray.includes(this.part._id)) {
                  this.idArray.push(this.part._id);
                }

                if (this.mode === 'kit' && this.mainPart === false) {
                  this.alert.value = true;
                  this.alert.type = 'error';
                  this.alert.message = `${
                    this.$i18n.t('parts.insert_parts.qr_scanner.not_found_message')} ${
                    this.decodedString}`;
                } else {
                  this.alert.value = true;
                  this.alert.type = 'success';
                  this.alert.message = `${
                    this.$i18n.t('parts.insert_parts.qr_scanner.found_message')} ${
                    this.decodedString}`;
                }
              } else {
                this.found = false;
                this.alert.value = true;
                this.alert.type = 'error';
                this.alert.message = `${
                  this.$i18n.t('parts.insert_parts.qr_scanner.not_found_message')} ${
                  this.decodedString}`;
              }
              this.loading = false;
            })
            .catch((error) => {
              console.log(error.response);
              this.found = false;
              this.loading = false;
            });
        }
      }
    },
    reScan() {
      this.decodedString = null;
      this.found = null;
      if (this.mode === 'kit' && this.found === null) {
        this.alert.value = true;
        this.alert.type = 'info';
        this.alert.message = this.$i18n.t('parts.insert_parts.qr_scanner.scan_main_part_message');
      } else if (this.mode === 'location') {
        this.alert = {
          value: true,
          type: 'info',
          message: this.$i18n.t('parts.insert_parts.qr_scanner.scan_another_part_message'),
        };
      } else {
        this.alert = {
          value: false,
          type: 'success',
          message: '',
        };
      }
      this.physicalScannerError = false;
      this.loading = false;
      this.paused = false;
    },
    open(data) {
      this.dialog = true;
      this.mode = data.mode;
      if (this.mode === 'kit' && this.found === null) {
        this.alert.value = true;
        this.alert.type = 'info';
        this.alert.message = this.$i18n.t('parts.insert_parts.qr_scanner.scan_main_part_message');
      }
    },
    close() {
      this.dialog = false;
      this.paused = false;
      this.loading = false;
      this.part = {};
      this.found = null;
      this.alert = {
        value: false,
        type: 'success',
        message: '',
      };
      this.decodedString = null;
      this.mainPart = false;
      this.physicalScanner = false;
      this.physicalScannerError = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.$emit('close');
    },
    edit() {
      this.$emit('edit', this.part);
      this.close();
    },
    create() {
      this.loadingBtn = true;
      postRequest.object(
        `/temporary-part/insert/empty/single/${this.decodedString}`,
        { user_id: this.$store.getters.userId, institution_id: this.$store.getters.institutionId },
      )
        .then((response) => {
          this.loadingBtn = false;
          console.log(response);
          this.part = response.data.partResponse;
          this.edit();
        })
        .catch((error) => {
          this.loadingBtn = false;
          console.log(error.response);
          if (error.response.status === 403) {
            this.alert.value = true;
            this.alert.type = 'error';
            this.alert.message = `${
              this.$i18n.t('parts.insert_parts.qr_scanner.part_exists_message_1')} ${
              this.decodedString} ${
              this.$i18n.t('parts.insert_parts.qr_scanner.part_exists_message_2')}`;
          } else {
            this.alert.value = true;
            this.alert.type = 'error';
            this.alert.message = this.$i18n.t('parts.insert_parts.qr_scanner.create_error_message');
          }
        });
    },

    scanAnotherPart() {
      this.mode = 'multiple';
      this.decodedString = null;
      this.found = null;
      this.physicalScannerError = false;
      this.loading = false;
      this.paused = false;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('parts.insert_parts.qr_scanner.scan_another_part_message'),
      };
      console.log(this.idArray);
    },
    removeId(id) {
      const index = this.idArray.indexOf(id);
      if (index > -1) this.idArray.splice(index, 1);
    },
    switchToLocationChanger() {
      this.mode = 'location';
      this.decodedString = null;
      this.found = null;
      this.physicalScannerError = false;
      this.loading = false;
      this.paused = false;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('parts.insert_parts.qr_scanner.scan_shelf_message'),
      };
    },
    changeLocation() {
      this.loadingLocation = true;
      postRequest.object('/temporary-part/insert/location', {
        parts_ids: this.idArray,
        repository_id: this.locationData.repository._id,
        room_id: this.locationData.room._id,
        location_id: this.locationData.location._id,
        shelf_id: this.locationData.shelf._id,
      })
        .then(() => {
          this.loadingLocation = false;
          this.$emit('changed-location');
          this.close();
        })
        .catch((error) => {
          this.loadingLocation = false;
          this.alert = {
            value: true,
            type: 'error',
            message: error,
          };
        });
    },
    /* KIT -------------------------------------------------------------------------------------- */
    selectMainPart() {
      this.$emit('select-main-part', this.part.kit_id);
      this.close();
    },
  },
};
</script>
