<template>
  <v-dialog
    v-model="dialog"
    :width="videoWidth"
    max-width="700"
  >
    <v-card
      flat
      tile
      color="grey darken-4"
      class="d-flex"
    >
      <v-layout
        row
        align-center
        justify-center
        wrap
      >
        <v-flex xs12>
          <v-layout align-center>
            <v-btn
              dark
              icon
              @click="download"
            >
              <v-icon>download</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              class="ma-2"
              outline
              icon
              large
              color="red"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <video
            ref="video"
            controls
            :src="source"
            :width="videoWidth"
            :height="650"
          />
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
    source: null,
  }),
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 240;
        case 'sm':
          return 480;
        case 'md':
          return 680;
        default:
          return 680;
      }
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.source = null;
        this.$emit('close');
      }
    },
  },
  methods: {
    open(video) {
      console.log(video);


      if (video.type === 'string') this.source = `${this.$serverUri}/videos?q=${video.data}`;
      else if (video.type === 'blob') this.source = URL.createObjectURL(video.blob);
      else this.source = null;
      this.dialog = true;
    },
    close() {
      this.source = null;
      this.dialog = false;
      this.$emit('close');
    },
    download() {
      const { video } = this.$refs;
      fetch(video.currentSrc)
        .then(response => response.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'video');
          link.click();
        });
    },


  },
};
</script>
