/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user_id: '',
    user_name: '',
    approved_parts_number: 0,
    used_plates_number: 0,
    institution_id: '',
    institution_email: '',
    institution_export_ebay_leko: false,
    institution_display_features: null,
    role: '',
    vehicles: 0,
    temporary_parts: 0,
    inventory: 0,
    total_parts: 0,
    approved_parts: [],
    active: { active: false },
    language: 'en',
    loading: false,
    logged: false,
  },
  mutations: {
    auth_success(state, data) {
      state.logged = true;
      state.user_id = data._id; // eslint-disable-line no-underscore-dangle
      state.user_name = data.name;
      state.approved_parts_number = data.approved_parts_number;
      state.used_plates_number = data.used_plates_number;
      state.institution_id = data.institution_id;
      state.institution_email = data.institution_email;
      state.role = data.role;
      state.vehicles = data.vehicles;
      state.temporary_parts = data.temporary_parts;
      state.inventory = data.inventory;
      state.total_parts = data.total_parts;
    },
    set_language(state, lang) {
      state.language = lang;
    },
    active_mutation(state, data) {
      state.active = data;
    },
    logout(state) {
      state.logged = false;
      state.user_id = '';
      state.user_name = '';
      state.institution_id = '';
      state.role = '';
      state.vehicles = 0;
      state.temporary_parts = 0;
      state.inventory = 0;
      state.total_parts = 0;
      // state.language = '';
    },
    start_loading(state) {
      state.loading = true;
    },
    finish_loading(state) {
      state.loading = false;
    },
    set_institution_export_ebay_leko(state, data) {
      state.institution_export_ebay_leko = data;
    },
    set_institution_display_features(state, data) {
      state.institution_display_features = data;
    },
    /*
    set_approved_parts(state, approvedParts) {
      state.approved_parts = approvedParts;
    },
    */
  },
  actions: {
    languageAction({ commit }, lang) {
      commit('set_language', lang);
    },
    verifyToken({ commit }, data) {
      commit('auth_success', data);
    },
    activeAction({ commit }, data) {
      commit('active_mutation', data);
    },
    logoutAction({ commit }) {
      commit('logout');
    },
    startLoading({ commit }) {
      commit('start_loading');
    },
    finishLoading({ commit }) {
      commit('finish_loading');
    },
    institutionExportEbayLekoAction({ commit }, data) {
      commit('set_institution_export_ebay_leko', data);
    },
    institutionDisplayFeaturesAction({ commit }, data) {
      commit('set_institution_display_features', data);
    },
    /*
    approvedPartsAction({ commit }, approvedParts) {
      commit('set_approved_parts', approvedParts);
    },
    */
  },
  getters: {
    logged(state) {
      return state.logged;
    },
    userId(state) {
      return state.user_id;
    },
    userName(state) {
      return state.user_name;
    },
    approvedPartsNumber(state) {
      return state.approved_parts_number;
    },
    usedPlatesNumber(state) {
      return state.used_plates_number;
    },
    institutionId(state) {
      return state.institution_id;
    },
    institutionEmail(state) {
      return state.institution_email;
    },
    institutionExportEbayLeko(state) {
      return state.institution_export_ebay_leko;
    },
    institutionDisplayFeatures(state) {
      return state.institution_display_features;
    },
    role(state) {
      return state.role;
    },
    vehicles(state) {
      return state.vehicles;
    },
    temporaryParts(state) {
      return state.temporary_parts;
    },
    inventory(state) {
      return state.inventory;
    },
    totalParts(state) {
      return state.total_parts;
    },
    language(state) {
      return state.language;
    },
    active(state) {
      return state.active;
    },
    loading(state) {
      return state.loading;
    },
    /*
    approvedParts(state) {
      return state.approved_parts;
    },
    */
  },
});
