<template>
  <v-card flat>
    <v-card-title>
      <span class="headline"> {{ $t('reservations.order.order_list') }} </span>
    </v-card-title>
    <v-card-text>
      <span v-if="items.length < 1">
        {{ $t('reservations.order.empty') }}
      </span>
      <v-list two-line>
        <v-list-tile
          v-for="item in items"
          :key="item._id"
          avatar
          @click="''"
        >
          <v-list-tile-avatar v-if="item.__type && item.__type === 'temp'">
            <img
              :src="item.images[0].dataUrl"
            >
          </v-list-tile-avatar>
          <v-list-tile-avatar v-else>
            <img
              :src="`${server}/images/thumb?q=${item._id}-${item.images[0]
              }&date=${item.date_created}`"
            >
          </v-list-tile-avatar>

          <v-list-tile-content v-if="item.__type && item.__type === 'temp'">
            <v-list-tile-title> {{ item.description }} </v-list-tile-title>
            <v-list-tile-sub-title> {{ item.notes }} </v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-content v-else>
            <v-list-tile-title>
              {{ item.old_id
                ? item.old_id.slice(item.old_id.length-6, item.old_id.length)
                : item._id.slice(item._id.length-6, item._id.length)
              }} {{ item.title.al ? ` - ${item.title.al}` : '' }}
            </v-list-tile-title>
            <v-list-tile-sub-title> {{ item.notes }} </v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <v-chip
              outline
              :color="item.__type && item.__type === 'temp' ? 'primary' : 'success' "
            >
              {{ item.__type && item.__type === 'temp' ? 'Temporary' : 'Inventory' }}
            </v-chip>
          </v-list-tile-action>

          <v-list-tile-action>
            <v-btn
              icon
              @click="removePart(item._id)"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    parts: {
      type: Array,
      default: null,
    },
  },
  computed: {
    server() {
      return this.$serverUri;
    },
    items() {
      if (this.$props.parts) return this.$props.parts;
      return [];
    },
  },
  methods: {
    removePart(id) {
      this.$emit('remove-part', id);
    },
  },
};
</script>
