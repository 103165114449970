<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-cener
        justify-start
      >
        <v-toolbar-title>{{ $t('home.home') }}</v-toolbar-title>
        <v-divider
          class="mx-5 hidden-md-and-down"
          inset
          vertical
        />
        <v-spacer />
        <v-btn
          color="grey darken-4"
          dark
          @click="openSearchDialog"
        >
          <v-icon left>
            fas fa-qrcode
          </v-icon>
          {{ $t('home.search_part.label') }}
        </v-btn>
      </v-layout>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    openSearchDialog() {
      this.$emit('search-dialog');
    },
  },
};
</script>
