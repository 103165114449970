<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <v-card>
      <v-card-title>{{ this.$i18n.t('parts.inventory.actions.shareData') }}</v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex
            class="pa-1"
            style="font-size: 20px;"
          >
            <ShareNetwork
              network="whatsapp"
              class="pointer inline pa-1"
              :url="getUrl"
              title=""
            >
              <span
                class="fab fa-whatsapp-square"
                style="font-size: 30px"
              />
              Whatsapp
            </ShareNetwork>
          </v-flex>

          <v-flex
            class="pa-1"
            style="font-size: 20px;"
          >
            <a @click="shareSubito">
              <img
                height="30"
                width="30"
                src="@/assets/icons/subito.png"
              >
              <span style="text-decoration-line: underline;">Subito</span>
            </a>
          </v-flex>

          <v-flex
            class="pa-1"
            style="font-size: 20px;"
          >
            <a @click="shareEbay">
              <img
                height="30"
                width="30"
                src="@/assets/icons/ebay_colored.png"
              >
              <span style="text-decoration-line: underline;">Ebay</span>
            </a>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import JSZip from 'jszip';
import config from '../../config';

export default {
  components: {},
  data: () => ({
    dialog: false,
    carData: {},
  }),

  computed: {
    getUrl() { return `${config.getShopUri()}/share/car/${this.carData._id}`; },
  },

  methods: {
    open(data) {
      this.carData = data;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close');
    },
    shareSubito() {
      const xml = `
        <?xml version="1.0" encoding="UTF-8"?>
        <subito>
          <ad>
            <type>sell</type>
            <category>auto</category>
            <ad_code>${this.carData._id}</ad_code>
            <subject><![CDATA[ ${this.carData.brand.title} ]]></subject>
            <body>
              <![CDATA[ ${this.carData.brand.title} ${this.carData.model.description}
                DETTAGLI
                - Marca: ${this.carData.brand.title}
                - ${this.carData.model.fullDescription}
                - ${this.carData.version.fullDescription}
                - ${this.carData.version.supplyCode}
                - ${this.carData.transmissionType}
                - ${this.carData.chassisCode}
                - KM ${this.carData.km}
                - ${this.carData.externalColor}
                - Good state
              ]]>
            </body>
            <features>
              <geo>
                <town>
                  <id>${this.carData.institution_id.city}</id>
                </town>
                <province>
                  <id><![CDATA[ Italy ]]></id>
                </province>
              </geo>
              <price type="integer"> $$$$$$$ </price>
            </features>
            <advertiser>
              <email>${this.carData.institution_id.email}</email>
              <phone_hidden type="boolean">false</phone_hidden>
              <phone>${this.carData.institution_id.phone}</phone>
              <company type="boolean">true</company>
              <name><![CDATA[${this.carData.institution_id.title}]]></name>
            </advertiser>
            <images>
              <image>http://lekotech.com/logo.jpg</image>
            </images>
          </ad>
        </subito>
      `;

      const zip = new JSZip();
      zip.file('data.xml', xml);
      zip.generateAsync({ type: 'blob' }).then((result) => {
        const url = window.URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'subito.xml');
        document.body.appendChild(link);
        link.click();
      });
    },
    shareEbay() {
      const xml = `
        <?xml version="1.0" encoding="UTF-8"?>
        <ebay>
          <data>
          ...loading - try again later...
          </data>
        </ebay>
      `;
      const url = window.URL.createObjectURL(new Blob([xml.trim()]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ebay.xml');
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
