<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title class="red darken-2">
        <span
          v-if="vehicle && vehicle.brand && vehicle.model"
          class="headline"
        >
          {{ $i18n.t('vehicle_entry.edit_car') }}
          {{ vehicle.brand.title }}
          {{ vehicle.model.fullDescription }}
        </span>
        <span
          v-else
          class="headline"
        >
          {{ $i18n.t('vehicle_entry.edit_car') }}
        </span>

        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <!-- SELECT CAR -->
          <v-flex xs12>
            <select-vehicle
              ref="selectVehicle"
              @selected="setSelectedVehicle"
            />
          </v-flex>
          <!-- VEHICLE DETAILS -->
          <v-flex
            xs12
            md6
            :class="{ 'mt-5': $vuetify.breakpoint.mdAndUp }"
          >
            <vehicle-details
              ref="vehicleDetails"
              @car="selectVehicle"
            />
          </v-flex>

          <!-- VEHICLE VIEW -->
          <v-flex
            xs12
            md6
          >
            <vehicle-view
              ref="vehicleView"
              @position="openPartSelectionDialog"
            />
          </v-flex>
        </v-layout>
      </v-card-text>
      <!-- ACTIONS -->
      <v-card-actions>
        <v-layout wrap>
          <v-btn
            color="primary"
            depressed
            @click="openPartsList"
          >
            {{ $i18n.t('vehicle_entry.parts_list.label') }}
            <v-icon right>
              fas fa-file-pdf
            </v-icon>
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="openPhotosDialog"
          >
            {{ $i18n.t('vehicle_entry.photos') }}
          </v-btn>

          <v-btn
            :disabled="deleteDocument"
            color="primary"
            class="mr-2"
            depressed
            @click="openPdfInputComponent"
          >
            {{ $i18n.t('vehicle_entry.upload_document') }}
          </v-btn>
          <input
            ref="pdfInput"
            type="file"
            style="display: none"
            accept="application/pdf"
            @change="onPdfInput"
          >

          <v-btn
            color="primary"
            depressed
            @click="showVideos"
          >
            {{ $i18n.t('vehicle_entry.videos') }}
            <v-icon right>
              videocam
            </v-icon>
          </v-btn>

          <!--new pdf-->
          <v-btn
            v-if="pdf"
            :disabled="deleteDocument"
            color="primary"
            depressed
            @click="viewPdf('new')"
          >
            {{ $i18n.t('vehicle_entry.view_document') }}
          </v-btn>

          <!--existing pdf-->
          <v-btn
            v-else-if="vehicle && vehicle.document"
            :disabled="deleteDocument"
            color="primary"
            depressed
            @click="viewPdf('existing')"
          >
            {{ $i18n.t('vehicle_entry.view_document') }}
          </v-btn>

          <v-btn
            v-if="vehicle && vehicle.document"
            color="error"
            depressed
            @click="removeDocument"
          >
            {{ $i18n.t('vehicle_entry.delete_document') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="success"
            depressed
            :loading="loadingSave"
            @click="save"
          >
            {{ $i18n.t('vehicle_entry.save') }}
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
    <!-- HIDDEN COMPONENTS -->
    <parts-selection
      ref="partsSelection"
      @save="saveParts"
      @close="showDialog"
    />
    <photos
      ref="photos"
      @open-img-dialog="openImgDialog"
      @save="setPhotos"
      @close="showDialog"
    />

    <videos
      ref="videos"
      @save="setVideo"
      @close="showDialog"
    />

    <img-dialog
      ref="imgDialog"
      @close="showPhotos"
    />
  </v-dialog>
</template>

<script>
import SelectVehicle from '@/components/VehicleEntry/selectVehicle.vue';
import VehicleDetails from '@/components/VehicleEntry/vehicleDetails.vue';
import VehicleView from '@/components/VehicleEntry/vehicleView.vue';

import PartsSelection from '@/components/VehicleEntry/partsSelection.vue';
import Photos from '@/components/VehicleEntry/photos.vue';
import Videos from '@/components/VehicleEntry/videos.vue';
import ImgDialog from '@/components/imgDialog.vue';

import postRequest from '../../api/postRequest';

function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export default {
  components: {
    'select-vehicle': SelectVehicle,
    'vehicle-details': VehicleDetails,
    'vehicle-view': VehicleView,
    'parts-selection': PartsSelection,
    photos: Photos,
    videos: Videos,
    'img-dialog': ImgDialog,
  },
  data: () => ({
    dialog: false,
    loadingSave: false,
    vehicle: null,
    pdf: null,
    photosToDelete: [],
    deleteDocument: false,
  }),
  methods: {
    open(vehicle) {
      this.vehicle = vehicle;
      this.dialog = true;
      const { selectVehicle, vehicleDetails } = this.$refs;
      selectVehicle.setVehicle({
        brand: vehicle.brand,
        model: vehicle.model,
        version: vehicle.version,
      });
      vehicleDetails.setDetails(vehicle);
    },
    close() {
      const { vehicleDetails, selectVehicle } = this.$refs;
      this.dialog = false;
      this.vehicle = null;
      this.pdf = null;
      this.photosToDelete = [];
      this.deleteDocument = false;
      vehicleDetails.clear();
      selectVehicle.clear();
      this.$emit('close');
    },
    save() {
      const { vehicleDetails } = this.$refs;
      this.vehicle.info = vehicleDetails.getDetails();
      this.loadingSave = true;
      const formData = new FormData();

      formData.append('data', JSON.stringify({
        vehicle: {
          institution_id: this.$store.getters.institutionId,
          brand: this.vehicle.details.brand,
          model: this.vehicle.details.model,
          version: this.vehicle.details.version,
          targa: this.vehicle.info.targa,
          vin: this.vehicle.info.vin,
          transmissionType: this.vehicle.info.transmissionType,
          transmissionLayout: this.vehicle.info.transmissionLayout,
          externalColor: this.vehicle.info.externalColor,
          internalColor: this.vehicle.info.internalColor,
          internalState: this.vehicle.info.internalState,
          vehicleCondition: this.vehicle.info.vehicleCondition,
          // frame: this.vehicle.info.frame,
          pra: this.vehicle.info.pra,
          km: this.vehicle.info.km,
          location: this.vehicle.info.location,
          notes: this.vehicle.info.notes,
          registrationYear: this.vehicle.info.registrationYear,
          withdrawnPIVA: this.vehicle.info.withdrawnPIVA,
          workingEngine: this.vehicle.info.workingEngine,
          parts: this.vehicle.parts,
          partsList: this.vehicle.partsList,
          photos: this.vehicle.photos.filter(p => p && p.image),
          video: this.vehicle.video,
          document: this.vehicle.document,
        },
      }));
      this.vehicle.photos.forEach((p) => { if (p && p.blob) formData.append('car-photos', p.blob, p.title); });
      if (this.pdf && this.pdf.blob) formData.append('car-documents', this.pdf.blob, this.pdf.title);
      if (this.vehicle.video && this.vehicle.video.blob) formData.append('car-videos', this.vehicle.video.blob, this.vehicle.video.title);

      postRequest.formData('/car/edit', `car_id=${this.vehicle._id}`, formData)
        .then((response) => { console.log(response.data); })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingSave = false;
          this.$emit('save');
          this.close();
        });
    },
    openPartSelectionDialog(position) {
      const { partsSelection } = this.$refs;
      partsSelection.open(position, this.vehicle, true);
      this.hideDialog();
    },
    openPhotosDialog() {
      const { photos } = this.$refs;
      photos.open(this.vehicle.photos);
      this.hideDialog();
    },
    saveParts(data) {
      if (!this.vehicle.parts) this.vehicle.parts = {};
      this.vehicle.parts[data.position] = data.items;
      this.vehicle.parts.comment = data.comment;
    },
    setSelectedVehicle(details) {
      console.log(details);
      if (details) this.vehicle.details = details;
    },
    openImgDialog(data) {
      const { photos, imgDialog } = this.$refs;
      imgDialog.open(data);
      photos.hide();
    },
    openPartsList() {
      this.$emit('parts-list', { vehicle: this.vehicle, partsList: this.vehicle.partsList, component: 'edit' });
      this.hideDialog();
    },
    showPhotos() {
      const { photos } = this.$refs;
      photos.show();
    },
    setPhotos(data) {
      this.photosToDelete = data.photosToDelete;
      this.vehicle.photos = data.photos;
    },
    showVideos() {
      const { videos } = this.$refs;
      videos.open(this.vehicle.video);
      this.hideDialog();
    },
    setVideo(data) {
      this.vehicle.video = data.video;

      console.log(this.vehicle);
    },
    showDialog() { this.dialog = true; },
    hideDialog() { this.dialog = false; },
    selectVehicle(data) {
      const { selectVehicle } = this.$refs;
      selectVehicle.setVehicle(data);
    },
    openPdfInputComponent() { this.$refs.pdfInput.click(); },
    onPdfInput(input) {
      const { files } = input.target;
      const fileReader = new FileReader();
      for (const file of files) { // eslint-disable-line no-restricted-syntax
        if (file) { fileReader.readAsDataURL(file); }
      }

      const random = Math.random()
        .toString(36)
        .substring(9);

      fileReader.addEventListener('load', () => {
        const dataUrl = fileReader.result;
        const blob = dataURLtoBlob(dataUrl);
        this.pdf = { dataUrl, blob, title: `${random}${new Date().getTime().toString(16)}.pdf` };
      });
    },
    viewPdf(type) {
      if (type === 'new') {
        const objectUrl = window.URL.createObjectURL(this.pdf.blob);
        window.open(objectUrl);
        window.URL.revokeObjectURL(objectUrl);
      } else if (type === 'existing') {
        window.open(`${this.$serverUri}/documents?q=${this.vehicle.document}`);
      }
    },
    removeDocument() {
      this.deleteDocument = true;
      this.vehicle.document = null;
    },
  },
};
</script>
