<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-img
      :src="`${server}/images/id-photos?q=${data.filename}&date=${data.date}`"
      width="500"
    >
      <v-layout
        row
        justify-end
      >
        <v-btn
          class="ma-2"
          outline
          icon
          large
          color="red"
          @click="dialog=false"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-layout>
    </v-img>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    data: {},
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  methods: {
    showImage(data) {
      this.data.filename = data.filename;
      this.data.date = data.date;
      this.dialog = true;
    },
  },
};
</script>
