<template>
  <v-dialog
    v-model="dialog"
    width="250"
  >
    <v-card flat>
      <v-card-text>
        {{ reason }}
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    reason: null,
  }),
  watch: {
    dialog(value) {
      if (!value) { this.notes = null; }
    },
  },
  methods: {
    open(reason) {
      this.dialog = true;
      this.reason = reason;
    },
  },
};
</script>

