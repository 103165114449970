<template>
  <v-app class="page">
    <v-container
      class="container"
      fluid
    >
      <v-layout
        row
        align-top
        justify-start
        fill-height
      >
        <!-- LOGIN FORM -->
        <v-flex
          class="form"
          xs12
          sm6
          md4
          lg3
        >
          <v-layout
            align-center
            justify-center
          >
            <img
              height="80"
              src="@/assets/logo_dark.png"
              class="logo"
            >
          </v-layout>
          <v-alert
            :value="alert.value"
            type="error"
          >
            {{ alert.message }}
          </v-alert>
          <v-alert
            :value="alert_s.value"
            type="success"
          >
            {{ alert_s.message }}
          </v-alert>
          <v-form>
            <v-container transition="fade-transition">
              <v-text-field
                v-model="login.email"
                label="Email"
                type="email"
                prepend-inner-icon="alternate_email"
                color="red darken-2"
                @keyup="validateCharacters"
              />
              <v-text-field
                id="password"
                v-model="login.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                label="Password"
                prepend-inner-icon="password"
                color="red darken-2"
                @keyup="validateCharacters"
              />
              <v-select
                v-model="language"
                :items="selectLanguage"
                item-text="lang"
                item-value="locale"
                hint="Select Language"
                prepend-inner-icon="language"
                color="red darken-2"
                persistent-hint
                return-object
                filled
                dense
                outlined
              />
              <v-layout
                align-center
                justify-center
              >
                <v-btn
                  rounded
                  :loading="loading"
                  depressed
                  color="red darken-4"
                  dark
                  @click="loginMethod()"
                >
                  <v-icon left>
                    login
                  </v-icon>
                  Login
                </v-btn>
              </v-layout>
            </v-container>
          </v-form>
        </v-flex>
        <!-- PLACEHOLDER -->
        <v-flex />
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import determineIpLocation from '../utils/determineIpLocation';

export default {
  props: { source: String }, // eslint-disable-line vue/require-default-prop
  data: () => ({
    language: {
      lang: 'Italiano',
      locale: 'it',
    },
    selectLanguage: [
      {
        lang: 'Italiano',
        locale: 'it',
      },
      // {
      //   lang: 'Shqip',
      //   locale: 'sq',
      // },
      // {
      //   lang: 'English',
      //   locale: 'en',
      // },
    ],
    password: '',
    showPassword: false,
    login: {},
    register: {},
    institutions: [],
    states: [],
    cities: [],
    state_id: String,
    alert: {
      value: false,
      message: String,
    },
    alert_s: {
      value: false,
      message: String,
    },
    loading: false,
  }),
  created() { this.setLanguage(); },
  methods: {
    validateCharacters(event) {
      if (event.keyCode === 13
        && ((this.login.email
        && this.login.email.length > 0)
        && (this.login.password
        && this.login.password.length > 0))
      ) { this.loginMethod(); }
    },
    loginMethod() {
      this.loading = true;
      this.axios.post(`${this.$serverUri}/login`, this.login).then((res) => {
        if (res.status === 200) {
          localStorage.setItem('exp', res.data.exp);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('language', this.language.locale);
          this.$store.dispatch('languageAction', this.language.locale);
          this.$router.push({
            path: '/home',
          });
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.alert.value = true;
        this.alert.message = 'Something went wrong';
        this.loading = false;
        setTimeout(() => {
          this.alert.value = false;
        }, 2000);
      });
    },
    setLanguage() {
      determineIpLocation()
        .then((response) => {
          const { country } = response;
          switch (country) {
            // case 'AL':
            //   this.language = {
            //     lang: 'Shqip',
            //     locale: 'sq',
            //   };
            //   break;
            case 'IT':
              this.language = {
                lang: 'Italiano',
                locale: 'it',
              };
              break;
            default:
              break;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-image: url(../assets/login_background0.png);
  background-position: center center;
  background-size: cover;
}

.container {
  margin: 0;
  padding: 0;
}

.logo {
  margin-top: 50px;
  margin-bottom: 50px;
}

.form {
  padding: 50px;
  background-color: #FAFAFA;
}
</style>
