<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('common_codes.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
    </v-toolbar>
    <!--DATATABLE-->
    <v-data-table
      :headers="headers"
      :items="parts"
      :loading="loading"
      hide-actions
      class="elevation-1"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr>
          <td class="text-xs">
            {{ props.item.oemCode }}
          </td>
          <td class="text-xs">
            {{ props.item.count }}
          </td>
          <td class="text-xs">
            {{ props.item.category[$store.getters.language] }}
          </td>
          <td class="text-xs">
            {{ props.item.title[$store.getters.language] }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import getRequest from '../api/getRequest';

export default {
  data: () => ({
    headers: [{
      text: '',
      sortable: false,
      value: 'oemCode',
    },
    {
      text: '',
      sortable: false,
      value: 'count',
    },
    {
      text: '',
      sortable: false,
      value: 'category',
    },
    {
      text: '',
      sortable: false,
      value: 'title',
    }],
    loading: false,
    parts: [],
  }),
  created() {
    this.getLocale();
    this.getParts();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('common_codes.table.oem');
      this.headers[1].text = this.$i18n.t('common_codes.table.count');
      this.headers[2].text = this.$i18n.t('common_codes.table.category');
      this.headers[3].text = this.$i18n.t('common_codes.table.title');
    },
    getParts() {
      this.loading = true;
      getRequest('/part/inventory/common-codes', `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`)
        .then((response) => {
          console.log(response);
          this.parts = response.parts;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
  },
};
</script>
