<template>
  <v-container
    grid-list-md
    class="pa-0"
  >
    <v-layout
      row
      wrap
    >
      <!------------------------------------------DIVIDER------------------------------------------>
      <v-flex xs12>
        <v-layout
          class="mt-2 mb-2"
          row
          wrap
        >
          <v-divider
            dark
            class="mt-2"
            style="border-width: 1px; border-color: black;"
          />
          <div class="ml-4 mr-4 title black--text">
            {{ $t('vehicle_entry.car_details') }}
          </div>
          <v-divider
            dark
            class="mt-2"
            style="border-width: 1px; border-color: black;"
          />
        </v-layout>
      </v-flex>
      <!---------------------------------------LICENSE PLATE--------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          return-masked-value
          mask="AA###AA"
          v-model="targa"
          :disabled="!canSearchTarga"
          :color="foundTarga ? 'success': 'error'"
          :append-icon="foundTarga ? 'check_circle': 'error'"
          :label="$t('vehicle_entry.targa') + ' ' + examplePlateFormat"
          :loading="loadingTarga"
          outline
          hide-details
          @keyup.enter="searchTarga()"
          @click:append="searchTarga()"
        />
      </v-flex>
      <!--------------------------------------------VIN-------------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="vin"
          :disabled="$props.disabled"
          :label="$t('vehicle_entry.info.frame')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!-------------------------------------VEHICLE CONDITION------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="vehicleCondition"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.vehicle_condition')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!--------------------------------------INTERNAL STATE--------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="internalState"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.internal_state')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!-------------------------------------TRANSMISSION TYPE------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="transmissionType"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.transmission_type')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!------------------------------------TRANSMISSION LAYOUT------------------------------------>
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="transmissionLayout"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.transmission_layout')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!--------------------------------------EXTERNAL COLOR--------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="externalColor"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.external_color')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!--------------------------------------INTERNAL COLOR--------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="internalColor"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.internal_color')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!------------------------------------------DIVIDER------------------------------------------>
      <v-flex xs12>
        <v-layout
          class="mt-2 mb-2"
          row
          wrap
        >
          <v-divider
            dark
            class="mt-2"
            style="border-width: 1px; border-color: black;"
          />
          <div class="ml-4 mr-4 title black--text">
            {{ $t('vehicle_entry.other_details') }}
          </div>
          <v-divider
            dark
            class="mt-2"
            style="border-width: 1px; border-color: black;"
          />
        </v-layout>
      </v-flex>
      <!--------------------------------------PRACTICE NUMBER-------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="pra"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.practice_number')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!---------------------------------------NUMBER OF KM---------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="km"
          :disabled="$props.disabled"
          type="number"
          :min="0"
          :label="$i18n.t('vehicle_entry.info.number_of_km')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!-----------------------------------------LOCATION------------------------------------------>
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-select
          v-model="location"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.location')"
          :items="carLocations"
          item-text="name"
          item-value="_id"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!-------------------------------------REGISTRATION YEAR------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="registrationYear"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.registration_year')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!-------------------------------------------NOTES------------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="notes"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.notes')"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!--------------------------------------WITHDRAWN PIVA--------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-select
          v-model="withdrawnPIVA"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.withdrawn_piva')"
          :items="withdrawnPIVASelection"
          item-text="title"
          item-value="value"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!--------------------------------------WORKING ENGINE--------------------------------------->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-select
          v-model="workingEngine"
          :disabled="$props.disabled"
          :label="$i18n.t('vehicle_entry.info.working_engine')"
          :items="workingEngineSelection"
          item-text="title"
          item-value="value"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
      <!--TEMPORARY-->
      <v-flex
        xs12
        sm6
        lg3
      >
        <v-text-field
          v-model="location"
          :disabled="true"
          label="Vecchia posizione"
          color="red darken-1"
          outline
          hide-details
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  props: ['disabled'],
  data: () => ({
    targa: '',
    examplePlateFormat:": (DG853SV)",
    vin: '',
    transmissionType: '',
    transmissionLayout: '',
    externalColor: '',
    internalColor: '',
    internalState: '',
    vehicleCondition: '',
    // frame: '',
    pra: '',
    km: '',
    location: null,
    notes: '',
    workingEngine: true,
    workingEngineSelection: [
      { title: 'Yes', value: true },
      { title: 'No', value: false },
    ],
    registrationYear: '',
    withdrawnPIVA: false,
    withdrawnPIVASelection: [
      { title: 'Yes', value: true },
      { title: 'No', value: false },
    ],
    loadingTarga: false,
    foundTarga: null,
    canSearchTarga: false,
    carLocations: [],
    loadingCarLocations: false,
  }),
  created() {
    this.getLocale();
    this.getInstitution();
    this.getCarLocations();
  },
  methods: {
    getLocale() {
      this.workingEngineSelection[0].title = this.$i18n.t('vehicle_entry.info.yes');
      this.workingEngineSelection[1].title = this.$i18n.t('vehicle_entry.info.no');
      this.withdrawnPIVASelection[0].title = this.$i18n.t('vehicle_entry.info.yes');
      this.withdrawnPIVASelection[1].title = this.$i18n.t('vehicle_entry.info.no');
    },
    getInstitution() {
      getRequest('/institution', `id=${this.$store.getters.institutionId}`)
        .then((response) => {
          if (response.institution && response.institution.length > 0) {
            this.canSearchTarga = !!(response.institution[0].plates.remaining > 0);
            console.log(this.canSearchTarga);
          }
        })
        .catch((error) => { console.log(error); });
    },
    getCarLocations() {
      this.loadingCarLocations = true;
      getRequest(
        '/car-location',
        `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => { this.carLocations = response.carLocations; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingCarLocations = false; });
    },
    searchTarga() {
      if (this.targa && this.targa.length > 0) {
        this.loadingTarga = true;
        getRequest(`/info-car/plate/${this.targa}`, '')
          .then(async (response) => {
            console.log(response);
            try {
              const brand = await getRequest('/info-car/brand', `id=${response.brandId}`);
              const model = await getRequest('/info-car/model', `id=${response.modelId}`);
              const version = await getRequest('/info-car/version', `id=${response.versionId}`);

              this.foundPlate = true;
              this.$emit('car', { brand, model, version });
              this.foundTarga = true;
              this.vin = response.chassis;
              this.getInstitution();
            } catch (error) {
              console.log(error);
              this.foundTarga = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.foundTarga = false;
          })
          .finally(() => { this.loadingTarga = false; });
      }
    },
    getDetails() {
      return {
        targa: this.targa,
        vin: this.vin,
        transmissionType: this.transmissionType,
        transmissionLayout: this.transmissionLayout,
        externalColor: this.externalColor,
        internalColor: this.internalColor,
        internalState: this.internalState,
        vehicleCondition: this.vehicleCondition,
        // frame: this.frame,
        pra: this.pra,
        km: this.km,
        location: this.location,
        notes: this.notes,
        workingEngine: this.workingEngine,
        registrationYear: this.registrationYear,
        withdrawnPIVA: this.withdrawnPIVA,
      };
    },
    setDetails(data) {
      this.targa = data.targa;
      this.vin = data.vin;
      this.transmissionType = data.transmissionType;
      this.transmissionLayout = data.transmissionLayout;
      this.externalColor = data.externalColor;
      this.internalColor = data.internalColor;
      this.internalState = data.internalState;
      this.vehicleCondition = data.vehicleCondition;
      // this.frame = data.frame;
      this.pra = data.pra;
      this.km = data.km;
      this.location = data.location;
      this.notes = data.notes;
      this.workingEngine = data.workingEngine;
      this.registrationYear = data.registrationYear;
      this.withdrawnPIVA = data.withdrawnPIVA;
    },
    clear() {
      this.targa = '';
      this.vin = '';
      this.transmissionType = '';
      this.transmissionLayout = '';
      this.externalColor = '';
      this.internalColor = '';
      this.internalState = '';
      this.vehicleCondition = '';
      // this.frame = '';
      this.pra = '';
      this.km = '';
      this.location = null;
      this.notes = '';
      this.workingEngine = true;
      this.registrationYear = '';
      this.withdrawnPIVA = false;
    },
  },
};
</script>
