<template>
  <v-container grid-list-lg>
    <h2>WhatsApp E-Commerce ChatBot</h2>
    <h3 class="pt-4">
      {{ $t('whatsAppChatBot.whatsApp_phone_number') }}
    </h3>
    <!--------------------------------------------------------------------------------------------->
    <v-layout row>
      <v-flex xs4>
        <phone-number-input
          v-model="whatsappChatbot.phone"
          :input-id="'phone'"
          :options="{showDialCode: true}"
          :preferred-countries="['IT', 'FR', 'BE', 'DE']"
          color="#b71c1c"
          default-country-code="IT"
          hint="Phone number that will be connected with your LekoTech WhatsApp ChatBot"
          label="ENTER YOUR PHONE NUMBER"
        />
      </v-flex>
    </v-layout>
    <h3>WhatsApp ChatBot</h3>
    <!--------------------------------------------------------------------------------------------->
    <v-switch
      v-model="whatsappChatbot.enabled"
      :label="$t('whatsAppChatBot.enable_whatsApp')"
      color="error"
      hide-details
      value="error"
    />

    <div class="mt-3">
      <div>
        <h2 class="pb-4">
          {{ $t('whatsAppChatBot.why_whatsapp') }}
        </h2>
        <p>{{ $t('whatsAppChatBot.whatsapp_paragraph1') }}</p>
        <p>{{ $t('whatsAppChatBot.whatsapp_paragraph2') }}</p>
        <p>
          <a :href="'https://www.youtube.com/watch?v=I_NBDQvMZqE&ab_channel=LekoTech'">
            {{ $t('whatsAppChatBot.see_video') }}</a>
        </p>
        <img
          class="whatsappImages"
          src="../../assets/icons/WhatsApp.jpeg"
        >
      </div>
    </div>
  </v-container>
</template>

<script>
import PhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
  name: 'WhatsApp', // eslint-disable-line vue/require-prop-types
  components: {
    PhoneNumberInput,
  },
  props: ['institution'],
  data() {
    return {};
  },
  computed: {
    whatsappChatbot() {
      return this.$props.institution.preferences.whatsappChatbot || {};
    },
  },

};
</script>
<style>
.whatsappImages {
  width: 706px;
}
</style>
