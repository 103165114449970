<template>
  <v-container
    fluid
    class="pa-0"
  >
    <toolbar
      @search="search"
      @adv-search="advancedSearch"
      @clear-search="clearSearch"
    />
    <datatable
      :search="searchObject"
      @movement="openMovementDialog"
      @images="openImagesCarousel"
      @qr="openQrDialog"
    />
    <movement-dialog ref="movementDialog" />
    <qr-dialog ref="qrDialog" />
    <img-carousel ref="imgCarousel" />
  </v-container>
</template>

<script>
import Datatable from '@/components/Movements/datatable.vue';
import Toolbar from '@/components/Movements/toolbar.vue';

import MovementDialog from '@/components/movementDialog.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import QrDialog from '@/components/qrDialog.vue';

export default {
  components: {
    datatable: Datatable,
    toolbar: Toolbar,
    'movement-dialog': MovementDialog,
    'img-carousel': ImgCarousel,
    'qr-dialog': QrDialog,
  },
  data: () => ({
    searchObject: {
      type: null,
      data: {},
    },
  }),
  mounted() {},
  methods: {
    /* Search ----------------------------------------------------------------------------------- */
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    advancedSearch(data) {
      this.searchObject = {
        type: 'advancedSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    /* Movement Dialog -------------------------------------------------------------------------- */
    openMovementDialog(data) {
      const { movementDialog } = this.$refs;
      movementDialog.open(data);
    },
    /* Img Carousel ----------------------------------------------------------------------------- */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
      console.log(data);
    },
    /* QR Dialog -------------------------------------------------------------------------------- */
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
  },
};
</script>
