<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <span
          v-if="car && car.brand && car.model"
          class="headline"
        >
          {{ $i18n.t('vehicle_entry.car_details') }}
          {{ car.brand.title }}
          {{ car.model.fullDescription }}
        </span>
        <span
          v-else
          class="headline"
        >{{ $i18n.t('vehicle_entry.car_details') }}</span>
        <v-spacer />
        <v-btn
          outline
          icon
          large
          color="black"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="loading && !car">
        <v-layout
          align-center
          justify-center
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="50"
            color="red darken-2"
          />
        </v-layout>
      </v-card-text>

      <v-card-text v-if="car">
        <v-layout
          row
          wrap
        >
          <!--------------------------------------------------------------------------------------->
          <v-flex xs12>
            <!-- CAR DETAILS -->
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ car.version.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex
                class="hidden-sm-and-down"
                md2
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ car.version.startingDate | moment }}
                    -
                    {{ car.version.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ car.version.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2"> {{ car.version.power.kw }} kw </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ car.version.engineCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(car.version.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
          <v-flex xs12>
            <!-- VEHICLE DETAILS -->
            <v-layout
              row
              wrap
              mt-4
            >
              <!--Plate-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.targa"
                  :label="$t('vehicle_entry.targa')"
                  disabled
                />
              </v-flex>
              <!--Vin-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.vin"
                  :label="$t('vehicle_entry.info.frame')"
                  disabled
                />
              </v-flex>
              <!--Transmission type-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.transmissionType"
                  :label="$t('vehicle_entry.info.transmission_type')"
                  disabled
                />
              </v-flex>
              <!--Transmission layout-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.transmissionLayout"
                  :label="$t('vehicle_entry.info.transmission_layout')"
                  disabled
                />
              </v-flex>
              <!--External color-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.externalColor"
                  :label="$t('vehicle_entry.info.external_color')"
                  disabled
                />
              </v-flex>
              <!--Internal color-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.internalColor"
                  :label="$t('vehicle_entry.info.internal_color')"
                  disabled
                />
              </v-flex>
              <!--Internal state-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.internalState"
                  :label="$t('vehicle_entry.info.internal_state')"
                  disabled
                />
              </v-flex>
              <!--Vehicle condition-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.vehicleCondition"
                  :label="$t('vehicle_entry.info.vehicle_condition')"
                  disabled
                />
              </v-flex>
              <!--Practice number-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.pra"
                  :label="$t('vehicle_entry.info.practice_number')"
                  disabled
                />
              </v-flex>
              <!--Odometer-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.km"
                  :label="$t('vehicle_entry.info.number_of_km')"
                  disabled
                />
              </v-flex>
              <!--Location-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-select
                  v-model="car.location"
                  :label="$t('vehicle_entry.info.location')"
                  :items="carLocations"
                  item-text="name"
                  item-value="_id"
                  disabled
                />
              </v-flex>
              <!--Notes-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.notes"
                  :label="$t('vehicle_entry.info.notes')"
                  disabled
                />
              </v-flex>
              <!--Registration Year-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-text-field
                  v-model="car.registrationYear"
                  :label="$t('vehicle_entry.info.registration_year')"
                  disabled
                />
              </v-flex>
              <!--Withdrawn PIVA-->
              <v-flex
                xs12
                sm6
                :class="{ 'pl-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-select
                  v-model="car.withdrawnPIVA"
                  :label="$t('vehicle_entry.info.withdrawn_piva')"
                  :items="withdrawnPIVASelection"
                  item-text="title"
                  item-value="value"
                  disabled
                />
              </v-flex>
              <!--Working engine-->
              <v-flex
                xs12
                sm6
                :class="{ 'pr-2': $vuetify.breakpoint.smAndUp }"
              >
                <v-select
                  v-model="car.workingEngine"
                  :label="$t('vehicle_entry.info.working_engine')"
                  :items="workingEngineSelection"
                  item-text="title"
                  item-value="value"
                  disabled
                />
              </v-flex>
            </v-layout>
            <!-- PHOTO GRID -->
            <v-layout
              row
              wrap
            >
              <v-flex
                v-for="(image, index) in car.photos"
                :key="index"
                xs4
                sm3
                pa-1
                d-flex
              >
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    flat
                    tile
                    class="d-flex"
                  >
                    <v-img :src="`${server}/images?q=${image.thumb}`">
                      <v-layout
                        row
                        ma-1
                      >
                        <v-icon
                          v-if="hover"
                          x-large
                          color="primary"
                          class="pointer"
                          @click="viewImage(image)"
                        >
                          image
                        </v-icon>
                        <v-spacer />
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('parts.inventory.form.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- HIDDEN COMPONENTS -->
    <img-dialog
      ref="imgDialog"
      @close="showDialog"
    />
  </v-dialog>
</template>

<script>
import moment from 'moment';
import ImgDialog from '@/components/imgDialog.vue';

import getRequest from '../api/getRequest';

export default {
  components: {
    'img-dialog': ImgDialog,
  },
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    hidden: false,
    car: null,
    carLocations: [],
    loadingCarLocations: false,
    withdrawnPIVASelection: [
      { title: 'Yes', value: true },
      { title: 'No', value: false },
    ],
    workingEngineSelection: [
      { title: 'Yes', value: true },
      { title: 'No', value: false },
    ],
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  watch: {
    dialog(value) {
      if (!value && !this.hidden) this.close();
    },
  },
  created() {
    this.getLocale();
  },
  methods: {
    open(data) {
      this.dialog = true;
      this.loading = true;
      this.getCarLocations();
      getRequest('/car/', `id=${data.carId}`)
        .then((response) => { this.car = response.car; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    close() {
      this.dialog = false;
      this.hidden = false;
      this.car = null;
      this.carLocations = [];
      this.$emit('close');
    },
    viewImage(image) {
      const { imgDialog } = this.$refs;
      imgDialog.open({ type: 'string', data: image });
      this.hideDialog();
    },
    hideDialog() {
      this.hidden = true;
      this.dialog = false;
    },
    showDialog() {
      this.hidden = false;
      this.dialog = true;
    },
    getCarLocations() {
      this.loadingCarLocations = true;
      getRequest(
        '/car-location',
        `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => { this.carLocations = response.carLocations; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingCarLocations = false; });
    },
    getLocale() {
      this.workingEngineSelection[0].title = this.$i18n.t('vehicle_entry.info.yes');
      this.workingEngineSelection[1].title = this.$i18n.t('vehicle_entry.info.no');
      this.withdrawnPIVASelection[0].title = this.$i18n.t('vehicle_entry.info.yes');
      this.withdrawnPIVASelection[1].title = this.$i18n.t('vehicle_entry.info.no');
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
