<template>
  <v-layout
    row
    wrap
  >
    <!--LEFT-->
    <v-flex style="margin-top: 100px">
      <v-layout
        column
        wrap
      >
        <!-- TOP LEFT -->
        <v-flex xs12>
          <v-layout
            row
            wrap
            justify-end
            align-end
          >
            <!-- BODY -->
            <div
              class="tl pointer"
              :style="{ backgroundImage: position === 'tl'
                ? `url(${tl}), url(${tl_}), url(${tlb})`
                : `url(${tl}), url(${tl_})` }"
              @mouseover.stop="setPosition('tl')"
              @mouseleave.stop="setPosition(null)"
              @click.stop="select('tl')"
            >
              <!-- FENDER -->
              <div
                class="tll pointer"
                :style="{ backgroundImage: position === 'tll'
                  ? `url(${tll}),url(${tllb})`
                  : `url(${tll})` }"
                @mouseover.stop="setPosition('tll')"
                @mouseleave.stop="setPosition(null)"
                @click.stop="select('tll')"
              />
            </div>
          </v-layout>
        </v-flex>

        <!--BOTTOM LEFT-->
        <v-flex xs12>
          <v-layout
            row
            wrap
            justify-end
            align-end
            mt-4
          >
            <div
              class="bl pointer"
              :style="{ backgroundImage: position === 'bl'
                ? `url(${bl}), url(${bl_}), url(${blb})`
                : `url(${bl}), url(${bl_})` }"
              @mouseover.stop="setPosition('bl')"
              @mouseleave.stop="setPosition(null)"
              @click.stop="select('bl')"
            />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>

    <!--CENTER-->
    <v-flex>
      <v-layout
        column
        wrap
        justify-center
        align-center
      >
        <!-- TOP -->
        <v-flex>
          <div
            class="ttc pointer"
            :style="{ backgroundImage: position === 'ttc'
              ? `url(${ttc}),url(${ttcb})`
              : `url(${ttc})` }"
            @mouseover.stop="setPosition('ttc')"
            @mouseleave.stop="setPosition(null)"
            @click.stop="select('ttc')"
          />
        </v-flex>

        <!-- MIDDLE -->
        <v-flex>
          <div
            class="c"
            :style="{ backgroundImage: `url(${c}), url(${c_})` }"
          >
            <v-layout
              column
              wrap
              align-center
              justify-center
            >
              <!-- TOP CENTER -->
              <v-flex xs12>
                <div
                  class="tc pointer"
                  :style="{ backgroundImage: position === 'tc'
                    ? `url(${tc}),url(${tcb})`
                    : `url(${tc})` }"
                  @mouseover.stop="setPosition('tc')"
                  @mouseleave.stop="setPosition(null)"
                  @click.stop="select('tc')"
                />
              </v-flex>
              <!-- CENTER CENTER -->
              <v-flex xs12>
                <div
                  class="cc pointer"
                  :style="{ backgroundImage: position === 'cc'
                    ? `url(${cc}),url(${ccb})`
                    : `url(${cc})` }"
                  @mouseover.stop="setPosition('cc')"
                  @mouseleave.stop="setPosition(null)"
                  @click.stop="select('cc')"
                />
              </v-flex>
              <!-- BOTTOM CENTER -->
              <v-flex xs12>
                <div
                  class="bc pointer"
                  :style="{ backgroundImage: position === 'bc'
                    ? `url(${bc}),url(${bcb})`
                    : `url(${bc})` }"
                  @mouseover.stop="setPosition('bc')"
                  @mouseleave.stop="setPosition(null)"
                  @click.stop="select('bc')"
                />
              </v-flex>
            </v-layout>
          </div>
        </v-flex>

        <!-- TOP -->
        <v-flex>
          <div
            class="bbc pointer"
            :style="{ backgroundImage: position === 'bbc'
              ? `url(${bbc}),url(${bbcb})`
              : `url(${bbc})` }"
            @mouseover.stop="setPosition('bbc')"
            @mouseleave.stop="setPosition(null)"
            @click.stop="select('bbc')"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <!--RIGHT-->
    <v-flex style="margin-top: 100px">
      <v-layout
        column
        wrap
      >
        <!-- TOP RIGHT -->
        <v-flex xs12>
          <v-layout
            row
            wrap
            justify-start
            align-start
          >
            <!-- BODY -->
            <div
              class="tr pointer"
              :style="{ backgroundImage: position === 'tr'
                ? `url(${tr}), url(${tr_}), url(${trb})`
                : `url(${tr}), url(${tr_})` }"
              @mouseover.stop="setPosition('tr')"
              @mouseleave.stop="setPosition(null)"
              @click.stop="select('tr')"
            >
              <!-- FENDER -->
              <div
                class="trr pointer"
                :style="{ backgroundImage: position === 'trr'
                  ? `url(${trr}),url(${trrb})`
                  : `url(${trr})` }"
                @mouseover.stop="setPosition('trr')"
                @mouseleave.stop="setPosition(null)"
                @click.stop="select('trr')"
              />
            </div>
          </v-layout>
        </v-flex>

        <!--BOTTOM RIGHT-->
        <v-flex xs12>
          <v-layout
            row
            wrap
            justify-start
            align-start
            mt-4
          >
            <div
              class="br pointer"
              :style="{ backgroundImage: position === 'br'
                ? `url(${br}), url(${br_}), url(${brb})`
                : `url(${br}), url(${br_})` }"
              @mouseover.stop="setPosition('br')"
              @mouseleave.stop="setPosition(null)"
              @click.stop="select('br')"
            />
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import tl from '../../../public/car/tl.png';
import tl_ from '../../../public/car/tl_.png';
import tlb from '../../../public/car/tlb.png';

import tll from '../../../public/car/tll.png';
import tllb from '../../../public/car/tllb.png';

import bl from '../../../public/car/bl.png';
import bl_ from '../../../public/car/bl_.png';
import blb from '../../../public/car/blb.png';

import c from '../../../public/car/c.png';
import c_ from '../../../public/car/c_.png';

import ttc from '../../../public/car/ttc.png';
import ttcb from '../../../public/car/ttcb.png';

import bbc from '../../../public/car/bbc.png';
import bbcb from '../../../public/car/bbcb.png';

import tc from '../../../public/car/tc.png';
import tcb from '../../../public/car/tcb.png';

import cc from '../../../public/car/cc.png';
import ccb from '../../../public/car/ccb.png';

import bc from '../../../public/car/bc.png';
import bcb from '../../../public/car/bcb.png';

import tr from '../../../public/car/tr.png';
import tr_ from '../../../public/car/tr_.png';
import trb from '../../../public/car/trb.png';

import trr from '../../../public/car/trr.png';
import trrb from '../../../public/car/trrb.png';

import br from '../../../public/car/br.png';
import br_ from '../../../public/car/br_.png';
import brb from '../../../public/car/brb.png';

export default {
  data: () => ({
    position: null,
    tl,
    tl_,
    tlb,
    tll,
    tllb,
    bl,
    bl_,
    blb,
    c,
    c_,
    ttc,
    ttcb,
    bbc,
    bbcb,
    tc,
    tcb,
    cc,
    ccb,
    bc,
    bcb,
    tr,
    tr_,
    trb,
    trr,
    trrb,
    br,
    br_,
    brb,
  }),
  methods: {
    setPosition(position) { this.position = position; },
    select(position) { this.$emit('position', position); },
  },
};
</script>

<style scoped>
  /* TOP LEFT */
  .tl {
    height: 211px;
    width: 73px;
  }

  .tll {
    height: 117px;
    width: 48px;
  }

  /* BOTTOM LEFT */
  .bl {
    height: 172px;
    width: 74px;
  }

  /* CENTER */
  .c {
    height: 380px;
    width: 147px;
  }

  .ttc {
    height: 119px;
    width: 146px;
  }

  .bbc {
    height: 110px;
    width: 100px;
    margin-top: 10px;
  }

  .tc {
    height: 49px;
    width: 146px;
    margin-top: 75px;
  }

  .cc {
    height: 98px;
    width: 51px;
    margin-top: 10px;
  }

  .bc {
    height: 122px;
    width: 93px;
    margin-top: 10px;
  }

  /* TOP RIGHT */
  .tr {
    height: 210px;
    width: 73px;
  }

  .trr {
    height: 117px;
    width: 48px;
    margin-left: 25px;
  }

  /* BOTTOM RIGHT */
  .br {
    height: 172px;
    width: 77px;
  }
</style>
