<template>
  <div>
    <!-------------------------------------------BUTTON-------------------------------------------->
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-layout
          justify-center
          align-center
        >
          <v-btn
            outline
            color="success"
            @click="openQrScanner"
          >
            {{ $t('parts.insert_parts.repository.scan_repository') }}
          </v-btn>
          <v-btn
            icon
            @click="showRepo = !showRepo"
          >
            <v-icon v-if="showRepo">
              expand_less
            </v-icon>
            <v-icon v-else>
              expand_more
            </v-icon>
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>
    <!--------------------------------------SELECTION FIELDS--------------------------------------->
    <v-layout
      v-if="showRepo"
      wrap
    >
      <v-flex xs6>
        <v-select
          v-model="repository"
          :items="repositories"
          :label="$t('parts.insert_parts.repository.select_repository')"
          color="red darken-1"
          item-text="name"
          return-object
        />
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="room"
          :items="repository.rooms"
          :label="$t('parts.insert_parts.repository.select_room')"
          color="red darken-1"
          item-text="name"
          return-object
        />
      </v-flex>
      <v-flex xs4>
        <v-select
          v-model="location"
          :items="room.locations"
          :label="$t('parts.insert_parts.repository.select_location')"
          color="red darken-1"
          item-text="code"
          return-object
        />
      </v-flex>
      <v-flex xs4>
        <v-select
          v-model="shelf.x"
          :items="xPositions"
          :label="$t('parts.insert_parts.repository.select_x')"
          color="red darken-1"
        />
      </v-flex>
      <v-flex xs4>
        <v-select
          v-model="shelf.y"
          :items="yPositions"
          :label="$t('parts.insert_parts.repository.select_y')"
          color="red darken-1"
        />
      </v-flex>
    </v-layout>
    <!---------------------------------------SCANNER DIALOG---------------------------------------->
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
    >
      <v-card flat>
        <v-layout
          column
          wrap
        >
          <!------------------------------------CAMERA SCANNER------------------------------------->
          <v-flex
            v-if="!physicalScanner"
            xs12
          >
            <qrcode-stream
              v-if="dialog"
              :camera="constraints"
              :paused="paused"
              @decode="decode"
            />
          </v-flex>
          <!------------------------------------PHYSICAL SCANNER----------------------------------->
          <v-flex
            v-if="physicalScanner"
            xs12
          >
            <v-layout
              mt-2
              mb-2
              justify-center
            >
              <div
                v-if="found === null && !physicalScannerError"
                class="ma-3 blue--text subheading"
              >
                {{ $t('parts.insert_parts.repository.physical_scanner') }}
              </div>
              <div
                v-if="physicalScannerError"
                class="ma-3 red--text subheading"
              >
                {{ $t('parts.insert_parts.repository.physical_scanner_error') }}
              </div>
            </v-layout>
          </v-flex>
          <!--------------------------------------------------------------------------------------->
          <v-flex xs12>
            <v-layout
              mt-2
              column
              align-center
              justify-center
            >
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              />
              <span
                v-if="found === true"
                class="green--text subheading"
              >
                {{ $t('parts.insert_parts.repository.found_repository_message') }}
                {{ decodedString }}
              </span>
              <span
                v-if="found === false"
                class="red--text subheading"
              >
                {{ $t('parts.insert_parts.repository.not_found_repository_message') }}
                {{ decodedString }}
              </span>
              <span
                v-if="found === null"
                class="subheading"
              >
                {{ $t('parts.insert_parts.repository.scan_shelf_message') }}
              </span>
              <v-layout v-if="found === true">
                <v-btn
                  outline
                  color="success"
                  @click="addLocation"
                >
                  {{ $t('parts.insert_parts.repository.add_location') }}
                </v-btn>
              </v-layout>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <!--
          <v-btn
            v-if="decodedString"
            outline
            color="primary"
            @click="reScan">
            {{ $t('parts.insert_parts.repository.rescan') }}
          </v-btn>
          -->
          <v-btn
            flat
            icon
            @click="reScan"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            flat
            icon
            @click="switchScanner"
          >
            <v-icon>fas fa-qrcode</v-icon>
          </v-btn>
          <v-btn
            flat
            icon
            @click="switchCamera"
          >
            <v-icon>switch_camera</v-icon>
          </v-btn>
          <v-btn
            outline
            color="error"
            @click="close"
          >
            {{ $t('parts.insert_parts.repository.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--------------------------------------------------------------------------------------------->
  </div>
</template>

<script>
import getRequest from '../../api/getRequest';

export default {
  data: () => ({
    dialog: false,
    showRepo: false,
    front: false,
    part: {},
    repositories: [],
    repository: {},
    room: {},
    location: {},
    shelf: {},
    xPositions: [],
    yPositions: [],
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    paused: false,
    loading: false,
    found: null,
    decodedString: null,
    shelfData: {},
    physicalScanner: false,
    physicalScannerError: false,
    buffer: '',
    autocompletion: false,
  }),
  watch: {
    repository() {
      if (this.autocompletion === false) {
        this.room = {};
        this.location = {};
        this.shelf = {};
      }
    },
    room() {
      if (this.autocompletion === false) {
        this.location = {};
        this.shelf = {};
      }
    },
    location(value) {
      if (value.position_x && value.position_y) {
        this.generateCoordinates(value.position_x, value.position_y);
      }
      if (this.autocompletion === false) {
        this.shelf = {};
      }
    },
    shelf: {
      handler(value) {
        if (value.x && value.y) {
          const shelf = this.location.shelves.find(s => s.x === value.x && s.y === value.y);
          this.$emit('location', {
            repository_id: this.repository._id,
            room_id: this.room._id,
            location_id: this.location._id,
            shelf_id: shelf._id,
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    /* SCANNER ---------------------------------------------------------------------------------- */
    openQrScanner() {
      this.$emit('hide', true);
      this.dialog = true;
    },
    decode(value) {
      if (value !== null && value.length > 0) {
        this.found = null;
        this.paused = true;
        this.decodedString = value;
        this.getShelf(value);
      }
    },
    addLocation() {
      this.repository = this.shelfData.repository;
      this.room = this.repository.rooms[this.shelfData.roomIndex];
      this.location = this.room.locations[this.shelfData.locationIndex];
      this.shelf = this.shelfData.shelf;

      this.close();
    },
    reScan() {
      this.decodedString = null;
      this.found = null;
      this.loading = false;
      this.paused = false;
      this.physicalScannerError = false;
    },
    switchCamera() {
      this.physicalScanner = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.front = !this.front;
      this.constraints = {
        audio: false,
        video: { facingMode: this.front ? 'user' : 'environment' },
      };
    },
    switchScanner() {
      this.physicalScanner = true;
      document.addEventListener('keydown', this.listenToEvent);
    },
    listenToEvent(e) {
      // console.log(e);
      if (e.key !== 'Enter') {
        this.buffer += e.key;
      } else if (this.buffer.length > 24) {
        this.physicalScannerError = true;
        this.buffer = '';
      } else {
        this.decode(this.buffer);
        this.buffer = '';
      }
    },
    open(data) {
      this.part = data;
      this.getRepositories();
    },
    close() {
      this.$emit('hide', false);
      this.dialog = false;
      this.paused = false;
      this.loading = false;
      this.found = null;
      this.decodedString = null;
      this.shelfData = {};
      this.physicalScanner = false;
      this.physicalScannerError = false;
      document.removeEventListener('keydown', this.listenToEvent);
    },
    /* REPOSITORY ------------------------------------------------------------------------------- */
    getShelf(id) {
      this.loading = true;
      getRequest('/repository/shelf', `id=${id}`)
        .then((response) => {
          console.log(response);
          this.found = true;
          this.loading = false;
          this.shelfData = response;
        })
        .catch((error) => {
          console.log(error);
          this.found = false;
          this.loading = false;
        });
    },
    getRepositories() {
      getRequest(
        '/repository/',
        `role=${this.$store.getters.role}&institution_id=${
          this.$store.getters.institutionId
        }`,
      )
        .then((response) => {
          console.log(response);
          this.repositories = response.repositories;
          this.autocompleteLocation(this.repositories);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    autocompleteLocation(repositories) {
      this.autocompletion = true;
      if (this.part.repository_id) {
        this.repository = repositories.find(r => r._id === this.part.repository_id);
      }
      if (this.repository.rooms && this.part.room_id) {
        this.room = this.repository.rooms.find(r => r._id === this.part.room_id);
      }
      if (this.room.locations && this.part.location_id) {
        this.location = this.room.locations.find(l => l._id === this.part.location_id);
      }
      if (this.location.shelves && this.part.shelf_id) {
        const shelf = this.location.shelves.find(s => s._id === this.part.shelf_id);
        this.shelf = { x: shelf.x, y: shelf.y };
      }
      /**
       * Extend autocomplete duration in order to prevent watchers from clearing properties' values.
       * TODO: Find a cleaner solution.
       */
      setTimeout(() => { this.autocompletion = false; }, 100);
    },
    generateCoordinates(x, y) {
      console.log('Generating coordinates...');
      const xArr = [];
      const yArr = [];
      for (let xc = 1; xc <= x; xc += 1) {
        xArr.push(xc);
      }
      for (let yc = 1; yc <= y; yc += 1) {
        yArr.push(yc);
      }
      this.xPositions = xArr;
      this.yPositions = yArr;
    },
    /* ------------------------------------------------------------------------------------------ */
    clear() {
      this.shelfData = {};
      this.repositories = [];
      this.repository = {};
      this.room = {};
      this.location = {};
      this.shelf = {};
      this.xPositions = [];
      this.yPositions = [];
      this.dialog = false;
      this.showRepo = false;
      this.paused = false;
      this.loading = false;
      this.found = null;
      this.decodedString = null;
      this.physicalScanner = false;
      this.physicalScannerError = false;
      document.removeEventListener('keydown', this.listenToEvent);
    },
  },
};
</script>
