<template>
  <v-content>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1000px"
    >
      <v-card>
        <v-card-title class="redColor">
          <span
            class="headline"
          > {{ $t('clients.cars.add_vehicle') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
              >
                <v-autocomplete
                  v-model="brand"
                  :items="brands"
                  :loading="loadingBrand"
                  :label="$t('parts.advanced_search.brand')"
                  solo
                  flat
                  item-text="title"
                  color="red darken-1"
                  return-object
                  :append-icon="brand === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                  @click:append="brand = null"
                >
                  <template v-slot:item="{ item }">
                    <v-layout
                      row
                      wrap
                      align-center
                      justify-center
                    >
                      <v-flex
                        xs3
                        sm2
                        lg1
                      >
                        <v-img
                          :src="item.logo"
                          max-height="35"
                          max-width="35"
                        />
                      </v-flex>
                      <v-flex
                        xs9
                        sm10
                        lg11
                      >
                        <span> {{ item.title }} </span>
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-layout
                      row
                      wrap
                      align-center
                      justify-center
                    >
                      <v-flex
                        xs3
                        lg2
                      >
                        <v-img
                          :src="item.logo"
                          max-height="35"
                          max-width="35"
                        />
                      </v-flex>
                      <v-flex
                        xs9
                        lg10
                      >
                        <span> {{ item.title }} </span>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <v-autocomplete
                  v-model="model"
                  :items="models"
                  :loading="loadingModel"
                  :label="$t('parts.advanced_search.model')"
                  solo
                  flat
                  item-text="fullDescription"
                  color="red darken-1"
                  return-object
                  :append-icon="model === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                  @click:append="model = null"
                >
                  <template v-slot:item="{ item }">
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        md7
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                          <span class="body-2">
                            {{ item.description }}
                          </span>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        class="hidden-sm-and-down"
                        md5
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                          <span class="body-2">
                            {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                          </span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        md7
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                          <span class="body-2">
                            {{ item.description }}
                          </span>
                        </v-layout>
                      </v-flex>

                      <v-flex
                        class="hidden-sm-and-down"
                        md5
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                          <span class="body-2">
                            {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                          </span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="version"
                  :items="versions"
                  :loading="loadingVersion"
                  :label="$t('parts.advanced_search.version')"
                  solo
                  flat
                  item-text="fullDescription"
                  color="red darken-1"
                  return-object
                  :append-icon="version === null ? 'arrow_drop_down' : 'keyboard_backspace'"
                  @click:append="version = null"
                >
                  <template v-slot:item="{ item }">
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        md5
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                          <span class="body-2">{{ item.description }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex
                        class="hidden-sm-and-down"
                        md2
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                          <span class="body-2">
                            {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                          </span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                          <span class="body-2">{{ item.numberOfDoors }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                          <span class="body-2">
                            {{ item.power.kw }} kw,
                          </span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                          <span class="body-2">{{ item.engineCode }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">
                            {{ $t('parts.advanced_search.supply.supply') }}
                          </span>
                          <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </template>

                  <template v-slot:selection="{ item }">
                    <v-layout
                      row
                      wrap
                    >
                      <v-flex
                        xs12
                        md5
                      >
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                          <span class="body-2">{{ item.description }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                          <span class="body-2">
                            {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                          </span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                          <span class="body-2">{{ item.numberOfDoors }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                          <span class="body-2">
                            {{ item.power.kw }} kw,
                          </span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                          <span class="body-2">{{ item.engineCode }}</span>
                        </v-layout>
                      </v-flex>
                      <v-flex class="hidden-sm-and-down">
                        <v-layout
                          column
                          wrap
                        >
                          <span class="caption">
                            {{ $t('parts.advanced_search.supply.supply') }}
                          </span>
                          <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <v-text-field
                  v-model="vin"
                  :label="$t('clients.cars.vin')"
                  solo
                  flat
                  color="red darken-1"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
              >
                <v-text-field
                  v-model="notes"
                  :label="$t('clients.cars.notes')"
                  solo
                  flat
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-layout
                  align-center
                  justify-center
                >
                  <v-btn
                    color="primary"
                    @click="openSpecDialog"
                  >
                    {{ $t('clients.cars.specifications') }}
                    <v-icon right>
                      add
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout justify-center>
                  <v-list v-if="specifications && specifications.length > 0">
                    <v-list-tile
                      v-for="(item, index) in specifications"
                      :key="index"
                      @click="''"
                    >
                      <v-list-tile-content>
                        <v-list-tile-title> {{ item.key }} : {{ item.value }} </v-list-tile-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <v-btn
                          icon
                          @click="removeSpec(item.index)"
                        >
                          <v-icon>remove_circle_outline</v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            flat
            @click.native="close"
          >
            {{ $t('clients.form.close') }}
          </v-btn>
          <v-btn
            :disabled="!brand || !model || !version"
            color="blue darken-1"
            flat
            @click.native="save"
          >
            {{ $t('clients.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="specDialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="subheading"> {{ $t('clients.cars.add_specifications') }} </span>
        </v-card-title>
        <v-card-text>
          <v-layout
            row
            wrap
            justify-center
          >
            <v-flex
              xs6
              pr-2
            >
              <v-text-field
                v-model="specification.key"
                color="red darken-1"
                :label="$t('clients.cars.name')"
              />
            </v-flex>
            <v-flex
              xs6
              pl-2
            >
              <v-text-field
                v-model="specification.value"
                color="red darken-1"
                :label="$t('clients.cars.value')"
              />
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="red darken-1"
            flat
            @click.native="closeSpecDialog"
          >
            {{ $t('clients.form.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            flat
            @click.native="saveSpec"
          >
            {{ $t('clients.form.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    dialog: false,
    specDialog: false,
    client: null,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    brand: null,
    brands: [],
    model: null,
    models: [],
    version: null,
    versions: [],
    vin: '',
    notes: '',
    specification: { key: null, value: null },
    specifications: [],
  }),
  watch: {
    brand(value) {
      if (value) {
        this.models = [];
        this.model = null;
        this.versions = [];
        this.version = null;
        this.getModels(value.brandCode);
      }
    },
    model(value) {
      if (value) {
        this.versions = [];
        this.version = null;
        this.getVersions(value.brandCode, value.modelCode);
      }
    },
  },
  methods: {
    open(client) {
      console.log(client);
      this.dialog = true;
      this.getBrands();
    },
    close() {
      this.dialog = false;
      this.client = null;
      this.brand = null;
      this.brand = [];
      this.model = null;
      this.models = [];
      this.version = null;
      this.versions = [];
      this.vin = '';
      this.notes = '';
      this.specification = { key: null, value: null };
      this.specifications = [];
      this.$emit('close');
    },
    save() {
      this.$emit('save', {
        brand: this.brand,
        model: this.model,
        version: this.version,
        vin: this.vin,
        notes: this.notes,
        specifications: this.specifications,
      });
      this.close();
    },
    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => { this.models = response.models; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => { this.versions = response.versions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },
    openSpecDialog() {
      this.specDialog = true;
    },
    closeSpecDialog() {
      this.specDialog = false;
      this.specification = { key: null, value: null };
    },
    saveSpec() {
      this.specifications.push(this.specification);
      this.closeSpecDialog();
    },
    removeSpec(index) {
      this.specifications.splice(index, 1);
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },
  },
};
</script>
