<template>
  <v-container
    fluid
    class="red darken-3 ma-0"
  >
    <!---------------------------------------REGULAR SEARCH---------------------------------------->
    <v-layout
      row
      wrap
      align-center
      justify-center
    >
      <v-flex
        xs12
        sm6
        md4
        :class="{ 'pr-1': $vuetify.breakpoint.smAndUp }"
      >
        <!--SEARCH BY TITLE-->
        <v-autocomplete
          v-if="searchType === 'title'"
          v-model="titleCode"
          :loading="loadingTitle"
          :items="titles"
          :search-input.sync="searchTitle"
          :label="$t('reservations.parts.search')"
          :item-text="`title.${$store.getters.language}`"
          item-value="partCode"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          hide-no-data
          class="ml-2 mr-2"
          @keyup.enter="search()"
          @click:append="search()"
        />
        <!--SEARCH FIELD-->
        <v-text-field
          v-else
          v-model="searchField"
          :label="$t('reservations.parts.search')"
          flat
          solo
          color="grey darken-4"
          append-icon="search"
          single-line
          hide-details
          class="ml-2 mr-2"
          @keyup.enter="search()"
          @click:append="search()"
        />
      </v-flex>

      <v-flex
        xs12
        sm3
        md2
        xl1
        :class="{ 'pl-1': $vuetify.breakpoint.smAndUp }"
      >
        <v-select
          v-model="searchType"
          :items="searchTypes"
          item-text="label"
          item-value="value"
          color="grey darken-4"
          solo
          flat
          hide-details
          class="ml-2 mr-2"
          :class="{ 'mt-2': $vuetify.breakpoint.xsOnly }"
        />
      </v-flex>
    </v-layout>
    <!---------------------------------------ADVANCED SEARCH--------------------------------------->
    <v-layout
      row
      color="red darken-3"
      class="pb-2"
      wrap
    >
      <!-------------------------------------------BRAND------------------------------------------->
      <v-flex
        xs12
        sm6
        xl3
      >
        <v-autocomplete
          v-model="brand"
          :items="brands"
          :label="$t('parts.advanced_search.brand')"
          item-text="title"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
          return-object
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                xs3
                sm2
                lg1
              >
                <v-img
                  :src="item.logo"
                  max-height="35"
                  max-width="35"
                />
              </v-flex>
              <v-flex
                xs9
                sm10
                lg11
              >
                <span> {{ item.title }} </span>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
              align-center
              justify-center
            >
              <v-flex
                xs3
                lg2
              >
                <v-img
                  :src="item.logo"
                  max-height="35"
                  max-width="35"
                />
              </v-flex>
              <v-flex
                xs9
                lg10
              >
                <span> {{ item.title }} </span>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <!-------------------------------------------MODEL------------------------------------------->
      <v-flex
        xs12
        sm6
        xl3
      >
        <v-autocomplete
          v-model="model"
          :items="models"
          :label="$t('parts.advanced_search.model')"
          item-text="fullDescription"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
          return-object
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md7
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                  <span class="body-2">
                    {{ item.description }}
                  </span>
                </v-layout>
              </v-flex>

              <v-flex
                class="hidden-sm-and-down"
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md7
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.model') }}</span>
                  <span class="body-2">
                    {{ item.description }}
                  </span>
                </v-layout>
              </v-flex>

              <v-flex
                class="hidden-sm-and-down"
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <!------------------------------------------VERSION------------------------------------------>
      <v-flex
        xs12
        xl6
      >
        <v-autocomplete
          v-model="version"
          :items="versions"
          :label="$t('parts.advanced_search.version')"
          item-text="version"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
          return-object
        >
          <template v-slot:item="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ item.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex
                class="hidden-sm-and-down"
                md2
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ item.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ item.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ item.chassisCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>

          <template v-slot:selection="{ item }">
            <v-layout
              row
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.version') }}</span>
                  <span class="body-2">{{ item.description }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.year') }}</span>
                  <span class="body-2">
                    {{ item.startingDate | moment }} - {{ item.endingDate | moment }}
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.body') }}</span>
                  <span class="body-2">{{ item.numberOfDoors }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.power') }}</span>
                  <span class="body-2">
                    {{ item.power.kw }} kw,
                  </span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.engine') }}</span>
                  <span class="body-2">{{ item.chassisCode }}</span>
                </v-layout>
              </v-flex>
              <v-flex class="hidden-sm-and-down">
                <v-layout
                  column
                  wrap
                >
                  <span class="caption">{{ $t('parts.advanced_search.supply.supply') }}</span>
                  <span class="body-2">{{ getSupplyLocale(item.supplyCode) }}</span>
                </v-layout>
              </v-flex>
            </v-layout>
          </template>
        </v-autocomplete>
      </v-flex>
      <!---------------------------------------INSTITUTIONS---------------------------------------->
      <v-flex
        v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee'"
      >
        <v-autocomplete
          v-model="advancedSearchItems.institution"
          :items="institutions"
          :loading="loadingInstitution"
          :label="$t('parts.advanced_search.institution')"
          item-text="title"
          item-value="_id"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
        />
      </v-flex>
      <!-----------------------------------------SHIPMENTS----------------------------------------->
      <v-flex>
        <v-autocomplete
          v-model="advancedSearchItems.shipment"
          :items="shipments"
          :loading="loadingShipment"
          :label="$t('parts.advanced_search.shipment')"
          item-text="name"
          item-value="_id"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
          hide-details
        />
      </v-flex>
      <!------------------------------------------STATUS------------------------------------------->
      <v-flex>
        <v-select
          v-model="advancedSearchItems.status"
          :items="statusItems"
          :label="$t('parts.advanced_search.status')"
          :hint="$t('parts.advanced_search.select_status')"
          item-text="title"
          item-value="value"
          solo
          flat
          chips
          deletable-chips
          multiple
          color="red darken-2"
          class="ml-2 mr-2 mt-2"
          persistent-hint
        />
      </v-flex>
      <!------------------------------------------QUALITY------------------------------------------>
      <v-flex
        xs12
      >
        <v-select
          v-model="advancedSearchItems.quality"
          :items="qualityItems"
          :loading="loadingQuality"
          :label="$t('parts.advanced_search.quality')"
          :hint="$t('parts.advanced_search.select_quality')"
          item-value="_id"
          item-text="title"
          solo
          flat
          chips
          multiple
          color="red darken-2"
          class="ml-2 mr-2 mt-2"
          persistent-hint
        >
          <template v-slot:item="{ item }">
            <v-chip small>
              <v-rating
                v-model="item.rating"
                :color="advancedSearchItems.quality.includes(item._id) ? 'red': 'grey'"
                small
                :background-color="
                  advancedSearchItems.quality.includes(item._id) ? 'red': 'grey'
                "
                readonly
              />
            </v-chip>
          </template>

          <template v-slot:selection="{ item }">
            <v-chip small>
              <v-rating
                v-model="item.rating"
                color="red"
                small
                background-color="red"
                readonly
              />
            </v-chip>
          </template>
        </v-select>
      </v-flex>
      <!-------------------------------------------PRICE------------------------------------------->
      <v-flex
        xs12
        md6
      >
        <v-layout
          row
          align-center
          justify-center
          wrap
        >
          <v-flex
            xs8
            sm9
            xl10
          >
            <v-range-slider
              v-model="advancedSearchItems.price"
              :label="$t('parts.advanced_search.price_range')"
              :max="50000"
              :min="0"
              :step="1000"
              color="grey darken-4"
              class="pl-2 pr-3"
            />
          </v-flex>
          <v-flex
            xs4
            sm3
            xl2
          >
            <span class="pl-3 pr-2">
              {{ advancedSearchItems.price[0] }} - {{ advancedSearchItems.price[1] }}
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <!-------------------------------------------DATE-------------------------------------------->
      <!--STARTING DATE-->
      <v-flex
        xs12
        sm6
        md3
      >
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="advancedSearchItems.startDate"
              :label="$t('parts.advanced_search.starting_date')"
              :hint="$t('parts.advanced_search.select_starting_date')"
              solo
              flat
              color="black"
              persistent-hint
              prepend-icon="event"
              readonly
              class="ml-2 mr-2"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="advancedSearchItems.startDate"
            color="red darken-3"
            @input="startDateMenu = false"
          />
        </v-menu>
      </v-flex>
      <!--ENDING DATE-->
      <v-flex
        xs12
        sm6
        md3
      >
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          lazy
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="advancedSearchItems.endDate"
              :label="$t('parts.advanced_search.ending_date')"
              :hint="$t('parts.advanced_search.select_ending_date')"
              solo
              flat
              color="black"
              persistent-hint
              prepend-icon="event"
              readonly
              class="ml-2 mr-2"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="advancedSearchItems.endDate"
            color="red darken-3"
            @input="endDateMenu = false"
          />
        </v-menu>
      </v-flex>
    </v-layout>
    <!--------------------------------------------------------------------------------------------->
    <v-layout
      align-center
      justify-center
      row
      wrap
    >
      <v-btn
        dark
        color="grey darken-4"
        @click="clear"
      >
        {{ $t('parts.advanced_search.clear') }}
        <v-icon right>
          clear_all
        </v-icon>
      </v-btn>
      <v-btn
        dark
        color="grey darken-4"
        @click="search"
      >
        {{ $t('parts.advanced_search.search') }}
        <v-icon right>
          search
        </v-icon>
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import moment from 'moment';
import getRequest from '../../../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const valid = moment(date).isValid();
      if (valid) return moment(date).format('MM/YYYY');
      return '';
    },
  },
  data: () => ({
    searchTypes: [
      { label: 'Title', value: 'title' },
      { label: 'ID', value: 'id' },
      { label: 'Other', value: 'other' },
    ],
    searchType: 'title',
    searchField: '',
    searchTitle: null,
    titleCode: null,
    titles: [],
    brands: [],
    models: [],
    versions: [],
    brand: null,
    model: null,
    version: null,
    advancedSearchItems: {
      brand: null,
      model: null,
      version: null,
      institution: null,
      shipment: null,
      status: [1, 2, 2.1, 3, 4],
      quality: [],
      price: [0, 50000],
      startDate: '',
      endDate: '',
    },
    statusItems: [
      { title: 'Entered', value: 1 },
      { title: 'Returned', value: 2 },
      { title: 'Returned-Shop', value: 2.1 },
      { title: 'Re-Entered', value: 3 },
      { title: 'Found', value: 4 },
    ],
    qualityItems: [],
    institutions: [],
    shipments: [],
    loadingTitle: false,
    loadingBrand: false,
    loadingModel: false,
    loadingVersion: false,
    loadingQuality: false,
    loadingInstitution: false,
    loadingShipment: false,
    startDateMenu: false,
    endDateMenu: false,
  }),
  watch: {
    searchTitle(value) {
      if (value) {
        this.loadingTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.titles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingTitle = false; });
      } else {
        this.titleCode = null;
      }
    },
    'advancedSearchItems.institution': {
      handler(value) {
        if (value) this.getShipments(value);
      },
    },
    brand(value) {
      if (value) {
        this.models = [];
        this.model = null;
        this.versions = [];
        this.version = null;
        this.getModels(value.brandCode);
        this.advancedSearchItems.brand = value.brandCode;
      }
    },
    model(value) {
      if (value) {
        this.versions = [];
        this.version = null;
        this.getVersions(value.brandCode, value.modelCode);
        this.advancedSearchItems.model = value.modelCode;
      }
    },
    version(value) {
      if (value) { this.advancedSearchItems.version = value.versionCode; }
    },
  },
  mounted() {
    this.getLocale();
    this.getQualities();
    this.getBrands();
    if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee') {
      this.getInstitutions();
    } else {
      this.getShipments(this.$store.getters.institutionId);
    }
  },
  methods: {
    search() {
      this.$emit('search', {
        type: this.searchType,
        searchField: this.searchField,
        titleCode: this.titleCode,
        advancedSearchFields: this.advancedSearchItems,
      });
    },
    clear() {
      this.searchField = '';
      this.advancedSearchItems = {
        brand: null,
        model: null,
        version: null,
        institution: null,
        shipment: null,
        status: [1, 2, 2.1, 3, 4],
        quality: [],
        price: [0, 50000],
        startDate: '',
        endDate: '',
      };
      this.brand = null;
      this.model = null;
      this.version = null;
      this.qualityItems.forEach(quality => this.advancedSearchItems.quality.push(quality._id));
      this.$emit('clear');
    },
    getLocale() {
      this.searchTypes[0].label = this.$i18n.t('reservations.parts.title');
      this.searchTypes[1].label = this.$i18n.t('reservations.parts.id');
      this.searchTypes[2].label = this.$i18n.t('reservations.parts.other');
      this.statusItems[0].title = this.$i18n.t('parts.status.entered');
      this.statusItems[1].title = this.$i18n.t('parts.status.returned');
      this.statusItems[2].title = this.$i18n.t('parts.status.returned_shop');
      this.statusItems[3].title = this.$i18n.t('parts.status.re_entered');
      this.statusItems[4].title = this.$i18n.t('parts.status.found');
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.advanced_search.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.advanced_search.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.advanced_search.supply.gas');
        default:
          return supply;
      }
    },

    getBrands() {
      this.loadingBrand = true;
      getRequest('/info-car/brands')
        .then((response) => { this.brands = response.brands; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingBrand = false; });
    },
    getModels(brandCode) {
      this.loadingModel = true;
      getRequest('/info-car/models', `brand_code=${brandCode}`)
        .then((response) => { this.models = response.models; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingModel = false; });
    },
    getVersions(brandCode, modelCode) {
      this.loadingVersion = true;
      getRequest('/info-car/versions', `brand_code=${brandCode}&model_code=${modelCode}`)
        .then((response) => { this.versions = response.versions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingVersion = false; });
    },

    getQualities() {
      this.loadingQuality = true;
      getRequest('/quality/').then((response) => {
        console.log(response);
        this.qualityItems = response.qualities;
        this.advancedSearchItems.quality = [];
        this.qualityItems.forEach(quality => this.advancedSearchItems.quality.push(quality._id));
        this.loadingQuality = false;
      }).catch((error) => {
        console.log(error);
        this.loadingQuality = false;
      });
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/').then((response) => {
        console.log(response);
        this.institutions = response.institutions;
        this.loadingInstitution = false;
      }).catch((error) => {
        console.log(error);
        this.loadingInstitution = false;
      });
    },
    getShipments(institutionId) {
      this.loadingShipment = true;
      getRequest('/shipment/institution', `id=${institutionId}`).then((response) => {
        console.log(response);
        this.shipments = response.shipments;
        this.loadingShipment = false;
      }).catch((error) => {
        console.log(error);
        this.loadingShipment = false;
      });
    },
  },
};
</script>
