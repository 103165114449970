<template>
  <div>
    <!---------------------------------------- MAIN DIALOG ---------------------------------------->
    <v-dialog
      v-model="mainDialog"
      max-width="800"
      persistent
    >
      <v-card>
        <!-- TITLE BAR -->
        <v-card-title class="red darken-2">
          <span
            v-if="vehicle && vehicle.brand && vehicle.model"
            class="headline"
          >
            {{ $i18n.t('vehicle_entry.parts_list.label') }}
            {{ vehicle.brand.title }}
            {{ vehicle.model.fullDescription }}
          </span>
          <span
            v-else
            class="headline"
          >
            {{ $i18n.t('vehicle_entry.parts_list.label') }}
          </span>

          <v-spacer />

          <v-btn
            outline
            icon
            large
            color="black"
            @click="closeMainDialog"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <!-- CONTENT -->
        <v-card-text>
          <!-- Loading -->
          <v-layout
            v-if="loading"
            align-center
            justify-center
          >
            <v-progress-circular
              :size="50"
              color="red"
              indeterminate
            />
          </v-layout>
          <!-- Previous lists -->
          <v-layout
            v-if="partsList && partsList.previousLists && partsList.previousLists.length"
            column
            justify-center
          >
            <v-flex
              v-for="item in partsList.previousLists"
              :key="item._id"
            >
              <v-divider />
              <v-layout
                align-center
                justify-center
                wrap
              >
                <v-flex xs8>
                  {{ item.date_created | fullDate }}
                </v-flex>
                <v-flex xs2>
                  <v-layout row>
                    <v-btn
                      icon
                      @click="openPreviousListDialog(item.list)"
                    >
                      <v-icon> list </v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      @click="print(vehicle, item.list, partsList.notes)"
                    >
                      <v-icon> print </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!-- View List -->
          <v-layout
            v-if="!loading"
            align-center
            justify-center
          >
            <v-btn
              color="red darken-1"
              @click="openCurrentListDialog"
            >
              {{ $i18n.t('vehicle_entry.parts_list.view_list') }}
            </v-btn>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            flat
            @click="closeMainDialog"
          >
            {{ $t('vehicle_entry.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!---------------------------------------- LIST DIALOG ---------------------------------------->
    <v-dialog
      v-model="listDialog"
      max-width="800"
      persistent
    >
      <v-card>
        <!-- TITLE BAR -->
        <v-card-title class="red darken-2">
          <span
            v-if="vehicle && vehicle.brand && vehicle.model"
            class="headline"
          >
            {{ $i18n.t('vehicle_entry.parts_list.label') }}
            {{ vehicle.brand.title }}
            {{ vehicle.model.fullDescription }}
          </span>
          <span
            v-else
            class="headline"
          >
            {{ $i18n.t('vehicle_entry.parts_list.label') }}
          </span>

          <v-spacer />

          <v-btn
            outline
            icon
            large
            color="black"
            @click="closeListDialog"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-card-title>
        <!-- CONTENT -->
        <v-card-text>
          <!-- List -->
          <v-layout
            column
            justify-center
          >
            <v-flex
              v-for="(item, index) in partsList.list"
              :key="index"
            >
              <v-divider />
              <v-layout
                align-center
                wrap
              >
                <v-flex
                  xs2
                  sm1
                >
                  <v-checkbox
                    v-model="item.selected"
                    :disabled="disabled"
                    class="mt-2"
                    color="red darken-1"
                  />
                  <!-- :disabled="disabled || item.selectedPreviously" -->
                </v-flex>

                <v-flex
                  xs10
                  sm11
                  md5
                >
                  <div
                    v-if="item.category"
                    class="caption"
                  >
                    {{ item.category.it }}
                  </div>

                  <div class="subheading">
                    <!-- :style="item.selectedPreviously
                    ? { 'text-decoration-line': 'line-through' } : {} "
                    -->

                    {{ item.title.it }}
                  </div>
                </v-flex>

                <v-flex
                  xs12
                  md6
                >
                  <v-text-field
                    v-model="item.notes"
                    :disabled="disabled"
                    :label="$t('vehicle_entry.parts_list.notes')"
                    color="red darken-1"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <!--Comment-->
          <v-textarea
            v-model="partsList.notes"
            :disabled="disabled"
            :label="$t('vehicle_entry.parts_list.notes')"
            color="red darken-1"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            :disabled="!partsList.list || partsList.list.length === 0"
            flat
            @click="print(vehicle, partsList.list, partsList.notes)"
          >
            {{ $t('vehicle_entry.parts_list.print') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="error"
            flat
            @click="closeListDialog"
          >
            {{ $t('vehicle_entry.close') }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="disabled"
            flat
            @click="save"
          >
            {{ $t('vehicle_entry.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import QrCode from 'qrcode';
import Printd from 'printd';
// import { Printd } from 'printd';
import getRequest from '../../api/getRequest';

export default {
  filters: {
    fullDate(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  data: () => ({
    mainDialog: false,
    listDialog: false,
    loading: false,
    disabled: false,
    vehicle: null,
    partsList: { previousLists: null, notes: null, list: null },
    _currentList: null,
    previousListMode: false,
  }),
  methods: {
    open(vehicle, partsList, disabled) {
      this.mainDialog = true;
      this.vehicle = vehicle;
      if (!partsList || !partsList.list || partsList.list.length === 0) this.getPartsList();
      else this.partsList = partsList;
      if (disabled) this.disabled = true;
    },
    closeMainDialog() {
      this.mainDialog = false;
      this.vehicle = null;
      this.partsList = { previousLists: null, notes: null, list: null };
      this.disabled = false;
      this.$emit('close');
    },
    openCurrentListDialog() {
      this.mainDialog = false; // Hide main dialog.
      this.listDialog = true;
      this.partsList.list.forEach((item, index) => { // Set the selected items as prev. selected.
        if (item.selected) {
          this.partsList.list[index].selectedPreviously = true;
        }
      });
      this._currentList = this.partsList.list.map(item => item); // Copy current list.
    },
    openPreviousListDialog(list) {
      this.mainDialog = false;
      this.listDialog = true;
      this.disabled = true;
      this._currentList = this.partsList.list.map(item => item); // Copy current list.
      this.partsList.list = list;
    },
    closeListDialog() {
      this.listDialog = false;
      this.mainDialog = true;
      this.partsList.list = this._currentList;
      this._currentList = null;
      this.disabled = false;
    },
    save() {
      if (!this.partsList.previousLists) this.partsList.previousLists = [];
      this.partsList.previousLists.push({ list: this._currentList });

      this.$emit('save', { ...this.partsList });
      this.closeListDialog();
      this.closeMainDialog();
    },
    print(vehicle, list, notes) {
      QrCode.toDataURL(vehicle._id, (error, qrCode) => {
        const qrImg = document.createElement('img');
        qrImg.setAttribute('src', qrCode);
        qrImg.style.width = '100px';
        qrImg.style.height = '100px';

        const qrId = document.createElement('div');
        qrId.textContent = vehicle._id.slice(vehicle._id.length - 6, vehicle._id.length);
        qrId.setAttribute('class', 'qrId');

        const img = document.createElement('div');
        img.setAttribute('class', 'img');
        img.appendChild(qrImg);

        const row1 = document.createElement('div');
        row1.setAttribute('class', 'row1');
        row1.appendChild(qrId);

        const row2 = document.createElement('div');
        row2.setAttribute('class', 'row2');
        row2.innerHTML = `
        <div> ${vehicle.brand.title} </div>
        <div>
          <span> ${vehicle.model.fullDescription} </span>
          <span> ${vehicle.version.fullDescription} </span>
        </div>
      `;

        const wrapper = document.createElement('div');
        wrapper.setAttribute('class', 'wrapper');
        wrapper.appendChild(img);
        wrapper.appendChild(row1);
        wrapper.appendChild(row2);

        const table = document.createElement('table');
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');
        list.forEach((element) => {
          const row = document.createElement('tr');
          row.innerHTML = `
          <th> <input type="checkbox" ${element.selected ? 'checked' : ''}> </th>
          <th> <span class="t ${element.selectedPreviously ? 'lt' : ''}">${element.title.it || ''}</span> </th>
          <th> <span class="t">${element.notes || ''}</span> </th>
        `;
          tbody.appendChild(row);
        });
        thead.innerHTML = `
        <tr>
          <th> <span class="h"> Selezionato </span> </th>
          <th> <span class="h"> Titolo </span> </th>
          <th> <span class="h"> Note </span> </th>
        </tr>
      `;
        table.appendChild(thead);
        table.appendChild(tbody);

        const notesDiv = document.createElement('div');
        notesDiv.innerHTML = `
        <br>
        <div class="h"> Note: </div>
        <div class="t"> ${notes || ''} </div>
      `;

        const content = document.createElement('body');
        content.appendChild(wrapper);
        content.appendChild(table);
        content.appendChild(notesDiv);

        const css = `
        @media print {
          @page {
            size: A4;
            margin: 10px 0px 10px 0px;
          }

          body {
            -webkit-print-color-adjust: exact !important;
            font-family: "Roboto";
          }

          table {
            width: 100%;
          }

          thead {
            background-color: #C5C8D7;
          }

          thead th:nth-child(1) {
            width: 5%;
          }
          thead th:nth-child(2) {
            width: 45%;
          }
          thead th:nth-child(3) {
            width: 50%;
          }

          tbody tr:nth-child(odd) {
            background-color: #E0E1EB;
          }
          tbody tr:nth-child(even) {
            background-color: #C5C8D7;
          }

          th, td {
            text-align: left;
            padding: 0.2em;
          }

          .img {
            display: flex;
            justify-content: center;
          }
          .row1 {
            text-align: center;
            font-weight: bold;
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 20px;
          }
          .row2 {
            margin-bottom: 10px;
            font-size: 24px;
          }

          .t {
            font-size: 14px;
            font-weight: bold;
          }

          .h {
            font-size: 16px;
            font-weight: bold;
          }

          .lt {
            text-decoration-line: line-through;
          }
        }`;
        const d = new Printd();
        d.print(content, [css, 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons']);
      });
    },
    getPartsList() {
      this.loading = true;
      getRequest('/car/part-list')
        .then((response) => { this.partsList.list = response; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
  },
};
</script>
